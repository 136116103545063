import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import EqualizerIcon from '@mui/icons-material/Equalizer'
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import {
  AppBar,
  Box,
  IconButton,
  SxProps,
  Tab,
  Tabs,
  Theme,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material'
import { ReactNode, useContext, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import theme from '../../theme'
import StandardButton from '../buttons/StandardButton'

import Stack from '@mui/material/Stack'
import { BarChart, pieArcLabelClasses, PieChart } from '@mui/x-charts'
import { MdOutlineFullscreen, MdSplitscreen } from 'react-icons/md'
import CommonValue from '../../contexts/CommonValue'
import { useLoginHook } from '../../hooks/useLogin'
import useTranslation from '../../hooks/useTranslation'
import {
  getFormattedDate,
  handleBackClick,
  handleCopyClick,
} from '../../lib/Utility'
import { IhcImageUploader } from './IhcImageUploder'
import { Properties } from './Properties'

type Props = {
  children: ReactNode
  id: number | undefined
  uuid: string | undefined
  directory_id: number | undefined
  specimen_id: string
  infoData: InforDataElement[] | string
  date_of_acquisition: Date | null
  carcinoma: string
  person_in_charge: string
  note: string
  handleContentChange: (type: 'ROI' | 'UMAP') => void
}

type InforDataElement = {
  head: string
  items: dataElementItem[]
}

type dataElementItem = {
  label: string
  value: string
}

type tabsTypes = 'ROI' | 'UMAP' | 'STATS' | 'INFO' | 'UPLOAD'
type tabsStates = {
  [key in tabsTypes]: {
    isActivated: boolean
    id: number
  }
}

export default function Sidebar({
  children,
  id,
  uuid,
  directory_id,
  specimen_id,
  infoData,
  date_of_acquisition,
  carcinoma,
  person_in_charge,
  note,
  handleContentChange,
}: Props): JSX.Element {
  const navigate = useNavigate()
  const [tabsControl, setTabsControl] = useState<tabsStates>({
    ROI: {
      isActivated: false,
      id: 0,
    },
    UMAP: {
      isActivated: false,
      id: 1,
    },
    STATS: {
      isActivated: false,
      id: 2,
    },
    INFO: {
      isActivated: false,
      id: 3,
    },
    UPLOAD: {
      isActivated: false,
      id: 4,
    },
  })
  // State management of the "Edit File Properties" dialog
  const [editPropDialogOpen, setEditPropDialogOpen] = useState<boolean>(false)
  const { globalLanguage, userFeatures } = useContext(CommonValue)
  const t = useTranslation(globalLanguage)
  const [graphData, setGraphData] = useState<GraphData[] | []>([])
  // state for width of side bar stats graphs
  const [width, setWidth] = useState(500)
  // Switch to the tab with the selected icon.

  const { checkUserFeatures } = useLoginHook()

  const handleTabSelect = (tab: tabsTypes) => {
    const newTabsControl = { ...tabsControl }
    for (const key in newTabsControl) {
      newTabsControl[key as tabsTypes].isActivated = false
    }
    newTabsControl[tab].isActivated = true
    setTabsControl(newTabsControl)
  }

  const handleResetTabs = () => {
    const newTabsControl = { ...tabsControl }
    for (const key in newTabsControl) {
      newTabsControl[key as tabsTypes].isActivated = false
    }
    setTabsControl(newTabsControl)
  }

  function handleEditButton(): void {
    setEditPropDialogOpen(true)
  }

  function handleEeditPropDialogClose(): void {
    setEditPropDialogOpen(false)
  }

  const getPieData = (itemData: InforDataElement) => {
    const pieData = itemData.items.map((cell) => {
      return {
        id: cell.label,
        value: parseFloat(cell.value),
        label: cell.label,
      }
    })

    return {
      type: 'pie',
      title: itemData.head,
      data: pieData,
    }
  }

  const getBarsData = (itemData: InforDataElement) => {
    const labels = itemData.items.map((cell) => cell.label)
    const barsData = itemData.items.map((cell) => {
      return parseFloat(cell.value)
    })

    return {
      type: 'bar',
      title: itemData.head,
      data: {
        labels,
        barsData,
      },
    }
  }

  useEffect(() => {
    if (infoData !== undefined && typeof infoData === 'object') {
      const graphDataFormed = infoData
        .map(({ head, items }) => {
          const returnElements = []
          if (head !== 'Tissue') {
            returnElements.push(getBarsData({ head, items }))
          }
          if (head === 'Cell' || head === 'Tissue') {
            const itemsConverted = convertValuesToPorcentage(items)
            returnElements.push(getPieData({ head, items: itemsConverted }))
          }
          return returnElements
        })
        .flat()
      setGraphData(graphDataFormed as GraphData[])
    }
  }, [infoData])

  useEffect(() => {
    const isUserFeaturesNull = Object.keys(userFeatures).every((feature) => {
      return userFeatures[feature as keyof typeof userFeatures] === null
    })
    if (isUserFeaturesNull) {
      checkUserFeatures()
    }
  }, [userFeatures])

  const convertValuesToPorcentage = (values: dataElementItem[]) => {
    const total = values.reduce((acc, curr) => acc + parseFloat(curr.value), 0)
    return values.map((value) => {
      return {
        label: value.label,
        value: ((parseFloat(value.value) / total) * 100).toFixed(2),
      }
    })
  }

  const isRoiMapAllowed = userFeatures.spatial_transcriptomics
  const isUploadIhcImageAllowed = userFeatures.ihc_registration
  const tabValue = Object.values(tabsControl).find((tab) => tab.isActivated)
  const isSideBarTab =
    tabValue?.id !== tabsControl.ROI.id && tabValue?.id !== tabsControl.UMAP.id
      ? tabValue?.id
      : null
  return (
    <Box>
      <AppBar sx={appBarStyle}>
        <Toolbar>
          <IconButton
            edge='start'
            color='inherit'
            aria-label='back'
            onClick={() => handleBackClick(navigate)}
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography sx={titleStyle}>{specimen_id}</Typography>
          <Tooltip title='Copy URL'>
            <IconButton
              edge='start'
              color='inherit'
              aria-label='back'
              onClick={() => handleCopyClick(uuid)}
            >
              <ContentCopyIcon />
            </IconButton>
          </Tooltip>
        </Toolbar>
      </AppBar>
      <Box sx={boxStyle}>
        <Tabs value={tabValue?.id ?? 0} orientation='vertical' sx={tabsStyle}>
          {
            // If the user has the permission to view the ROI map, display the icon.
            isRoiMapAllowed && (
              <Tab
                icon={<MdOutlineFullscreen size={24} />}
                onClick={() => {
                  handleContentChange('ROI')
                  handleTabSelect('ROI')
                }}
                sx={tabStyle}
                tabIndex={tabsControl.ROI.id}
              />
            )
          }
          {isRoiMapAllowed && (
            <Tab
              icon={<MdSplitscreen size={24} />}
              onClick={() => {
                handleTabSelect('UMAP')
                handleContentChange('UMAP')
              }}
              sx={tabStyle}
              tabIndex={tabsControl.UMAP.id}
            />
          )}
          <Tab
            icon={<EqualizerIcon />}
            onClick={() => {
              handleContentChange('ROI')
              handleTabSelect('STATS')
            }}
            sx={tabStyle}
            tabIndex={tabsControl.STATS.id}
          />
          <Tab
            icon={<InfoOutlinedIcon />}
            onClick={() => handleTabSelect('INFO')}
            sx={tabStyle}
            tabIndex={tabsControl.INFO.id}
          />
          {
            // If the user has the permission to view the ROI map, display the icon.
            isUploadIhcImageAllowed && (
              <Tab
                icon={<FileCopyOutlinedIcon />}
                onClick={() => handleTabSelect('UPLOAD')}
                sx={tabStyle}
              />
            )
          }
        </Tabs>
        <TabPanel
          isFullWidth={true}
          value={isSideBarTab ?? null}
          index={tabsControl.STATS.id}
          sx={scrollableTabPanelStyle}
          isResizable={true}
          handleSetWidth={setWidth}
        >
          {typeof infoData === 'string' && <Typography>{infoData}</Typography>}
          {typeof infoData === 'object' ? (
            <Stack
              height={'100%'}
              direction={'column'}
              alignItems={'center'}
              justifyContent={'space-between'}
              position={'relative'}
              zIndex={100}
            >
              {graphData.map((graph) => {
                let graph_title = ''
                if (graph.title === 'Genetic Mutation') {
                  graph_title = 'Genetic Mutation (%)'
                } else if (graph.type === 'pie') {
                  graph_title = graph.title + ' (%)'
                } else if (graph.type === 'bar' && graph.title === 'Cell') {
                  graph_title = 'Cell (counts)'
                }
                if (graph.type === 'bar') {
                  const maxValue = Math.max(...graph.data.barsData)
                  return (
                    <Box key={graph_title}>
                      <Typography textAlign={'center'} pb={2}>
                        {graph_title}
                      </Typography>
                      <BarChart
                        xAxis={[
                          {
                            scaleType: 'band',
                            data: graph.data.labels,
                            min: 0,
                            max: 100,
                          },
                        ]}
                        yAxis={[
                          {
                            scaleType: 'linear',
                            min: 0,
                            max: maxValue,
                          },
                        ]}
                        series={[
                          {
                            data: graph.data.barsData,
                          },
                        ]}
                        width={width}
                        height={width / 2}
                      />
                    </Box>
                  )
                } else if (graph.type === 'pie') {
                  return (
                    <Box key={graph_title}>
                      <Typography textAlign={'center'} pb={2}>
                        {graph_title}
                      </Typography>
                      <PieChart
                        margin={{
                          bottom: 100,
                        }}
                        slotProps={{
                          legend: {
                            direction: 'row',
                            position: {
                              vertical: 'bottom',
                              horizontal: 'middle',
                            },
                          },
                        }}
                        series={[
                          {
                            arcLabel: 'value',
                            data: graph.data,
                            highlightScope: {
                              faded: 'global',
                              highlighted: 'item',
                            },
                            faded: {
                              innerRadius: 30,
                              additionalRadius: -30,
                              color: 'gray',
                            },
                          },
                        ]}
                        sx={{
                          [`& .${pieArcLabelClasses.root}`]: {
                            fill: 'white',
                            fontWeight: 'bold',
                          },
                        }}
                        width={width}
                        height={width / 2 + 100}
                      />
                    </Box>
                  )
                }
              })}
            </Stack>
          ) : null}
        </TabPanel>
        <TabPanel
          value={tabValue?.id ?? 0}
          index={tabsControl.INFO.id}
          sx={tabPanelStyle}
        >
          <Box>
            <Typography sx={headerStyle}>{t.specimen_id}</Typography>
            <Typography sx={contentValueStyle}>{specimen_id}</Typography>
            <Typography sx={headerStyle}>{t.date_of_acquisition}</Typography>
            <Typography sx={contentValueStyle}>
              {date_of_acquisition
                ? getFormattedDate(date_of_acquisition, t.dateFormat)
                : ''}
            </Typography>
            <Typography sx={headerStyle}>{t.carcinoma}</Typography>
            <Typography sx={contentValueStyle}>{carcinoma}</Typography>
            <Typography sx={headerStyle}>{t.person_in_charge}</Typography>
            <Typography sx={contentValueStyle}>{person_in_charge}</Typography>
            <Typography sx={headerStyle}>{t.note}</Typography>
            <Typography sx={contentNoteValueStyle}>{note}</Typography>
          </Box>
          {id !== undefined && directory_id !== undefined && (
            <Box sx={editButtonStyle}>
              <StandardButton onClick={handleEditButton} text={t.edit} />
            </Box>
          )}
        </TabPanel>
        {children}
      </Box>
      {id !== undefined && directory_id !== undefined && (
        <Properties
          open={editPropDialogOpen}
          onClose={handleEeditPropDialogClose}
          id={id!}
          uuid={uuid!}
          directory_id={directory_id!}
          specimen_id={specimen_id}
          date_of_acquisition={date_of_acquisition}
          person_in_charge={person_in_charge}
          note={note}
        />
      )}
      <IhcImageUploader
        open={tabsControl.UPLOAD.isActivated}
        onClose={handleResetTabs}
        id={id!}
      />
    </Box>
  )
}

type GraphData = {
  type: 'bar' | 'pie'
  title: string
  data: any
}

type TabPanelProps = {
  children: ReactNode
  value: number | null
  index: number
  sx?: SxProps<Theme>
  isFullWidth?: boolean
  isResizable?: boolean
  handleSetWidth?: (width: number) => void
}

function TabPanel({
  children,
  value,
  index,
  sx,
  isFullWidth,
  isResizable,
  handleSetWidth,
}: TabPanelProps): JSX.Element {
  const resizableRef = useRef<HTMLDivElement>(null)

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    const startX = e.clientX
    const startWidth = resizableRef.current
      ? resizableRef.current.offsetWidth
      : 0

    const onMouseMove = (e: MouseEvent) => {
      if (resizableRef.current) {
        const newWidth = startWidth + e.clientX - startX
        resizableRef.current.style.width = `${newWidth}px`
        if (handleSetWidth) {
          handleSetWidth(newWidth)
        }
      }
    }

    const onMouseUp = () => {
      document.removeEventListener('mousemove', onMouseMove)
      document.removeEventListener('mouseup', onMouseUp)
    }

    document.addEventListener('mousemove', onMouseMove)
    document.addEventListener('mouseup', onMouseUp)
  }

  const styles = {
    ...(isFullWidth ? tabsOneThirdWidthPanel : tabsFixedWidthPanel),
    ...sx,
  }
  return (
    <Box sx={{ position: 'relative' }}>
      {value === index && value !== null && (
        <>
          <Box ref={resizableRef} sx={{ ...styles, position: 'relative' }}>
            <>{children}</>
          </Box>
          {isResizable && (
            <Box
              sx={{
                position: 'absolute',
                right: 0,
                bottom: 0,
                width: '10px',
                height: '100%',
                backgroundColor: 'transparent',
                cursor: 'col-resize',
                zIndex: 9999,
              }}
              onMouseDown={handleMouseDown}
            />
          )}
        </>
      )}
    </Box>
  )
}

const boxStyle: SxProps<Theme> = {
  flexGrow: 1,
  display: 'flex',
  height: '100vh',
  paddingTop: '64px',
}

const tabsStyle: SxProps<Theme> = {
  minWidth: '50px',
  width: '50px',
  '& .MuiTabs-indicator': {
    backgroundColor: theme.palette.bgPrimary.main,
  },
  bgcolor: theme.palette.bgDark.main,
  zIndex: '1501',
}

const tabStyle: SxProps<Theme> = {
  '&.Mui-selected': {
    backgroundColor: theme.palette.bgPrimary.main,
    color: 'white',
  },
  minWidth: 'unset',
  width: 'auto',
  padding: 0,
}

const tabPanelStyle: SxProps<Theme> = {
  height: '100%',
  backgroundColor: theme.palette.bgPrimary.main,
  padding: '10px',
  wordBreak: 'break-word',
  overflowWrap: 'break-word',
  position: 'absolute',
  zIndex: '1100',
  animation: 'faderight 0.2s ease',
}

const tabsFixedWidthPanel: SxProps<Theme> = {
  width: '240px',
}

const tabsOneThirdWidthPanel: SxProps<Theme> = {
  width: 'calc(100vw / 3)',
}

const scrollableTabPanelStyle: SxProps<Theme> = {
  ...tabPanelStyle,
  overflowY: 'auto',
  '&::-webkit-scrollbar': {
    width: '12px',
  },
  '&::-webkit-scrollbar-track': {
    background: theme.palette.bgSecondary.main,
  },
  '&::-webkit-scrollbar-thumb': {
    background: theme.palette.bgDark.main,
    borderRadius: '4px',
  },
}

const formPanelStyle: SxProps<Theme> = {
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'auto', // 必要に応じて調整
  height: 'auto', // 必要に応じて調整
  backgroundColor: theme.palette.bgPrimary.main,
  zIndex: 9999,
  padding: '40px',
  borderRadius: '4px',
}

const editButtonStyle: SxProps<Theme> = {
  position: 'absolute',
  right: '16px',
  bottom: '16px',
}

const appBarStyle: SxProps<Theme> = {
  color: 'default',
  background: theme.palette.bgPrimary.main,
}

const titleStyle: SxProps<Theme> = {
  variant: 'h6',
  color: 'inherit',
  flexGrow: 1,
  textAlign: 'center',
}

const headerStyle: SxProps<Theme> = {
  color: theme.palette.line.contrastText,
  fontSize: '14px',
  fontWeight: 'bold',
  display: 'flex',
  alignItems: 'center',
}

const contentValueStyle: SxProps<Theme> = {
  fontSize: '14px',
  pl: '14px',
  mb: 1,
}

const contentNoteValueStyle: SxProps<Theme> = {
  whiteSpace: 'pre-wrap',
  fontSize: '14px',
  pl: '14px',
  mb: '10px',
}
