import { Polyline, Rectangle, Tooltip } from 'react-leaflet'
import { LatLngBoundsExpression } from 'leaflet'
import { Typography } from '@mui/material'
import { useRef } from 'react'
import { RoiLayerType } from '../../contexts/RoiInteracts'

type Props = {
  renderLabel: boolean
  isDeleteEnabled: boolean
  handleDeleteRoiByIndex: (index: number) => void
  onDeleteDone: () => void
  color: string
  opacity?: number
  roisList: RoiLayerType[]
}
function DrawArea({
  renderLabel,
  isDeleteEnabled,
  handleDeleteRoiByIndex,
  onDeleteDone,
  color,
  opacity,
  roisList,
}: Props) {
  const ref = useRef<never>(null)
  return (
    <>
      {roisList.map((roi: RoiLayerType, index: number) => {
        const { squareCords, freeHandCords } = roi
        const areaNumber = roisList.length - index
        if (squareCords.length > 0) {
          return (
            <Rectangle
              eventHandlers={{
                click: () => {
                  if (isDeleteEnabled) {
                    handleDeleteRoiByIndex(index)
                    onDeleteDone()
                  }
                },
              }}
              key={index}
              ref={ref}
              pathOptions={{
                color: color,
                fillColor: color,
                fillOpacity: opacity || 0,
              }}
              bounds={squareCords as unknown as LatLngBoundsExpression}
            >
              {renderLabel && (
                <Tooltip
                  offset={[0, 0]}
                  permanent
                  direction='center'
                  position={[squareCords[0][0], squareCords[0][1]]}
                >
                  <Typography>Area {areaNumber}</Typography>
                </Tooltip>
              )}
            </Rectangle>
          )
        }
        if (freeHandCords.length > 0) {
          return (
            <Polyline
              eventHandlers={{
                click: () => {
                  if (isDeleteEnabled) {
                    handleDeleteRoiByIndex(index)
                    onDeleteDone()
                  }
                },
              }}
              key={index}
              pathOptions={{
                color: color,
                fillColor: color,
                fillOpacity: opacity || 0,
              }}
              positions={freeHandCords}
            >
              {renderLabel && (
                <Tooltip
                  offset={[0, 0]}
                  permanent
                  direction='center'
                  position={freeHandCords[0]}
                >
                  <Typography>Area {areaNumber}</Typography>
                </Tooltip>
              )}
            </Polyline>
          )
        }
        return null
      })}
    </>
  )
}
export default DrawArea
