//TODO: Adjust to fit the eslint rules
/* eslint-disable */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import {
  createTileLayerComponent,
  updateGridLayer,
  LayerProps,
} from '@react-leaflet/core'
import L from 'leaflet'
import { fetchBlobs } from '../../../lib/BlobsClient'

const tileCache = new Map()
const requestQueue = []
const maxConcurrentRequests = 10
let activeRequests = 0
let isFirstRender = true
let timerId = null
let abortControllers = []

// Function to abort all ongoing requests
const abortAllRequests = () => {
  abortControllers.forEach((controller) => controller.abort())
  abortControllers = []
}

// ensure that we don't have more than maxConcurrentRequests requests running at the same time
const processQueue = () => {
  if (activeRequests >= maxConcurrentRequests || requestQueue.length === 0) {
    return
  }

  const { coords, done, tile, tileUrl, tileKey, abortController } =
    requestQueue.shift()
  activeRequests++

  fetchBlobs(tileUrl, { signal: abortController.signal })
    .then((data) => {
      const objectUrl = URL.createObjectURL(data)
      tileCache.set(tileKey, objectUrl)
      tile.src = objectUrl

      return tile.decode()
    })
    .then(() => {
      done(null, tile)
    })
    .catch(() => {
      done(null, tile)
    })
    .finally(() => {
      activeRequests--
      processQueue()
    })
}

const iiifTileLayer = L.TileLayer.extend({
  options: {
    continuousWorld: true,
    tileSize: 512,
    updateWhenIdle: true,
    updateInterval: 200,
    tileFormat: 'jpg',
    fitBounds: true,
    setMaxBounds: false,
    noWrap: true, // ensure that the image is not repeated
  },

  createTile: function (coords, done) {
    const tile = document.createElement('img')
    const tileUrl = this.getTileUrl(coords)
    const tileKey = `${tileUrl}_${coords.z}`

    if (tileCache.has(tileKey)) {
      tile.src = tileCache.get(tileKey)
      tile
        .decode()
        .then(() => {
          done(null, tile)
        })
        .catch(() => {
          done(null, tile)
        })
    } else {
      const abortController = new AbortController()
      abortControllers.push(abortController)
      // if we have more than maxConcurrentRequests requests, add the request to the queue
      requestQueue.push({
        coords,
        done,
        tile,
        tileUrl,
        tileKey,
        abortController,
      })
      if (isFirstRender) {
        processQueue()
      }
    }

    return tile
  },

  initialize: function (url, options, onInitialized) {
    options = typeof options !== 'undefined' ? options : {}

    if (options.maxZoom) {
      this._customMaxZoom = true
    }

    if (options.tileSize) {
      this._explicitTileSize = true
    }

    if (options.quality) {
      this._explicitQuality = true
    }

    options = L.setOptions(this, options)
    this._infoPromise = null
    this._infoUrl = url
    this._baseUrl = this._templateUrl()
    this._getInfo()

    Promise.all([this._infoPromise]).then(() => {
      onInitialized && onInitialized(this._getScaleRatios())
    })
  },
  getTileUrl: function (coords) {
    const _this = this,
      x = coords.x,
      y = coords.y,
      zoom = _this._getZoomForUrl(),
      scale = Math.pow(2, _this.maxNativeZoom - zoom),
      tileBaseSize = _this.options.tileSize * scale,
      minx = Math.abs(x * tileBaseSize),
      miny = Math.abs(y * tileBaseSize),
      maxx = Math.min(minx + tileBaseSize, _this.x),
      maxy = Math.min(miny + tileBaseSize, _this.y)

    // tileBaseSize = 512 * scale,
    const xDiff = Math.abs(maxx - minx)
    const yDiff = Math.abs(maxy - miny)

    // Enforce maximum tile size (512px)
    let sizeX = Math.min(512, Math.ceil(xDiff / scale))
    let sizeY = Math.min(512, Math.ceil(yDiff / scale))

    let size = sizeX + ',' + sizeY

    if (_this.type === 'ImageService3') {
      size = sizeX + ',' + sizeY
    }
    // let size = Math.ceil(xDiff / scale) + ','

    return L.Util.template(
      this._baseUrl,
      L.extend(
        {
          format: _this.options.tileFormat,
          quality: _this.quality,
          region: [minx, miny, xDiff, yDiff].join(','),
          rotation: 0,
          size: size,
        },
        this.options,
      ),
    )
  },
  onAdd: function (map) {
    const _this = this
    _this._addZoomListeners(map)

    Promise.all([_this._infoPromise])
      .then(function () {
        _this._imageSizesOriginal = _this._imageSizes.slice(0)

        map._layersMaxZoom = _this.maxZoom

        L.TileLayer.prototype.onAdd.call(_this, map)

        let smallestImage = _this._imageSizes[0]
        const mapSize = _this?._map?.getSize()
        let newMinZoom = 0

        if (!mapSize) return

        for (let i = 1; i <= 5; i++) {
          if (smallestImage.x > mapSize.x || smallestImage.y > mapSize.y) {
            smallestImage = smallestImage.divideBy(2)
            _this._imageSizes.unshift(smallestImage)
            newMinZoom = -i
          } else {
            break
          }
        }
        _this.options.minZoom = newMinZoom
        _this.options.minNativeZoom = newMinZoom
        _this._prev_map_layersMinZoom = _this._map._layersMinZoom
        _this._map._layersMinZoom = newMinZoom

        if (_this.options.fitBounds) {
          _this._fitBounds()
        }

        if (_this.options.setMaxBounds) {
          _this._setMaxBounds()
        }

        _this.on('tileload', function (tile, url) {
          const height = tile.tile.naturalHeight,
            width = tile.tile.naturalWidth

          // No need to resize if tile is 256 x 256
          if (height === 256 && width === 256) return

          tile.tile.style.width = width + 'px'
          tile.tile.style.height = height + 'px'
        })
      })
      .catch(function (err) {
        console.error(err)
      })
  },
  onRemove: function (map) {
    const _this = this

    map._layersMinZoom = _this._prev_map_layersMinZoom
    _this._imageSizes = _this._imageSizesOriginal

    if (_this.options.setMaxBounds) {
      map.setMaxBounds(null)
    }

    L.TileLayer.prototype.onRemove.call(_this, map)
    tileCache.clear()
  },
  _fitBounds: function () {
    const _this = this

    const initialZoom = _this._getInitialZoom(_this._map.getSize())
    const offset = 0 //_this._imageSizes.length - 1 - _this.options.maxNativeZoom;
    const imageSize = _this._imageSizes[initialZoom + offset]
    const sw = _this._map.options.crs.pointToLatLng(
      L.point(0, imageSize.y),
      initialZoom,
    )
    const ne = _this._map.options.crs.pointToLatLng(
      L.point(imageSize.x, 0),
      initialZoom,
    )
    const bounds = L.latLngBounds(sw, ne)

    _this._map.fitBounds(bounds, true)
  },
  _addZoomListeners(map) {
    map.on('zoomstart', () => {
      if (!isFirstRender) {
        requestQueue.length = 0 // Cancel processing when zooming
        abortAllRequests()
      }
    })

    map.on('zoom', () => {
      if (timerId) clearTimeout(timerId) // Cancel processing when zooming
    })

    map.on('zoomend', () => {
      if (isFirstRender) isFirstRender = false
      timerId = setTimeout(() => {
        processQueue()
        timerId = null
      }, 500) // Trigger processing after zooming
    })

    map.on('moveend', () => {
      processQueue() // Trigger processing after panning
    })
  },
  _setMaxBounds: function () {
    const _this = this

    const initialZoom = _this._getInitialZoom(_this._map.getSize())
    const imageSize = _this._imageSizes[initialZoom]
    const sw = _this._map.options.crs.pointToLatLng(
      L.point(0, imageSize.y),
      initialZoom,
    )
    const ne = _this._map.options.crs.pointToLatLng(
      L.point(imageSize.x, 0),
      initialZoom,
    )
    const bounds = L.latLngBounds(sw, ne)

    _this._map.setMaxBounds(bounds, true)
  },
  _getInfo: function () {
    const _this = this
    _this._infoPromise = fetch(_this._infoUrl, {
      headers: {
        'access-token': window.localStorage.getItem('access_token'),
        client: window.localStorage.getItem('client'),
        uid: window.localStorage.getItem('uid'),
      },
    })
      .then(function (response) {
        return response.json()
      })
      .catch(function (err) {
        console.error(err)
      })
      .then(function (data) {
        _this.y = data.height
        _this.x = data.width

        let tierSizes = [],
          imageSizes = [],
          scale,
          width_,
          height_,
          tilesX_,
          tilesY_

        if (data.profile instanceof Array) {
          _this.profile = data.profile[0]
        } else {
          _this.profile = data.profile
        }
        _this.type = data.type

        _this._setQuality()

        if (!_this._explicitTileSize) {
          _this.options.tileSize = 512
          if (data.tiles) {
            _this.options.tileSize = data.tiles[0].width
          } else if (data.tile_width) {
            _this.options.tileSize = data.tile_width
          }
        }

        function ceilLog2(x) {
          return Math.ceil(Math.log(x) / Math.LN2)
        }

        _this.maxNativeZoom = Math.max(
          ceilLog2(_this.x / _this.options.tileSize),
          ceilLog2(_this.y / _this.options.tileSize),
          0,
        )
        _this.options.maxNativeZoom = _this.maxNativeZoom

        if (
          _this._customMaxZoom &&
          _this.options.maxZoom > _this.maxNativeZoom
        ) {
          _this.maxZoom = _this.options.maxZoom
        } else {
          _this.maxZoom = _this.maxNativeZoom
        }

        for (let i = 0; i <= _this.maxZoom; i++) {
          scale = Math.pow(2, _this.maxNativeZoom - i)
          width_ = Math.ceil(_this.x / scale)
          height_ = Math.ceil(_this.y / scale)
          tilesX_ = Math.ceil(width_ / _this.options.tileSize)
          tilesY_ = Math.ceil(height_ / _this.options.tileSize)
          tierSizes.push([tilesX_, tilesY_])
          imageSizes.push(L.point(width_, height_))
        }

        _this._tierSizes = tierSizes
        _this._imageSizes = imageSizes
      })
      .catch(function (err) {
        console.error(err)
      })
  },

  _setQuality: function () {
    const _this = this
    let profileToCheck = _this.profile

    if (_this._explicitQuality) {
      return
    }

    if (typeof profileToCheck === 'object') {
      profileToCheck = profileToCheck['@id']
    }

    switch (true) {
      case /^http:\/\/library.stanford.edu\/iiif\/image-api\/1.1\/compliance.html.*$/.test(
        profileToCheck,
      ):
        _this.options.quality = 'native'
        break
      default:
        _this.options.quality = 'default'
        break
    }
  },

  _infoToBaseUrl: function () {
    return (this._infoUrl || '').replace('info.json', '')
  },
  _templateUrl: function () {
    return (
      this._infoToBaseUrl() + '{region}/{size}/{rotation}/{quality}.{format}'
    )
  },
  _isValidTile: function (coords) {
    const _this = this
    const zoom = _this._getZoomForUrl()
    const sizes = _this._tierSizes[zoom]
    const x = coords.x
    const y = coords.y
    if (zoom < 0 && x >= 0 && y >= 0) {
      return true
    }

    if (!sizes) return false
    if (x < 0 || sizes[0] <= x || y < 0 || sizes[1] <= y) {
      return false
    } else {
      return true
    }
  },
  _tileShouldBeLoaded: function (coords) {
    return this._isValidTile(coords)
  },
  _getInitialZoom: function (mapSize) {
    const _this = this
    const tolerance = 0.8
    let imageSize
    //NOTE: 2024/02/13 最大倍率を増やすとこの offset の影響でエラーとなるため、コメントアウト。問題なければ後で削除予定
    // var offset = _this._imageSizes.length - 1 - _this.options.maxNativeZoom;
    for (let i = _this._imageSizes.length - 1; i >= 0; i--) {
      imageSize = _this._imageSizes[i]
      if (
        imageSize.x * tolerance < mapSize.x &&
        imageSize.y * tolerance < mapSize.y
      ) {
        // return i - offset;
        return i
      }
    }
    return 2
  },

  _getScaleRatios() {
    if (!this.x || !this.y || !this._imageSizes) return []
    return this._imageSizes.map((size) => ({
      x: this.x / size.x,
      y: this.y / size.y,
    }))
  },
})

interface IIIFTileLayerProps extends L.TileLayerOptions, LayerProps {
  url: string
  tileFormat?: string
  fitBounds?: boolean
  color?: number
  onInitialized?: (scaleRatios: Ratio[]) => void
}

const IIIFTileLayer = createTileLayerComponent<L.TileLayer, IIIFTileLayerProps>(
  function createTileLayer({ url, onInitialized, ...options }, context) {
    if (!options.tileSize) {
      options.tileSize = 512
    } // ensure that the default tileSize is set and not always 256

    return {
      instance: new iiifTileLayer(url, options, onInitialized),
      context,
    }
  },
  updateGridLayer,
)

export default IIIFTileLayer
