import { FormEvent, useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { ApiClient } from '../lib/ApiClient'
import { HTTP_STATUS_CODE } from '../constants/ErrorCode'

import { UserData, UserDataResponse, UserFeatures } from '../types/UserData'
import CommonValue from '../contexts/CommonValue'
import useTranslation from './useTranslation'
import { responseError } from '../types/CommonTypes'

/**
 * Login Hooks
 *
 * @returns articles
 * @returns errorMessage
 * @returns isLoading
 * @returns fetchArticles
 */
export const useLoginHook = () => {
  const [articles, setArticles] = useState<UserData>()
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()
  const { globalLanguage, handleUserFeatures } = useContext(CommonValue)
  const t = useTranslation(globalLanguage)

  const fetchArticles = async (
    e: FormEvent<HTMLFormElement>,
    email: string,
    password: string,
  ) => {
    e.preventDefault() // フォームのデフォルトの動作（リロード）を防ぐ
    setIsLoading(true) // ローディング開始
    setErrorMessage('') // エラーメッセージを初期化
    const putEmail: string = email
    const putPassword: string = password

    // API通信
    await ApiClient.post<UserDataResponse>('/auth/sign_in', {
      email: putEmail,
      password: putPassword,
    })
      .then((response) => {
        const d = response.data.data
        console.log(d)
        // レスポンスから利用したい要素を UserData 型 の配列でセット
        setArticles({
          email: d.email,
          id: d.id,
          name: d.name,
          nickname: d.nickname,
          image: d.image,
        })

        handleUserFeatures({
          ihc_registration: d.additional_features.ihc_registration,
          spatial_transcriptomics:
            d.additional_features.spatial_transcriptomics,
        })

        window.localStorage.setItem(
          'access_token',
          response.headers['access-token'],
        )
        window.localStorage.setItem(
          'directory_id',
          d.root_directory_id.toString(),
        )
        window.localStorage.setItem('uid', response.headers['uid'])
        window.localStorage.setItem('client', response.headers['client'])
        navigate('/')
      })
      .catch((error: responseError) => {
        if (error.response.status === HTTP_STATUS_CODE.UNAUTHORIZED) {
          setErrorMessage(t.message.LOGIN_ERROR)
        } else if (error.response.status === HTTP_STATUS_CODE.NOT_FOUND) {
          setErrorMessage(t.message.LOGIN_ERROR)
        }
      })

    setIsLoading(false) // ローディング終了
  }

  const checkUserFeatures = async () => {
    const accessToken = window.localStorage.getItem('access_token')
    const uid = window.localStorage.getItem('uid')
    const client = window.localStorage.getItem('client')
    await ApiClient.get<UserFeatures>('/user_features', {
      headers: {
        'access-token': accessToken,
        uid: uid,
        client: client,
      },
    }).then((response) => {
      const d = response.data
      handleUserFeatures({
        ihc_registration: Boolean(d.ihc_registration),
        spatial_transcriptomics: Boolean(d.spatial_transcriptomics),
      })
    })
  }

  return {
    articles,
    errorMessage,
    isLoading,
    fetchArticles,
    checkUserFeatures,
  }
}
