import { useMemo, useState, useCallback, useEffect, ReactElement } from 'react'
import { useEventHandlers, useLeafletContext } from '@react-leaflet/core'
import L, { LeafletMouseEvent } from 'leaflet'
import { MapContainer, Rectangle, useMap, useMapEvent } from 'react-leaflet'
import IIIFTileLayer from './ReactLeafletIiif'
import LayerControl from '../leaflets/LayerControl'
import DragBar from '../../rayoutItem/DragBar'
import 'leaflet/dist/leaflet.css'
import '../leaflets/Leaflet.css'
import { AnalysisResult } from '../../../types/DetailPathologicalImages'
import SelectionToolMenu from '../../tools/selectionTool/SelectionToolMenu'
import { Ratio } from '../../../types/Ratio'
import { elementPositions } from '../../../types/MapElementTypes'
import { POSITION_CLASSES } from '../../../constants/utils'
import { ScaleBar } from '../../mapElements/ScaleBar'
import { zoomKeyValues } from '../../../lib/Utility'
import { LayersColors } from '../../../contexts/LayersColors'
import { MapMarkers } from '../../mapElements/Marker'

type Props = {
  id: number
  base_layer_url: string
  analysis_result: AnalysisResult
  mpp: number
  hdf5: string
}

/**
 * Leaflet View
 */
const LeafletView = ({
  id,
  base_layer_url,
  analysis_result,
  mpp,
  hdf5,
}: Props) => {
  const [ratios, setRatios] = useState<Ratio[]>([])
  const position = new L.LatLng(0.0, 0.0)

  function handleInitialized(ratios: Ratio[]) {
    setRatios(ratios)
  }

  return (
    <MapContainer
      center={position}
      zoom={1} // 1, 2を指定するとなぜか初期表示で一部タイルが表示されないので、3以上をセットする。それでも初期表示はなぜか2になる。
      minZoom={1}
      maxZoom={10}
      scrollWheelZoom={true}
      zoomControl={true}
      doubleClickZoom={false}
      preferCanvas={true}
      attributionControl={false}
      style={{
        position: 'relative',
      }}
    >
      {/* base window */}
      <IIIFTileLayer
        url={base_layer_url}
        maxZoom={10}
        onInitialized={handleInitialized}
      />
      {/*TODO: Split map tools (control, map ...) to mapElements*/}

      {/* scale control */}
      {/* <ScaleControl imperial={false} /> */}

      {/* navigation window */}
      <MinimapControl
        url={base_layer_url}
        position={POSITION_CLASSES.bottomright}
      />

      {/* layer window */}
      <LayersColors>
        <LayerControl position='topright' analysis_result={analysis_result} />
      </LayersColors>

      {/* scale bar */}
      <div className={POSITION_CLASSES.bottomleft}>
        <ScaleBar mpp={mpp} />
      </div>

      <SelectionToolMenu id={id} scaleRatios={ratios} hdf5={hdf5} />

      {/*Component to handle any map events or leaflet features having map container as the parent*/}
      <MapEvents />

      {/* user's markers */}
      <MapMarkers mpp={mpp} />
    </MapContainer>
  )
}
export default LeafletView

const BOUNDS_STYLE = { weight: 1 }

interface CntlProps {
  url: string
  position: string
  zoom?: number
}

/**
 *  navigation window
 */
function MinimapControl({ url, position, zoom }: CntlProps) {
  const parentMap = useMap()
  const mapZoom = zoom || 0

  // Memoize the minimap so it's not affected by position changes
  const minimap = useMemo(
    () => (
      <>
        <DragBar />
        <MapContainer
          style={{ height: 250, width: 240, borderRadius: '0 0 8px 8px' }}
          center={parentMap.getCenter()}
          zoom={mapZoom}
          dragging={true}
          doubleClickZoom={false}
          scrollWheelZoom={false}
          attributionControl={false}
          zoomControl={false}
        >
          <IIIFTileLayer url={url} />

          <MinimapBounds parentMap={parentMap} zoom={mapZoom} />
        </MapContainer>
      </>
    ),
    [mapZoom, parentMap, url],
  )

  const positionClass =
    (position && POSITION_CLASSES[position as elementPositions]) ||
    POSITION_CLASSES.bottomright

  return (
    <div className={positionClass + ' leaflet-minimap'}>
      <div className='leaflet-control leaflet-bar'>{minimap}</div>
    </div>
  )
}

interface BoundsProps {
  parentMap: L.Map
  zoom?: number
}

/**
 *  Display frame of the navigation window
 */
function MinimapBounds({ parentMap, zoom }: BoundsProps) {
  const minimap = useMap()
  const context = useLeafletContext()

  // Clicking a point on the minimap sets the parent's map center
  const onClick = useCallback(
    (e: LeafletMouseEvent) => {
      parentMap.setView(e.latlng, parentMap.getZoom())
    },
    [parentMap],
  )
  useMapEvent('click', onClick)

  // Keep track of bounds in state to trigger renders
  const [bounds, setBounds] = useState(parentMap.getBounds())
  const onChange = useCallback(() => {
    setBounds(parentMap.getBounds())
    // Update the minimap's view to match the parent map's center and zoom
    minimap.setView(parentMap.getCenter(), zoom)
  }, [minimap, parentMap, zoom])

  // Listen to events on the parent map
  const handlers = useMemo(
    () => ({ move: onChange, zoom: onChange }),
    [onChange],
  )
  useEventHandlers({ instance: parentMap, context }, handlers)

  return <Rectangle bounds={bounds} pathOptions={BOUNDS_STYLE} />
}

// Component to handle any map events or leaflet features having map container as the parent
// This is for the main map not the minimap or any other extra components
const MapEvents = (): null | ReactElement => {
  const [mouseMapLoc, setMouseMapLoc] = useState<L.LatLng | null>(null) // used for keyboard zooming
  const map = useMap()

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      const key = e.key
      const zoom = zoomKeyValues.get(key)

      if (zoom && key) {
        if (mouseMapLoc) {
          e.stopPropagation()
          map.setView(mouseMapLoc, zoom)
          return
        }
        e.stopPropagation()
        map.setZoom(zoom)
      }
    }

    const handlMouseMove = (e: MouseEvent) => {
      const latlng = map.mouseEventToLatLng(e)
      setMouseMapLoc(latlng)
    }

    window.addEventListener('keydown', handleKeyDown)
    window.addEventListener('mousemove', handlMouseMove)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
      window.removeEventListener('mousemove', handlMouseMove)
    }
  }, [mouseMapLoc]) // keyboard zooming

  return null
}
