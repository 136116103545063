const en = {
  title: 'DeepPathFinder',
  search: 'Search',
  specimen_id: 'Specimen ID',
  date_of_acquisition: 'Date of Acquisition',
  carcinoma: 'Carcinoma',
  person_in_charge: 'Person in Charge',
  date_of_uploading: 'Date of uploading',
  status: 'Status',
  upload: 'Upload',
  drag_message: 'Drag your file here.',
  selected_file: 'Selected file: ',
  note: 'note',
  user_id: 'User ID',
  password: 'Password',
  sign_in: 'Sign In',
  cell: 'Cell',
  tissue: 'Tissue',
  edit: 'edit',
  lung: 'Lung',
  colon: 'Colon',
  breast: 'Breast',
  stomach: 'Stomach',
  delete_message: 'Do you want to delete the selected file(s)?',
  delete_message_second: 'Delete files cannot be restarted.',
  delete: 'Delete',
  cancel: 'Cancel',
  message: {
    LOGIN_ERROR:
      'Login failed.\r\nEither the information you entered is not correct.',
    FETCH_LIST_DATA_ERROR: 'Failed to fetch list data. Please try again later.',
    NOT_DATA: 'Data could not be retrieved.',
    UPLOAD_IMAGE_ERROR: 'Failed to upload the image. Please try again later.',
    DELETE_IMAGE_ERROR: 'Failed to delete the image. Please try again later.',
    EXPORT_CSV_ERROR: 'Failed to export CSV. Please try again later.',
  },
  not_found: 'The page you are looking for could not be found.',
  log_out: 'Sign out',
  ok: 'OK',
  edit_properties: 'Edit File Properties',
  dateFormat: 'MM/dd/yyyy',
  dateFormatWithTime: 'MM/dd/yyyy hh:mm',
  ihc_title: 'IHC Image',
  ihc_select_file: 'Select file',
  ihc_selected_file_label: 'Selected file:',
  ihc_name_label: 'IHC name:',
  ihc_color_label: 'Color:',

  roi_status_title: 'ROI status',
  roi_area_label: 'Area',
  roi_cell_label: 'Cell',
  roi_download_button: 'csv download',
}

export default en
