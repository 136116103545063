import { createTheme, Theme } from '@mui/material/styles'
import { red } from '@mui/material/colors'

declare module '@mui/material/styles' {
  interface CustomPaletteColor {
    main: string
    contrastText: string
  }

  interface Palette {
    bgPrimary: CustomPaletteColor
    bgSecondary: CustomPaletteColor
    bgDark: CustomPaletteColor
    line: CustomPaletteColor
    title: CustomPaletteColor
  }

  interface PaletteOptions {
    bgPrimary?: CustomPaletteColor
    bgSecondary?: CustomPaletteColor
    bgDark?: CustomPaletteColor
    line?: CustomPaletteColor
    title?: CustomPaletteColor
  }
}

// A custom theme for this app
const theme: Theme = createTheme({
  typography: {
    fontFamily: ['Helvetica', 'Arial', 'sans-serif'].join(','),
  },
  palette: {
    mode: 'dark',
    primary: {
      main: '#35BFC8',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#000000',
    },
    error: {
      main: red.A700,
    },
    line: {
      main: '#999999',
      contrastText: '#ffffff66',
    },
    bgPrimary: {
      main: '#333333',
    },
    bgSecondary: {
      main: '#444444',
    },
    bgDark: {
      main: '#242424',
    },
    title: {
      main: 'rgba(255, 0, 0, 0)',
    },
  },
})

export default theme
