import { ChangeEvent, useContext, useEffect, useState } from 'react'
import {
  Box,
  Typography,
  TextField,
  Grid,
  Backdrop,
  Dialog,
  DialogContent,
} from '@mui/material'
import theme from '../../theme'
import CalendarInput from '../inputs/CalendarInput'
import MiddleButton from '../buttons/MiddleButton'
import MiddleOutlineButton from '../buttons/MiddleOutlineButton'
import { usePathologicalImageEdit } from '../../hooks/usePathologicalImageEdit'
import CustomCircularProgress from '../customIcons/CustomCircularProgress'
import CloseButton from '../buttons/CloseButton'
import CommonValue from '../../contexts/CommonValue'
import useTranslation from '../../hooks/useTranslation'

interface PropertiesProps {
  open: boolean
  onClose: () => void
  id: number
  uuid: string
  directory_id: number
  specimen_id: string
  date_of_acquisition: Date | null
  person_in_charge: string
  note: string
}

export const Properties = ({
  open,
  onClose,
  id,
  uuid,
  directory_id,
  specimen_id,
  date_of_acquisition,
  person_in_charge,
  note,
}: PropertiesProps) => {
  const { editPathologicalImage } = usePathologicalImageEdit()
  const [name, setName] = useState(specimen_id)
  const [personInCharge, setPersonInCharge] = useState(person_in_charge)
  const [acquisitionDate, setAcquisitionDate] = useState<Date | null>(
    date_of_acquisition,
  )
  const [editNote, setEditNote] = useState(note)
  const [isEditComplete, setIsEditComplete] = useState<boolean>(false)
  const [isloading, setIsloading] = useState<boolean>(false)
  const { globalLanguage } = useContext(CommonValue)
  const t = useTranslation(globalLanguage)

  useEffect(() => {
    setName(specimen_id)
    setPersonInCharge(person_in_charge)
    setAcquisitionDate(date_of_acquisition)
    setEditNote(note)
  }, [specimen_id, person_in_charge, date_of_acquisition, note])

  const handleAcquisitionDateChange = (date: Date | null) => {
    setAcquisitionDate(date)
  }

  const handleCancel = () => {
    onClose()
  }

  const handleEdit = async (event: Event): Promise<void> => {
    event.preventDefault()
    const acquisitionDateString = acquisitionDate?.toISOString() || ''
    setIsloading(true)
    await editPathologicalImage(
      id,
      uuid,
      directory_id,
      name,
      personInCharge,
      acquisitionDateString,
      editNote,
    )
    setIsloading(false)
    setIsEditComplete(true)
  }

  function handleSpecimanIdChange(event: ChangeEvent<{ value: string }>): void {
    setName(event.target.value)
  }

  function handlePersonInChargeChange(
    event: ChangeEvent<{ value: string }>,
  ): void {
    setPersonInCharge(event.target.value)
  }

  function handleNoteChange(event: ChangeEvent<{ value: string }>): void {
    setEditNote(event.target.value)
  }

  useEffect(() => {
    if (isEditComplete) {
      window.location.reload()
    }
  }, [isEditComplete])

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby='upload-dialog-title'
      sx={{ zIndex: 1800 }}
    >
      <DialogContent sx={{ minWidth: '600px', minHeight: '300px' }}>
        <Box sx={{ marginBottom: 2 }}>
          <Box>
            <Typography variant='h5'>{t.edit_properties}</Typography>
            <Box sx={{ paddingTop: '20px' }}>
              <Box component='form' sx={{ width: '100%' }}>
                <Grid container spacing={2} sx={{ paddingBottom: '10px' }}>
                  <Grid item xs={12} md={6}>
                    <Box sx={{ width: '260px' }}>
                      <Typography>{t.specimen_id}</Typography>
                      <TextField
                        defaultValue={name}
                        margin='dense'
                        fullWidth
                        sx={{
                          '& input': {
                            height: '26px',
                            padding: '5px 12px',
                          },
                          '& fieldset': {
                            paddingTop: '8px',
                            paddingBottom: '8px',
                          },
                          backgroundColor: theme.palette.bgSecondary.main,
                        }}
                        onChange={handleSpecimanIdChange}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}></Grid>
                </Grid>
                <Grid container spacing={2} sx={{ paddingBottom: '10px' }}>
                  <Grid item xs={12} md={6}>
                    <Box sx={{ width: '260px' }}>
                      <Typography>{t.person_in_charge}</Typography>
                      <TextField
                        defaultValue={person_in_charge}
                        margin='dense'
                        fullWidth
                        sx={{
                          '& input': {
                            height: '26px',
                            padding: '5px 12px',
                          },
                          '& fieldset': {
                            paddingTop: '8px',
                            paddingBottom: '8px',
                          },
                          backgroundColor: theme.palette.bgSecondary.main,
                        }}
                        onChange={handlePersonInChargeChange}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Box sx={{ width: '260px' }}>
                      <Typography sx={{ paddingBottom: '7px' }}>
                        {t.date_of_acquisition}
                      </Typography>
                      <CalendarInput
                        selectedDate={acquisitionDate}
                        onChange={handleAcquisitionDateChange}
                      />
                    </Box>
                  </Grid>
                </Grid>
                <Box>
                  <Typography>{t.note}</Typography>
                  <TextField
                    defaultValue={note}
                    margin='dense'
                    fullWidth
                    multiline
                    minRows={6}
                    maxRows={6}
                    sx={{ backgroundColor: theme.palette.bgSecondary.main }}
                    onChange={handleNoteChange}
                  />
                </Box>
                <Grid
                  container
                  spacing={2}
                  sx={{ marginTop: 2 }}
                  justifyContent='flex-end'
                >
                  <Grid item>
                    <MiddleOutlineButton
                      text={t.cancel}
                      onClick={handleCancel}
                    />
                  </Grid>
                  <Grid item>
                    <MiddleButton text={t.ok} onClick={handleEdit} />
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>
          <Backdrop
            open={isloading}
            sx={{
              zIndex: (theme) => theme.zIndex.drawer + 1,
              color: '#fff',
            }}
          >
            <CustomCircularProgress />
          </Backdrop>
        </Box>
        <Box sx={{ position: 'absolute', top: 0, right: 0 }}>
          <CloseButton onClick={onClose} />
        </Box>
      </DialogContent>
    </Dialog>
  )
}
