import { Typography } from '@mui/material'

export const UmapStatus = () => {
  return (
    <>
      <Typography textAlign={'center'}>UMAP Status</Typography>
      <table
        style={{
          width: '100%',
        }}
        border={1}
      >
        <tr
          style={{
            width: '50%',
          }}
        >
          <th>Cluster ID</th>
          <th>Cell Counts</th>
        </tr>
        <tr
          style={{
            width: '50%',
          }}
        >
          <td>1</td>
          <td>1961</td>
        </tr>
        <tr>
          <td>2</td>
          <td>456</td>
        </tr>
        <tr>
          <td>3</td>
          <td>1481</td>
        </tr>
        <tr>
          <td>4</td>
          <td>1</td>
        </tr>
        <tr>
          <td>5</td>
          <td>191</td>
        </tr>
        <tr>
          <td>6</td>
          <td>903</td>
        </tr>
      </table>
    </>
  )
}
