import { RgbaColorPicker } from 'react-colorful'
import React, { useEffect, useRef, useState } from 'react'
import Box from '@mui/material/Box'
import { useLayersColors } from '../../contexts/LayersColors'
import { rgbToHex } from '@mui/material'

export interface colorObjType {
  r: number
  g: number
  b: number
  a: number
}

interface ColorPickerProps {
  id: string
  parentRef?: React.RefObject<HTMLElement>
  hexColor?: string
}

export const ColorPickerRenderColor = ({ id, parentRef }: ColorPickerProps) => {
  const colorPickerref = useRef<HTMLElement>(null)
  const [pickerPosition, setPickerPosition] = useState(20)
  const { setLayersColors, handleSaveLayerColor } = useLayersColors()
  const [showColorPicker, setShowColorPicker] = useState<boolean>(false)
  const { layersColors } = useLayersColors()
  const [color, setColor] = useState<colorObjType>({
    r: 0,
    g: 0,
    b: 0,
    a: 1,
  })

  const layerColorSelected = layersColors.find(
    (layerColor) => layerColor.layerName.split(' ')[0] === id,
  )
  const red = layerColorSelected?.color.r
  const green = layerColorSelected?.color.g
  const blue = layerColorSelected?.color.b

  const handleChange = (color: colorObjType) => {
    setColor(color)
    setLayersColors(id, color)
  }

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        colorPickerref.current &&
        !colorPickerref.current.contains(event.target as never)
      ) {
        setShowColorPicker(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <Box
      onClick={(e) => {
        e.stopPropagation()
        const positionClicked = (e.target as Element).getBoundingClientRect()
          .top
        if (parentRef) {
          const top = parentRef.current?.getBoundingClientRect().top
          const scrollTop = parentRef.current?.scrollTop
          if (top && scrollTop) {
            if (scrollTop > 0 && positionClicked > 200) {
              setPickerPosition(positionClicked - top + scrollTop - 100)
            }
          }
        } else {
          setPickerPosition(positionClicked)
        }
        setShowColorPicker(!showColorPicker)
      }}
    >
      <Box
        borderRadius={'100%'}
        width={18}
        height={18}
        sx={{
          marginTop: '6px',
          cursor: 'pointer',
          backgroundColor: rgbToHex(`rgb(${red}, ${green}, ${blue})`),
        }}
      ></Box>
      <Box
        ref={colorPickerref}
        sx={{
          display: showColorPicker ? 'block' : 'none',
          position: 'absolute',
          top: pickerPosition,
          right: 0,
          zIndex: 1000,
          borderRadius: '0 0 8px 8px',
        }}
      >
        <RgbaColorPicker
          style={{
            height: '150px',
          }}
          color={color}
          onClick={(e) => e.stopPropagation()}
          onChange={handleChange}
          onMouseUp={() => handleSaveLayerColor(id, color)}
        />
      </Box>
    </Box>
  )
}
