import { NavigateFunction } from 'react-router-dom'

/**
 * Date 型を String に変換する
 * @param {Date} date - Date型の日付データ
 * @param {string} format - 変換したい日付フォーマット
 * @returns {string} - 変換された日付文字列
 */
export const getFormattedDate = (date: Date, format: string): string => {
  const symbol = {
    M: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    m: date.getMinutes(),
    s: date.getSeconds(),
  }

  const formatted = format.replace(/(M+|d+|h+|m+|s+)/g, (v) =>
    (
      (v.length > 1 ? '0' : '') + symbol[v.slice(-1) as keyof typeof symbol]
    ).slice(-2),
  )

  return formatted.replace(/(y+)/g, (v) =>
    date.getFullYear().toString().slice(-v.length),
  )
}

/**
 * 10進数を カラーコード(#999999) に変換する
 */
export const getColorCode = (color: number): string => {
  return '#' + ('000000' + color.toString(16)).slice(-6)
}

/**
 * 前画面に遷移する
 * @param {NavigateFunction} navigate - react-router-domのNavigateFunction
 */
export const handleBackClick = (navigate: NavigateFunction): void => {
  navigate('/')
}

/**
 * 現在開いている検体のURLをコピーする
 */
export const handleCopyClick = (uuid: string | undefined): void => {
  const url = new URL(window.location.href)
  url.searchParams.delete('uuid')
  const share_url = url + '?uuid=' + uuid
  global.navigator.clipboard.writeText(share_url)
}

export const zoomKeyValues = new Map<string, number>()
  .set('1', 2)
  .set('2', 3)
  .set('3', 4)
  .set('4', 5)
  .set('5', 6)
  .set('6', 7)
  .set('7', 8)
  .set('8', 9)
  .set('9', 9)
  .set('0', 2)

export const pathoImageProccessingTypes = new Map()
  .set(true, 'complete')
  .set(false, 'ai processing')
export const sortKeyDropDownValuesPerId = new Map()
  .set(1, 'name')
  .set(2, 'date_of_acquisition')
  .set(3, 'carcinoma')
  .set(4, 'person_in_charge')
