import axios from 'axios'

const port = window.location.port
const baseURL =
  port === '3000'
    ? 'http://localhost:3001/api'
    : `${window.location.origin}/api`

export const ApiClient = axios.create({
  baseURL: baseURL,
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json',
    Authorization: window.localStorage.getItem('access_token'),
  },
})

ApiClient.interceptors.request.use((config) => {
  const accessToken = window.localStorage.getItem('access_token')
  const uid = window.localStorage.getItem('uid')
  const client = window.localStorage.getItem('client')
  const directoryId = window.localStorage.getItem('directory_id')
  if (accessToken) {
    config.headers['access-token'] = accessToken
    config.headers['uid'] = uid
    config.headers['client'] = client
    config.headers['directory_id'] = directoryId
  }

  return config
})
