import { Button, SxProps, Theme } from '@mui/material'
import theme from '../../theme'

type Props = {
  text: string
  onClick?: () => void | undefined
  htmlFor?: string
  disabled?: boolean
}

export default function StandardButton({
  text,
  onClick,
  htmlFor,
  disabled,
}: Props): JSX.Element {
  return (
    <Button
      variant='contained'
      sx={buttonStyle}
      onClick={onClick}
      component='label'
      htmlFor={htmlFor}
      disabled={disabled}
    >
      {text}
    </Button>
  )
}

const buttonStyle: SxProps<Theme> = {
  backgroundColor: theme.palette.primary.main,
  textTransform: 'none',
  width: '100px',
}
