import { createContext, useContext, useEffect, useState } from 'react'
import { usePathologicalImageUpload } from '../hooks/usePathologicalImageUpload'
import { PathologicalImage } from '../types/FileDataList'

type UploadFilesProps = {
  children: React.ReactNode
}

type UploadFiles = {
  errorMessage: string
  handleQueueFiles: (filesToUpload: UploadFilesArgType[]) => void
  uploadingPercents: { [key: string]: number }
  temporalCards: PathologicalImage[]
  refetch: number
}

const initialRoiInteractsContext: UploadFiles = {
  errorMessage: '',
  handleQueueFiles: () => null,
  uploadingPercents: {},
  temporalCards: [],
  refetch: 0,
}

const UploadFilesContext = createContext<UploadFiles | undefined>(
  initialRoiInteractsContext,
)

const UploadFiles = ({ children }: UploadFilesProps) => {
  const { uploadPathologicalImage, errorMessage } = usePathologicalImageUpload()
  const [temporalCards, setTemporalCards] = useState<PathologicalImage[]>([])
  const [files, setFiles] = useState<UploadFilesArgType[]>([])
  const [uploadingPercents, setUploadingPercents] = useState<{
    [key: string]: number
  }>({})
  const [refetch, setRefetch] = useState(0)

  // handleQueueFiles: upload files in queue and filter out files that are already in the list
  const handleQueueFiles = (filesToUpload: UploadFilesArgType[]) => {
    const newFiles = filesToUpload.filter(
      (file) => !files.some((f) => f.name === file.name),
    )
    setFiles((prevFiles) => [...prevFiles, ...newFiles])
    uploadAllFiles(newFiles)
  }

  const updateUploadingPercents = (fileName: string, percent: number) => {
    setUploadingPercents((prev) => ({
      ...prev,
      [fileName]: percent,
    }))
  }

  const handleUploadPathologicalImage = async (file: UploadFilesArgType) => {
    try {
      await uploadPathologicalImage(
        file.file,
        file.organ_type_id,
        file.name,
        file.person_in_charge,
        file.date_of_acquisition,
        file.note,
        (percent: number) => updateUploadingPercents(file.name, percent),
      )
      // refetch file list after uploading done
      setRefetch((prev) => prev + 1)
    } catch (error) {
      console.error(error)
    }
  }

  // upload all files in the queue and clear the queue after all files are uploaded
  const uploadAllFiles = async (filesToUpload: UploadFilesArgType[]) => {
    const uploadPromises = filesToUpload.map((file) =>
      handleUploadPathologicalImage(file),
    )
    try {
      await Promise.all(uploadPromises)
      setFiles([])
    } catch (error) {
      console.error(error)
    }
  }

  // build temporal cards while uploading and discard them when upload is done
  useEffect(() => {
    const filteredFiles = files.filter(
      (file) => uploadingPercents[file.name] !== 100,
    )
    const temporalCards = filteredFiles.map((file) => {
      const temporalFile = createTemporalFileWhileUploading(
        file.name,
        file.organ_type_id,
        file.date_of_acquisition,
        file.person_in_charge,
        file.note,
      )
      return temporalFile
    })
    setTemporalCards(temporalCards)
  }, [files])

  return (
    <UploadFilesContext.Provider
      value={{
        errorMessage,
        handleQueueFiles,
        uploadingPercents,
        temporalCards,
        refetch,
      }}
    >
      {children}
    </UploadFilesContext.Provider>
  )
}

const useUploadFilesContext = () => {
  const context = useContext(UploadFilesContext)
  if (context === undefined) {
    throw new Error(
      'useRoiInteracts must be used within a RoiInteractsProvider',
    )
  }
  return context
}

export type UploadFilesArgType = {
  file: File
  organ_type_id: number
  name: string
  person_in_charge: string
  date_of_acquisition: string
  note: string
}

export default UploadFiles
export { useUploadFilesContext }

const createTemporalFileWhileUploading = (
  name: string,
  organ_type_id: number,
  date_of_acquisition: string,
  person_in_charge: string,
  note: string,
): PathologicalImage => {
  const nowTime = new Date().toString()
  const file: PathologicalImage = {
    id: -Math.random(),
    uuid: '',
    user_id: 1,
    directory_id: 1,
    original_filename: name,
    name,
    created_at: nowTime,
    updated_at: nowTime,
    organ_type_id,
    person_in_charge,
    date_of_acquisition,
    note: note,
    is_processed: false,
    ifff_url: null,
    organ_type: {
      id: 1,
      name: '',
      created_at: nowTime,
      updated_at: nowTime,
    },
    thumbnail_url: '/images/Uploading.png',
  }
  return file
}
