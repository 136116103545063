import { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import {
  AppBar,
  SxProps,
  Theme,
  Toolbar,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Box,
} from '@mui/material'
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined'

import theme from '../../theme'
import CommonValue from '../../contexts/CommonValue'
import useTranslation from '../../hooks/useTranslation'

type Props = {
  titleLogo: string
  userId: string
}

export default function Header({ titleLogo, userId }: Props): JSX.Element {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const { globalLanguage } = useContext(CommonValue)
  const t = useTranslation(globalLanguage)

  const logoSrc = '/images/' + titleLogo

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const navigate = useNavigate()

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleLogout = () => {
    window.localStorage.removeItem('access_token')
    window.localStorage.removeItem('uid')
    window.localStorage.removeItem('client')
    navigate('/sign_in')
  }

  return (
    <AppBar sx={appBarStyle}>
      <Toolbar>
        <Box sx={titleStyle}>
          <img src={logoSrc} alt='logos' loading='lazy' height={'40px'} />
        </Box>
        <IconButton
          edge='end'
          color='inherit'
          sx={{ borderRadius: '0.5rem' }}
          onClick={handleClick}
        >
          <Typography>{userId}</Typography>
          <KeyboardArrowDownOutlinedIcon />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
        >
          <MenuItem onClick={handleLogout}>{t.log_out}</MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  )
}

const appBarStyle: SxProps<Theme> = {
  position: 'static',
  background: theme.palette.bgPrimary.main,
  height: '65px',
}

const titleStyle: SxProps<Theme> = {
  variant: 'h6',
  fontWeight: 'bold',
  color: 'transparent',
  background: 'linear-gradient(0deg, #35BFC8 0, #A9FFDD 100% )',
  WebkitBackgroundClip: 'text',
  flexGrow: 1,
}
