import { Box, SxProps, Theme } from '@mui/material'
import theme from '../../theme'

export default function DragBar(): JSX.Element {
  const numBars = 10 // Boxの数
  const bars = []
  for (let i = 0; i < numBars; i++) {
    bars.push(<Box key={i} sx={dragBarStyle} />)
  }
  return <Box sx={boxIconStyle}>{bars}</Box>
}

const dragBarStyle: SxProps<Theme> = {
  backgroundColor: '#555555',
  height: '5px',
  width: '1px',
  margin: '1px',
}

const boxIconStyle: SxProps<Theme> = {
  borderRadius: '6px 6px 0 0',
  backgroundColor: theme.palette.bgPrimary.main,
  height: '16px',
  width: '240px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}
