import L, { Icon } from 'leaflet'
import { Polyline, Tooltip, Marker, useMap } from 'react-leaflet'
import { getScaleMap } from '../../constants/utils'
import { useEffect, useState } from 'react'
import theme from '../../theme'

interface Props {
  mpp: number
}
export const MapMarkers = ({ mpp }: Props) => {
  const map = useMap()
  const [markersState, setMarkersState] = useState<L.LatLng[] | []>([])

  const calculateDistance = (startMarker: L.LatLng, endMarker: L.LatLng) => {
    const start_x = startMarker.lng
    // const start_y = startMarker.lat
    const end_x = endMarker.lng
    const end_y = endMarker.lat

    const scale = getScaleMap(map)
    const displayMpp = mpp * scale
    return Math.sqrt((start_x - end_x) ** 2 + (end_x - end_y) ** 2) * displayMpp
  }

  const calculateMidpoint = (startMarker: L.LatLng, endMarker: L.LatLng) => {
    return L.latLng(
      (startMarker.lat + endMarker.lat) / 2,
      (startMarker.lng + endMarker.lng) / 2,
    )
  }

  const handleDragEnd = (i: number) => {
    return (e: L.DragEndEvent) => {
      const updatedMarkers = [...markersState]
      updatedMarkers[i] = e.target.getLatLng()
      setMarkersState(updatedMarkers)
    }
  }

  useEffect(() => {
    const resetMarkers = () => {
      setMarkersState([])
    }

    window.addEventListener('keydown', (e) => {
      if (e.key === 'Escape') {
        resetMarkers()
      }
    })

    return () => {
      window.removeEventListener('keydown', resetMarkers)
    }
  }, [map, mpp, markersState]) // Necessary to render the values when user scrolls

  useEffect(() => {
    const handleMapClick = (e: L.LeafletMouseEvent) => {
      if (e.originalEvent.shiftKey) {
        e.originalEvent.stopPropagation()
        const newMarker = e.latlng
        setMarkersState((prev) => [...prev, newMarker])
      }
    }
    map.on('click', handleMapClick)
    return () => {
      map.off('click', handleMapClick)
    }
  }, [map]) // on render only add the event listener to add user's markers data

  return (
    <>
      <div>
        {markersState.map((mark, i) => {
          return (
            <Marker
              draggable
              key={i + ' Mark'}
              position={mark}
              icon={
                new Icon({
                  iconUrl: '/images/markIcon.svg',
                  iconSize: [30, 30],
                  iconAnchor: [15, 15],
                })
              }
              eventHandlers={{ dragend: handleDragEnd(i) }}
            />
          )
        })}
        {markersState.slice(0, -1).map((mark, i) => {
          const nextMark = markersState[i + 1]
          const distance = calculateDistance(mark, nextMark)
          const midpoint = calculateMidpoint(mark, nextMark)

          return (
            <Polyline
              color={theme.palette.primary.main}
              key={i + ' Polyline'}
              positions={[mark, nextMark]}
            >
              <Tooltip
                permanent
                direction='center'
                offset={[0, 0]}
                opacity={1}
                position={midpoint}
              >
                <p>{distance.toFixed(3)} μm</p>
              </Tooltip>
            </Polyline>
          )
        })}
      </div>
    </>
  )
}
