import React, { useContext } from 'react'
import CommonValue from '../../contexts/CommonValue'
import useTranslation from '../../hooks/useTranslation'

export const NotFound = () => {
  const { globalLanguage } = useContext(CommonValue)
  const t = useTranslation(globalLanguage)

  return (
    <>
      <h1>404</h1>
      <h3>{t.not_found}</h3>
    </>
  )
}
