export const constants = {
  roiAppearance: {
    weight: 1,
    opacity: 0.75,
  },

  debug: {
    adjustScale: 4,
  },
} as const
