import { useEffect, useState } from 'react'

export const useFileListSort = (): FileListHookReturn => {
  const [searchInput, setSearchInput] = useState<string>('')
  const [selectValue, setSelectValue] = useState<string>('1')
  const [isAscending, setIsAscending] = useState<boolean>(true)

  const handleIsAscending = () => {
    const newIsAscending = !isAscending
    setIsAscending(newIsAscending)
    window.localStorage.setItem('isAscending', newIsAscending.toString())
  }

  const handleSearch = (text: string) => {
    setSearchInput(text)
    if (text.length === 0) {
      window.localStorage.removeItem('searchInput')
    }
  }

  useEffect(() => {
    if (selectValue !== '1' || searchInput) {
      window.localStorage.setItem('searchInput', searchInput)
      window.localStorage.setItem('selectValue', selectValue)
    }
  }, [selectValue, searchInput])

  useEffect(() => {
    const localSearchInput = window.localStorage.getItem('searchInput')
    const localSelectValue = window.localStorage.getItem('selectValue')
    const localIsAscending = window.localStorage.getItem('isAscending')
    if (!searchInput || selectValue !== '1') {
      setSearchInput(localSearchInput || '')
      setSelectValue(localSelectValue || '1')
    }
    setIsAscending(localIsAscending === 'true')
  }, []) // Necessary to keep user sort and search input after refresh or back to the page

  return {
    handleSetSort: setSelectValue,
    handleSetIsAscending: handleIsAscending,
    handleSetSearchInput: setSearchInput,
    sortKeysValues: {
      searchInput,
      selectValue,
      isAscending,
    },
    handleSearch,
  }
}

interface FileListHookReturn {
  handleSetSort: (value: string) => void
  handleSetIsAscending: () => void
  handleSetSearchInput: (value: string) => void
  sortKeysValues: {
    searchInput: string
    selectValue: string
    isAscending: boolean
  }
  handleSearch: (text: string) => void
}
