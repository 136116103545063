import UncheckedIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import BackgroundIcon from '@mui/icons-material/SquareRounded'
import CheckedOutlineIcon from '@mui/icons-material/CheckBoxOutlined'
import { Box, SxProps, Theme } from '@mui/material'
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined'

type CheckBoxProps = {
  onChange: (isChecked: boolean) => void
  checked?: boolean
  sx?: SxProps<Theme>
  clickZone: string
}

export default function CheckBox({
  onChange,
  checked,
  sx,
  clickZone,
}: CheckBoxProps): JSX.Element {
  const handleToggle = (event: MouseEvent): void => {
    event.stopPropagation()
    onChange(!checked)
  }

  const combinedSx = {
    ...boxStyle,
    ...sx,
  }

  return (
    <Box onClick={handleToggle as never} sx={combinedSx}>
      <Box
        sx={{
          position: 'absolute',
          zIndex: 4,
          color: 'transparent',
          width: clickZone,
          height: clickZone,
        }}
      />
      <BackgroundIcon sx={checked ? bgIconStyle : bgIconInactiveStyle} />
      {checked !== undefined ? (
        <CheckedOutlineIcon
          sx={checked ? checkOutlineStyle : { display: 'none' }}
        />
      ) : (
        <IndeterminateCheckBoxOutlinedIcon sx={checkOutlineStyle} />
      )}
      <UncheckedIcon sx={uncheckedIconStyle} onClick={handleToggle as never} />
    </Box>
  )
}

const boxStyle: SxProps<Theme> = {
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}

const bgIconStyle: SxProps<Theme> = {
  position: 'absolute',
  color: '#444444',
  zIndex: 1,
}

const bgIconInactiveStyle: SxProps<Theme> = {
  position: 'absolute',
  color: '#44444433',
  zIndex: 1,
}

const uncheckedIconStyle: SxProps<Theme> = {
  position: 'absolute',
  color: '#999999',
  cursor: 'pointer',
  zIndex: 3,
}

const checkOutlineStyle: SxProps<Theme> = {
  position: 'absolute',
  cursor: 'pointer',
  color: 'white',
  zIndex: 2,
}
