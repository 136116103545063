import {
  Box,
  Button,
  SxProps,
  Theme,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import { useMapEvents } from 'react-leaflet'
import { PiCursor, PiSelection, PiArrowSquareRight } from 'react-icons/pi'
import { TbLasso, TbEraser, TbDeselect } from 'react-icons/tb'
import L, { LeafletMouseEvent, Point } from 'leaflet'
import theme from '../../../theme'
import { SelectionToolType } from './selectionToolType'
import { useSelectionTool } from './useSelectionTool'
import { Ratio } from '../../../types/Ratio'
import { ROIStatusFloatingPanel } from './ROIStatusFloatingPanel'
import Stack from '@mui/material/Stack'
import { useRoiInteracts } from '../../../contexts/RoiInteracts'
import DrawArea from '../../mapElements/DrawArea'

type SelectionToolMenuProps = {
  id: number
  scaleRatios: Ratio[]
  hdf5: string
}
const SelectionToolMenu = ({
  id,
  scaleRatios,
  hdf5,
}: SelectionToolMenuProps) => {
  const [isShowModal, setIsShowModal] = useState(false)
  const [location, setLocation] = useState({ x: 0, y: 0 })
  const [isShownMenu, setIsShownMenu] = useState(false)
  const [isShowHideRoiOption, setIsShowHideRoiOption] = useState(false)
  const { tool, selectTool, cleanup, handleRegionImage } = useSelectionTool(
    id,
    scaleRatios,
    setIsShowModal,
  )
  const {
    handleRoiDelete,
    rois,
    resetRois,
    isRoiDelete,
    handleDeleteRoiByIndex,
    roisAnalyzed,
    handleSetRoiAnalyzed,
    exclusionZones,
  } = useRoiInteracts()

  const menuWrapRef = useRef<HTMLDivElement>(null)
  const mapSize = useRef<Point>()

  const map = useMapEvents({
    contextmenu: onContextMenu,
    resize: (event) => (mapSize.current = event.newSize),
    click: () => setIsShownMenu(false),
    dragstart: () => setIsShownMenu(false),
    zoom: () => setIsShownMenu(false),
  })

  function onContextMenu(event: LeafletMouseEvent): void {
    const menuWidth = menuWrapRef.current
      ? Number(menuWrapRef.current.offsetWidth)
      : 0
    const menuHeight = menuWrapRef.current
      ? Number(menuWrapRef.current.offsetHeight)
      : 0

    const position = event.containerPoint
    const x = getLocation(position.x, menuWidth, mapSize.current?.x)
    const y = getLocation(position.y, menuHeight, mapSize.current?.y)

    setLocation({ x, y })
    setIsShownMenu(true)

    return

    function getLocation(position: number, menuSize: number, mapSize?: number) {
      return mapSize && position > mapSize - menuSize
        ? position - menuSize
        : position
    }
  }

  function handleToolTypeChange(
    _: React.MouseEvent<HTMLElement>,
    value: SelectionToolType | null,
  ): void {
    if (value == null) return
    if (Array.isArray(value)) {
      selectTool(value[0], true)
      return
    }
    selectTool(value)
  }

  function handleStatusClose() {
    handleSetRoiAnalyzed([])
    cleanup()
    resetRois()
  }

  useEffect(() => {
    mapSize.current = map.getSize()

    if (menuWrapRef.current) {
      L.DomEvent.disableClickPropagation(menuWrapRef.current)
    }
  }, [map])

  const handleContinue = () => {
    setIsShowModal(false)
  }

  const isRenderExtraOptions = isShowHideRoiOption && !isShowModal
  const isOptionsEnabled = Boolean(!hdf5)
  if (isOptionsEnabled) return null
  return (
    <>
      <Box
        ref={menuWrapRef}
        sx={{
          display: isShownMenu ? 'block' : 'none',
          position: 'absolute',
          zIndex: 10000,
          top: `${location.y}px`,
          left: `${location.x}px`,
          ...menuStyle,
        }}
      >
        <Box display={!isShowModal ? 'flex' : 'none'}>
          <ToggleButtonGroup
            disabled={isOptionsEnabled}
            value={tool}
            exclusive
            onChange={handleToolTypeChange}
            onClick={() => {
              setIsShownMenu(false)
            }}
          >
            <ToggleButton value={SelectionToolType.Cursor}>
              <PiCursor size={24} />
            </ToggleButton>
            <ToggleButton value={SelectionToolType.Rectanguler}>
              <PiSelection size={24} />
            </ToggleButton>
            <ToggleButton value={SelectionToolType.Lasso}>
              <TbLasso size={24} />
            </ToggleButton>
            <ToggleButton
              onClick={() => handleRoiDelete(true, 'roi')}
              value={SelectionToolType.eraser}
            >
              <TbEraser size={24} />
            </ToggleButton>
          </ToggleButtonGroup>
          <Box
            borderRadius={'8px'}
            width={'48px'}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            position={'relative'}
            onMouseEnter={() => setIsShowHideRoiOption(true)}
            onMouseLeave={() => setIsShowHideRoiOption(false)}
          >
            <TbDeselect size={24} />
            {isRenderExtraOptions && (
              <ToggleButtonGroup
                disabled={isOptionsEnabled}
                onChange={handleToolTypeChange}
                onClick={() => {
                  setIsShownMenu(false)
                }}
                orientation={'vertical'}
                sx={{ ...subMenuStyle }}
              >
                <ToggleButton value={SelectionToolType.Rectanguler}>
                  <PiSelection size={24} />
                </ToggleButton>
                {/*<ToggleButton value={SelectionToolType.Lasso}>*/}
                {/*  <TbLasso size={24} />*/}
                {/*</ToggleButton>*/}
                <ToggleButton
                  onClick={() => handleRoiDelete(true, 'exclude')}
                  value={SelectionToolType.eraser}
                >
                  <TbEraser size={24} />
                </ToggleButton>
                <ToggleButton
                  onClick={() => handleRegionImage(true)}
                  value={SelectionToolType.Cursor}
                >
                  <PiArrowSquareRight size={24} />
                </ToggleButton>
              </ToggleButtonGroup>
            )}
          </Box>
        </Box>
      </Box>

      <ROIStatusFloatingPanel
        status={roisAnalyzed}
        onClose={handleStatusClose}
      />
      <DrawArea
        roisList={rois}
        color={'lightgreen'}
        renderLabel={true}
        isDeleteEnabled={isRoiDelete.isActivated}
        handleDeleteRoiByIndex={(i: number) => handleDeleteRoiByIndex(i, false)}
        onDeleteDone={() => handleRoiDelete(false, 'roi')}
      />
      <DrawArea
        roisList={exclusionZones}
        opacity={0.3}
        color={'red'}
        renderLabel={false}
        isDeleteEnabled={isRoiDelete.isActivated}
        handleDeleteRoiByIndex={(i: number) => handleDeleteRoiByIndex(i, true)}
        onDeleteDone={() => handleRoiDelete(false, 'exclude')}
      />

      {/*Render every rectangle by user ROI selection */}
      {isShowModal && (
        <Stack
          padding={2}
          width={300}
          justifyContent={'space-between'}
          direction={'row'}
          sx={popupStyle}
        >
          <Button
            sx={{
              width: '100px',
            }}
            onClick={() => {
              handleRegionImage()
              setIsShowModal(false)
            }}
            variant={'contained'}
          >
            Run
          </Button>
          <Button
            onClick={handleContinue}
            sx={{
              width: '100px',
            }}
            variant={'outlined'}
          >
            Continue
          </Button>
        </Stack>
      )}
      {/*Modal is displayed on every user selection roi process*/}
    </>
  )
}

const menuStyle: SxProps<Theme> = {
  backgroundColor: theme.palette.background.default,
  color: theme.palette.primary.contrastText,
}

const popupStyle: SxProps<Theme> = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  zIndex: 1000,
  backgroundColor: 'white',
  borderRadius: '8px',
  transform: 'translate(-50%, -50%)',
}

const subMenuStyle: SxProps<Theme> = {
  ...menuStyle,
  position: 'absolute',
  left: 0,
  top: '100%',
}

export default SelectionToolMenu
