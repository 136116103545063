import { useContext, useState, useRef, useEffect } from 'react'
import {
  Box,
  Typography,
  TextField,
  Dialog,
  SxProps,
  Theme,
  Grid,
  DialogContent,
  LinearProgress,
} from '@mui/material'

import theme from '../../theme'
import StandardButton from '../buttons/StandardButton'
import MiddleOutlineButton from '../buttons/MiddleOutlineButton'
import CommonValue from '../../contexts/CommonValue'
import useTranslation from '../../hooks/useTranslation'
import MiddleButton from '../buttons/MiddleButton'
import CloseButton from '../buttons/CloseButton'
import { useIhcUpload } from '../../hooks/useIhcUpload'

interface IhcImageUploadeProps {
  open: boolean
  onClose: () => void
  id: number
}

export const IhcImageUploader = ({
  open,
  onClose,
  id,
}: IhcImageUploadeProps) => {
  const [selectedFile, setSelectedFile] = useState<File>()
  const [name, setName] = useState<string>('')
  const [color, setColor] = useState<string>('#ffffff')
  const [uoloadingPercent, setUploadingPercent] = useState<number>(0)

  const { globalLanguage } = useContext(CommonValue)
  const tn = useTranslation(globalLanguage)
  const { isLoading, uploadIhcImage } = useIhcUpload()

  const colorPickerRef = useRef<HTMLInputElement>(null)
  const abortControllerRef = useRef(new AbortController())

  const canUpload = !!selectedFile && !!name && !isLoading

  useEffect(() => {
    if (open) initialize()
  }, [open])

  function initialize(): void {
    setSelectedFile(undefined)
    setName('')
    setColor('#ffffff')
    setUploadingPercent(0)
  }

  function handleFileChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const file = event.target.files?.[0]
    setSelectedFile(file)
  }

  function handleNameChange(event: React.ChangeEvent<HTMLInputElement>): void {
    setName(event.target.value)
  }

  function handleColorBoxClick(event: React.MouseEvent<HTMLDivElement>): void {
    if (!isLoading) {
      colorPickerRef.current?.click()
    }
    event.stopPropagation()
  }

  function handleColorChange(event: React.ChangeEvent<HTMLInputElement>): void {
    setColor(event.target.value)
  }

  async function handleUpload(): Promise<void> {
    if (!validate()) {
      console.error('All fields are required')
      return
    }

    const success = await uploadIhcImage(
      id,
      color,
      name!,
      selectedFile!,
      setUploadingPercent,
      abortControllerRef.current,
    )
    if (success) {
      onClose()
    }

    setUploadingPercent(0)
    return

    function validate() {
      return !!selectedFile && !!name
    }
  }

  function handleCanceled(): void {
    if (isLoading) {
      abortControllerRef.current.abort()
      abortControllerRef.current = new AbortController()
      return
    }

    onClose()
  }

  return (
    <Dialog open={open} onClose={onClose} sx={dialogStyle}>
      <DialogContent sx={{ minWidth: '600px', minHeight: '300px' }}>
        <Box sx={containerStyle}>
          <Box sx={horizontalLayoutStyle}>
            <Typography variant='h5' sx={{ marginRight: '40px' }}>
              {tn.ihc_title}
            </Typography>
            <Box>
              <input
                accept='.ndpi,.svs,.tiff,.tif'
                type='file'
                id='upload-button-file'
                style={{ display: 'none' }}
                onChange={handleFileChange}
              />
              <StandardButton
                text={tn.ihc_select_file}
                htmlFor='upload-button-file'
                disabled={isLoading}
              />
            </Box>
          </Box>

          <Box sx={horizontalLayoutStyle}>
            <Typography sx={labelFieldStyle}>
              {tn.ihc_selected_file_label}
            </Typography>
            <Typography>{selectedFile?.name ?? 'None'}</Typography>
          </Box>
          <Box sx={horizontalLayoutStyle}>
            <Typography sx={labelFieldStyle}>{tn.ihc_name_label}</Typography>
            <TextField
              margin='dense'
              fullWidth
              sx={textFieldStyle}
              disabled={isLoading}
              onChange={handleNameChange}
            />
          </Box>

          <Box sx={horizontalLayoutStyle}>
            <Typography sx={labelFieldStyle}>{tn.ihc_color_label}</Typography>
            <Box
              sx={
                isLoading
                  ? colorPickerContainerDisableStyle
                  : colorPickerContainerStyle
              }
              onClick={handleColorBoxClick}
            >
              <Box sx={{ ...colorPickerStyle, backgroundColor: color }} />
              <input
                ref={colorPickerRef}
                type='color'
                value={color}
                style={{
                  width: '0',
                  opacity: 0,
                  position: 'absolute',
                  pointerEvents: 'none',
                }}
                onChange={handleColorChange}
              />
            </Box>
            <Typography>{color}</Typography>
          </Box>

          <Grid
            container
            spacing={2}
            sx={{ marginTop: 2 }}
            alignItems='center'
            justifyContent='flex-end'
          >
            {isLoading && (
              <Grid item xs>
                <Box sx={{ marginRight: '20px' }}>
                  <LinearProgress
                    variant='determinate'
                    value={uoloadingPercent}
                  />
                </Box>
              </Grid>
            )}
            <Grid item xs='auto'>
              <MiddleOutlineButton text={tn.cancel} onClick={handleCanceled} />
            </Grid>
            <Grid item xs='auto'>
              <MiddleButton
                text={tn.upload}
                onClick={handleUpload}
                disabled={!canUpload}
              />
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ position: 'absolute', top: 0, right: 0 }}>
          <CloseButton onClick={onClose} disabled={isLoading} />
        </Box>
      </DialogContent>
    </Dialog>
  )
}

const dialogStyle: SxProps<Theme> = {
  zIndex: 1800,
}

const containerStyle: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
}

const horizontalLayoutStyle: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '20px',
  marginBottom: '20px',
}

const colorPickerStyle: SxProps<Theme> = {
  border: '1px solid #000',
  width: '20px',
  height: '20px',
  display: 'inline-block',
  marginLeft: '10px',
}

const colorPickerContainerStyle: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
}

const colorPickerContainerDisableStyle: SxProps<Theme> = {
  ...colorPickerContainerStyle,
  cursor: 'arrow',
}

const textFieldStyle: SxProps<Theme> = {
  '& input': {
    height: '26px',
    padding: '5px 12px',
  },
  '& fieldset': {
    paddingTop: '8px',
    paddingBottom: '8px',
  },
  backgroundColor: theme.palette.bgSecondary.main,
}

const labelFieldStyle: SxProps<Theme> = {
  minWidth: '120px',
}
