import {
  Box,
  LinearProgress,
  SxProps,
  Theme,
  Tooltip,
  Typography,
} from '@mui/material'
import theme from '../../theme'
import { PathologicalImage } from '../../types/FileDataList'
import { useContext, useEffect, useState } from 'react'
import CheckBox from '../inputs/CheckBox'
import { getFormattedDate } from '../../lib/Utility'
import CommonValue from '../../contexts/CommonValue'
import useTranslation from '../../hooks/useTranslation'
import { fetchBlobs } from '../../lib/BlobsClient'

type Props = {
  onClick: (rowData: PathologicalImage) => void
  content: PathologicalImage
  onCheckboxChange: (image: PathologicalImage, isChecked: boolean) => void
  checked: boolean
  uploadProgress: number
  fetchFileDataList: () => void
}

function ThumbnailBox({ content }: { content: PathologicalImage }) {
  const [imgSrc, setImgSrc] = useState<string | null>(null)

  useEffect(() => {
    const image_url =
      content.thumbnail_url === '/images/Uploading.png' ||
      !content.thumbnail_url
        ? content.thumbnail_url || '/images/AI_Processing.png'
        : `${window.location.protocol}//${window.location.hostname}/fcgi-bin/iipsrv.fcgi?${content.thumbnail_url}`
    fetchBlobs(image_url).then((blob) => {
      const url = URL.createObjectURL(blob)
      setImgSrc(url)
    })
  }, [content])

  return (
    <>
      {imgSrc && (
        <Box
          component='img'
          src={imgSrc}
          alt={content.name}
          sx={cardMediaStyle}
        />
      )}
    </>
  )
}

export default function PicCard({
  onClick,
  content,
  onCheckboxChange,
  checked,
  uploadProgress,
}: Props): JSX.Element {
  const { globalLanguage } = useContext(CommonValue)
  const t = useTranslation(globalLanguage)
  const [progress, setProgress] = useState(uploadProgress)

  const handleCheckboxChange = (isChecked: boolean) => {
    onCheckboxChange(content, isChecked)
  }

  useEffect(() => {
    setProgress(uploadProgress)
  }, [uploadProgress])

  return (
    <Box sx={cardStyle} onClick={() => onClick(content)}>
      <Box sx={relativeBoxStyle}>
        <ThumbnailBox content={content} />
        <Box sx={checkboxContainerStyle}>
          <CheckBox
            checked={checked}
            sx={checkboxStyle}
            onChange={handleCheckboxChange}
            clickZone='50px'
          />
        </Box>
        {content.thumbnail_url === '/images/Uploading.png' &&
          !content.is_processed && (
            <Box
              sx={{
                position: 'absolute',
                bottom: 10,
                width: '100%',
                paddingX: '10px',
              }}
            >
              <LinearProgress variant='determinate' value={progress} />
            </Box>
          )}
        <Box sx={contentContainerStyle}>
          <Tooltip
            disableHoverListener={content.name.length < 80}
            title={content.name}
            placement='bottom'
          >
            <Typography sx={TitleStyle}>{content.name}</Typography>
          </Tooltip>
          <Typography
            sx={ContentStyle}
          >{`${getFormattedDate(new Date(content.created_at), t.dateFormatWithTime)}`}</Typography>
        </Box>
      </Box>
    </Box>
  )
}

const cardStyle: SxProps<Theme> = {
  maxWidth: 250,
  minHeight: 250,
  backgroundColor: 'transparent',
  cursor: 'pointer',
  transition: 'background-color 0.3s ease',
  '&:hover': {
    backgroundColor: theme.palette.bgSecondary.main,
  },
  borderRadius: '10px',
}

const relativeBoxStyle: SxProps<Theme> = {
  position: 'relative',
}

const cardMediaStyle: SxProps<Theme> = {
  padding: 1,
  height: '200px',
  width: '250px',
  borderRadius: '20px',
  objectFit: 'contain',
  position: 'relative',
  marginTop: '25px',
}

//TODO: In case top change is accepted, delete this
// const overlayImageStyle: SxProps<Theme> = {
//   position: 'absolute',
//   top: 0,
//   left: 0,
//   height: '200px',
//   objectFit: 'contain',
//   marginTop: '25px'
// };
//
// const overlayImgStyle: React.CSSProperties = {
//   height: '100%',
//   width: '100%',
//   borderRadius: '20px',
//   objectFit: 'cover',
//   padding: 8,
//   opacity: 0.7,
// };

const checkboxContainerStyle: SxProps<Theme> = {
  position: 'absolute',
  top: '2px',
  right: '5px',
}

const checkboxStyle: SxProps<Theme> = {
  paddingRight: 2.5,
  paddingTop: 2.5,
}

const contentContainerStyle: SxProps<Theme> = {
  paddingX: 1,
}

const TitleStyle: SxProps<Theme> = {
  color: theme.palette.primary.contrastText,
  fontSize: '14px',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  lineHeight: '1.1',
  height: '2.2em',
}

const ContentStyle: SxProps<Theme> = {
  color: theme.palette.line.main,
  fontSize: '14px',
  pb: 1,
}
