import { IconButton, SxProps, Theme } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

import theme from '../../theme'

type Props = {
  onClick?: () => void
  disabled?: boolean
  padding?: string
}

export default function CloseButton({
  onClick,
  disabled,
  padding = '8px',
}: Props): JSX.Element {
  return (
    <IconButton onClick={onClick} disabled={disabled} sx={{ padding: padding }}>
      <CloseIcon sx={disabled ? disableIconStyle : iconStyle} />
    </IconButton>
  )
}

const iconStyle: SxProps<Theme> = {
  color: theme.palette.primary.contrastText,
}

const disableIconStyle: SxProps<Theme> = {
  color: theme.palette.text.disabled,
}
