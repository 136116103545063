import { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { ApiClient } from '../lib/ApiClient'
import { PathologicalImage } from '../types/PathologicalImage'
import CommonValue from '../contexts/CommonValue'
import useTranslation from './useTranslation'
import { HTTP_STATUS_CODE } from '../constants/ErrorCode'
import { responseError } from '../types/CommonTypes'

/**
 * usePathologicalImageUpload Hooks
 *
 * @returns isLoading
 * @returns errorMessage
 * @returns uploadPathologicalImage
 */
export const usePathologicalImageUpload = () => {
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [isLoading, setIsLoading] = useState(false)
  const { globalLanguage } = useContext(CommonValue)
  const t = useTranslation(globalLanguage)

  const uploadPathologicalImage = async (
    file: File,
    organ_type_id: number,
    name: string,
    person_in_charge: string,
    date_of_acquisition: string,
    note: string,
    setUploadingPercents: (percents: number) => void,
  ) => {
    setIsLoading(true)
    setErrorMessage('')

    const url = '/pathological_images'
    const directoryId = window.localStorage.getItem('directory_id')

    const formData = new FormData()
    formData.append('parent_directory_id', directoryId ?? '')
    formData.append('organ_type_id', organ_type_id.toString())
    formData.append('name', name)
    formData.append('person_in_charge', person_in_charge)
    formData.append('date_of_acquisition', date_of_acquisition)
    formData.append('note', note)
    formData.append('upload_file', file)

    await ApiClient.post<PathologicalImage>(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: (progressEvent) => {
        const percentCompleted = progressEvent.total
          ? Math.round((progressEvent.loaded * 100) / progressEvent.total)
          : 0
        setUploadingPercents(percentCompleted)
      },
    }).catch((error: responseError) => {
      setErrorMessage(t.message.UPLOAD_IMAGE_ERROR)
      setIsLoading(false)
    })

    setIsLoading(false)
  }

  return {
    errorMessage,
    isLoading,
    uploadPathologicalImage,
  }
}
