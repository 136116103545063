import { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { ApiClient } from '../lib/ApiClient'
import { FileDataList, PathologicalImage } from '../types/FileDataList'
import CommonValue from '../contexts/CommonValue'
import useTranslation from './useTranslation'
import { HTTP_STATUS_CODE } from '../constants/ErrorCode'
import { sortKeyDropDownValuesPerId } from '../lib/Utility'
import { responseError } from '../types/CommonTypes'

/**
 * useFileDataList Hooks
 *
 * @returns images
 * @returns errorMessage
 * @returns isLoading
 * @returns fetchFileDataList
 */
export const useFileDataList = (): FileDataListProps => {
  const [directoryData, setDirectoryData] = useState<FileDataList | null>(null)
  const [directoryPagination, setDirectoryPagination] = useState({
    page: 1,
    totalPage: 1,
  })
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [isLoading, setIsLoading] = useState(false)
  const [userId, setUserId] = useState('')
  const { globalLanguage } = useContext(CommonValue)
  const t = useTranslation(globalLanguage)
  const perPage = 20

  const navigate = useNavigate()

  const fetchFileDataList = async (page: number): Promise<void> => {
    setIsLoading(true) // ローディング開始
    setErrorMessage('') // エラーメッセージを初期化

    // local storage selected values by the user
    const localSearchInput = window.localStorage.getItem('searchInput')
    const localSelectValue = window.localStorage.getItem('selectValue')
    const localIsAscending = window.localStorage.getItem('isAscending')
    const url = '/directories/root'
    const sortDropDownValue = sortKeyDropDownValuesPerId.get(
      Number(localSelectValue),
    )

    await ApiClient.get<FileDataList>(url, {
      params: {
        page: page,
        per: perPage,
        is_asc: localIsAscending,
        sort_search_input: localSearchInput,
        sort_key: sortDropDownValue,
      },
    })
      .then((response): void => {
        // レスポンスから利用したい要素を FileDataList 型の配列でセット
        setDirectoryData(response.data)
        setDirectoryPagination({
          ...directoryPagination,
          page: response.data.pagination.current_page,
          totalPage: response.data.pagination.total_pages,
        })

        window.localStorage.setItem(
          'access_token',
          response.headers['access-token'],
        )
        window.localStorage.setItem('uid', response.headers['uid'])
        window.localStorage.setItem('client', response.headers['client'])
        setUserId(response.headers['uid'])
      })
      .catch((error: responseError) => {
        if (error.response.status === HTTP_STATUS_CODE.UNAUTHORIZED) {
          navigate('/sign_in')
        }
        setErrorMessage(t.message.FETCH_LIST_DATA_ERROR)
      })
    setIsLoading(false)
  }

  const fetchFileData = async (page?: number) => {
    page = page ? page : directoryPagination.page
    await fetchFileDataList(page)
  }

  const handleAttachFile = (fileItem: PathologicalImage) => {
    const files = [...(directoryData?.pathological_image || []), fileItem]
    const newDirectoryData = {
      ...directoryData,
      pathological_image: files,
    }
    setDirectoryData(newDirectoryData as FileDataList)
  }

  return {
    directoryData,
    errorMessage,
    isLoading,
    userId,
    fetchFileData,
    filePagination: directoryPagination,
    handleAttachFile,
  }
}

interface FileDataListProps {
  handleAttachFile: (fileItem: PathologicalImage) => void
  directoryData: FileDataList | null
  errorMessage: string
  isLoading: boolean
  userId: string
  fetchFileData: (page?: number) => Promise<void>
  filePagination: {
    page: number
    totalPage: number
  }
}
