import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  ReactNode,
} from 'react'
import { LeafletProvider, useLeafletContext } from '@react-leaflet/core'
import { Layer } from 'leaflet'
import { useMap } from 'react-leaflet'

import { useLayerControlContext } from './layerControlContext'

interface IProps {
  checked?: boolean
  name: string
  color: number
  group: string
  children: ReactNode[] | ReactNode
}

const createControlledLayer = (
  addLayerToControl: (
    layerContext: {
      addGroup: (
        layer: Layer,
        name: string,
        color: number,
        group: string,
      ) => void
    },
    layer: Layer,
    name: string,
    color: number,
    group: string,
  ) => void,
) => {
  const ControlledLayer = (props: IProps) => {
    const context = useLeafletContext()
    const layerContext = useLayerControlContext()
    const propsRef = useRef(props)
    const parentMap = useMap()

    const [layer, setLayer] = useState<Layer | null>(null)

    const addLayer = useCallback(
      (layerToAdd: Layer) => {
        if (propsRef.current.checked) {
          parentMap.addLayer(layerToAdd)
        }

        addLayerToControl(
          layerContext,
          layerToAdd,
          propsRef.current.name,
          propsRef.current.color,
          propsRef.current.group,
        )
        setLayer(layerToAdd)
      },
      [context],
    )

    const removeLayer = useCallback(
      (layerToRemove: Layer) => {
        context.layersControl?.removeLayer(layerToRemove)
        setLayer(null)
      },
      [context],
    )

    const newContext = useMemo(() => {
      return context
        ? Object.assign({}, context, {
            layerContainer: {
              addLayer,
              removeLayer,
            },
          })
        : null
    }, [context, addLayer, removeLayer])

    useEffect(() => {
      if (layer !== null && propsRef.current !== props) {
        if (
          props.checked === true &&
          (propsRef.current.checked == null || !propsRef.current.checked)
        ) {
          parentMap.addLayer(layer)
        } else if (
          propsRef.current.checked === true &&
          (props.checked == null || !props.checked)
        ) {
          parentMap.removeLayer(layer)
        }

        propsRef.current = props
      }

      return () => {
        if (layer !== null) {
          context.layersControl?.removeLayer(layer)
        }
      }
    }, [layer])

    return props.children
      ? React.createElement(
          LeafletProvider,
          {
            value: newContext,
          },
          props.children,
        )
      : null
  }

  return ControlledLayer
}

export default createControlledLayer
