import { useContext, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'

import Box from '@mui/material/Box'

import Sidebar from '../../rayoutItem/Sidebar'
import Leaflet from '../leaflets/LeafletView'

import { useVewerHook } from '../../../hooks/useViewer'

import { DetailPathologicalImages } from '../../../types/DetailPathologicalImages'
import CommonValue from '../../../contexts/CommonValue'
import useTranslation from '../../../hooks/useTranslation'
import { Umap } from './Umap'
import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels'
import RoiInteracts from '../../../contexts/RoiInteracts'
import { appColorCodes } from '../../../constants/utils'
import ViewContext from '../../../contexts/ViewContext'

/**
 * Viewer View
 * @returns JSX
 */
export default function Viewer(): JSX.Element {
  const location = useLocation()
  const { globalLanguage } = useContext(CommonValue)
  const t = useTranslation(globalLanguage)
  const search = useLocation().search
  const query = new URLSearchParams(search)
  if (location.state === null) {
    location.state = { id: query.get('uuid') }
  }
  const { data } = useVewerHook(location.state.id)
  const infoData = setInfoData(data, t)
  const [currentContent, setCurrentContent] = useState<contentType>('ROI')

  const pageContent = useMemo(() => {
    switch (currentContent) {
      case 'ROI':
        if (data === undefined) return null
        return (
          <RoiInteracts>
            <Leaflet
              id={data?.id}
              mpp={data?.mpp} // TODO: Change later to context hold map share data on components
              base_layer_url={
                window.location.protocol +
                '//' +
                window.location.hostname +
                '/fcgi-bin/iipsrv.fcgi?' +
                data?.ifff_url
              }
              analysis_result={data?.analysis_result}
              hdf5={data.hdf5}
            />
          </RoiInteracts>
        )
      case 'UMAP':
        return (
          <>
            <RoiInteracts>
              <PanelGroup direction={'horizontal'}>
                <Panel
                  style={{
                    backgroundColor: appColorCodes.blackSecondary,
                  }}
                  defaultSize={30}
                >
                  <Umap />
                </Panel>
                <PanelResizeHandle
                  style={{
                    backgroundColor: appColorCodes.blackSecondary,
                    width: '6px',
                  }}
                />
                <Panel defaultSize={70}>
                  <Leaflet
                    id={data!.id}
                    mpp={data!.mpp} // TODO: Change later to context hold map share data on components
                    base_layer_url={
                      window.location.protocol +
                      '//' +
                      window.location.hostname +
                      '/fcgi-bin/iipsrv.fcgi?' +
                      data?.ifff_url
                    }
                    analysis_result={data!.analysis_result}
                    hdf5={data?.hdf5 ?? ''}
                  />
                </Panel>
              </PanelGroup>
            </RoiInteracts>
          </>
        )
    }
  }, [data, infoData])

  return (
    <ViewContext>
      <Box sx={{ position: 'relative' }}>
        <Sidebar
          id={data ? data.id : undefined}
          uuid={data ? data.uuid : undefined}
          directory_id={data ? data.directory_id : undefined}
          specimen_id={data ? data.name : ''}
          infoData={infoData}
          date_of_acquisition={data ? data.date_of_acquisition : null}
          carcinoma={data ? data.organ_type.name : ''}
          person_in_charge={data ? data.person_in_charge : ''}
          note={data?.note ? data.note : ''}
          handleContentChange={setCurrentContent}
        >
          {pageContent}
        </Sidebar>
      </Box>
    </ViewContext>
  )
}

/**
 * インフォメーションデータ作成
 *
 * @returns string エラー内容
 * @returns DetailPathologicalImages 基本情報
 */
function setInfoData(
  data: DetailPathologicalImages | undefined,
  t: { message: { NOT_DATA: string } },
) {
  if (data === undefined) {
    return t.message.NOT_DATA
  }
  const infoData = []
  for (const layerKey in data.analysis_result.statistics) {
    const elememts = data.analysis_result.statistics[layerKey]
    const items = elememts.map((element) => {
      return { label: element.key + '', value: element.value + '' }
    })
    infoData.push({ head: layerKey, items: items })
  }
  return infoData
}

type contentType = 'ROI' | 'UMAP'
