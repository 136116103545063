import { ApiClient } from '../lib/ApiClient'

const useLayerSaveState = () => {
  const accessToken = window.localStorage.getItem('access_token')
  const uid = window.localStorage.getItem('uid')
  const client = window.localStorage.getItem('client')

  const postLayerColorByLayerId = async (
    layerType: string,
    color: string,
    layerId: number,
    onSucess: () => void,
  ) => {
    const response = await ApiClient.put(
      '/layer_color',
      {
        layer_type: layerType,
        color: color,
        layer_id: layerId,
      },
      {
        headers: {
          'access-token': accessToken,
          uid: uid,
          client: client,
          'Content-Type': 'application/json',
        },
      },
    ).catch()
    if (response) {
      onSucess()
    }
  }

  const postLayerVisibilityByLayerId = async (
    layerType: string,
    visibility: boolean,
    layerId: number,
    onSucess?: () => void,
  ) => {
    const response = await ApiClient.put(
      '/layer_color',
      { layer_type: layerType, layer_id: layerId, visible: visibility },
      {
        headers: {
          'access-token': accessToken,
          uid: uid,
          client: client,
          'Content-Type': 'application/json',
        },
      },
    ).catch()
    if (response) {
      onSucess && onSucess()
    }
  }

  return {
    postLayerColorByLayerId,
    postLayerVisibilityByLayerId,
  }
}

export default useLayerSaveState
