import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { ApiClient } from '../lib/ApiClient'
import { HTTP_STATUS_CODE } from '../constants/ErrorCode'

import {
  DetailPathologicalImages,
  DetailPathologicalImagesResponse,
} from '../types/DetailPathologicalImages'
import CommonValue from '../contexts/CommonValue'
import useTranslation from './useTranslation'

/**
 * Leaflet Hooks
 *
 * @returns data
 * @returns errorMessage
 * @returns isLoading
 * @returns fetchArticles
 */
export const useVewerHook = (selectedId: number) => {
  const [data, setData] = useState<DetailPathologicalImages>()
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [isDataLoading, setIsDataLoading] = useState(false)
  const { globalLanguage } = useContext(CommonValue)
  const t = useTranslation(globalLanguage)

  const navigate = useNavigate()

  useEffect(() => {
    ;(async () => {
      setIsDataLoading(true) // ローディング開始
      setErrorMessage('') // エラーメッセージを初期化

      const url = '/pathological_images'

      // API通信
      await ApiClient.get<DetailPathologicalImagesResponse>(
        url + '/' + selectedId,
      )
        .then((response) => {
          const d = response.data
          // レスポンスから利用したい要素を DetailPathologicalImages 型 でセット
          setData({
            id: d.id,
            uuid: d.uuid,
            user_id: d.user_id,
            directory_id: d.directory_id,
            original_filename: d.original_filename,
            name: d.name,
            created_at: new Date(d.created_at),
            updated_at: new Date(d.updated_at),
            organ_type_id: d.organ_type_id,
            organ_type: d.organ_type,
            person_in_charge: d.person_in_charge,
            date_of_acquisition: new Date(d.date_of_acquisition),
            note: d.note,
            is_processed: d.is_processed,
            ifff_url: d.ifff_url,
            analysis_result: d.analysis_result,
            mpp: d.mpp,
            hdf5: d.hdf5,
          })

          window.localStorage.setItem(
            'access_token',
            response.headers['access-token'],
          )
          window.localStorage.setItem('uid', response.headers['uid'])
          window.localStorage.setItem('client', response.headers['client'])
        })
        .catch((error) => {
          if (error.response.status === HTTP_STATUS_CODE.UNAUTHORIZED) {
            navigate('/sign_in')
          } else if (error.response.status === HTTP_STATUS_CODE.NOT_FOUND) {
            setErrorMessage(t.message.LOGIN_ERROR)
          }
        })

      setIsDataLoading(false)
    })()
  }, [selectedId])

  return {
    data,
    errorMessage,
    isDataLoading,
  }
}
