const ja = {
  title: 'DeepPathFinder',
  search: '検索',
  specimen_id: '検体ID',
  date_of_acquisition: '採取日',
  carcinoma: '癌腫',
  person_in_charge: '担当者',
  date_of_uploading: 'アップロード時刻',
  status: 'ステータス',
  upload: 'アップロード',
  drag_message: 'ファイルをドロップしてください。',
  selected_file: '選択したファイル: ',
  note: '備考',
  user_id: 'ユーザーID',
  password: 'パスワード',
  sign_in: 'サインイン',
  cell: '細胞',
  tissue: '組織',
  edit: '編集',
  lung: '肺癌',
  colon: '大腸癌',
  breast: '乳癌',
  stomach: '胃癌',
  delete_message: '選択したファイルを削除しますか?',
  delete_message_second: 'ファイルの削除は取り消しできません。',
  delete: '削除',
  cancel: 'キャンセル',
  message: {
    LOGIN_ERROR:
      'ログインに失敗しました。\r\n入力した情報が正しくない可能性があります。',
    FETCH_LIST_DATA_ERROR:
      'リストデータの取得に失敗しました。後ほどお試しください。',
    NOT_DATA: 'データを取得できませんでした。',
    UPLOAD_IMAGE_ERROR:
      '画像のアップロードに失敗しました。後ほどお試しください。',
    DELETE_IMAGE_ERROR: '画像の削除に失敗しました。後ほどお試しください。',
    EXPORT_CSV_ERROR: 'CSVのエクスポートに失敗しました。後ほどお試しください。',
  },
  not_found: 'お探しのページは見つかりませんでした。',
  log_out: 'サインアウト',
  ok: '登録',
  edit_properties: 'プロパティの編集',
  dateFormat: 'yyyy/MM/dd',
  dateFormatWithTime: 'yyyy/MM/dd hh:mm',
  ihc_image_title: 'IHC Image',
  ihc_select_file: 'Select file',
  ihc_selected_file_label: 'Selected file:',
  ihc_name_label: 'IHC name:',
  ihc_color_label: 'Color:',

  roi_status_title_: 'ROI status',
  roi_area_label: 'Area',
  roi_cell_label: 'Cell',
  roi_download_button: 'CSV download',
}

export default ja
