import { LayerOptions, Map, Point } from 'leaflet'
import L from 'leaflet'
import { ROICentroids, ROIData, ROIStatus } from './types/roiTypes'
import { Ratio } from '../../../types/Ratio'
import { constants } from './roiConstants'

export function renderROI(
  map: Map,
  roi: ROIData,
  scaleRatio: Ratio,
): { cleanup: () => void; layer: L.Layer } {
  const featureGroup = createLayer()

  Object.keys(roi).forEach((key) => {
    renderGroup(roi[key].centroids, roi[key].color).addTo(featureGroup)
  })

  featureGroup.addTo(map)

  map.addEventListener('zoomend', onZoomEnd)

  return {
    cleanup: () => {
      map.removeEventListener('zoomend', onZoomEnd)
      map.removeLayer(featureGroup)
    },
    layer: featureGroup,
  }

  function createLayer() {
    const layer = L.featureGroup(undefined, {
      weight: getWeight(),
      interactive: false,
    } as LayerOptions)

    return layer
  }

  function renderGroup(roiCentroids: ROICentroids, color: string) {
    const polylines = roiCentroids.map<L.Layer>((roiPoint) => {
      const [x, y] = roiPoint
      const point = map.unproject(
        new Point(x / scaleRatio.x, y / scaleRatio.y),
        map.getZoom(),
      )

      return L.polyline(
        [
          [point.lat, point.lng],
          [point.lat, point.lng],
        ],
        {
          color,
          weight: getWeight(),
          opacity: constants.roiAppearance.opacity,
          interactive: false,
        },
      )
    })

    return L.featureGroup(polylines)
  }

  function getWeight() {
    return constants.roiAppearance.weight + (map.getZoom() - 2)
  }

  function onZoomEnd() {
    featureGroup.setStyle({ weight: getWeight() })
  }
}

export function downloadCsv(roi: ROIStatus | ROIStatus[]): void {
  if (!roi) return
  let csv
  if (Array.isArray(roi) && roi.length > 0) {
    const roiDataList = roi.map((roiData) => roiData.data) as ROIData[]
    csv = createCsv(roiDataList)
  } else {
    // @ts-ignore
    csv = createCsv(roi.data)
  }

  download(csv)

  return

  function createCsv(roiData: ROIData | ROIData[]) {
    let csvData = ''

    if (Array.isArray(roiData)) {
      roiData.forEach((roi) => {
        csvData += `${Object.values(roi)
          .map((x) => x.name)
          .join(',')}\n`
        csvData += `${Object.values(roi).map(
          (value) => value.centroids.length,
        )}\n`
      })
    } else {
      csvData += `${Object.values(roiData)
        .map((x) => x.name)
        .join(',')}\n`
      csvData += `${Object.values(roiData).map(
        (value) => value.centroids.length,
      )}\n`
    }
    return csvData
  }

  function download(csvData: string) {
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' })
    const url = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', 'roi.csv')
    document.body.appendChild(link)
    link.click()
  }
}
