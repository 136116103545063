import {
  Map,
  LeafletMouseEvent,
  Point,
  Bounds,
  DomUtil,
  LatLng,
  DomEvent,
} from 'leaflet'
import { Ratio } from '../../../../types/Ratio'

export const registerRectangularSelector = (
  map: Map,
  scaleRatios: Ratio[],
  isExcludeSelection: boolean,
) => {
  map.dragging.disable()
  map.boxZoom.disable()

  map.addEventListener('unload', finish)
  map.addEventListener('mousedown', onMouseDown)

  let selectorBox: HTMLDivElement | null
  let startLatLng = new LatLng(0, 0)
  let startPoint = new Point(0, 0)
  let dragging = false

  function finish() {
    if (dragging) {
      DomUtil.remove(selectorBox!)
      DomUtil.removeClass(map.getContainer(), 'leaflet-crosshair')
    }

    DomUtil.enableTextSelection()
    DomUtil.enableImageDrag()

    DomEvent.off(document as unknown as HTMLElement, {
      mousemove: onMouseMove,
      mouseup: onMouseUp,
      keydown: onKeyDown,
    })
  }

  function onMouseDown(event: LeafletMouseEvent) {
    if (event.originalEvent.button !== 0) return false

    dragging = false
    startLatLng = event.latlng
    startPoint = map.mouseEventToContainerPoint(event.originalEvent)

    DomUtil.disableTextSelection()
    DomUtil.disableImageDrag()

    DomEvent.on(document as unknown as HTMLElement, {
      mousemove: onMouseMove,
      mouseup: onMouseUp,
      keydown: onKeyDown,
    })
  }

  function onMouseMove(event: Event) {
    if (!dragging) {
      selectorBox = startSelection()
      dragging = true
    }

    const mouseEvent = event as MouseEvent

    const point = map.mouseEventToContainerPoint(mouseEvent)
    const bounds = new Bounds(point, startPoint)

    DomUtil.setPosition(selectorBox!, bounds.min!)

    const size = bounds.getSize()
    selectorBox!.style.width = size.x + 'px'
    selectorBox!.style.height = size.y + 'px'

    return

    function startSelection() {
      const box = DomUtil.create('div', 'leaflet-zoom-box', map.getContainer())
      DomUtil.addClass(map.getContainer(), 'leaflet-crosshair')

      if (isExcludeSelection) {
        map.fire('excludeselectionstart')
      } else {
        map.fire('selectionstart')
      }
      return box
    }
  }

  function onMouseUp(event: Event) {
    finish()

    if (!dragging) return

    const mouseEvent = event as MouseEvent
    const endLatLng = map.mouseEventToLatLng(mouseEvent)

    const scaleRatio =
      scaleRatios[Math.min(map.getZoom(), scaleRatios.length - 1)]
    const sp = Scale(map.project(startLatLng, map.getZoom()), scaleRatio)
    const ep = Scale(map.project(endLatLng, map.getZoom()), scaleRatio)

    const bounds = new Bounds(sp, ep)
    const points = [
      bounds.getTopLeft(),
      bounds.getTopRight(),
      bounds.getBottomRight(),
      bounds.getBottomLeft(),
    ]
    const squareLocation = {
      start: startLatLng,
      end: endLatLng,
    }

    if (isExcludeSelection) {
      map.fire('excludeselectionend', { bounds, points, squareLocation })
    } else {
      map.fire('selectionend', { bounds, points, squareLocation })
    }
    return

    function Scale(point: Point, ratio: Ratio) {
      return new Point(point.x * ratio.x, point.y * ratio.y)
    }
  }

  function onKeyDown(event: Event) {
    const keyboardEvent = event as KeyboardEvent
    if (keyboardEvent.code !== 'Escape') return

    finish()
  }

  return () => {
    map.dragging.enable()
    map.boxZoom.enable()

    map.removeEventListener('unload', finish)
    map.removeEventListener('mousedown', onMouseDown)
  }
}
