import { createContext, useContext, useEffect, useState } from 'react'
import { Bounds, LatLng, Layer, Point } from 'leaflet'
import {
  ROIObj,
  ROIStatus,
} from '../components/tools/selectionTool/types/roiTypes'

interface RoiDataByKey {
  [key: string]: ROIObj
}

type RoiInteractsContextType = {
  isRoiDelete: {
    type: 'roi' | 'exclude'
    isActivated: boolean
  }
  rois: RoiLayerType[]
  roisAnalyzed: ROIStatus[]
  exclusionZones: RoiLayerType[]
  handleRoiDelete: (val: boolean, type: 'roi' | 'exclude') => void
  handleDeleteRoiByIndex: (index: number, isExclude: boolean) => void
  handleSetRoiData: (data: RoiLayerDataType) => void
  handleSetRoiLayer: (layer: Layer, index: number) => void
  handleSetRoiAnalyzed: (roi: ROIStatus | ROIStatus[]) => void
  handleSetExclusionZones: (data: RoiLayerDataType) => void
  handleSetExclusionZonesAnalyzed: (roi: ROIStatus[]) => void
  resetRois: () => void
}

type Props = {
  children: React.ReactNode
}

const initialRoiInteractsContext: RoiInteractsContextType = {
  isRoiDelete: {
    type: 'roi',
    isActivated: false,
  },
  rois: [],
  roisAnalyzed: [],
  exclusionZones: [],
  handleRoiDelete: () => null,
  handleDeleteRoiByIndex: () => null,
  handleSetRoiData: () => null,
  handleSetRoiLayer: () => null,
  handleSetRoiAnalyzed: () => null,
  handleSetExclusionZones: () => null,
  handleSetExclusionZonesAnalyzed: () => null,
  resetRois: () => null,
}

const RoiInteractsContext = createContext<RoiInteractsContextType | undefined>(
  initialRoiInteractsContext,
)

export type RoiLayerType = RoiLayerDataType & {
  layer: Layer | null
}

type RoiLayerDataType = {
  points: Point[]
  bounds: Bounds
  squareCords: number[][]
  freeHandCords: LatLng[]
}

const RoiInteracts = ({ children }: Props) => {
  const [isRoiDelete, setIsRoiDelete] = useState<{
    type: 'roi' | 'exclude'
    isActivated: boolean
  }>({
    type: 'roi',
    isActivated: false,
  })
  const [rois, setRois] = useState<RoiLayerType[]>([])
  const [exclusionZones, setExclusionZones] = useState<RoiLayerType[]>([])
  const [roisAnalyzed, setRoisAnalyzed] = useState<ROIStatus[]>([])
  const [roisAnalyzedCopy, setRoisAnalyzedCopy] = useState<ROIStatus[]>([])
  const [excludeZonesAnalyzed, setExcludeZonesAnalyzed] = useState<ROIStatus[]>(
    [],
  )

  const handleRoiDelete = (val: boolean, type: 'roi' | 'exclude') => {
    setIsRoiDelete({
      type,
      isActivated: val,
    })
  }

  const handleDeleteRoiByIndex = (index: number) => {
    // remove ROI square and ROI layer
    if (isRoiDelete.type === 'exclude') {
      const exclude = exclusionZones[index]
      if (exclude?.layer) {
        exclude.layer.remove()
      }

      setExclusionZones((lastVals) => {
        return lastVals.filter((_, i) => i !== index)
      })
      setExcludeZonesAnalyzed((lastVals) => {
        return lastVals.filter((_, i) => i !== index)
      })
    } else if (isRoiDelete.type === 'roi') {
      const roi = rois[index]
      if (roi?.layer) {
        roi.layer.remove()
      }
      setRois((lastVals) => {
        return lastVals.filter((_, i) => i !== index)
      })

      // remove ROI analyzed data from the list
      const roiAnalyzedCopyWithoutIndex = roisAnalyzed.filter(
        (_, i) => i !== index,
      )
      setRoisAnalyzed(roiAnalyzedCopyWithoutIndex)
    }
  }

  const handleSetRoiData = (data: RoiLayerDataType) => {
    const newRoi = {
      layer: null,
      ...data,
    }
    setRois((lastVals) => [newRoi, ...lastVals])
  }

  const handleSetExclusionZones = (data: RoiLayerDataType) => {
    const newRoi = {
      layer: null,
      ...data,
    }
    setExclusionZones((lastVals) => [newRoi, ...lastVals])
  }

  const handleSetRoiAnalyzed = (roi: ROIStatus | ROIStatus[]) => {
    if (Array.isArray(roi) && roi.length > 0) {
      setRoisAnalyzed((lastVals) => [...roi, ...lastVals])
      setRoisAnalyzedCopy((lastVals) => [...roi, ...lastVals])
    } else {
      const copyRoisAnalyzed = [...roisAnalyzed]
      const copyRoisAnalyzedCopy = [...roisAnalyzedCopy]
      copyRoisAnalyzed.push(roi as ROIStatus)
      copyRoisAnalyzedCopy.push(roi as ROIStatus)
      setRoisAnalyzed(copyRoisAnalyzed)
      setRoisAnalyzedCopy(copyRoisAnalyzedCopy)
      // save a copy of the analyzed data for exclusion zones calculation
    }
  }

  const handleSetExclusionZonesAnalyzed = (roi: ROIStatus[]) => {
    setExcludeZonesAnalyzed([])
    setExcludeZonesAnalyzed((lastVals) => [...roi, ...lastVals])
  }

  const handleSetRoiLayer = (layer: Layer, index: number) => {
    setRois((lastVals) => {
      return lastVals.map((roi, i) => {
        if (i === index) {
          return {
            ...roi,
            layer,
          }
        }
        return roi
      })
    })
  }

  const resetRois = () => {
    rois.forEach((roi) => {
      if (roi.layer) {
        roi.layer.remove()
      }
    })
    setRois([])
    setRoisAnalyzed([])
    setExclusionZones([])
    setExcludeZonesAnalyzed([])
  }

  const calculateRoiExcludeZones = () => {
    //TODO: fix ts-ignore adjusting types
    const excludeZonesData = excludeZonesAnalyzed.flatMap(
      (exclusion: ROIStatus) => {
        if (exclusion.data) {
          return Object.values(exclusion.data)
        }
      },
    )
    const roiCopy = [...roisAnalyzedCopy]
    const roiExclusion = roiCopy.map((roi) => {
      const filteredData = {} as RoiDataByKey

      if (!roi.data) return
      Object.keys(roi.data).forEach((key) => {
        if (!roi.data) return
        const roiData = roi?.data[key]
        const matchingExclusion = excludeZonesData.filter(
          (exclusion) =>
            exclusion?.name === roiData.name &&
            exclusion?.centroids.some((exclusionCentroid) =>
              roiData.centroids.some(
                (roiCentroid) =>
                  roiCentroid[0] === exclusionCentroid[0] &&
                  roiCentroid[1] === exclusionCentroid[1],
              ),
            ),
        )
        if (matchingExclusion.length > 0) {
          const newCentroids = roiData.centroids.filter(
            (roiCentroid) =>
              !matchingExclusion.some((exclusion) =>
                exclusion?.centroids.some(
                  (exclusionCentroid) =>
                    roiCentroid[0] === exclusionCentroid[0] &&
                    roiCentroid[1] === exclusionCentroid[1],
                ),
              ),
          )
          filteredData[key] = {
            ...roiData,
            centroids: newCentroids,
          }
        } else {
          filteredData[key] = roiData
        }
      })
      return {
        bounds: roi.bounds,
        data: filteredData,
      }
    })
    setRoisAnalyzed(roiExclusion as unknown as ROIStatus[])
  }

  useEffect(() => {
    if (roisAnalyzed.length > 0) {
      calculateRoiExcludeZones()
    }
  }, [excludeZonesAnalyzed])

  return (
    <RoiInteractsContext.Provider
      value={{
        isRoiDelete,
        rois,
        roisAnalyzed,
        exclusionZones,
        handleRoiDelete,
        handleDeleteRoiByIndex,
        handleSetRoiData,
        handleSetRoiLayer,
        handleSetRoiAnalyzed,
        handleSetExclusionZones,
        handleSetExclusionZonesAnalyzed,
        resetRois,
      }}
    >
      {children}
    </RoiInteractsContext.Provider>
  )
}

const useRoiInteracts = () => {
  const context = useContext(RoiInteractsContext)
  if (context === undefined) {
    throw new Error(
      'useRoiInteracts must be used within a RoiInteractsProvider',
    )
  }
  return context
}

export default RoiInteracts
export { useRoiInteracts }
