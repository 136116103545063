import { createContext } from 'react'
import en from './en'
import ja from './ja'
import { LanguageObject } from '../../types/CommonTypes'

interface Translations {
  [key: string]: LanguageObject
}

const translations: Translations = {
  en,
  ja,
}

const TranslationContext = createContext(translations)

export default TranslationContext
