import { useContext, useState, useEffect, useRef } from 'react'
import { Box, Typography, SxProps, Theme, Grid } from '@mui/material'

import theme from '../../../theme'
import CommonValue from '../../../contexts/CommonValue'
import useTranslation from '../../../hooks/useTranslation'
import MiddleButton from '../../buttons/MiddleButton'
import CloseButton from '../../buttons/CloseButton'
import { ROIStatus } from './types/roiTypes'
import L, { Point } from 'leaflet'
import LeafletDragBar from '../../rayoutItem/LeafletDragBar'
import { downloadCsv } from './roi'
import { useMap } from 'react-leaflet'

export const ROIStatusFloatingPanel = ({
  status,
  onClose,
}: {
  status: ROIStatus[]
  onClose: () => void
}) => {
  const [model, setModel] = useState<model>([])
  const map = useMap()
  const ref = useRef<HTMLDivElement>(null)
  const { globalLanguage } = useContext(CommonValue)
  const tn = useTranslation(globalLanguage)
  const [isDownloading, setIsDownloading] = useState<boolean>(false)

  const location = {
    x: 50,
    y: 10,
  }

  useEffect(() => {
    if (status.length > 0 && status[0].data) initialize()
    else {
      setModel([])
    }

    if (ref.current) {
      L.DomEvent.disableClickPropagation(ref.current)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status])

  function initialize(): void {
    const models = status.map((s) => {
      return {
        area: `${toString(s.bounds.min!)} - ${toString(s.bounds.max!)}`,
        cells: Object.keys(s.data!).map(
          (key) =>
            `${s.data![key].name} : ${s.data![key].centroids.length} count`,
        ),
      }
    })
    models.reverse() // neccesary to keep the same order with the ROI area numbers on the map
    setModel(models)

    function toString(point: Point) {
      return `(${point.x.toFixed(2)}, ${point.y.toFixed(2)})`
    }
  }

  function handleDownload(): void {
    setIsDownloading(true)
    downloadCsv(status)
    setIsDownloading(false)
  }

  function handleDragging(movement: { x: number; y: number }): void {
    if (!ref.current) return

    ref.current.style.left = `${getLocationLeft(movement.x)}px`
    ref.current.style.top = `${getLocationTop(movement.y)}px`
  }

  function handleDragged(movement: { x: number; y: number }): void {
    if (!ref.current) return

    location.x = getLocationLeft(movement.x)
    location.y = getLocationTop(movement.y)
  }

  function getLocationTop(movement: number) {
    return getLocation(
      location.y + movement,
      Number(ref.current?.offsetHeight ?? 0),
      map.getContainer().offsetHeight,
    )
  }

  function getLocationLeft(movement: number) {
    return getLocation(
      location.x + movement,
      Number(ref.current?.offsetWidth ?? 0),
      map.getContainer().offsetWidth,
    )
  }

  function getLocation(position: number, panelSize: number, mapSize: number) {
    return Math.max(
      position > mapSize - panelSize ? mapSize - panelSize : position,
      0,
    )
  }

  const calculateTotal = (model: model) => {
    if (model.length > 0) {
      const cellsNamesArr = model.map((m) =>
        m.cells.map((c) => c.split(':')[0].trim()),
      )
      const cellNames = cellsNamesArr.flat()
      const uniqueNames = new Set(cellNames)
      const uniqueNamesArr = Array.from(uniqueNames)

      const totalsByNames = uniqueNamesArr.map((name) => {
        const total = model.map((m) => {
          const cell = m.cells.find((c) => c.includes(name))
          return cell ? parseInt(cell.split(':')[1].trim()) : 0
        })
        return {
          name,
          total: total.reduce((acc, cur) => acc + cur),
        }
      })
      return totalsByNames
    } else {
      return []
    }
  }

  return model.length > 0 ? (
    <Box sx={popupStyle} className='leaflet-bar leaflet-control' ref={ref}>
      <LeafletDragBar
        sx={dragBarStyle}
        onDragging={handleDragging}
        onDragged={handleDragged}
      />
      <Box
        onMouseEnter={() => {
          map.scrollWheelZoom.disable()
        }}
        onMouseLeave={() => {
          map.scrollWheelZoom.enable()
        }}
        sx={containerStyle}
      >
        <Typography variant='h5' sx={labelStyle}>
          {tn.roi_status_title}
        </Typography>

        {model.map((m, i) => {
          return (
            <Box key={i} sx={{ marginLeft: '8px' }}>
              <Typography variant='h6' sx={labelStyle}>
                {tn.roi_area_label}
                <span
                  style={{
                    marginLeft: '8px',
                  }}
                >
                  {i + 1}
                </span>
              </Typography>
              <Box sx={{ marginLeft: '8px' }}>
                <Typography>{m.area}</Typography>
              </Box>

              <Typography variant='h6' sx={labelStyle}>
                {tn.roi_cell_label}
              </Typography>
              <Box sx={{ marginLeft: '8px' }}>
                {m.cells.map((cell, index) => (
                  <Typography key={`cell-${index}`}>{cell}</Typography>
                ))}
              </Box>
            </Box>
          )
        })}
        {
          <Box sx={{ marginLeft: '8px' }}>
            <Typography variant='h6' sx={labelStyle}>
              Total
            </Typography>
            <Typography variant='h6' sx={labelStyle}>
              {tn.roi_cell_label}
            </Typography>
            {calculateTotal(model).map((cell) => {
              return (
                <Typography key={cell.name}>
                  {cell.name} : {cell.total}
                </Typography>
              )
            })}
          </Box>
        }
        <Grid
          container
          spacing={2}
          sx={{ marginTop: 2 }}
          alignItems='center'
          justifyContent='flex-end'
        >
          <Grid item xs='auto'>
            <MiddleButton
              sx={{ width: '120px' }}
              text={tn.roi_download_button}
              onClick={handleDownload}
              disabled={isDownloading}
            />
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ position: 'absolute', top: 0, right: '4px', padding: 0 }}>
        <CloseButton onClick={onClose} padding='0' />
      </Box>
    </Box>
  ) : (
    <></>
  )
}

const popupStyle: SxProps<Theme> = {
  zIndex: 1800,
  position: 'absolute',
  top: '10px',
  left: '50px',
  minWidth: '240px',
  userSelect: 'none',
}

const dragBarStyle: SxProps<Theme> = {
  width: '100%',
  height: '24px',
  cursor: 'grab',
  '&:active': {
    cursor: 'grabbing',
  },
}

const containerStyle: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  backgroundColor: theme.palette.background.default,
  padding: '20px',
  gap: '16px',
  maxHeight: '70vh',
  '&::-webkit-scrollbar': {
    width: '12px',
    backgroundColor: '#444444',
    borderBottomRightRadius: '10px',
    overflow: 'hidden',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#242424',
    borderRadius: '7px',
  },
  overflowY: 'auto !important',
  borderBottomRightRadius: '11px',
}

const labelStyle: SxProps<Theme> = {
  fontSize: '1rem',
}

type model = {
  area: string
  cells: string[]
}[]
