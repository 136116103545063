import { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { ApiClient } from '../lib/ApiClient'
import CommonValue from '../contexts/CommonValue'
import useTranslation from './useTranslation'
import { HTTP_STATUS_CODE } from '../constants/ErrorCode'
import { responseError } from '../types/CommonTypes'

/**
 * usePathologicalImageEdit Hooks
 *
 * @returns isLoading
 * @returns errorMessage
 * @returns editPathologicalImage
 */
export const usePathologicalImageEdit = () => {
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [isLoading, setIsLoading] = useState(false)
  const { globalLanguage } = useContext(CommonValue)
  const t = useTranslation(globalLanguage)

  const navigate = useNavigate()

  const editPathologicalImage = async (
    id: number,
    uuid: string,
    directory_id: number,
    name: string,
    person_in_charge: string,
    date_of_acquisition: string,
    note: string,
  ) => {
    setIsLoading(true)
    setErrorMessage('')
    const url = '/pathological_images'

    const formData = new FormData()
    formData.append('name', name)
    formData.append('parent_directory_id', directory_id.toString())
    formData.append('person_in_charge', person_in_charge)
    formData.append('date_of_acquisition', date_of_acquisition)
    formData.append('note', note)

    await ApiClient.put(url + '/' + id, formData, {
      headers: {
        'Content-Type': 'application/json',
      },
    }).catch((error: responseError) => {
      if (error.response.status === HTTP_STATUS_CODE.UNAUTHORIZED) {
        navigate('/sign_in')
      }
      setErrorMessage(t.message.UPLOAD_IMAGE_ERROR)
    })

    setIsLoading(false)
  }

  return {
    errorMessage,
    isLoading,
    editPathologicalImage,
  }
}
