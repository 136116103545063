import { useState } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import LogIn from './components/pages/LogIn'
import Viewer from './components/pages/Viewer/Viewer'
import FileList from './components/pages/FileList/FileList'

import { NotFound } from './components/pages/NotFound'

import CommonValue, { userFeaturesType } from './contexts/CommonValue'
import UploadFiles from './contexts/UploadFiles'

function App() {
  const [globalLanguage, setGlobalLanguage] = useState('en')
  // eslint-disable-next-line no-irregular-whitespace
  // true: 画像有りリスト画面　false: 画像無しリスト画面
  const [globalViewMode, setGlobalViewMode] = useState<boolean>(true)
  const [userFeatures, handleUserFeatures] = useState<userFeaturesType>({
    ihc_registration: null,
    spatial_transcriptomics: null,
  })

  return (
    <UploadFiles>
      <CommonValue.Provider
        value={{
          globalLanguage,
          setGlobalLanguage,
          globalViewMode,
          setGlobalViewMode,
          userFeatures,
          handleUserFeatures,
        }}
      >
        <BrowserRouter>
          <Routes>
            <Route path='/' element={<FileList />} />
            <Route path='/sign_in' element={<LogIn />} />
            <Route path='/viewer' element={<Viewer />} />

            {/* other */}
            <Route path='*' element={<NotFound />} />
          </Routes>
        </BrowserRouter>
      </CommonValue.Provider>
    </UploadFiles>
  )
}
export default App
