import { Box, SxProps, Theme } from '@mui/material'
import theme from '../../theme'
import { useEffect, useMemo, useRef } from 'react'
import L, { Point } from 'leaflet'

export default function LeafletDragBar({
  sx,
  onDragging,
  onDragged,
}: {
  sx?: SxProps<Theme>
  onDragging?: (movement: { x: number; y: number }) => void
  onDragged?: (movement: { x: number; y: number }) => void
}): JSX.Element {
  const ref = useRef<HTMLDivElement>(null)
  let startPos: Point | undefined = undefined

  useEffect(() => {
    const container = ref.current

    if (container) {
      L.DomEvent.on(container, 'pointerdown', onMouseDown)
      L.DomEvent.on(container, 'pointerup', onMouseUp)
      L.DomEvent.on(container, 'pointermove', onMouseMove)
    }

    return () => {
      if (!container) return
      L.DomEvent.off(container, 'pointerdown', onMouseDown)
      L.DomEvent.off(container, 'pointerup', onMouseUp)
      L.DomEvent.off(container, 'pointermove', onMouseMove)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref])

  const bars = useMemo(
    () =>
      [...Array(10)] // Boxの数
        .map((_, i) => i)
        .map((i) => <Box key={i} sx={dragBarStyle} />),
    [],
  )

  function getMovement(x: number, y: number) {
    return {
      x: x - (startPos?.x ?? 0),
      y: y - (startPos?.y ?? 0),
    }
  }

  function onMouseDown(event: Event) {
    const pointerEvent = event as PointerEvent
    if (!pointerEvent.pointerId) return

    startPos = new Point(pointerEvent.screenX, pointerEvent.screenY)

    ref.current?.setPointerCapture(pointerEvent.pointerId)

    event.stopPropagation()
  }

  function onMouseUp(event: Event) {
    if (!startPos) return

    const pointerEvent = event as PointerEvent
    ref.current?.releasePointerCapture(pointerEvent.pointerId)
    ref.current!.style.pointerEvents = 'auto'

    const movement = getMovement(pointerEvent.screenX, pointerEvent.screenY)
    onDragged && onDragged(movement)

    startPos = undefined
  }

  function onMouseMove(event: Event) {
    if (!startPos) return

    const pointerEvent = event as PointerEvent
    const movement = getMovement(pointerEvent.screenX, pointerEvent.screenY)

    onDragging && onDragging(movement)
  }

  const style = {
    ...boxIconStyle,
    ...sx,
  }
  return (
    <Box sx={style} ref={ref}>
      {bars}
    </Box>
  )
}

const dragBarStyle: SxProps<Theme> = {
  backgroundColor: '#555555',
  height: '5px',
  width: '1px',
  margin: '1px',
  pointerEvents: 'none',
}

const boxIconStyle: SxProps<Theme> = {
  borderRadius: '6px 6px 0 0',
  backgroundColor: theme.palette.bgPrimary.main,
  height: '16px',
  width: '240px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}
