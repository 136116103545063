const headers: { [key: string]: string } = {}
const token = window.localStorage.getItem('access_token')
const client = window.localStorage.getItem('client')
const uid = window.localStorage.getItem('uid')

if (token) headers['access-token'] = token
if (client) headers['client'] = client
if (uid) headers['uid'] = uid

export const fetchBlobs = async (
  url: string,
  options?: { signal: AbortSignal },
): Promise<Awaited<Blob>> => {
  const response = await fetch(url, {
    headers,
    signal: options?.signal,
  })
  const blobs = await response.blob()
  return Promise.resolve(blobs)
}
