import {
  Box,
  FormControl,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  TextFieldProps,
} from '@mui/material'
import DatePicker, { CalendarContainerProps } from 'react-datepicker'
import { MouseEventHandler, forwardRef } from 'react'
import { ChevronLeft, ChevronRight, EventOutlined } from '@mui/icons-material'
import 'react-datepicker/dist/react-datepicker.css'

import theme from '../../theme'
import './CalendarStyle.css'

type Props = {
  selectedDate: Date | null
  onChange: (event: Date | null) => void
}

export default function CalendarInput({
  selectedDate,
  onChange,
}: Props): JSX.Element {
  //TODO: Adjust to fit the eslint rules
  // eslint-disable-next-line react/display-name
  const CustomInput = forwardRef<HTMLInputElement, TextFieldProps>(
    ({ onClick, value, onBlur, onChange }, ref) => (
      <TextField
        value={value}
        onBlur={onBlur}
        onChange={onChange}
        onClick={onClick}
        inputRef={ref}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              <IconButton
                edge='end'
                onClick={
                  onClick as unknown as MouseEventHandler<HTMLButtonElement>
                }
              >
                <EventOutlined />
              </IconButton>
            </InputAdornment>
          ),
          sx: {
            width: '260px',
            height: '36px',
            backgroundColor: theme.palette.bgSecondary.main,
          },
        }}
      />
    ),
  )

  const years: number[] = []
  const currentYear = new Date().getFullYear()
  for (let i = currentYear - 50; i <= currentYear + 50; i++) {
    years.push(i)
  }

  type CustomHeaderProps = {
    date: Date
    changeYear: (year: number) => void
    changeMonth: (month: number) => void
    decreaseMonth: () => void
    increaseMonth: () => void
    prevMonthButtonDisabled: boolean
    nextMonthButtonDisabled: boolean
  } & CalendarContainerProps

  const months = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12']

  const CustomHeader: React.FC<CustomHeaderProps> = ({
    date,
    changeYear,
    changeMonth,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
  }) => (
    <Box
      display='flex'
      justifyContent='center'
      alignItems='center'
      onClick={(event) => event.stopPropagation()}
    >
      <IconButton
        color='primary'
        onClick={decreaseMonth}
        disabled={prevMonthButtonDisabled}
      >
        <ChevronLeft />
      </IconButton>
      <FormControl>
        <Select
          value={date.getFullYear()}
          onChange={({ target: { value } }) => changeYear(Number(value))}
          sx={{
            backgroundColor: theme.palette.bgPrimary.main,
            height: '40px',
            margin: '5px',
          }}
        >
          {years.map((year) => (
            <MenuItem key={year} value={year}>
              {year}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl>
        <Select
          value={date.getMonth()}
          onChange={({ target: { value } }) => changeMonth(Number(value))}
          sx={{
            backgroundColor: theme.palette.bgPrimary.main,
            height: '40px',
            margin: '5px',
          }}
        >
          {months.map((month, index) => (
            <MenuItem key={month} value={index}>
              {month}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <IconButton
        color='primary'
        onClick={increaseMonth}
        disabled={nextMonthButtonDisabled}
      >
        <ChevronRight />
      </IconButton>
    </Box>
  )

  const CustomCalendarContainer: React.FC<CalendarContainerProps> = ({
    children,
    className,
  }) => {
    const handleMouseDown = (
      event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    ) => {
      event.stopPropagation()
    }

    return (
      <div className={className} onMouseDown={handleMouseDown}>
        {children}
      </div>
    )
  }

  return (
    <Box>
      <DatePicker
        selected={selectedDate}
        onChange={(date) => onChange(date)}
        dateFormat='yyyy/MM/dd'
        placeholderText='Select a date'
        renderCustomHeader={CustomHeader}
        calendarContainer={CustomCalendarContainer}
        customInput={<CustomInput />}
      />
    </Box>
  )
}
