import { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { ApiClient } from '../lib/ApiClient'
import CommonValue from '../contexts/CommonValue'
import useTranslation from './useTranslation'
import { HTTP_STATUS_CODE } from '../constants/ErrorCode'
import { CsvSelection } from '../components/pages/FileList/ModalSpecimenCsvSelection'
import { responseError } from '../types/CommonTypes'

/**
 * useCsvExport Hooks
 *
 * @returns isLoading
 * @returns errorMessage
 * @returns exportCsv
 */
export const useCsvExport = () => {
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [isLoading, setIsLoading] = useState(false)
  const { globalLanguage } = useContext(CommonValue)
  const t = useTranslation(globalLanguage)

  const navigate = useNavigate()

  const exportCsv = async (
    ids: number[],
    selectedItemsForCsv?: CsvSelection,
  ) => {
    setIsLoading(true)
    setErrorMessage('')

    const url = `/csv_export`

    await ApiClient.post(
      url,
      { ids: ids, items: selectedItemsForCsv },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
      .then((response) => {
        if (response.status === 200) {
          setErrorMessage('')
          const csvData = response.data
          const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' })
          const url = URL.createObjectURL(blob)
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'export.csv')
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        } else {
          setErrorMessage(t.message.EXPORT_CSV_ERROR)
        }
      })
      .catch((error: responseError) => {
        if (error.response.status === HTTP_STATUS_CODE.UNAUTHORIZED) {
          navigate('/sign_in')
        }
        setErrorMessage(t.message.EXPORT_CSV_ERROR)
      })

    setIsLoading(false)
  }

  return {
    errorMessage,
    isLoading,
    exportCsv,
  }
}
