import { useContext } from 'react'
import TranslationContext from '../lib/translation/TranslationContext'
import { LanguageObject } from '../types/CommonTypes'

const useTranslation = (language: string) => {
  const translations = useContext(TranslationContext)
  return (translations[language] as LanguageObject) || translations['en']
}

export default useTranslation
