import React, { Dispatch, SetStateAction } from 'react'

export type userFeaturesType = {
  ihc_registration: boolean | null
  spatial_transcriptomics: boolean | null
}

interface MyContextType {
  globalLanguage: string
  setGlobalLanguage: Dispatch<SetStateAction<string>>
  globalViewMode: boolean
  setGlobalViewMode: Dispatch<SetStateAction<boolean>>
  userFeatures: userFeaturesType
  handleUserFeatures: Dispatch<SetStateAction<userFeaturesType>>
}

const defaultValue: MyContextType = {
  globalLanguage: '',
  setGlobalLanguage: () => null,
  globalViewMode: true,
  setGlobalViewMode: () => null,
  userFeatures: {
    ihc_registration: null,
    spatial_transcriptomics: null,
  },
  handleUserFeatures: () => null,
}

const CommonValue = React.createContext<MyContextType>(defaultValue)

export default CommonValue
