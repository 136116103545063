import React from 'react'
import { Box, IconButton } from '@mui/material'
import GridViewIcon from '@mui/icons-material/GridViewRounded'
import SortIcon from '@mui/icons-material/Sort'
import theme from '../../theme'

interface ViewModeSwitchProps {
  viewMode: boolean
  handleSwitch: (bool: React.SetStateAction<boolean>) => void
}

export default function ViewModeSwitch({
  viewMode,
  handleSwitch,
}: ViewModeSwitchProps): JSX.Element {
  return (
    <Box sx={{ display: 'flex' }}>
      <Box>
        <IconButton
          onClick={() => {
            handleSwitch(true)
          }}
          sx={{
            margin: '5px',
            borderRadius: 1,
            backgroundColor: viewMode
              ? theme.palette.bgSecondary.main
              : 'transparent',
            '&:hover': {
              backgroundColor: theme.palette.bgSecondary.main,
            },
            height: '30px',
            width: '30px',
          }}
        >
          <GridViewIcon />
        </IconButton>
      </Box>
      <Box>
        <IconButton
          onClick={() => {
            handleSwitch(false)
          }}
          sx={{
            margin: '5px',
            borderRadius: 1,
            backgroundColor: !viewMode
              ? theme.palette.bgSecondary.main
              : 'transparent',
            '&:hover': {
              backgroundColor: theme.palette.bgSecondary.main,
            },
            height: '30px',
            width: '30px',
          }}
        >
          <SortIcon />
        </IconButton>
      </Box>
    </Box>
  )
}
