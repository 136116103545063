/* eslint-disable */
// TODO: Adjust to fit with eslint rules
import { useEffect, useMemo, useRef, useState } from 'react'
import { LatLng, Layer, Util } from 'leaflet'
import { MapContainer, useMapEvents, LayerGroup, useMap } from 'react-leaflet'
import IIIFTileLayer from './ReactLeafletIiif'
import lodashGroupBy from 'lodash.groupby'

import { Paper, Typography, IconButton, SxProps, Theme } from '@mui/material'
import FormControlLabel from '@mui/material/FormControlLabel'
import Box from '@mui/material/Box'
import LayersIcon from '@mui/icons-material/Layers'
import Stack from '@mui/material/Stack'
import Slider from '@mui/material/Slider'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'

import Checkbox from '../../inputs/CheckBox'
import DragBar from '../../rayoutItem/DragBar'
import CloseButton from '../../buttons/CloseButton'

import theme from '../../../theme'

import { LayersControlProvider } from './layerControlContext'
import createControlledLayer from './controlledLayer'
import { AnalysisResult } from '../../../types/DetailPathologicalImages'
import { ColorPickerRenderColor } from '../../mapElements/ColorPickerLayer'
import { useLayersColors } from '../../../contexts/LayersColors'
import useLayerSaveState from '../../../hooks/useLayerSaveState'
import { layersZIndexMap } from '../../../constants/utils'

const POSITION_CLASSES: { [key: string]: string } = {
  bottomleft: 'leaflet-bottom leaflet-left',
  bottomright: 'leaflet-bottom leaflet-right',
  topleft: 'leaflet-top leaflet-left',
  topright: 'leaflet-top leaflet-right',
}

interface IProps {
  position: string
  analysis_result: AnalysisResult
}

interface ILayerObj {
  layer: Layer & { setZIndex?: (index: number) => void }
  group: string
  name: string
  color: number
  checked: boolean
  id: number
}

type layerItem = {
  type: string
  layer_id: number
  name: string
  color: string
}

const LayerControl = ({ position, analysis_result }: IProps) => {
  const layerRef = useRef(null)
  const [isFirstRender, setIsFirstRender] = useState(true)
  const [groupedLayersState, setGroupedLayersState] = useState<any>({})
  const { handleLoadLayers, layersColors, handleSetSegmentationOpacity } =
    useLayersColors()
  const [collapsed, setCollapsed] = useState(true)
  const totalElements = Object.values(analysis_result.layer).reduce(
    (total, currentArray) => total + currentArray.length,
    0,
  )
  const [opacityes, setOpacityes] = useState(new Array(totalElements).fill(100))
  const [layers, setLayers] = useState<ILayerObj[]>([])
  const positionClass =
    (position && POSITION_CLASSES[position]) || POSITION_CLASSES.topright
  const mapHook = useMap()
  const { postLayerVisibilityByLayerId } = useLayerSaveState()

  const map = useMapEvents({
    layerremove: () => {
      // console.log('layer removed');
    },
    layeradd: () => {
      // console.log('layer add');
    },
    overlayadd: () => {
      // console.log(layers);
    },
  })
  const onLayerClick = (layerObj: ILayerObj) => {
    if (map?.hasLayer(layerObj.layer)) {
      map.removeLayer(layerObj.layer)
      setLayers((lastLayer) => {
        return lastLayer.map((layer) => {
          if (layer.id === layerObj.id)
            return {
              ...layer,
              checked: false,
            }

          return layer
        })
      })
    } else {
      layerObj.layer.setZIndex &&
        layerObj.layer.setZIndex(layersZIndexMap.get(layerObj?.group) ?? 0)

      map.addLayer(layerObj.layer)
      const center = map.getCenter()
      setLayers((lastLayer) => {
        return lastLayer.map((layer) => {
          if (layer.id === layerObj.id)
            return {
              ...layer,
              checked: true,
            }

          return layer
        })
      })
      setTimeout(() => {
        map.panTo([center.lat + 0.000001, center.lng]) //needed to render the layer
        map.panTo(center)
      }, 100)
    }
  }

  const onGroupAdd = (
    layer: Layer,
    name: string,
    color: number,
    group: string,
  ) => {
    const cLayers = layers
    cLayers.push({
      layer,
      group,
      name,
      checked: map?.hasLayer(layer),
      id: Util.stamp(layer),
      color,
    })
    setLayers(cLayers)
  }

  useEffect(() => {
    const newGroupedLayers = lodashGroupBy(layers, 'group')
    setGroupedLayersState(newGroupedLayers)
  }, [layers, layersColors])

  useEffect(() => {
    if (Object.keys(groupedLayersState).length === 0) return
    if (!isFirstRender) return

    setTimeout(() => {
      Object.keys(groupedLayersState).forEach((section) => {
        const sectionLayers = groupedLayersState[section]
        sectionLayers.forEach((layer: any) => {
          analysis_result.layer[section].forEach((l) => {
            if (l.name === layer.name) {
              if (l.visible) {
                onLayerClick(layer)
              }
            }
          })
        })
      })
    }, 500)
    setIsFirstRender(false)
  }, [groupedLayersState, isFirstRender])
  //Render the grouped layers based on the server visibility data on first render

  const overlayData = useMemo(() => {
    let overallIndex = 0
    const overlayData: any[] = []
    for (const layerKey in analysis_result.layer) {
      const cellData = analysis_result.layer[layerKey]
      cellData.forEach((element, idx) => {
        const newData = (
          <GroupedLayer
            key={`${element.name}_${idx}`}
            name={element.name}
            color={element.color}
            group={layerKey}
          >
            <LayerGroup>
              <IIIFTileLayer
                className={element.name.split(' ')[0]}
                url={
                  window.location.protocol +
                  '//' +
                  window.location.hostname +
                  '/fcgi-bin/iipsrv.fcgi?' +
                  element.ifff_url
                }
                tileFormat='png'
                opacity={opacityes[overallIndex] / 100}
                fitBounds={false}
              />
            </LayerGroup>
          </GroupedLayer>
        )
        overlayData.push(newData)
      })
      overallIndex++
    }
    return overlayData
  }, [analysis_result])

  const handleChange = (newValue: number | number[], num: number) => {
    setOpacityes(
      opacityes.map((value, index) =>
        num === index ? (newValue as number) : value,
      ),
    )
  }

  useEffect(() => {
    const layersNames = Object.keys(analysis_result.layer).map((key) => {
      // @ts-ignore
      return analysis_result.layer[key].map((layer: layerItem) => {
        return {
          layerType: layer.type,
          layerId: layer.layer_id,
          layerName: layer.name,
          color: String(layer.color),
        }
      })
    })

    handleLoadLayers(layersNames.flat())
  }, [])

  const handleChangeSaveVisibility = async (val: boolean, layer: ILayerObj) => {
    const layerFiltered = layersColors.find(
      (layerColor) => layerColor.layerName === layer.name,
    )
    await postLayerVisibilityByLayerId(
      layerFiltered?.layerType ?? '',
      val,
      Number(layerFiltered?.layerId) ?? 0,
    )
    onLayerClick(layer)
  }

  return (
    <LayersControlProvider
      value={{
        layers,
        addGroup: onGroupAdd,
      }}
    >
      <Box position={'relative'} className={positionClass + ' layer-control'}>
        <Box
          ref={layerRef}
          sx={{
            ...scrollbarStyle,
          }}
          maxHeight={'50vh'}
          //Necessary to avoid map zoom while interacting with the layer control
          onMouseEnter={() => {
            mapHook.scrollWheelZoom.disable()
          }}
          onMouseLeave={() => {
            mapHook.scrollWheelZoom.enable()
          }}
          className='leaflet-control leaflet-bar'
        >
          {
            <MapContainer
              center={new LatLng(0.0, 0.0)}
              zoom={0}
              dragging={true}
              doubleClickZoom={false}
              scrollWheelZoom={false}
              attributionControl={false}
              zoomControl={false}
            >
              <Paper
                sx={{
                  borderTopRightRadius: '0px',
                  borderBottomRightRadius: '0px',
                }}
              >
                {collapsed && (
                  <IconButton onClick={() => setCollapsed(false)}>
                    <LayersIcon fontSize='medium' />
                  </IconButton>
                )}
                {!collapsed && (
                  <>
                    <DragBar />

                    <Box sx={{ position: 'absolute', top: 0, right: 0 }}>
                      <CloseButton onClick={() => setCollapsed(true)} />
                    </Box>
                    <Box sx={overlayPeperStyle}>
                      {Object.keys(groupedLayersState).map((section, index) => (
                        <Box sx={{ mb: 1 }} key={`${section} ${index}`}>
                          <Box sx={selectHeaderStyle}>
                            <Typography sx={rowHeaderStyle}>
                              {section}
                            </Typography>
                          </Box>
                          <Box sx={selectHeaderSlideStyle}>
                            <Stack
                              spacing={2}
                              direction='row'
                              alignItems='center'
                            >
                              <RemoveIcon />
                              <Slider
                                min={0}
                                max={100}
                                valueLabelDisplay={'auto'}
                                aria-label='Volume'
                                size='small'
                                value={opacityes[index]}
                                onChange={(e, value) => {
                                  const segmentation = layersColors.find(
                                    (color) => {
                                      return (
                                        color.layerName ===
                                        groupedLayersState[section][index].name
                                      )
                                    },
                                  )
                                  handleChange(value, index)
                                  handleSetSegmentationOpacity(
                                    segmentation?.layerType ?? '',
                                    value as number,
                                  )
                                }}
                              />
                              <AddIcon />
                            </Stack>
                          </Box>
                          {groupedLayersState[section]?.map(
                            (layerObj: any, index: any) => (
                              <Box
                                key={`accDetails_${index}`}
                                sx={selectBoxStyle}
                              >
                                <Box
                                  sx={selectBoxesStyle}
                                  onClick={() => onLayerClick(layerObj)}
                                >
                                  <Stack direction={'row'}>
                                    <>
                                      <FormControlLabel
                                        sx={FormConcontrolStyle}
                                        control={
                                          <Checkbox
                                            checked={layerObj.checked}
                                            onChange={async (val) => {
                                              await handleChangeSaveVisibility(
                                                val,
                                                layerObj,
                                              )
                                            }}
                                            sx={checkBoxStyle}
                                            clickZone='30px'
                                          />
                                        }
                                        label={
                                          <Typography sx={TypographyStyle}>
                                            {layerObj.name}
                                          </Typography>
                                        }
                                      />
                                      <ColorPickerRenderColor
                                        parentRef={layerRef}
                                        id={
                                          layersColors.find(
                                            (layer) =>
                                              layer.layerName === layerObj.name,
                                          )?.id ?? ''
                                        }
                                      />
                                    </>
                                  </Stack>
                                </Box>
                              </Box>
                            ),
                          )}
                        </Box>
                      ))}
                    </Box>
                  </>
                )}
              </Paper>
            </MapContainer>
          }
        </Box>
        {overlayData}
      </Box>
    </LayersControlProvider>
  )
}

const GroupedLayer = createControlledLayer(
  (layersControl, layer, name, color, group) => {
    layersControl.addGroup(layer, name, color, group)
  },
)

const overlayPeperStyle: SxProps<Theme> = {
  backgroundColor: theme.palette.bgPrimary.main,
  width: '240px',
  pb: 2,
  pt: 2,
}

const selectHeaderStyle: SxProps<Theme> = {
  color: theme.palette.line.main,
  float: 'left',
  px: 2,
}

const selectHeaderSlideStyle: SxProps<Theme> = {
  color: theme.palette.line.main,
  float: 'right',
  width: '60%',
  mr: 1,
}

const selectBoxStyle: SxProps<Theme> = {
  color: theme.palette.primary.contrastText,
  mx: 2,
}

const selectBoxesStyle: SxProps<Theme> = {
  display: 'inline-block',
}

const rowHeaderStyle: SxProps<Theme> = {
  fontSize: '14px',
  fontWeight: 'bold',
}

const TypographyStyle: SxProps<Theme> = {
  fontSize: '14px',
}

const FormConcontrolStyle: SxProps<Theme> = {
  color: theme.palette.primary.contrastText,
  fontSize: '14px',
  width: '168px',
  mx: 0.5,
  my: 0.5,
}

const checkBoxStyle: SxProps<Theme> = {
  position: 'initial',
  mr: 4,
}

const circleStyle: SxProps<Theme> = {
  width: '16px',
  height: '16px',
  ml: 1,
}

export const scrollbarStyle: SxProps<Theme> = {
  '&::-webkit-scrollbar': {
    width: '12px',
    backgroundColor: '#444444',
    borderTopRightRadius: '10px',
    borderBottomRightRadius: '10px',
    overflow: 'hidden',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#242424',
    borderRadius: '7px',
  },
  overflowY: 'auto',
  borderTopRightRadius: '11px',
  borderBottomRightRadius: '11px',
}

export default LayerControl
export { GroupedLayer }
