import { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { ApiClient } from '../lib/ApiClient'
import CommonValue from '../contexts/CommonValue'
import useTranslation from './useTranslation'
import { HTTP_STATUS_CODE } from '../constants/ErrorCode'
import { responseError } from '../types/CommonTypes'

/**
 * usePathologicalImageDelete Hooks
 *
 * @returns isLoading
 * @returns errorMessage
 * @returns deletePathologicalImage
 */
export const usePathologicalImageDelete = () => {
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [isLoading, setIsLoading] = useState(false)
  const { globalLanguage } = useContext(CommonValue)
  const t = useTranslation(globalLanguage)

  const navigate = useNavigate()

  const deletePathologicalImage = async (id: number) => {
    setIsLoading(true)
    setErrorMessage('')

    const url = `/pathological_images/${id}`

    await ApiClient.delete(url)
      .then((response) => {
        if (response.status === 204) {
          setErrorMessage('')
        } else {
          setErrorMessage(t.message.DELETE_IMAGE_ERROR)
        }
      })
      .catch((error: responseError) => {
        if (error.response.status === HTTP_STATUS_CODE.UNAUTHORIZED) {
          navigate('/sign_in')
        }
        setErrorMessage(t.message.DELETE_IMAGE_ERROR)
      })

    setIsLoading(false)
  }

  return {
    errorMessage,
    isLoading,
    deletePathologicalImage,
  }
}
