import axios, { AxiosProgressEvent } from 'axios'
import { HTTP_STATUS_CODE } from '../../constants/ErrorCode'
import { NavigateFunction } from 'react-router-dom'
import { responseError } from '../../types/CommonTypes'

//NOTE: 影響範囲を絞るため、新規で作成する箇所のみ適用する

export function handleRequestError(
  error: responseError,
  navigate: NavigateFunction,
): boolean {
  console.log(error)
  if (axios.isCancel(error)) {
    console.error('Operation canceled')
    return true
  }
  if (error.response.status === HTTP_STATUS_CODE.UNAUTHORIZED) {
    navigate('/sign_in')
    return true
  }

  return false
}

export function createRequestHeaders() {
  return {
    'Content-Type': 'multipart/form-data',
    'access-token': window.localStorage.getItem('access_token'),
    uid: window.localStorage.getItem('uid'),
    client: window.localStorage.getItem('client'),
  }
}

export function getPercentCompleted(progressEvent: AxiosProgressEvent) {
  return progressEvent.total
    ? Math.round((progressEvent.loaded * 100) / progressEvent.total)
    : 0
}
