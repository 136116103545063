import {
  Box,
  FormControl,
  MenuItem,
  Select,
  SxProps,
  Theme,
} from '@mui/material'
import { SelectChangeEvent } from '@mui/material/Select'

import theme from '../../theme'

type Props = {
  value?: string
  defaultValue?: string
  onChange: (event: SelectChangeEvent<string>) => void
  menuItems: { id: number; label: string }[]
}

export default function SelectBox({
  value,
  defaultValue,
  onChange,
  menuItems,
}: Props): JSX.Element {
  return (
    <Box>
      <FormControl>
        <Select
          value={value}
          defaultValue={defaultValue}
          onChange={onChange}
          sx={underCirculeStyle}
        >
          {menuItems.map((item) => (
            <MenuItem key={item.id} value={item.id}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  )
}

const underCirculeStyle: SxProps<Theme> = {
  width: '260px',
  height: '36px',
  backgroundColor: theme.palette.bgSecondary.main,
}
