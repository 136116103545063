import {
  CircularProgress,
  createTheme,
  SxProps,
  Theme,
  ThemeProvider,
} from '@mui/material'

import theme from '../../theme'

const Customtheme = createTheme({
  palette: {
    primary: {
      main: '#35BFC8',
    },
  },
})

export default function CustomCircularProgress(): JSX.Element {
  return (
    <ThemeProvider theme={Customtheme}>
      <CircularProgress
        variant='determinate'
        value={100}
        sx={underCirculeStyle}
      />
      <CircularProgress />
    </ThemeProvider>
  )
}

const underCirculeStyle: SxProps<Theme> = {
  position: 'absolute',
  color: theme.palette.line.main,
}
