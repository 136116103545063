import React, { useContext } from 'react'
import { SxProps, Theme } from '@mui/material'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import CustomCircularProgress from '../customIcons/CustomCircularProgress'
import MiddleButton from '../buttons/MiddleButton'
import TextField from '../inputs/TextField'
import TextPasswordField from '../inputs/TextPasswordField'

import { useLoginHook } from '../../hooks/useLogin'
import CommonValue from '../../contexts/CommonValue'
import useTranslation from '../../hooks/useTranslation'

/**
 * Login View
 * @returns JSX
 */
export default function LogInSide() {
  const { errorMessage, isLoading, fetchArticles } = useLoginHook()
  const { globalLanguage } = useContext(CommonValue)

  const t = useTranslation(globalLanguage)

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const data = new FormData(event.currentTarget)
    const email: string = data.get('email')?.toString() || ''
    const password: string = data.get('password')?.toString() || ''

    fetchArticles(event, email, password)
  }

  // ローディング中
  if (isLoading) {
    return (
      <Box
        sx={{
          my: 0,
          mx: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
        }}
      >
        <CustomCircularProgress />
      </Box>
    )
  }

  return (
    <Grid
      container
      component='main'
      sx={{ height: '100vh', margin: '0px auto' }}
    >
      <Grid item xs={12} component={Paper} elevation={6} square>
        <Box
          sx={{
            my: 0,
            mx: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh',
          }}
        >
          <Box sx={titleLabelStyle}>
            <img
              src='/images/logo_typesymbol_v.png'
              alt='logos'
              loading='lazy'
              width={'340px'}
            />
          </Box>
          <Box
            component='form'
            noValidate
            onSubmit={handleSubmit}
            sx={{ mt: 1 }}
          >
            <TextField title={t.user_id} id='email' name='email' value='' />
            <TextPasswordField
              title={t.password}
              id='password'
              name='password'
              value=''
            />
            <Typography
              variant='inherit'
              component='h2'
              color='error'
              height={56}
              sx={{ whiteSpace: 'pre-line' }}
            >
              {errorMessage}
            </Typography>
            <MiddleButton text={t.sign_in} sx={loginBtnStyle} />
          </Box>
        </Box>
      </Grid>
    </Grid>
  )
}

const titleLabelStyle: SxProps<Theme> = {
  mb: 6,
}

const loginBtnStyle: SxProps<Theme> = {
  margin: '0 auto',
  display: 'block',
}
