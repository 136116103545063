import { createContext, useContext, useState } from 'react'
import { UmapDataType } from '../components/pages/Viewer/Umap'

type ViewContextType = {
  plotData: UmapDataType[]
  setPlotData: (data: UmapDataType[]) => void
}

type Props = {
  children: React.ReactNode
}

const initialRoiInteractsContext: ViewContextType = {
  plotData: [],
  setPlotData: () => null,
}

const RoiInteractsContext = createContext<ViewContextType | undefined>(
  initialRoiInteractsContext,
)

const ViewContext = ({ children }: Props) => {
  // TODO: Temporal until plots on umap are not static
  const [plotData, setPlotData] = useState<UmapDataType[]>([])

  return (
    <RoiInteractsContext.Provider
      value={{
        plotData,
        setPlotData,
      }}
    >
      {children}
    </RoiInteractsContext.Provider>
  )
}

const useViewContext = () => {
  const context = useContext(RoiInteractsContext)
  if (context === undefined) {
    throw new Error(
      'useRoiInteracts must be used within a RoiInteractsProvider',
    )
  }
  return context
}

export default ViewContext
export { useViewContext }
