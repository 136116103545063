import {
  Button,
  Checkbox,
  Collapse,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
} from '@mui/material'
import Stack from '@mui/material/Stack'
import { ReactElement, useEffect, useState } from 'react'
import FormControlLabel from '@mui/material/FormControlLabel'
import { ApiClient } from '../../../lib/ApiClient'
import { HiChevronRight, HiChevronDown } from 'react-icons/hi'
import { useCsvExport } from '../../../hooks/useCsvExport'

export default function ModalSpecimenCsvSelection({
  isOpen,
  listIds,
  onClose,
}: Props) {
  const accessToken = window.localStorage.getItem('access_token')
  const uid = window.localStorage.getItem('uid')
  const client = window.localStorage.getItem('client')
  const url = '/csv_columns'
  const [csvColumns, setCsvColumns] = useState<csvColumn[]>([])
  const [csvData, setCsvData] = useState<columnState>({})
  const [isAllSelected, setIsAllSelected] = useState(false)

  const { exportCsv } = useCsvExport()

  const handleDownload = async () => {
    const csvFormatted = Object.keys(csvData).reduce(
      (acc: columnState, key) => {
        if (csvData[key].isChecked) {
          acc[key] = csvData[key].elements as unknown as columnItem
        }
        return acc
      },
      {},
    )
    await exportCsv(listIds, csvFormatted as unknown as CsvSelection)
  }

  // Handle checkbox change and if that have children, set the children to the elements array or remove them if is unchecked
  const handleCheckBox = (key: string, val: boolean) => {
    const copyCsvData = { ...csvData }
    const column = csvColumns.find((col) => col.name === key)
    copyCsvData[key].isChecked = val

    if (column?.columns && column?.columns.length > 0 && val) {
      copyCsvData[key].elements = column.columns
    } else {
      copyCsvData[key].elements = []
    }
    setCsvData(copyCsvData)
  }

  // Necessary to handle the collapse of the children elements of the columns
  const handleCollapse = (key: string) => {
    const copyCsvData = { ...csvData }
    copyCsvData[key].isCollapsed = !copyCsvData[key].isCollapsed
    setCsvData(copyCsvData)
  }

  // Handle the children checkboxes and set them to the elements array or remove them if is unchecked also set the parent checkbox to checked if have any children checked
  const handleChildCheckBox = (key: string, val: string) => {
    const copyCsvData = { ...csvData }
    const elementsCopy = [...copyCsvData[key].elements]
    if (elementsCopy.includes(val)) {
      elementsCopy.splice(elementsCopy.indexOf(val), 1)
    } else {
      elementsCopy.push(val)
    }
    copyCsvData[key].isChecked =
      elementsCopy.length ===
      csvColumns.find((col) => col.name === key)?.columns.length

    copyCsvData[key].elements = elementsCopy
    setCsvData(copyCsvData)
  }

  const hanleSelectAll = () => {
    const copyCsvData = { ...csvData }
    const columns = csvColumns.map((col) => col.name)
    columns.forEach((col) => {
      copyCsvData[col].isChecked = true
      copyCsvData[col].elements =
        csvColumns.find((column) => column.name === col)?.columns || []
    })
    setCsvData(copyCsvData)
    setIsAllSelected(true)
  }

  const handleDeselectAll = () => {
    const copyCsvData = { ...csvData }
    const columns = csvColumns.map((col) => col.name)
    columns.forEach((col) => {
      copyCsvData[col].isChecked = false
      copyCsvData[col].elements = []
    })
    setCsvData(copyCsvData)
    setIsAllSelected(false)
  }

  useEffect(() => {
    if (accessToken && uid && client && csvColumns.length === 0) {
      const fetchData = async () => {
        try {
          const response = await ApiClient.get(url, {
            headers: {
              'access-token': accessToken,
              uid: uid,
              client: client,
            },
          })

          const columnsData = response.data.columns.map(
            (col: csvResponseType) => {
              return {
                name: col.column_name,
                columns: col.children.map((child) => child.column_name),
              }
            },
          )

          const columnsState = columnsData.reduce(
            (acc: columnState, column: { name: string }) => {
              acc[column.name] = {
                isChecked: false,
                elements: [],
                isCollapsed: false,
              }
              return acc
            },
            {},
          )

          setCsvColumns(columnsData)
          setCsvData(columnsState)
        } catch (e) {
          setCsvColumns([])
        }
      }
      fetchData()
        .then()
        .catch((e) => console.error(e))
    }
  }, [isOpen])

  if (!isOpen) return null
  return (
    <Dialog
      onClose={() => {
        onClose(false)
        handleDeselectAll()
      }}
      open={isOpen}
    >
      <DialogTitle textAlign={'center'}>Select elements to export</DialogTitle>
      <DialogContent
        sx={{
          paddingX: 10,
          width: 500,
        }}
      >
        <FormControl>
          {csvColumns.map((column) => {
            if (column.columns.length === 0) {
              return (
                <FormControlLabel
                  key={column.name}
                  control={
                    <Checkbox
                      checked={csvData[column.name].isChecked}
                      onChange={(e) =>
                        handleCheckBox(column.name, e.target.checked)
                      }
                    />
                  }
                  label={column.name}
                />
              )
            }
          })}
        </FormControl>

        {csvColumns.map((column) => {
          if (column.columns.length > 0) {
            return (
              <>
                <Stack
                  key={column.name}
                  direction={'row'}
                  alignItems={'center'}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={csvData[column.name].isChecked}
                        indeterminate={
                          csvData[column.name].elements.length > 0 &&
                          !csvData[column.name].isChecked
                        }
                        onChange={(e) => {
                          e.stopPropagation()
                          handleCheckBox(column.name, e.target.checked)
                        }}
                      />
                    }
                    label={column.name}
                  />
                  <div
                    style={{
                      cursor: 'pointer',
                    }}
                    onClick={() => handleCollapse(column.name)}
                  >
                    {csvData[column.name].isCollapsed ? (
                      <HiChevronDown />
                    ) : (
                      <HiChevronRight />
                    )}
                  </div>
                </Stack>
                <Collapse
                  in={csvData[column.name].isCollapsed}
                  timeout='auto'
                  unmountOnExit
                >
                  <Stack>
                    {column.columns.map((child) => (
                      <FormControlLabel
                        sx={{
                          marginLeft: 2,
                        }}
                        key={child}
                        control={
                          <Checkbox
                            checked={csvData[column.name].elements.includes(
                              child,
                            )}
                            onChange={() =>
                              handleChildCheckBox(column.name, child)
                            }
                          />
                        }
                        label={child}
                      />
                    ))}
                  </Stack>
                </Collapse>
              </>
            )
          }
        })}
        <Stack marginTop={4} justifyContent={'space-between'} direction={'row'}>
          <Button
            onClick={isAllSelected ? handleDeselectAll : hanleSelectAll}
            color={'secondary'}
            variant={'contained'}
          >
            {isAllSelected ? 'Deselect all' : 'Select all'}
          </Button>
          <Button onClick={handleDownload} variant={'contained'}>
            Download
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  )
}

type Props = {
  onClose: (val: boolean) => void
  isOpen: boolean
  listIds: number[]
}

export type CsvSelection = {
  id: boolean
  name: boolean
  tissue: string[]
  cells: string[]
  ihc: boolean
}

type csvColumn = {
  name: string
  columns: string[]
}

type csvResponseType = {
  column_name: string
  children: {
    column_name: string
    children: ReactElement[]
  }[]
}

type columnState = {
  [key: string]: columnItem
}

type columnItem = {
  isChecked: boolean
  elements: string[]
  isCollapsed: boolean
}
