import { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import CommonValue from '../contexts/CommonValue'
import useTranslation from './useTranslation'
import { ApiClient } from '../lib/ApiClient'
import { HTTP_STATUS_CODE } from '../constants/ErrorCode'
import axios, { AxiosProgressEvent } from 'axios'
import { responseError } from '../types/CommonTypes'

export const useIhcUpload = () => {
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [isLoading, setIsLoading] = useState(false)
  const { globalLanguage } = useContext(CommonValue)

  const tn = useTranslation(globalLanguage)
  const navigate = useNavigate()

  const uploadIhcImage = async (
    id: number,
    color: string,
    name: string,
    file: File,
    setUploadingPercent: (percent: number) => void,
    abortController?: AbortController,
  ) => {
    setIsLoading(true)
    setErrorMessage('')

    const url = '/ihcs'
    const formData = createFormData()

    const response = await ApiClient.post(url, formData, {
      onUploadProgress: onUloadProgress,
      signal: abortController?.signal,
    }).catch((error: responseError) => {
      if (axios.isCancel(error)) {
        console.error('Operation canceled')
        return
      }
      if (error.response.status === HTTP_STATUS_CODE.UNAUTHORIZED) {
        navigate('/sign_in')
      }
      setErrorMessage(tn.message.UPLOAD_IMAGE_ERROR)
    })

    setIsLoading(false)
    return !!response

    function createFormData() {
      const formData = new FormData()
      formData.append('pathological_image_id', id.toString())
      formData.append('color', color)
      formData.append('name', name)
      formData.append('upload_file', file)

      return formData
    }

    function onUloadProgress(progressEvent: AxiosProgressEvent) {
      const percentCompleted = progressEvent.total
        ? Math.round((progressEvent.loaded * 100) / progressEvent.total)
        : 0
      setUploadingPercent(percentCompleted)
    }
  }

  return {
    errorMessage,
    isLoading,
    uploadIhcImage,
  }
}
