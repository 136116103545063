import { useEffect, useRef, useState } from 'react'
import { sample } from '../../../constants/umap_all_cells.js'
import Plot from 'react-plotly.js'
import Box from '@mui/material/Box'
import { CircularProgress, Divider, Typography } from '@mui/material'
import Stack from '@mui/material/Stack'

import { BarChart, PieChart } from '@mui/x-charts'
import { appColorCodes } from '../../../constants/utils'
import { scrollbarStyle } from '../leaflets/LayerControl'
import { UmapStatus } from '../../tools/selectionTool/UmapStatus'
import { useViewContext } from '../../../contexts/ViewContext'
export type UmapDataType = {
  x: number[]
  y: number[]
  type: string
  mode: string
  marker: never
}

export const Umap = () => {
  const [umapData, setUmapData] = useState<UmapDataType[]>([])
  const [graphSize, setGraphSize] = useState({ width: 400, height: 400 })
  const ref = useRef<HTMLDivElement>(null)
  const { setPlotData, plotData } = useViewContext()

  useEffect(() => {
    if (window.Worker && plotData.length === 0) {
      const worker = new Worker(new URL('./umap.worker.js', import.meta.url))
      worker.postMessage(sample)
      worker.onmessage = (e) => {
        setUmapData(e.data)
        setPlotData(e.data)
      }
    }

    if (plotData.length > 0) {
      setUmapData(plotData)
    }
  }, [])

  useEffect(() => {
    const updateSize = () => {
      if (ref.current) {
        const { offsetWidth } = ref.current
        setGraphSize({
          width: offsetWidth,
          height: offsetWidth,
        })
      }
    }

    const resizeObserver = new ResizeObserver(updateSize)
    if (ref.current) {
      resizeObserver.observe(ref.current)
    }

    window.addEventListener('resize', updateSize)

    return () => {
      window.removeEventListener('resize', updateSize)
      if (ref.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        resizeObserver.unobserve(ref.current)
      }
    }
  }, [])

  const getLabels = () => {
    const labels = [] as string[]
    sample.forEach((cell) => {
      const labelString = String(cell.label)
      if (!labels.includes(labelString)) {
        labels.push(labelString)
      }
    })
    return labels
  }
  const getBarsData = () => {
    const labels = getLabels()
    const barsData = labels.map((label) => {
      return sample.filter((cell) => String(cell.label) === label).length
    })

    return {
      labels,
      barsData,
    }
  }

  const getPieData = () => {
    const labels = getLabels()
    const pieData = labels.map((label) => {
      const total = sample.filter((cell) => String(cell.label) === label).length
      return {
        id: label,
        value: total,
        label: label,
      }
    })

    return pieData
  }

  return (
    <Box height={'100%'}>
      <Box height={'25%'} sx={scrollbarStyle} p={2}>
        <UmapStatus />
      </Box>
      <Divider />
      <Stack
        sx={{
          height: '75%',
          ...scrollbarStyle,
          position: 'relative',
          zIndex: 10,
          paddingBottom: 4,
        }}
        alignItems={'center'}
        gap={2}
        bgcolor={appColorCodes.blackSecondary}
      >
        <Box width={'100%'} ref={ref}>
          {umapData.length > 0 ? (
            <Plot
              data={umapData as never}
              layout={{
                title: 'UMAP',
                width: graphSize.width,
                height: graphSize.height,
                paper_bgcolor: appColorCodes.blackSecondary,
                plot_bgcolor: appColorCodes.blackSecondary,
                font: {
                  color: '#ffffff',
                },
                yaxis: {
                  range: [-20, 20],
                  scaleanchor: 'x',
                },
                xaxis: {
                  range: [-20, 20],
                  scaleratio: 1,
                },
              }}
              config={{
                responsive: true,
              }}
            />
          ) : (
            <Stack py={2} gap={4} alignItems={'center'}>
              <Typography textAlign={'center'}>Loading Umap...</Typography>
              <CircularProgress />
            </Stack>
          )}
        </Box>
        <Divider sx={{ width: '100%' }} />
        <Box>
          <PieChart
            margin={{
              bottom: 100,
            }}
            slotProps={{
              legend: {
                direction: 'row',
                position: {
                  vertical: 'bottom',
                  horizontal: 'middle',
                },
              },
            }}
            series={[
              {
                data: getPieData(),
                highlightScope: { faded: 'global', highlighted: 'item' },
                faded: {
                  innerRadius: 30,
                  additionalRadius: -30,
                  color: 'gray',
                },
              },
            ]}
            width={graphSize.width}
            height={graphSize.width > 500 ? 500 : graphSize.width + 100}
          />
          <Typography textAlign={'center'} fontSize={'small'}>
            Cluster Composition Ratio
          </Typography>
        </Box>
        <Box>
          <BarChart
            xAxis={[
              {
                scaleType: 'band',
                data: getBarsData().labels,
              },
            ]}
            series={[
              {
                data: getBarsData().barsData,
              },
            ]}
            width={graphSize.width}
            height={graphSize.width > 500 ? 500 : graphSize.width}
          />
          <Typography textAlign={'center'} fontSize={'small'}>
            Distribution of Cell Numbers Across Clusters
          </Typography>
        </Box>
      </Stack>
    </Box>
  )
}
