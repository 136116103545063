import {
  Map,
  LeafletMouseEvent,
  Point,
  DomUtil,
  LatLng,
  DomEvent,
  Bounds,
} from 'leaflet'
import { Ratio } from '../../../../types/Ratio'

export const registerFreehandSelector = (map: Map, scaleRatios: Ratio[]) => {
  map.dragging.disable()
  map.boxZoom.disable()

  map.addEventListener('unload', finish)
  map.addEventListener('mousedown', onMouseDown)

  let dragging = false
  let selectorSvg: SVGSVGElement | null
  let drawer: (points: Point[]) => void
  const trajectory: LatLng[] = []

  function finish() {
    if (dragging) {
      selectorSvg?.remove()
      DomUtil.removeClass(map.getContainer(), 'leaflet-crosshair')
    }

    DomUtil.enableTextSelection()
    DomUtil.enableImageDrag()

    DomEvent.off(document as unknown as HTMLElement, {
      mousemove: onMouseMove,
      mouseup: onMouseUp,
      keydown: onKeyDown,
    })
  }

  function onMouseDown(event: LeafletMouseEvent) {
    if (event.originalEvent.button !== 0) return false

    dragging = false

    DomUtil.disableTextSelection()
    DomUtil.disableImageDrag()

    DomEvent.on(document as unknown as HTMLElement, {
      mousemove: onMouseMove,
      mouseup: onMouseUp,
      keydown: onKeyDown,
    })
  }

  function onMouseMove(event: Event) {
    if (!dragging) {
      ;[selectorSvg, drawer] = startSelection()
      dragging = true
    }

    const mouseEvent = event as MouseEvent

    trajectory.push(map.mouseEventToLatLng(mouseEvent))
    drawer(trajectory.map((x) => map.latLngToContainerPoint(x)))

    return

    function startSelection(): [SVGSVGElement, (points: Point[]) => void] {
      DomUtil.addClass(map.getContainer(), 'leaflet-crosshair')

      const svg = createSvgElement(map.getContainer())
      const drawer = createDrawer(svg)

      map.fire('selectionstart')

      return [svg, drawer]

      function createSvgElement(container: HTMLElement) {
        const svg = document.createElementNS(
          'http://www.w3.org/2000/svg',
          'svg',
        )
        svg.setAttribute('width', container.clientWidth.toString())
        svg.setAttribute('height', container.clientHeight.toString())
        svg.style.pointerEvents = 'none'
        svg.style.zIndex = '1001'
        svg.style.position = 'absolute'
        svg.style.left = '0'
        svg.style.top = '0'
        container.append(svg)

        return svg
      }

      function createDrawer(svg: SVGSVGElement) {
        const polygon = document.createElementNS(
          'http://www.w3.org/2000/svg',
          'polygon',
        )
        polygon.setAttribute('stroke', '#38f')
        polygon.setAttribute('stroke-dasharray', '2')
        polygon.setAttribute('fill', '#ffffff80')
        svg.append(polygon)

        return (points: Point[]) => {
          polygon.setAttribute(
            'points',
            points.map((p) => `${p.x},${p.y}`).join(' '),
          )
        }
      }
    }
  }

  function onMouseUp() {
    finish()

    if (!dragging) return

    const zoom = map.getZoom()
    const scaleRatio = scaleRatios[Math.min(zoom, scaleRatios.length - 1)]
    const points = trajectory.map((latlng) =>
      scale(map.project(latlng, zoom), scaleRatio),
    )

    const selectionCords = {
      start: trajectory,
      end: [],
    }
    const bounds = getBounds(points)

    map.fire('selectionend', { bounds, points, squareLocation: selectionCords })
    return

    function scale(point: Point, ratio: Ratio) {
      return new Point(point.x * ratio.x, point.y * ratio.y)
    }

    function getBounds(points: Point[]) {
      let minX = Number.MAX_SAFE_INTEGER
      let minY = Number.MAX_SAFE_INTEGER
      let maxX = Number.MIN_SAFE_INTEGER
      let maxY = Number.MIN_SAFE_INTEGER

      points.forEach((p) => {
        minX = Math.min(p.x, minX)
        minY = Math.min(p.y, minY)
        maxX = Math.max(p.x, maxX)
        maxY = Math.max(p.y, maxY)
      })

      return new Bounds(new Point(minX, minY), new Point(maxX, maxY))
    }
  }

  function onKeyDown(event: Event) {
    const keyboardEvent = event as KeyboardEvent
    if (keyboardEvent.code !== 'Escape') return

    finish()
  }

  return () => {
    map.dragging.enable()
    map.boxZoom.enable()

    map.removeEventListener('unload', finish)
    map.removeEventListener('mousedown', onMouseDown)
  }
}
