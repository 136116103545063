import { Typography } from '@mui/material'
import Stack from '@mui/material/Stack'
import { useMap } from 'react-leaflet'
import { getScaleMap } from '../../constants/utils'
import { useEffect, useState } from 'react'

interface ScaleBarProps {
  mpp: number
}
const barWidth = 100
export const ScaleBar = ({ mpp }: ScaleBarProps) => {
  const [barValue, setBarValue] = useState(0)
  const map = useMap()

  useEffect(() => {
    const calculateBarValue = () => {
      const scale = getScaleMap(map)
      const displayMpp = mpp * scale
      return displayMpp * barWidth
    }

    const listener = map.on('zoomend', () => {
      setBarValue(calculateBarValue())
    })

    return () => {
      map.off('zoomend', listener.off) // Cleanup function
    }
  }, [map, mpp])

  return (
    <Stack
      style={{
        backgroundColor: 'rgba(232,232,232,0.8)',
      }}
      direction={'row'}
      alignItems={'center'}
      columnGap={'4px'}
      padding={'4px'}
      marginLeft={'10px'}
      marginBottom={'10px'}
      borderRadius={1}
    >
      <Typography color={'black'}>{barValue.toFixed(3)} μm</Typography>
      <BarComponent width={barWidth} />
    </Stack>
  )
}

interface barProps {
  width: number
}
const BarComponent = ({ width }: barProps) => {
  return (
    <div
      style={{
        width: width + 'px',
        height: '8px',
        borderBottom: '2px solid black',
        borderLeft: '2px solid black',
        borderRight: '2px solid black',
      }}
    ></div>
  )
}
