import {
  SetStateAction,
  useState,
  useEffect,
  useContext,
  ChangeEvent,
} from 'react'
import { useNavigate } from 'react-router-dom'

import SwapVertRoundedIcon from '@mui/icons-material/SwapVertRounded'
import {
  IconButton,
  SelectChangeEvent,
  SxProps,
  Theme,
  Box,
  TableCell,
  TableRow,
  TableBody,
  TableContainer,
  TableHead,
  Table,
  Grid,
  Fab,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  DialogContentText,
  Backdrop,
  Pagination,
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import DownloadIcon from '@mui/icons-material/Download'

import Header from '../../rayoutItem/Header'
import theme from '../../../theme'
import SearchTextInput from '../../inputs/SearchTextInput'
import SelectBox from '../../inputs/SelectBox'
import ViewModeSwitch from '../../buttons/ViewModelSwitchButton'
import { useFileDataList } from '../../../hooks/useFileList'
import PicCard from '../../rayoutItem/PicCard'
import { PathologicalImage } from '../../../types/FileDataList'
import CloseButton from '../../buttons/CloseButton'
import { FileDropZone } from '../../rayoutItem/FileDropZone'
import CheckBox from '../../inputs/CheckBox'
import { usePathologicalImageDelete } from '../../../hooks/usePathologicalImageDelete'
import MiddleOutlineButton from '../../buttons/MiddleOutlineButton'
import MiddleButton from '../../buttons/MiddleButton'
import CustomCircularProgress from '../../customIcons/CustomCircularProgress'
import { getFormattedDate } from '../../../lib/Utility'
import useTranslation from '../../../hooks/useTranslation'
import CommonValue from '../../../contexts/CommonValue'
import Stack from '@mui/material/Stack'
import { useFileListSort } from '../../../hooks/useFileListSort'
import ModalSpecimenCsvSelection from './ModalSpecimenCsvSelection'
import { useUploadFilesContext } from '../../../contexts/UploadFiles'

export default function FileList(): JSX.Element {
  const [currentPage, setCurrentPage] = useState<number>(1)
  const { deletePathologicalImage, isLoading: isDeleteImgLoading } =
    usePathologicalImageDelete()
  const {
    directoryData,
    errorMessage,
    isLoading,
    userId,
    fetchFileData,
    filePagination,
  } = useFileDataList()
  const { sortKeysValues, handleSetIsAscending, handleSetSort, handleSearch } =
    useFileListSort()
  const { refetch, temporalCards, uploadingPercents } = useUploadFilesContext()

  const [uploadDialogOpen, setUploadDialogOpen] = useState<boolean>(false)
  const [selectedImages, setSelectedImages] = useState<PathologicalImage[]>([])
  const [confirmDeleteDialogOpen, setConfirmDeleteDialogOpen] =
    useState<boolean>(false)
  const { globalLanguage, globalViewMode, setGlobalViewMode } =
    useContext(CommonValue)
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

  const t = useTranslation(globalLanguage)
  const navigate = useNavigate()

  useEffect(() => {
    if (refetch !== 0) {
      fetchFileData()
    }
  }, [refetch])

  useEffect(() => {
    fetchFileData(currentPage)
      .then()
      .catch((e) => console.error(e))
  }, [
    sortKeysValues.selectValue,
    sortKeysValues.isAscending,
    sortKeysValues.searchInput,
  ])

  // 検索テキストが変更されたときのハンドラ
  const handleSearchTextChange = (
    event: ChangeEvent<HTMLInputElement>,
  ): void => {
    handleSearch(event.target.value)
  }

  // セレクトボックスの変更イベントハンドラ
  const handleChangeSelect = (event: SelectChangeEvent<string>) => {
    handleSetSort(event.target.value)
  }

  // 画像表示モードの切り替えハンドラ
  const handleSwitch = (bool: SetStateAction<boolean>): void => {
    setGlobalViewMode(bool)
  }

  // ビューワー画面に遷移する
  const handleRowClick = (rowData: rawDataType): void => {
    if (rowData.is_processed) {
      navigate('/viewer', { state: { id: rowData.id } })
    }
  }

  // アップロードポップを表示する
  const handleAddButton = (): void => {
    setUploadDialogOpen(true)
  }

  // アップロードポップを閉じる
  const handleUploadDialogClose = (): void => {
    setUploadDialogOpen(false)
  }

  // チェックボックスを切り替える処理
  const handleCheckboxChange = (
    image: PathologicalImage,
    isChecked: boolean,
  ) => {
    if (isChecked) {
      setSelectedImages((prevSelectedImages) => [...prevSelectedImages, image])
    } else {
      setSelectedImages((prevSelectedImages) =>
        prevSelectedImages.filter((item) => item.id !== image.id),
      )
    }
  }

  // // チェックボックスで選択したデータをCSVとしてダウンロードする
  // const handleSelectedIds = async (): Promise<void> => {
  //   const idsToExport = selectedImages.map((image) => {
  //     return image.id
  //   })
  //
  //   // CSVエクスポートを実行します。
  //   await exportCsv(idsToExport)
  // }

  // チェックボックスで選択したデータを削除する
  const handleDelete = (): void => {
    setConfirmDeleteDialogOpen(true)
  }

  const handleConfirmDelete = async (): Promise<void> => {
    await Promise.all(
      selectedImages.map(async (image) => {
        return await deletePathologicalImage(image.id)
      }),
    )
    await fetchFileData()
    setSelectedImages([])
    setConfirmDeleteDialogOpen(false)
  }

  const handleAllCheckboxSwitch = (): void => {
    if (selectedImages.length === directoryData?.pathological_image.length) {
      setSelectedImages([])
    } else {
      setSelectedImages(
        directoryData?.pathological_image.map((image) => {
          return image
        }) || [],
      )
    }
  }

  // セレクトボックスのメニューアイテム
  const selectboxItems = [
    { id: 1, label: t.specimen_id },
    { id: 2, label: t.date_of_acquisition },
    { id: 3, label: t.carcinoma },
    { id: 4, label: t.person_in_charge },
  ]

  const renderElements = isLoading || isDeleteImgLoading
  return (
    <Box sx={containerSx}>
      <Header titleLogo={'logo_symbol.png'} userId={userId} />
      <Box
        sx={{
          overflow: 'auto',
          height: 'calc(100vh - 65px)',
        }}
      >
        <Grid container>
          <Box sx={searchContainerSx}>
            <Box>
              <SearchTextInput
                value={sortKeysValues.searchInput}
                onChange={handleSearchTextChange}
              />
            </Box>
            <Box>
              <IconButton
                aria-label='search'
                size='small'
                onClick={() => handleSetIsAscending()}
              >
                <SwapVertRoundedIcon />
              </IconButton>
            </Box>
            <SelectBox
              value={sortKeysValues.selectValue}
              menuItems={selectboxItems}
              onChange={handleChangeSelect}
            />
            <Box sx={{ paddingLeft: 1 }}>
              <ViewModeSwitch
                viewMode={globalViewMode}
                handleSwitch={handleSwitch}
              />
            </Box>
          </Box>
          <Grid container>
            <Grid item xs={0.5}></Grid>
            <Grid item xs={11}>
              {/*Items List and Table List*/}
              {globalViewMode ? (
                <Box
                  sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    padding: '10px',
                  }}
                >
                  {directoryData?.pathological_image.map((image) => (
                    <Box sx={{ margin: '10px' }} key={image.created_at}>
                      <PicCard
                        content={image}
                        onClick={handleRowClick}
                        onCheckboxChange={handleCheckboxChange}
                        checked={selectedImages.some(
                          (selectedimage) => selectedimage.id === image.id,
                        )}
                        uploadProgress={0}
                        fetchFileDataList={fetchFileData}
                      />
                    </Box>
                  ))}
                  {temporalCards.map((card) => (
                    <Box sx={{ margin: '10px' }} key={card.created_at}>
                      <PicCard
                        content={card}
                        onClick={handleRowClick}
                        onCheckboxChange={handleCheckboxChange}
                        checked={selectedImages.some(
                          (selectedimage) => selectedimage.id === card.id,
                        )}
                        uploadProgress={uploadingPercents[card.name] || 0}
                        fetchFileDataList={fetchFileData}
                      />
                    </Box>
                  ))}
                </Box>
              ) : (
                <TableContainer>
                  {errorMessage && (
                    <Box>
                      <p>{errorMessage}</p>
                    </Box>
                  )}
                  <Table aria-label='simple table'>
                    <TableHead>
                      <TableRow>
                        <TableCell sx={tableHeaderStyle}>
                          <CheckBox
                            checked={
                              selectedImages.length ===
                                directoryData?.pathological_image.length &&
                              directoryData?.pathological_image.length !== 0
                                ? true
                                : selectedImages.length === 0 ||
                                    directoryData?.pathological_image.length ===
                                      0
                                  ? false
                                  : undefined
                            }
                            onChange={handleAllCheckboxSwitch}
                            clickZone='50px'
                          />
                        </TableCell>
                        <TableCell sx={tableHeaderStyle}>
                          {t.specimen_id}
                        </TableCell>
                        <TableCell sx={tableHeaderStyle}>
                          {t.date_of_acquisition}
                        </TableCell>
                        <TableCell sx={tableHeaderStyle}>
                          {t.carcinoma}
                        </TableCell>
                        <TableCell sx={tableHeaderStyle}>
                          {t.person_in_charge}
                        </TableCell>
                        <TableCell sx={tableHeaderStyle}>{t.status}</TableCell>
                        <TableCell sx={tableHeaderStyle}>{t.upload}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {directoryData?.pathological_image?.map((row) => (
                        <TableRow
                          key={row.id}
                          sx={rowHoverStyle}
                          onClick={() => handleRowClick(row)}
                        >
                          <TableCell
                            sx={!row.is_processed ? tableHeaderStyle : {}}
                          >
                            <CheckBox
                              checked={selectedImages.some(
                                (image) => image.id === row.id,
                              )}
                              onChange={(isChecked: boolean) =>
                                handleCheckboxChange(row, isChecked)
                              }
                              clickZone='50px'
                            />
                          </TableCell>
                          <TableCell
                            sx={!row.is_processed ? tableHeaderStyle : {}}
                          >
                            {row.name}
                          </TableCell>
                          <TableCell
                            sx={!row.is_processed ? tableHeaderStyle : {}}
                          >
                            {getFormattedDate(
                              new Date(row.date_of_acquisition),
                              t.dateFormat,
                            )}
                          </TableCell>
                          <TableCell
                            sx={!row.is_processed ? tableHeaderStyle : {}}
                          >
                            {row.organ_type.name}
                          </TableCell>
                          <TableCell
                            sx={!row.is_processed ? tableHeaderStyle : {}}
                          >
                            {row.person_in_charge}
                          </TableCell>
                          <TableCell
                            sx={!row.is_processed ? tableHeaderStyle : {}}
                          >
                            {row.is_processed ? 'Completed' : 'AI Processing'}
                          </TableCell>
                          <TableCell
                            sx={!row.is_processed ? tableHeaderStyle : {}}
                          >
                            {getFormattedDate(
                              new Date(row.created_at),
                              t.dateFormatWithTime,
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  <Dialog open={isModalOpen}>
                    <p>{isModalOpen}</p>
                  </Dialog>
                </TableContainer>
              )}
            </Grid>
            <Grid item xs={0.5}></Grid>
          </Grid>
        </Grid>

        {/*Pagination bar*/}
        <Stack
          display={renderElements ? 'none' : 'flex'}
          alignItems={'center'}
          paddingX={8}
          paddingY={4}
        >
          <Pagination
            onChange={(e, page) => {
              setCurrentPage(page)
              fetchFileData(page)
            }}
            shape={'rounded'}
            variant={'outlined'}
            page={currentPage}
            count={filePagination.totalPage}
          />
        </Stack>

        {/*Image select tool buttons*/}
        {selectedImages.length > 0 && (
          <>
            <Fab
              color='error'
              aria-label='add'
              sx={deleteIconStyle}
              onClick={handleDelete}
            >
              <DeleteIcon />
            </Fab>
            <Fab
              color='primary'
              aria-label='add'
              sx={downloadIconStyle}
              onClick={() => setIsModalOpen(true)}
            >
              <DownloadIcon />
            </Fab>
          </>
        )}
        <Fab
          color='primary'
          aria-label='add'
          sx={addIconStyle}
          onClick={handleAddButton}
        >
          <AddIcon />
        </Fab>
        <Dialog
          open={uploadDialogOpen}
          onClose={handleUploadDialogClose}
          aria-labelledby='upload-dialog-title'
        >
          <DialogContent sx={{ minWidth: '600px', minHeight: '300px' }}>
            <Box sx={{ marginBottom: 2 }}>
              <FileDropZone onCloseClick={handleUploadDialogClose} />
            </Box>
            <Box sx={{ position: 'absolute', top: 0, right: 0 }}>
              <CloseButton onClick={handleUploadDialogClose} />
            </Box>
          </DialogContent>
        </Dialog>
        <Dialog
          open={confirmDeleteDialogOpen}
          onClose={() => setConfirmDeleteDialogOpen(false)}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle id='alert-dialog-title'>{t.delete}</DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-description'>
              {t.delete_message}
              <br />
              {t.delete_message_second}
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{ width: '600px' }}>
            <Box sx={{ padding: '10px' }}>
              <MiddleOutlineButton
                text={t.cancel}
                onClick={() => setConfirmDeleteDialogOpen(false)}
              />
              <MiddleButton
                text={t.delete}
                onClick={handleConfirmDelete}
                sx={{
                  backgroundColor: 'red',
                  '&:hover': {
                    backgroundColor: 'darkred',
                  },
                  marginLeft: '10px',
                }}
              />
            </Box>
          </DialogActions>
        </Dialog>
        {/*Loading component*/}
        <Backdrop
          open={renderElements}
          sx={{
            zIndex: (theme) => theme.zIndex.drawer + 1,
            color: '#fff',
          }}
        >
          <CustomCircularProgress />
        </Backdrop>
      </Box>
      <ModalSpecimenCsvSelection
        listIds={selectedImages.map((s) => s.id)}
        onClose={(val) => setIsModalOpen(val)}
        isOpen={isModalOpen}
      />
    </Box>
  )
}

interface rawDataType {
  is_processed: boolean
  id: number
}

const containerSx: SxProps<Theme> = {
  backgroundColor: theme.palette.bgPrimary.main,
  height: '100vh',
  width: '100%',
  position: 'absolute',
  overflow: 'auto',
}

const searchContainerSx: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'row',
  paddingTop: '25px',
  marginLeft: 'auto',
  paddingRight: '50px',
}

const tableHeaderStyle: SxProps<Theme> = {
  color: theme.palette.line.main,
}

const rowHoverStyle: SxProps<Theme> = {
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
  '& td, & th': { borderBottom: 'none' },
  '&:last-child td, &:last-child th': { border: 0 },
}

const addIconStyle: SxProps<Theme> = {
  position: 'fixed',
  bottom: theme.spacing(5),
  right: theme.spacing(5),
}

const deleteIconStyle: SxProps<Theme> = {
  position: 'fixed',
  bottom: theme.spacing(5),
  right: theme.spacing(30),
}

const downloadIconStyle: SxProps<Theme> = {
  position: 'fixed',
  bottom: theme.spacing(5),
  right: theme.spacing(17),
}
