import { Button, SxProps, Theme } from '@mui/material'

type Props = {
  text: string
  sx?: SxProps<Theme>
  onClick?: (event: never) => void
  disabled?: boolean
}

export default function MiddleButton({
  text,
  sx,
  onClick,
  disabled,
}: Props): JSX.Element {
  const addSx = {
    ...buttonStyle,
    ...sx,
  }
  return (
    <Button
      type='submit'
      variant='contained'
      sx={addSx}
      onClick={onClick}
      disabled={disabled}
    >
      {text}
    </Button>
  )
}

const buttonStyle: SxProps<Theme> = {
  textTransform: 'none',
  width: '110px',
  height: '45px',
}
