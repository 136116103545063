import React, {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { Box, Typography, SxProps, Theme, TextField, Grid } from '@mui/material'
import theme from '../../theme'
import SelectBox from '../inputs/SelectBox'
import CalendarInput from '../inputs/CalendarInput'
import MiddleButton from '../buttons/MiddleButton'
import MiddleOutlineButton from '../buttons/MiddleOutlineButton'
import StandardButton from '../buttons/StandardButton'
import useTranslation from '../../hooks/useTranslation'
import CommonValue from '../../contexts/CommonValue'
import { SelectChangeEvent } from '@mui/material/Select'
import {
  UploadFilesArgType,
  useUploadFilesContext,
} from '../../contexts/UploadFiles'

interface FileDropZoneProps {
  sx?: SxProps<Theme>
  onCloseClick: () => void
}

export const FileDropZone: React.FC<FileDropZoneProps> = ({
  sx,
  onCloseClick,
}) => {
  const { handleQueueFiles } = useUploadFilesContext()
  const [selectedFile, setSelectedFile] = useState<File[] | null>(null)
  const [filesToUpload, setFilesToUpload] = useState<UploadFilesArgType[]>([])

  const [fileIndex, setFileIndex] = useState<number>(0)
  const [carcinoma, setCarcinoma] = useState<number>(0)
  const [name, setName] = useState('')
  const [note, setNote] = useState('')
  const [personInCharge, setPersonInCharge] = useState('')
  const [acquisitionDate, setAcquisitionDate] = useState<Date | null>(
    new Date(),
  )
  // const [isSaving, setIsSaving] = useState(false)
  const { globalLanguage } = useContext(CommonValue)

  const t = useTranslation(globalLanguage)

  const resetForm = () => {
    setName('')
    setCarcinoma(0)
    setPersonInCharge('')
    setAcquisitionDate(new Date())
    setNote('')
  }

  const handleFileToUpload = () => {
    if (!selectedFile) {
      return
    }
    const newFile = {
      file: selectedFile[fileIndex]!,
      organ_type_id: carcinoma,
      name,
      person_in_charge: personInCharge,
      date_of_acquisition: acquisitionDate?.toISOString() || '',
      note,
    }

    setFilesToUpload((prev) => [...prev, newFile])
    setFileIndex((prevVal) => prevVal + 1)
    resetForm()
  }

  const handleFileSelect = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.files && e.target.files.length > 0) {
        const files = Object.values(e.target.files)
        setSelectedFile(files)
      }
    },
    [],
  )

  const handleDragOver = useCallback((e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    e.stopPropagation()
  }, [])

  const handleDrop = useCallback((e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    e.stopPropagation()

    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      const files = Object.values(e.dataTransfer.files)
      setSelectedFile(files)
    }
  }, [])

  const handleCarcinomaChange = (event: SelectChangeEvent<string>) => {
    setCarcinoma(+event.target.value)
  }

  const handleAcquisitionDateChange = (date: Date | null) => {
    setAcquisitionDate(date)
  }

  const handleCancel = () => {
    onCloseClick()
  }

  function handleSpecimanIdChange(event: ChangeEvent<{ value: string }>): void {
    setName(event.target.value)
  }

  function handlePersonInChargeChange(
    event: ChangeEvent<{ value: string }>,
  ): void {
    setPersonInCharge(event.target.value)
  }

  function handleNoteChange(event: ChangeEvent<{ value: string }>): void {
    setNote(event.target.value)
  }

  useEffect(() => {
    if (selectedFile) {
      if (fileIndex >= selectedFile?.length) {
        onCloseClick()
        handleQueueFiles(filesToUpload)
      }
    }
  }, [fileIndex])

  // 癌腫
  const carcinomaLists: { id: number; label: string }[] = [
    { id: 0, label: 'ー' },
    { id: 1, label: t.lung },
    { id: 2, label: t.colon },
    { id: 3, label: t.breast },
    { id: 4, label: t.stomach },
  ]

  return selectedFile! && fileIndex < selectedFile?.length ? (
    <>
      <Box>
        <Typography variant='h5'>
          {t.selected_file}
          {selectedFile[fileIndex]?.name}
        </Typography>
        <Box sx={{ paddingTop: '20px' }}>
          <Box component='form' sx={{ width: '100%' }}>
            <Grid container spacing={2} sx={{ paddingBottom: '10px' }}>
              <Grid item xs={12} md={6}>
                <Box sx={{ width: '260px' }}>
                  <Typography>{t.specimen_id}</Typography>
                  <TextField
                    value={name}
                    margin='dense'
                    fullWidth
                    sx={{
                      '& input': {
                        height: '26px',
                        padding: '5px 12px',
                      },
                      '& fieldset': {
                        paddingTop: '8px',
                        paddingBottom: '8px',
                      },
                      backgroundColor: theme.palette.bgSecondary.main,
                    }}
                    onChange={handleSpecimanIdChange}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box sx={{ width: '260px' }}>
                  <Typography sx={{ paddingBottom: '7px' }}>
                    {t.carcinoma}
                  </Typography>
                  <SelectBox
                    value={`${carcinoma}`}
                    onChange={handleCarcinomaChange}
                    menuItems={carcinomaLists}
                  />
                </Box>
              </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ paddingBottom: '10px' }}>
              <Grid item xs={12} md={6}>
                <Box sx={{ width: '260px' }}>
                  <Typography>{t.person_in_charge}</Typography>
                  <TextField
                    value={personInCharge}
                    margin='dense'
                    fullWidth
                    sx={{
                      '& input': {
                        height: '26px',
                        padding: '5px 12px',
                      },
                      '& fieldset': {
                        paddingTop: '8px',
                        paddingBottom: '8px',
                      },
                      backgroundColor: theme.palette.bgSecondary.main,
                    }}
                    onChange={handlePersonInChargeChange}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box sx={{ width: '260px' }}>
                  <Typography sx={{ paddingBottom: '7px' }}>
                    {t.date_of_acquisition}
                  </Typography>
                  <CalendarInput
                    selectedDate={acquisitionDate}
                    onChange={handleAcquisitionDateChange}
                  />
                </Box>
              </Grid>
            </Grid>
            <Box>
              <Typography>{t.note}</Typography>
              <TextField
                value={note}
                margin='dense'
                fullWidth
                multiline
                minRows={6}
                maxRows={6}
                sx={{ backgroundColor: theme.palette.bgSecondary.main }}
                onChange={handleNoteChange}
              />
            </Box>
            <Grid
              container
              spacing={2}
              sx={{ marginTop: 2 }}
              justifyContent='flex-end'
            >
              <Grid item>
                <MiddleOutlineButton text='Cancel' onClick={handleCancel} />
              </Grid>
              <Grid item>
                <MiddleButton
                  text='Upload'
                  onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                    e.preventDefault()
                    handleFileToUpload()
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </>
  ) : (
    <>
      <Typography variant='h6' sx={{ paddingBottom: '10px' }}>
        {t.upload}
      </Typography>
      <Box
        sx={{
          border: '2px dashed',
          borderColor: 'divider',
          borderRadius: '4px',
          padding: 2,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '250px',
          ...sx,
        }}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        <Typography variant='body1'>{t.drag_message}</Typography>
        <Box sx={{ marginTop: 1 }}>
          <input
            style={{ display: 'none' }}
            id='contained-button-file'
            type='file'
            onChange={handleFileSelect}
          />
          <Box sx={{ paddingTop: '50px' }}>
            <StandardButton
              text='Select file'
              htmlFor='contained-button-file'
            />
          </Box>
        </Box>
      </Box>
    </>
  )
}
