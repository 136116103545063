export const sample = [
  { x: 3.656819342, y: -5.169487, label: 1, color: '#c23740' },
  { x: 3.364427567, y: -5.001612663, label: 1, color: '#c23740' },
  { x: 5.923496246, y: -5.491588593, label: 1, color: '#c23740' },
  { x: 2.815448761, y: 8.123292923, label: 2, color: '#c3b96f' },
  { x: 5.974492073, y: -3.59016037, label: 1, color: '#c23740' },
  { x: 2.095882893, y: 7.616714478, label: 2, color: '#c3b96f' },
  { x: 4.523528099, y: -5.496575356, label: 1, color: '#c23740' },
  { x: 2.234882355, y: 6.63763237, label: 2, color: '#c3b96f' },
  { x: 5.541377068, y: -3.634004593, label: 1, color: '#c23740' },
  { x: 3.072292328, y: -5.296964645, label: 1, color: '#c23740' },
  { x: 6.014924049, y: -4.357515335, label: 1, color: '#c23740' },
  { x: 5.572267532, y: -3.856217384, label: 1, color: '#c23740' },
  { x: 4.525432587, y: -4.427275658, label: 1, color: '#c23740' },
  { x: 3.839608192, y: -5.343236923, label: 1, color: '#c23740' },
  { x: 4.930070877, y: -4.562951088, label: 1, color: '#c23740' },
  { x: 4.724300385, y: -4.574878693, label: 1, color: '#c23740' },
  { x: 6.078486443, y: -3.560230255, label: 1, color: '#c23740' },
  { x: 5.350185394, y: -3.620880127, label: 1, color: '#c23740' },
  { x: 4.733171463, y: -4.010604858, label: 1, color: '#c23740' },
  { x: 3.960884094, y: -4.890387535, label: 1, color: '#c23740' },
  { x: 3.662174225, y: -5.188896179, label: 1, color: '#c23740' },
  { x: 5.789849281, y: -3.862459183, label: 1, color: '#c23740' },
  { x: 6.148730278, y: -4.841723442, label: 1, color: '#c23740' },
  { x: 3.252672195, y: -5.550538063, label: 1, color: '#c23740' },
  { x: 5.279933929, y: -5.033520699, label: 1, color: '#c23740' },
  { x: 4.001963615, y: -4.5386343, label: 1, color: '#c23740' },
  { x: 5.44773674, y: -3.796171188, label: 1, color: '#c23740' },
  { x: 3.848940849, y: -5.053028107, label: 1, color: '#c23740' },
  { x: 4.383465767, y: -4.281484604, label: 1, color: '#c23740' },
  { x: 5.188363075, y: -3.77954483, label: 1, color: '#c23740' },
  { x: 6.099462509, y: -3.566906929, label: 1, color: '#c23740' },
  { x: 3.760709763, y: -5.007187843, label: 1, color: '#c23740' },
  { x: 4.485006332, y: -4.181502342, label: 1, color: '#c23740' },
  { x: 5.391130447, y: -3.995481491, label: 1, color: '#c23740' },
  { x: 3.559556007, y: -5.758577347, label: 1, color: '#c23740' },
  { x: 4.104207516, y: -5.599000931, label: 1, color: '#c23740' },
  { x: 1.979227066, y: -6.537077904, label: 1, color: '#c23740' },
  { x: 3.672395706, y: -4.862363815, label: 1, color: '#c23740' },
  { x: 4.830841064, y: -4.867967606, label: 1, color: '#c23740' },
  { x: 5.281261444, y: -5.171317101, label: 1, color: '#c23740' },
  { x: 2.660036087, y: -5.968489647, label: 1, color: '#c23740' },
  { x: 5.851352692, y: -4.056770325, label: 1, color: '#c23740' },
  { x: 5.376588821, y: -4.369701385, label: 1, color: '#c23740' },
  { x: 3.14229393, y: -5.460674286, label: 1, color: '#c23740' },
  { x: 5.372627258, y: -3.684127808, label: 1, color: '#c23740' },
  { x: 4.954030991, y: -4.54737854, label: 1, color: '#c23740' },
  { x: 5.466945648, y: -4.27399826, label: 1, color: '#c23740' },
  { x: 3.47049427, y: -5.425282478, label: 1, color: '#c23740' },
  { x: 4.595721245, y: -4.389840126, label: 1, color: '#c23740' },
  { x: 3.856722832, y: -5.693508148, label: 1, color: '#c23740' },
  { x: 0.943688393, y: 5.691987038, label: 2, color: '#c3b96f' },
  { x: 3.541488647, y: -5.370103836, label: 1, color: '#c23740' },
  { x: 5.95995903, y: -4.319417953, label: 1, color: '#c23740' },
  { x: 5.843402863, y: -5.270175934, label: 1, color: '#c23740' },
  { x: 4.839294434, y: -4.397529602, label: 1, color: '#c23740' },
  { x: 5.497022629, y: -5.529121399, label: 1, color: '#c23740' },
  { x: 5.967645645, y: -3.853098869, label: 1, color: '#c23740' },
  { x: 1.159250736, y: 5.975047112, label: 2, color: '#c3b96f' },
  { x: 6.554931641, y: -4.461065292, label: 1, color: '#c23740' },
  { x: 4.246788025, y: -4.28289032, label: 1, color: '#c23740' },
  { x: 4.642097473, y: -5.300115585, label: 1, color: '#c23740' },
  { x: 4.489730835, y: -5.53068924, label: 1, color: '#c23740' },
  { x: 3.423174858, y: -6.210572243, label: 1, color: '#c23740' },
  { x: 0.530651093, y: 5.67975235, label: 2, color: '#c3b96f' },
  { x: 2.936632156, y: -6.631217957, label: 1, color: '#c23740' },
  { x: 5.014591217, y: -4.36964798, label: 1, color: '#c23740' },
  { x: 5.918561935, y: -3.929998398, label: 1, color: '#c23740' },
  { x: 3.710371494, y: -4.77336216, label: 1, color: '#c23740' },
  { x: 5.275022507, y: -5.936572075, label: 1, color: '#c23740' },
  { x: 4.882694244, y: -4.583501816, label: 1, color: '#c23740' },
  { x: 6.613985062, y: -5.582893372, label: 1, color: '#c23740' },
  { x: 5.997637749, y: -4.76008606, label: 1, color: '#c23740' },
  { x: 4.59118557, y: -6.351333618, label: 1, color: '#c23740' },
  { x: 2.327218056, y: -6.66828537, label: 1, color: '#c23740' },
  { x: 3.890125275, y: -5.27449131, label: 1, color: '#c23740' },
  { x: 2.196843147, y: -6.811262131, label: 1, color: '#c23740' },
  { x: 5.133106232, y: -5.241466522, label: 1, color: '#c23740' },
  { x: 3.884908676, y: -6.246997833, label: 1, color: '#c23740' },
  { x: 2.553356171, y: -6.104017258, label: 1, color: '#c23740' },
  { x: 0.699149132, y: 5.302027702, label: 2, color: '#c3b96f' },
  { x: 5.47913456, y: -3.641858101, label: 1, color: '#c23740' },
  { x: 5.626981735, y: -5.531360626, label: 1, color: '#c23740' },
  { x: 0.726649284, y: 5.353457451, label: 2, color: '#c3b96f' },
  { x: 5.752761841, y: -3.656661034, label: 1, color: '#c23740' },
  { x: 0.92118597, y: 5.637214661, label: 2, color: '#c3b96f' },
  { x: 6.261714935, y: -4.781280518, label: 1, color: '#c23740' },
  { x: 0.500527382, y: 6.005105972, label: 2, color: '#c3b96f' },
  { x: 5.949269295, y: -6.215845108, label: 1, color: '#c23740' },
  { x: 0.416644096, y: 5.255441666, label: 2, color: '#c3b96f' },
  { x: 5.257714272, y: -4.429497719, label: 1, color: '#c23740' },
  { x: 0.746803284, y: 5.655421257, label: 2, color: '#c3b96f' },
  { x: 4.778627396, y: -4.613156319, label: 1, color: '#c23740' },
  { x: 5.869686127, y: -3.688997269, label: 1, color: '#c23740' },
  { x: 5.921012878, y: -4.28151989, label: 1, color: '#c23740' },
  { x: 5.495447159, y: -4.385887146, label: 1, color: '#c23740' },
  { x: 0.219836712, y: 5.910357475, label: 2, color: '#c3b96f' },
  { x: 3.774336815, y: -5.693741798, label: 1, color: '#c23740' },
  { x: 3.181088448, y: -5.338038445, label: 1, color: '#c23740' },
  { x: 4.541590691, y: -5.469991684, label: 1, color: '#c23740' },
  { x: 2.261382103, y: 6.776442528, label: 2, color: '#c3b96f' },
  { x: 3.45558548, y: -5.511622429, label: 1, color: '#c23740' },
  { x: 2.382546425, y: -6.337532043, label: 1, color: '#c23740' },
  { x: 5.527349472, y: -3.65042305, label: 1, color: '#c23740' },
  { x: 5.999042511, y: -3.549907684, label: 1, color: '#c23740' },
  { x: 5.291585922, y: -4.060866356, label: 1, color: '#c23740' },
  { x: 4.121063232, y: -5.039504051, label: 1, color: '#c23740' },
  { x: 5.881462097, y: -4.463922501, label: 1, color: '#c23740' },
  { x: 5.965931892, y: -5.286304474, label: 1, color: '#c23740' },
  { x: 1.114068985, y: 5.665935516, label: 2, color: '#c3b96f' },
  { x: 4.267144203, y: -5.393512726, label: 1, color: '#c23740' },
  { x: 3.391169548, y: -5.607292175, label: 1, color: '#c23740' },
  { x: 5.953830719, y: -3.805686951, label: 1, color: '#c23740' },
  { x: 5.015254021, y: -4.190288544, label: 1, color: '#c23740' },
  { x: 4.230237007, y: -4.322061539, label: 1, color: '#c23740' },
  { x: 0.995765686, y: 5.617668152, label: 2, color: '#c3b96f' },
  { x: 5.362027168, y: -3.890903473, label: 1, color: '#c23740' },
  { x: 6.073181152, y: -4.154222488, label: 1, color: '#c23740' },
  { x: 5.63970089, y: -4.425151825, label: 1, color: '#c23740' },
  { x: 3.753318787, y: -4.768144608, label: 1, color: '#c23740' },
  { x: 5.518421173, y: -4.421920776, label: 1, color: '#c23740' },
  { x: 3.751663208, y: -4.840540886, label: 1, color: '#c23740' },
  { x: 5.049268723, y: -3.775249481, label: 1, color: '#c23740' },
  { x: 5.340006828, y: -4.031319618, label: 1, color: '#c23740' },
  { x: 4.517335892, y: -4.60766983, label: 1, color: '#c23740' },
  { x: 3.404839516, y: -5.24207592, label: 1, color: '#c23740' },
  { x: -0.154924393, y: 6.133760452, label: 2, color: '#c3b96f' },
  { x: 5.150284767, y: -5.095430374, label: 1, color: '#c23740' },
  { x: 5.415948868, y: -3.902806282, label: 1, color: '#c23740' },
  { x: 1.090275764, y: 5.606375694, label: 2, color: '#c3b96f' },
  { x: 0.656497002, y: 5.197117805, label: 2, color: '#c3b96f' },
  { x: 4.898878098, y: -3.995576859, label: 1, color: '#c23740' },
  { x: 4.650980949, y: -4.704269409, label: 1, color: '#c23740' },
  { x: 5.113473892, y: -4.141469955, label: 1, color: '#c23740' },
  { x: 6.258031845, y: -4.188474655, label: 1, color: '#c23740' },
  { x: 3.298230171, y: -5.394571304, label: 1, color: '#c23740' },
  { x: 0.820381165, y: 5.529972076, label: 2, color: '#c3b96f' },
  { x: 0.2822752, y: 6.460155487, label: 2, color: '#c3b96f' },
  { x: 0.519332886, y: 5.207465172, label: 2, color: '#c3b96f' },
  { x: 5.691959381, y: -4.120310783, label: 1, color: '#c23740' },
  { x: 0.46957016, y: 5.374828339, label: 2, color: '#c3b96f' },
  { x: 5.460010529, y: -4.239456177, label: 1, color: '#c23740' },
  { x: 4.110458374, y: -4.437878609, label: 1, color: '#c23740' },
  { x: 3.726753235, y: -4.87065506, label: 1, color: '#c23740' },
  { x: 3.8227005, y: -4.66519165, label: 1, color: '#c23740' },
  { x: 5.012701988, y: -4.182355881, label: 1, color: '#c23740' },
  { x: 4.792705536, y: -4.004095078, label: 1, color: '#c23740' },
  { x: 5.405139923, y: -4.212684631, label: 1, color: '#c23740' },
  { x: 4.702030182, y: -4.139701843, label: 1, color: '#c23740' },
  { x: 0.69581604, y: 5.404167175, label: 2, color: '#c3b96f' },
  { x: 0.56473732, y: 5.341281891, label: 2, color: '#c3b96f' },
  { x: 0.801677704, y: 5.548809052, label: 2, color: '#c3b96f' },
  { x: 3.353437901, y: -5.424267769, label: 1, color: '#c23740' },
  { x: 6.147904396, y: -3.901246071, label: 1, color: '#c23740' },
  { x: 6.083656311, y: -3.88495636, label: 1, color: '#c23740' },
  { x: 5.593055725, y: -3.749180794, label: 1, color: '#c23740' },
  { x: 2.411541939, y: 6.921718597, label: 2, color: '#c3b96f' },
  { x: 0.814222813, y: 5.41604805, label: 2, color: '#c3b96f' },
  { x: 5.662988663, y: -3.977661133, label: 1, color: '#c23740' },
  { x: 3.749914169, y: -4.82251358, label: 1, color: '#c23740' },
  { x: 5.203891754, y: -3.740924835, label: 1, color: '#c23740' },
  { x: 4.795384407, y: -3.940961838, label: 1, color: '#c23740' },
  { x: 4.958030701, y: -4.62347126, label: 1, color: '#c23740' },
  { x: 4.421855927, y: -4.181280136, label: 1, color: '#c23740' },
  { x: 6.093286514, y: -4.009337425, label: 1, color: '#c23740' },
  { x: 4.715727806, y: -4.393379211, label: 1, color: '#c23740' },
  { x: 3.385149956, y: -5.012194633, label: 1, color: '#c23740' },
  { x: 4.413151741, y: -4.345136642, label: 1, color: '#c23740' },
  { x: 5.17870903, y: -4.694850922, label: 1, color: '#c23740' },
  { x: 5.705883026, y: -3.952054024, label: 1, color: '#c23740' },
  { x: 5.87594986, y: -4.68836689, label: 1, color: '#c23740' },
  { x: 6.127679825, y: -6.261814117, label: 1, color: '#c23740' },
  { x: 6.448773384, y: -4.533983231, label: 1, color: '#c23740' },
  { x: 6.007658005, y: -3.840652466, label: 1, color: '#c23740' },
  { x: 3.565990448, y: -4.93611145, label: 1, color: '#c23740' },
  { x: 6.229956627, y: -3.696551323, label: 1, color: '#c23740' },
  { x: 5.513139725, y: -6.093660355, label: 1, color: '#c23740' },
  { x: 4.713077545, y: -4.102225304, label: 1, color: '#c23740' },
  { x: 5.354664803, y: -3.848733902, label: 1, color: '#c23740' },
  { x: 4.643489838, y: -4.565070152, label: 1, color: '#c23740' },
  { x: 6.380700111, y: -6.082880974, label: 1, color: '#c23740' },
  { x: 0.59028244, y: 5.33158493, label: 2, color: '#c3b96f' },
  { x: 5.391371727, y: -3.725099564, label: 1, color: '#c23740' },
  { x: 4.15823555, y: -5.007183075, label: 1, color: '#c23740' },
  { x: 2.046179771, y: 5.977964401, label: 2, color: '#c3b96f' },
  { x: 5.102467537, y: -3.960929871, label: 1, color: '#c23740' },
  { x: 5.514925003, y: -3.588752747, label: 1, color: '#c23740' },
  { x: 4.26036644, y: -4.389956474, label: 1, color: '#c23740' },
  { x: 3.502323151, y: -5.6908741, label: 1, color: '#c23740' },
  { x: 1.169905663, y: 5.592647552, label: 2, color: '#c3b96f' },
  { x: 1.443479538, y: 5.690778732, label: 2, color: '#c3b96f' },
  { x: 0.577936172, y: 5.255907059, label: 2, color: '#c3b96f' },
  { x: 0.631906509, y: 5.614187241, label: 2, color: '#c3b96f' },
  { x: 1.584575653, y: 5.939145088, label: 2, color: '#c3b96f' },
  { x: 0.871986389, y: 5.548638344, label: 2, color: '#c3b96f' },
  { x: 0.430364609, y: 4.990952492, label: 2, color: '#c3b96f' },
  { x: 3.42574501, y: -4.954723358, label: 1, color: '#c23740' },
  { x: 4.616835594, y: -4.05314827, label: 1, color: '#c23740' },
  { x: 3.988634109, y: -4.560515404, label: 1, color: '#c23740' },
  { x: 0.878240585, y: 5.605652809, label: 2, color: '#c3b96f' },
  { x: 4.883598328, y: -4.101385117, label: 1, color: '#c23740' },
  { x: 5.256999969, y: -3.882829666, label: 1, color: '#c23740' },
  { x: 5.658204079, y: -3.650072098, label: 1, color: '#c23740' },
  { x: 5.278810501, y: -3.691171646, label: 1, color: '#c23740' },
  { x: 3.509237289, y: -4.897506714, label: 1, color: '#c23740' },
  { x: 4.908593178, y: -4.172359467, label: 1, color: '#c23740' },
  { x: 5.706701279, y: -3.752159119, label: 1, color: '#c23740' },
  { x: 5.136467934, y: -4.745296478, label: 1, color: '#c23740' },
  { x: 5.81089592, y: -4.052640915, label: 1, color: '#c23740' },
  { x: 1.737303734, y: 6.258190155, label: 2, color: '#c3b96f' },
  { x: 0.464506149, y: 5.787553787, label: 2, color: '#c3b96f' },
  { x: 3.297252655, y: -5.795045853, label: 1, color: '#c23740' },
  { x: 3.134658337, y: -5.240461349, label: 1, color: '#c23740' },
  { x: 4.361171722, y: -4.847929001, label: 1, color: '#c23740' },
  { x: 3.195084572, y: -5.021793365, label: 1, color: '#c23740' },
  { x: 2.919956207, y: -5.667749405, label: 1, color: '#c23740' },
  { x: 6.115282059, y: -3.882701874, label: 1, color: '#c23740' },
  { x: 2.754616737, y: -5.861627579, label: 1, color: '#c23740' },
  { x: 3.820581436, y: -4.968709946, label: 1, color: '#c23740' },
  { x: 0.814489365, y: 5.573443413, label: 2, color: '#c3b96f' },
  { x: 3.038396835, y: -5.336604118, label: 1, color: '#c23740' },
  { x: 4.296175003, y: -4.666490555, label: 1, color: '#c23740' },
  { x: 5.084716797, y: -3.95927906, label: 1, color: '#c23740' },
  { x: 4.356469154, y: -4.747966766, label: 1, color: '#c23740' },
  { x: 4.573584557, y: -4.152420044, label: 1, color: '#c23740' },
  { x: 5.1802845, y: -4.486670494, label: 1, color: '#c23740' },
  { x: 4.768961906, y: -3.964157104, label: 1, color: '#c23740' },
  { x: 4.09883976, y: -5.169765472, label: 1, color: '#c23740' },
  { x: 5.820119858, y: -4.242913246, label: 1, color: '#c23740' },
  { x: 4.644296646, y: -4.19503212, label: 1, color: '#c23740' },
  { x: 6.054729462, y: -4.09510231, label: 1, color: '#c23740' },
  { x: 0.724750519, y: 5.359073639, label: 2, color: '#c3b96f' },
  { x: 4.840964317, y: -3.843204498, label: 1, color: '#c23740' },
  { x: 1.742973328, y: 5.682088852, label: 2, color: '#c3b96f' },
  { x: 1.00733757, y: 5.666717529, label: 2, color: '#c3b96f' },
  { x: 2.817462921, y: -5.64423275, label: 1, color: '#c23740' },
  { x: 6.110868454, y: -3.912817001, label: 1, color: '#c23740' },
  { x: 5.861519814, y: -4.93523407, label: 1, color: '#c23740' },
  { x: 4.158647537, y: -5.400812149, label: 1, color: '#c23740' },
  { x: 1.512765884, y: 6.307118416, label: 2, color: '#c3b96f' },
  { x: 6.29605484, y: -3.917321205, label: 1, color: '#c23740' },
  { x: 4.524429321, y: -5.316507339, label: 1, color: '#c23740' },
  { x: 5.142997742, y: -6.420593262, label: 1, color: '#c23740' },
  { x: 3.146101952, y: -5.416031837, label: 1, color: '#c23740' },
  { x: 5.220846176, y: -4.050076485, label: 1, color: '#c23740' },
  { x: 4.908943176, y: -4.161739349, label: 1, color: '#c23740' },
  { x: 3.632395744, y: -5.320011139, label: 1, color: '#c23740' },
  { x: 4.360960007, y: -4.363857269, label: 1, color: '#c23740' },
  { x: 4.061183929, y: -4.916194916, label: 1, color: '#c23740' },
  { x: 4.658971786, y: -4.800884247, label: 1, color: '#c23740' },
  { x: 5.004752159, y: -4.597724915, label: 1, color: '#c23740' },
  { x: 4.280759811, y: -4.32583046, label: 1, color: '#c23740' },
  { x: 1.203989029, y: 5.863550186, label: 2, color: '#c3b96f' },
  { x: 4.50564003, y: -4.419887543, label: 1, color: '#c23740' },
  { x: 5.939678192, y: -3.350448608, label: 1, color: '#c23740' },
  { x: 5.158886909, y: -4.096276283, label: 1, color: '#c23740' },
  { x: 4.981685638, y: -4.207520485, label: 1, color: '#c23740' },
  { x: 6.008171082, y: -4.603954315, label: 1, color: '#c23740' },
  { x: 5.891113281, y: -3.578639984, label: 1, color: '#c23740' },
  { x: 5.750679016, y: -3.734272003, label: 1, color: '#c23740' },
  { x: 0.64339447, y: 5.302425385, label: 2, color: '#c3b96f' },
  { x: 6.15335083, y: -4.70960331, label: 1, color: '#c23740' },
  { x: 0.698833466, y: 5.375580788, label: 2, color: '#c3b96f' },
  { x: 3.958621025, y: -4.449374199, label: 1, color: '#c23740' },
  { x: 4.826931, y: -4.484908104, label: 1, color: '#c23740' },
  { x: 3.451882362, y: -4.828097343, label: 1, color: '#c23740' },
  { x: 5.671218872, y: -5.788379669, label: 1, color: '#c23740' },
  { x: 4.662083626, y: -4.451994896, label: 1, color: '#c23740' },
  { x: 3.214565277, y: -5.288478851, label: 1, color: '#c23740' },
  { x: 1.656015396, y: -6.966862679, label: 1, color: '#c23740' },
  { x: 3.797821045, y: -4.940803528, label: 1, color: '#c23740' },
  { x: 4.537298203, y: -5.375225067, label: 1, color: '#c23740' },
  { x: 4.618290901, y: -4.749839783, label: 1, color: '#c23740' },
  { x: 6.070667267, y: -4.523348808, label: 1, color: '#c23740' },
  { x: 5.513385773, y: -4.425018311, label: 1, color: '#c23740' },
  { x: 4.252620697, y: -4.688556671, label: 1, color: '#c23740' },
  { x: 4.994176865, y: -4.543668747, label: 1, color: '#c23740' },
  { x: 1.969659805, y: -6.753160477, label: 1, color: '#c23740' },
  { x: 2.736440659, y: -6.700023651, label: 1, color: '#c23740' },
  { x: 3.4283638, y: -5.354679108, label: 1, color: '#c23740' },
  { x: 3.459611893, y: -5.564852715, label: 1, color: '#c23740' },
  { x: 4.947608948, y: -4.383584023, label: 1, color: '#c23740' },
  { x: 4.238221169, y: -4.766674042, label: 1, color: '#c23740' },
  { x: 3.986638069, y: -5.745193481, label: 1, color: '#c23740' },
  { x: 2.033343315, y: -6.73887825, label: 1, color: '#c23740' },
  { x: 5.146821022, y: -3.806838036, label: 1, color: '#c23740' },
  { x: 5.246364594, y: -4.077814102, label: 1, color: '#c23740' },
  { x: 3.180017471, y: -5.522434235, label: 1, color: '#c23740' },
  { x: 2.935380936, y: -5.541967392, label: 1, color: '#c23740' },
  { x: 3.309576035, y: -6.10477829, label: 1, color: '#c23740' },
  { x: -0.020107269, y: 6.494446754, label: 2, color: '#c3b96f' },
  { x: 2.433675766, y: -6.681165695, label: 1, color: '#c23740' },
  { x: 5.086105347, y: -4.600736618, label: 1, color: '#c23740' },
  { x: 3.712018013, y: -5.978988647, label: 1, color: '#c23740' },
  { x: 3.026441574, y: -6.359962463, label: 1, color: '#c23740' },
  { x: 2.184634209, y: 6.740888596, label: 2, color: '#c3b96f' },
  { x: 4.204927444, y: -5.886957169, label: 1, color: '#c23740' },
  { x: 2.37680912, y: -6.32138443, label: 1, color: '#c23740' },
  { x: 2.399803162, y: -6.399065018, label: 1, color: '#c23740' },
  { x: 0.766798973, y: 5.393340111, label: 2, color: '#c3b96f' },
  { x: 4.184528351, y: -6.040206909, label: 1, color: '#c23740' },
  { x: 5.4778862, y: -4.485220909, label: 1, color: '#c23740' },
  { x: 4.463616371, y: -5.211293221, label: 1, color: '#c23740' },
  { x: 4.948115349, y: -4.299779892, label: 1, color: '#c23740' },
  { x: 5.341960907, y: -3.797639847, label: 1, color: '#c23740' },
  { x: 4.947208405, y: -5.171777725, label: 1, color: '#c23740' },
  { x: 5.018922806, y: -4.101784706, label: 1, color: '#c23740' },
  { x: 4.337517738, y: -5.040498734, label: 1, color: '#c23740' },
  { x: 2.587169647, y: -6.691612244, label: 1, color: '#c23740' },
  { x: 2.536481857, y: -6.451457977, label: 1, color: '#c23740' },
  { x: 4.290427208, y: -6.550195694, label: 1, color: '#c23740' },
  { x: 4.503108025, y: -6.342715263, label: 1, color: '#c23740' },
  { x: 3.639873505, y: -5.095449448, label: 1, color: '#c23740' },
  { x: 5.582590103, y: -4.879699707, label: 1, color: '#c23740' },
  { x: 5.337560654, y: -5.261348724, label: 1, color: '#c23740' },
  { x: 6.438445091, y: -4.569410324, label: 1, color: '#c23740' },
  { x: 2.937911034, y: -6.010324478, label: 1, color: '#c23740' },
  { x: 5.908388138, y: -4.755802155, label: 1, color: '#c23740' },
  { x: 2.119901657, y: -6.969460487, label: 1, color: '#c23740' },
  { x: 5.012132645, y: -6.425611496, label: 1, color: '#c23740' },
  { x: 4.078279495, y: -5.203572273, label: 1, color: '#c23740' },
  { x: 6.303533554, y: -5.116436958, label: 1, color: '#c23740' },
  { x: 4.202099323, y: -5.625114441, label: 1, color: '#c23740' },
  { x: 2.189929008, y: -6.702500343, label: 1, color: '#c23740' },
  { x: 5.309491158, y: -6.09387207, label: 1, color: '#c23740' },
  { x: 1.753479004, y: 5.93758297, label: 2, color: '#c3b96f' },
  { x: 6.6704216, y: -4.853691101, label: 1, color: '#c23740' },
  { x: 5.002426147, y: -5.499908447, label: 1, color: '#c23740' },
  { x: 3.188769341, y: -6.471160889, label: 1, color: '#c23740' },
  { x: 2.272428513, y: 6.670465469, label: 2, color: '#c3b96f' },
  { x: 3.403573036, y: -6.66733551, label: 1, color: '#c23740' },
  { x: 6.405750275, y: -5.905182838, label: 1, color: '#c23740' },
  { x: 2.248882294, y: -6.692077637, label: 1, color: '#c23740' },
  { x: 5.492649078, y: -4.389099121, label: 1, color: '#c23740' },
  { x: 4.681452751, y: -6.028343201, label: 1, color: '#c23740' },
  { x: 5.673116684, y: -5.626932144, label: 1, color: '#c23740' },
  { x: 5.059183121, y: -6.39978981, label: 1, color: '#c23740' },
  { x: 5.737145424, y: -4.765985489, label: 1, color: '#c23740' },
  { x: 4.521659851, y: -4.384778976, label: 1, color: '#c23740' },
  { x: 2.855895996, y: -6.60238266, label: 1, color: '#c23740' },
  { x: 5.561904907, y: -5.660645485, label: 1, color: '#c23740' },
  { x: 2.141755104, y: -6.696699142, label: 1, color: '#c23740' },
  { x: 1.86648941, y: -6.710525513, label: 1, color: '#c23740' },
  { x: 4.489930153, y: -4.519182205, label: 1, color: '#c23740' },
  { x: 5.51411438, y: -5.094853401, label: 1, color: '#c23740' },
  { x: 5.505990982, y: -3.958533287, label: 1, color: '#c23740' },
  { x: 6.501137733, y: -4.217811584, label: 1, color: '#c23740' },
  { x: 3.834768295, y: -4.943264008, label: 1, color: '#c23740' },
  { x: 3.978088379, y: -5.590662956, label: 1, color: '#c23740' },
  { x: 3.675268173, y: -5.396577835, label: 1, color: '#c23740' },
  { x: 3.041765213, y: -6.593017578, label: 1, color: '#c23740' },
  { x: 3.174158096, y: -5.759465218, label: 1, color: '#c23740' },
  { x: 3.274086952, y: -5.831130981, label: 1, color: '#c23740' },
  { x: 4.272677422, y: -5.825906754, label: 1, color: '#c23740' },
  { x: 4.101463318, y: -6.438681602, label: 1, color: '#c23740' },
  { x: 2.406487465, y: 5.849079132, label: 2, color: '#c3b96f' },
  { x: 4.440752983, y: -6.399412155, label: 1, color: '#c23740' },
  { x: 3.065935135, y: -5.443681717, label: 1, color: '#c23740' },
  { x: 3.537597656, y: -6.612007141, label: 1, color: '#c23740' },
  { x: 0.917404175, y: 5.534509659, label: 2, color: '#c3b96f' },
  { x: 4.90117836, y: -4.33708477, label: 1, color: '#c23740' },
  { x: 2.403867722, y: -6.247947693, label: 1, color: '#c23740' },
  { x: 2.462703705, y: -6.391695976, label: 1, color: '#c23740' },
  { x: 4.48482132, y: -4.440888405, label: 1, color: '#c23740' },
  { x: 2.676787376, y: -6.55576992, label: 1, color: '#c23740' },
  { x: 2.581488609, y: -6.222709656, label: 1, color: '#c23740' },
  { x: 3.548521996, y: -6.039941788, label: 1, color: '#c23740' },
  { x: 6.54907608, y: -5.070171356, label: 1, color: '#c23740' },
  { x: 5.289649963, y: -5.319061279, label: 1, color: '#c23740' },
  { x: 4.453915596, y: -6.408714294, label: 1, color: '#c23740' },
  { x: 6.847658157, y: -5.268143654, label: 1, color: '#c23740' },
  { x: 1.969896317, y: -6.649974823, label: 1, color: '#c23740' },
  { x: 4.223132133, y: -4.592262268, label: 1, color: '#c23740' },
  { x: 2.889346123, y: -6.281431198, label: 1, color: '#c23740' },
  { x: 6.750164032, y: -5.373448372, label: 1, color: '#c23740' },
  { x: 4.717384338, y: -5.638889313, label: 1, color: '#c23740' },
  { x: 4.080757141, y: -4.895238876, label: 1, color: '#c23740' },
  { x: 3.282967567, y: -5.263484955, label: 1, color: '#c23740' },
  { x: 5.294924736, y: -4.548160553, label: 1, color: '#c23740' },
  { x: 2.811478615, y: -5.968696594, label: 1, color: '#c23740' },
  { x: 3.973745346, y: -5.483935356, label: 1, color: '#c23740' },
  { x: 2.129498005, y: -6.696019173, label: 1, color: '#c23740' },
  { x: 2.876877785, y: -5.656431198, label: 1, color: '#c23740' },
  { x: 5.159711838, y: -5.795928955, label: 1, color: '#c23740' },
  { x: 6.520467758, y: -5.340653419, label: 1, color: '#c23740' },
  { x: 2.888308525, y: -5.688830376, label: 1, color: '#c23740' },
  { x: 1.911500931, y: 7.199466705, label: 2, color: '#c3b96f' },
  { x: 2.41523838, y: 6.848397255, label: 2, color: '#c3b96f' },
  { x: 2.869842529, y: -5.946029663, label: 1, color: '#c23740' },
  { x: 2.100686073, y: 6.901634216, label: 2, color: '#c3b96f' },
  { x: 6.445559502, y: -5.840259552, label: 1, color: '#c23740' },
  { x: 3.597761154, y: -5.587285042, label: 1, color: '#c23740' },
  { x: 3.581801414, y: -5.756655693, label: 1, color: '#c23740' },
  { x: 4.796908379, y: -5.241521835, label: 1, color: '#c23740' },
  { x: 1.47459507, y: 5.918147087, label: 2, color: '#c3b96f' },
  { x: 6.540988922, y: -5.19976902, label: 1, color: '#c23740' },
  { x: 4.470254898, y: -6.541436195, label: 1, color: '#c23740' },
  { x: 3.018633842, y: -6.630708694, label: 1, color: '#c23740' },
  { x: 4.115878105, y: -4.571038246, label: 1, color: '#c23740' },
  { x: 3.341395378, y: -6.104755402, label: 1, color: '#c23740' },
  { x: 3.841610432, y: -4.721177101, label: 1, color: '#c23740' },
  { x: 3.505419731, y: -4.914018631, label: 1, color: '#c23740' },
  { x: 0.700429916, y: 6.02781868, label: 2, color: '#c3b96f' },
  { x: 3.507081985, y: -6.03404808, label: 1, color: '#c23740' },
  { x: 1.591385841, y: 5.922486305, label: 2, color: '#c3b96f' },
  { x: 4.668416977, y: -4.997432709, label: 1, color: '#c23740' },
  { x: 5.253340721, y: -4.142122269, label: 1, color: '#c23740' },
  { x: 1.90553093, y: 6.360208511, label: 2, color: '#c3b96f' },
  { x: 4.594964027, y: -4.807360649, label: 1, color: '#c23740' },
  { x: 4.833692551, y: -4.69560051, label: 1, color: '#c23740' },
  { x: 4.273657799, y: -5.082914352, label: 1, color: '#c23740' },
  { x: 1.576986313, y: 6.008652687, label: 2, color: '#c3b96f' },
  { x: 2.521412849, y: 6.997487068, label: 2, color: '#c3b96f' },
  { x: 1.818302155, y: 6.459247589, label: 2, color: '#c3b96f' },
  { x: 2.540171623, y: -6.449882507, label: 1, color: '#c23740' },
  { x: 4.398896217, y: -5.89509201, label: 1, color: '#c23740' },
  { x: 1.315983772, y: 5.762738228, label: 2, color: '#c3b96f' },
  { x: 1.791610718, y: 7.30382061, label: 2, color: '#c3b96f' },
  { x: 1.947320938, y: 5.977193832, label: 2, color: '#c3b96f' },
  { x: 2.934456825, y: -6.858972549, label: 1, color: '#c23740' },
  { x: 2.453006744, y: -6.509572983, label: 1, color: '#c23740' },
  { x: 1.773786545, y: 6.260115623, label: 2, color: '#c3b96f' },
  { x: 6.002837181, y: -5.504308701, label: 1, color: '#c23740' },
  { x: 2.100100517, y: -6.489274025, label: 1, color: '#c23740' },
  { x: 3.173048973, y: -5.162387848, label: 1, color: '#c23740' },
  { x: 3.68803978, y: -5.398846626, label: 1, color: '#c23740' },
  { x: 1.990190506, y: -7.085895538, label: 1, color: '#c23740' },
  { x: 1.93471241, y: -6.812995911, label: 1, color: '#c23740' },
  { x: 2.620952606, y: -5.83384037, label: 1, color: '#c23740' },
  { x: 5.071912766, y: -4.148748398, label: 1, color: '#c23740' },
  { x: 4.357774734, y: -5.142997742, label: 1, color: '#c23740' },
  { x: 5.50543499, y: -4.144040108, label: 1, color: '#c23740' },
  { x: 5.032688141, y: -5.854473114, label: 1, color: '#c23740' },
  { x: 5.233049393, y: -6.073235512, label: 1, color: '#c23740' },
  { x: 3.949782372, y: -4.636720657, label: 1, color: '#c23740' },
  { x: 6.536439896, y: -5.030061722, label: 1, color: '#c23740' },
  { x: 2.440753937, y: 7.21134758, label: 2, color: '#c3b96f' },
  { x: 5.582315445, y: -5.756289482, label: 1, color: '#c23740' },
  { x: 5.0759058, y: -4.155879021, label: 1, color: '#c23740' },
  { x: 4.27812767, y: -6.690214157, label: 1, color: '#c23740' },
  { x: 5.680912018, y: -4.968037605, label: 1, color: '#c23740' },
  { x: 5.122127533, y: -5.07964325, label: 1, color: '#c23740' },
  { x: 2.38670063, y: -6.234887123, label: 1, color: '#c23740' },
  { x: 1.841161251, y: 5.954000473, label: 2, color: '#c3b96f' },
  { x: 2.03046608, y: -6.872810364, label: 1, color: '#c23740' },
  { x: 4.547872543, y: -4.282240868, label: 1, color: '#c23740' },
  { x: 2.626811981, y: -6.913674355, label: 1, color: '#c23740' },
  { x: 4.857419014, y: -4.831912041, label: 1, color: '#c23740' },
  { x: 3.113918304, y: -5.9289217, label: 1, color: '#c23740' },
  { x: 3.839784622, y: -5.463676453, label: 1, color: '#c23740' },
  { x: 4.428899765, y: -5.624300957, label: 1, color: '#c23740' },
  { x: 2.070113182, y: -6.535633087, label: 1, color: '#c23740' },
  { x: 4.88151741, y: -6.098505974, label: 1, color: '#c23740' },
  { x: 2.618524075, y: 6.357931137, label: 2, color: '#c3b96f' },
  { x: 3.736162186, y: -4.868520737, label: 1, color: '#c23740' },
  { x: 5.014538765, y: -5.061237335, label: 1, color: '#c23740' },
  { x: 3.774701118, y: -6.722789764, label: 1, color: '#c23740' },
  { x: 5.504916191, y: -4.338914871, label: 1, color: '#c23740' },
  { x: 3.616529465, y: -5.519812584, label: 1, color: '#c23740' },
  { x: 3.927909851, y: -6.694754601, label: 1, color: '#c23740' },
  { x: 3.736068726, y: -6.250961304, label: 1, color: '#c23740' },
  { x: 4.258665085, y: -5.222366333, label: 1, color: '#c23740' },
  { x: 6.712512016, y: -5.57686615, label: 1, color: '#c23740' },
  { x: 2.787761688, y: -6.552240372, label: 1, color: '#c23740' },
  { x: 3.045957565, y: -6.778280258, label: 1, color: '#c23740' },
  { x: 6.145486832, y: -5.610667229, label: 1, color: '#c23740' },
  { x: 5.345843315, y: -4.538475037, label: 1, color: '#c23740' },
  { x: 5.242225647, y: -6.244354248, label: 1, color: '#c23740' },
  { x: 2.058129311, y: 6.01203537, label: 2, color: '#c3b96f' },
  { x: 2.719801426, y: -6.901996613, label: 1, color: '#c23740' },
  { x: 4.416378021, y: -4.82658577, label: 1, color: '#c23740' },
  { x: 4.077866554, y: -5.081116676, label: 1, color: '#c23740' },
  { x: 1.136312485, y: 5.847043991, label: 2, color: '#c3b96f' },
  { x: 4.816911697, y: -4.752948761, label: 1, color: '#c23740' },
  { x: 2.145866394, y: 5.92507267, label: 2, color: '#c3b96f' },
  { x: 4.537804604, y: -4.800599098, label: 1, color: '#c23740' },
  { x: 2.580742836, y: -6.56642437, label: 1, color: '#c23740' },
  { x: 5.408198357, y: -5.850382805, label: 1, color: '#c23740' },
  { x: 2.150588989, y: -6.924542427, label: 1, color: '#c23740' },
  { x: 3.025814056, y: -6.550530434, label: 1, color: '#c23740' },
  { x: 1.923624039, y: -6.690412521, label: 1, color: '#c23740' },
  { x: 4.968899727, y: -4.877236366, label: 1, color: '#c23740' },
  { x: 3.930078506, y: -5.122480392, label: 1, color: '#c23740' },
  { x: 5.049311638, y: -4.265591621, label: 1, color: '#c23740' },
  { x: 4.497763634, y: -6.256457329, label: 1, color: '#c23740' },
  { x: 4.410185814, y: -6.047355652, label: 1, color: '#c23740' },
  { x: 3.79573822, y: -4.738907814, label: 1, color: '#c23740' },
  { x: 4.484233856, y: -4.427863121, label: 1, color: '#c23740' },
  { x: 3.89198494, y: -5.907108307, label: 1, color: '#c23740' },
  { x: 3.180444717, y: -6.938167572, label: 1, color: '#c23740' },
  { x: 2.220425606, y: -6.483516693, label: 1, color: '#c23740' },
  { x: 5.713194847, y: -4.381500244, label: 1, color: '#c23740' },
  { x: 0.566387653, y: 5.234866142, label: 2, color: '#c3b96f' },
  { x: 4.970140457, y: -5.492534637, label: 1, color: '#c23740' },
  { x: 6.228727341, y: -5.348121643, label: 1, color: '#c23740' },
  { x: 5.338002205, y: -4.064340591, label: 1, color: '#c23740' },
  { x: 5.907511711, y: -5.792691231, label: 1, color: '#c23740' },
  { x: 5.064854622, y: -6.193763733, label: 1, color: '#c23740' },
  { x: 5.348973274, y: -6.153169632, label: 1, color: '#c23740' },
  { x: 2.38701582, y: 5.964226723, label: 2, color: '#c3b96f' },
  { x: 0.400350571, y: 5.384522438, label: 2, color: '#c3b96f' },
  { x: 5.884021759, y: -6.25474453, label: 1, color: '#c23740' },
  { x: 5.750492096, y: -4.497694016, label: 1, color: '#c23740' },
  { x: 5.29445076, y: -5.765221596, label: 1, color: '#c23740' },
  { x: 4.232603073, y: -6.660886765, label: 1, color: '#c23740' },
  { x: 6.260620117, y: -5.43229866, label: 1, color: '#c23740' },
  { x: 5.028402328, y: -6.269939423, label: 1, color: '#c23740' },
  { x: 4.849708557, y: -6.541303635, label: 1, color: '#c23740' },
  { x: 2.942474365, y: -6.639606476, label: 1, color: '#c23740' },
  { x: 6.274124146, y: -4.502832413, label: 1, color: '#c23740' },
  { x: 3.365610123, y: -5.160985947, label: 1, color: '#c23740' },
  { x: 3.738304138, y: -6.639979362, label: 1, color: '#c23740' },
  { x: 4.204269409, y: -5.235906601, label: 1, color: '#c23740' },
  { x: 6.242238998, y: -5.259527206, label: 1, color: '#c23740' },
  { x: 4.518838882, y: -6.621162415, label: 1, color: '#c23740' },
  { x: 5.687688828, y: -6.227779388, label: 1, color: '#c23740' },
  { x: 4.986879349, y: -5.739555359, label: 1, color: '#c23740' },
  { x: 1.92688942, y: -7.155789375, label: 1, color: '#c23740' },
  { x: 2.624835968, y: -5.835065842, label: 1, color: '#c23740' },
  { x: 5.602773666, y: -4.438511848, label: 1, color: '#c23740' },
  { x: 5.595161438, y: -4.308105469, label: 1, color: '#c23740' },
  { x: 5.19660759, y: -4.792064667, label: 1, color: '#c23740' },
  { x: 5.873652458, y: -6.279238701, label: 1, color: '#c23740' },
  { x: 4.315666199, y: -6.62739563, label: 1, color: '#c23740' },
  { x: 5.3534832, y: -5.306631088, label: 1, color: '#c23740' },
  { x: 6.361745834, y: -5.249207497, label: 1, color: '#c23740' },
  { x: 6.236127853, y: -6.0041399, label: 1, color: '#c23740' },
  { x: 3.09853363, y: -5.804242134, label: 1, color: '#c23740' },
  { x: 5.437579155, y: -6.36046505, label: 1, color: '#c23740' },
  { x: 3.137355804, y: -6.757113457, label: 1, color: '#c23740' },
  { x: 2.83864975, y: -5.918189049, label: 1, color: '#c23740' },
  { x: 6.258764267, y: -4.384539604, label: 1, color: '#c23740' },
  { x: 6.116627693, y: -3.696647644, label: 1, color: '#c23740' },
  { x: 3.937654495, y: -6.69865036, label: 1, color: '#c23740' },
  { x: 6.098617554, y: -4.989187241, label: 1, color: '#c23740' },
  { x: 2.145921707, y: 5.732302666, label: 2, color: '#c3b96f' },
  { x: 3.743299484, y: -6.59833622, label: 1, color: '#c23740' },
  { x: 4.307967186, y: -5.589645386, label: 1, color: '#c23740' },
  { x: 3.297562599, y: -6.890025139, label: 1, color: '#c23740' },
  { x: 6.784036636, y: -5.491493225, label: 1, color: '#c23740' },
  { x: 6.836931229, y: -5.067831039, label: 1, color: '#c23740' },
  { x: 3.790306091, y: -4.922921181, label: 1, color: '#c23740' },
  { x: 4.286561012, y: -6.251241684, label: 1, color: '#c23740' },
  { x: 6.543667793, y: -5.298776627, label: 1, color: '#c23740' },
  { x: 6.471185684, y: -5.517782211, label: 1, color: '#c23740' },
  { x: 5.544829369, y: -6.00406456, label: 1, color: '#c23740' },
  { x: 3.545991421, y: -6.91554451, label: 1, color: '#c23740' },
  { x: 2.22746706, y: -6.818526268, label: 1, color: '#c23740' },
  { x: 5.463468552, y: -5.173023224, label: 1, color: '#c23740' },
  { x: 2.609023571, y: -6.301855087, label: 1, color: '#c23740' },
  { x: 5.85025692, y: -6.047361374, label: 1, color: '#c23740' },
  { x: 5.443592072, y: -4.144172668, label: 1, color: '#c23740' },
  { x: 3.541402817, y: -6.558796883, label: 1, color: '#c23740' },
  { x: 4.465154648, y: -5.774776459, label: 1, color: '#c23740' },
  { x: 6.666732788, y: -5.25976944, label: 1, color: '#c23740' },
  { x: 5.716775894, y: -5.96637249, label: 1, color: '#c23740' },
  { x: 6.720942497, y: -5.546955109, label: 1, color: '#c23740' },
  { x: 2.550424576, y: -6.762969971, label: 1, color: '#c23740' },
  { x: 2.485057831, y: -6.466466904, label: 1, color: '#c23740' },
  { x: 3.800195694, y: -4.646639824, label: 1, color: '#c23740' },
  { x: 5.348230362, y: -4.496776581, label: 1, color: '#c23740' },
  { x: 6.129219055, y: -5.81493187, label: 1, color: '#c23740' },
  { x: 4.939409256, y: -5.397764206, label: 1, color: '#c23740' },
  { x: 2.078678131, y: -6.668877602, label: 1, color: '#c23740' },
  { x: 6.504577637, y: -4.37597847, label: 1, color: '#c23740' },
  { x: 1.418138981, y: 5.990647316, label: 2, color: '#c3b96f' },
  { x: 4.52833271, y: -4.962193489, label: 1, color: '#c23740' },
  { x: 4.984800339, y: -6.003106117, label: 1, color: '#c23740' },
  { x: 6.034874916, y: -4.423657417, label: 1, color: '#c23740' },
  { x: 1.705142021, y: 6.608587265, label: 2, color: '#c3b96f' },
  { x: 6.271633148, y: -4.778274536, label: 1, color: '#c23740' },
  { x: 2.841257095, y: -5.706191063, label: 1, color: '#c23740' },
  { x: 4.829998016, y: -6.620830536, label: 1, color: '#c23740' },
  { x: 2.583805084, y: -6.072546959, label: 1, color: '#c23740' },
  { x: 5.454319, y: -5.400816917, label: 1, color: '#c23740' },
  { x: 5.305612564, y: -4.074391365, label: 1, color: '#c23740' },
  { x: 3.934649467, y: -6.343188286, label: 1, color: '#c23740' },
  { x: 4.12762928, y: -6.339673042, label: 1, color: '#c23740' },
  { x: 2.783400536, y: -6.038478851, label: 1, color: '#c23740' },
  { x: 5.344137192, y: -4.124595642, label: 1, color: '#c23740' },
  { x: 3.045647621, y: -6.345475197, label: 1, color: '#c23740' },
  { x: 6.533438683, y: -5.978658676, label: 1, color: '#c23740' },
  { x: 6.101956367, y: -3.977115631, label: 1, color: '#c23740' },
  { x: 6.030900002, y: -3.440854073, label: 1, color: '#c23740' },
  { x: 5.576239586, y: -6.326730728, label: 1, color: '#c23740' },
  { x: 5.088009834, y: -4.144849777, label: 1, color: '#c23740' },
  { x: 3.21231842, y: -5.540583611, label: 1, color: '#c23740' },
  { x: 3.808052063, y: -6.508892059, label: 1, color: '#c23740' },
  { x: 3.149605751, y: -6.093863487, label: 1, color: '#c23740' },
  { x: 2.930353642, y: -6.319681168, label: 1, color: '#c23740' },
  { x: 3.251029968, y: -6.886522293, label: 1, color: '#c23740' },
  { x: 5.221859932, y: -4.635631561, label: 1, color: '#c23740' },
  { x: 3.377966881, y: -5.680242538, label: 1, color: '#c23740' },
  { x: 5.604135513, y: -4.273626328, label: 1, color: '#c23740' },
  { x: 4.059793472, y: -5.094450951, label: 1, color: '#c23740' },
  { x: 2.822107315, y: -6.157730103, label: 1, color: '#c23740' },
  { x: 4.241871834, y: -5.149856567, label: 1, color: '#c23740' },
  { x: 2.900131226, y: -6.140342712, label: 1, color: '#c23740' },
  { x: 2.226100922, y: 6.676435471, label: 2, color: '#c3b96f' },
  { x: 2.14818573, y: 7.19866848, label: 2, color: '#c3b96f' },
  { x: 2.052281857, y: -6.836072922, label: 1, color: '#c23740' },
  { x: 1.571763515, y: 5.947052002, label: 2, color: '#c3b96f' },
  { x: 4.679075241, y: -5.143034935, label: 1, color: '#c23740' },
  { x: 3.501978874, y: -5.672224998, label: 1, color: '#c23740' },
  { x: 5.036559105, y: -6.529176712, label: 1, color: '#c23740' },
  { x: 2.671134949, y: -6.201485634, label: 1, color: '#c23740' },
  { x: 1.884046555, y: 6.157047272, label: 2, color: '#c3b96f' },
  { x: 4.190273285, y: -5.160842896, label: 1, color: '#c23740' },
  { x: 1.614984512, y: 6.063615799, label: 2, color: '#c3b96f' },
  { x: 2.040746689, y: -6.902803421, label: 1, color: '#c23740' },
  { x: 1.902124405, y: 6.616847992, label: 2, color: '#c3b96f' },
  { x: 4.988244057, y: -6.428174973, label: 1, color: '#c23740' },
  { x: 2.468284607, y: -6.689571381, label: 1, color: '#c23740' },
  { x: 2.758195877, y: -5.732349396, label: 1, color: '#c23740' },
  { x: 2.308961391, y: 6.840715408, label: 2, color: '#c3b96f' },
  { x: -0.000792503, y: 6.358091354, label: 2, color: '#c3b96f' },
  { x: 3.359108448, y: -5.542114258, label: 1, color: '#c23740' },
  { x: 2.107216835, y: 6.232225418, label: 2, color: '#c3b96f' },
  { x: 1.992866516, y: 6.676912308, label: 2, color: '#c3b96f' },
  { x: 1.908503532, y: 5.866186142, label: 2, color: '#c3b96f' },
  { x: 1.349571228, y: 6.612280846, label: 2, color: '#c3b96f' },
  { x: 2.654054642, y: 6.52436161, label: 2, color: '#c3b96f' },
  { x: 4.869186401, y: -5.321329117, label: 1, color: '#c23740' },
  { x: 2.231710434, y: 6.703016281, label: 2, color: '#c3b96f' },
  { x: 0.304303169, y: 5.851926804, label: 2, color: '#c3b96f' },
  { x: -0.212104797, y: 6.240131378, label: 2, color: '#c3b96f' },
  { x: -0.119042397, y: 6.055223465, label: 2, color: '#c3b96f' },
  { x: 0.287071228, y: 6.41215992, label: 2, color: '#c3b96f' },
  { x: -0.120324135, y: 6.165756226, label: 2, color: '#c3b96f' },
  { x: -0.03401947, y: 6.201864243, label: 2, color: '#c3b96f' },
  { x: 2.333181381, y: -6.30398941, label: 1, color: '#c23740' },
  { x: 2.643032074, y: -6.020600319, label: 1, color: '#c23740' },
  { x: 3.350049973, y: -5.397407532, label: 1, color: '#c23740' },
  { x: 1.670713425, y: -6.811635017, label: 1, color: '#c23740' },
  { x: 2.996706963, y: -6.016141891, label: 1, color: '#c23740' },
  { x: 5.560341835, y: -4.402923584, label: 1, color: '#c23740' },
  { x: 3.734205246, y: -4.735826492, label: 1, color: '#c23740' },
  { x: 1.847524643, y: -6.711168289, label: 1, color: '#c23740' },
  { x: 1.943377495, y: -6.668265343, label: 1, color: '#c23740' },
  { x: 1.622695923, y: -6.933848381, label: 1, color: '#c23740' },
  { x: 1.668949127, y: -6.891839981, label: 1, color: '#c23740' },
  { x: 3.071105957, y: -5.736502647, label: 1, color: '#c23740' },
  { x: 3.258757591, y: -5.652885437, label: 1, color: '#c23740' },
  { x: 3.369171143, y: -5.13469696, label: 1, color: '#c23740' },
  { x: 4.431852341, y: -4.418566704, label: 1, color: '#c23740' },
  { x: 4.338929176, y: -4.687171936, label: 1, color: '#c23740' },
  { x: 5.282984734, y: -3.808166504, label: 1, color: '#c23740' },
  { x: 2.097305298, y: -6.496665955, label: 1, color: '#c23740' },
  { x: 3.635520935, y: -5.717873573, label: 1, color: '#c23740' },
  { x: 4.189813614, y: -4.533673286, label: 1, color: '#c23740' },
  { x: 3.816180229, y: -4.990156174, label: 1, color: '#c23740' },
  { x: 0.129352093, y: 5.965185165, label: 2, color: '#c3b96f' },
  { x: 3.967500687, y: -4.752893448, label: 1, color: '#c23740' },
  { x: 3.129162788, y: -5.768066406, label: 1, color: '#c23740' },
  { x: 5.529234886, y: -3.777126312, label: 1, color: '#c23740' },
  { x: -0.160650253, y: 6.029952049, label: 2, color: '#c3b96f' },
  { x: 4.211188316, y: -5.448509216, label: 1, color: '#c23740' },
  { x: -0.083029747, y: 6.107223511, label: 2, color: '#c3b96f' },
  { x: 3.764664173, y: -4.928289413, label: 1, color: '#c23740' },
  { x: 5.875852585, y: -4.568087578, label: 1, color: '#c23740' },
  { x: 2.790287971, y: -5.812925339, label: 1, color: '#c23740' },
  { x: 4.35376358, y: -4.697746277, label: 1, color: '#c23740' },
  { x: 6.187448502, y: -4.006036758, label: 1, color: '#c23740' },
  { x: 5.261676788, y: -3.950089455, label: 1, color: '#c23740' },
  { x: 5.226366043, y: -4.036108017, label: 1, color: '#c23740' },
  { x: 5.769569397, y: -3.661995888, label: 1, color: '#c23740' },
  { x: 1.493167877, y: 5.848903656, label: 2, color: '#c3b96f' },
  { x: 5.602343559, y: -3.722503662, label: 1, color: '#c23740' },
  { x: 5.834392548, y: -4.010959625, label: 1, color: '#c23740' },
  { x: 4.179850578, y: -4.915205956, label: 1, color: '#c23740' },
  { x: 5.303349495, y: -4.658701897, label: 1, color: '#c23740' },
  { x: 5.399593353, y: -4.942667007, label: 1, color: '#c23740' },
  { x: 6.143302917, y: -4.302412987, label: 1, color: '#c23740' },
  { x: 5.775178909, y: -5.418909073, label: 1, color: '#c23740' },
  { x: 5.350374222, y: -3.681653976, label: 1, color: '#c23740' },
  { x: 5.280611038, y: -3.875413895, label: 1, color: '#c23740' },
  { x: 4.629962921, y: -4.470251083, label: 1, color: '#c23740' },
  { x: 4.884872437, y: -5.329244614, label: 1, color: '#c23740' },
  { x: 0.533443451, y: 5.300284386, label: 2, color: '#c3b96f' },
  { x: 2.75319767, y: -6.105699539, label: 1, color: '#c23740' },
  { x: 0.794955254, y: 5.392490864, label: 2, color: '#c3b96f' },
  { x: 5.856552124, y: -3.815010071, label: 1, color: '#c23740' },
  { x: 3.42715168, y: -5.566706657, label: 1, color: '#c23740' },
  { x: 3.417102814, y: -5.74054718, label: 1, color: '#c23740' },
  { x: 5.567127228, y: -3.934267044, label: 1, color: '#c23740' },
  { x: 4.37167263, y: -6.372280121, label: 1, color: '#c23740' },
  { x: 4.834848404, y: -5.349953651, label: 1, color: '#c23740' },
  { x: 5.001776695, y: -5.465838432, label: 1, color: '#c23740' },
  { x: 5.64342308, y: -3.905557632, label: 1, color: '#c23740' },
  { x: 4.053064346, y: -5.333660126, label: 1, color: '#c23740' },
  { x: 4.552703857, y: -4.973485947, label: 1, color: '#c23740' },
  { x: 5.942546844, y: -4.77763176, label: 1, color: '#c23740' },
  { x: 3.321398735, y: -5.856372833, label: 1, color: '#c23740' },
  { x: 5.661919594, y: -3.754224777, label: 1, color: '#c23740' },
  { x: 2.522612095, y: -6.227846146, label: 1, color: '#c23740' },
  { x: 5.418313026, y: -3.863031387, label: 1, color: '#c23740' },
  { x: 4.076687813, y: -4.665953636, label: 1, color: '#c23740' },
  { x: 6.290798187, y: -4.232270241, label: 1, color: '#c23740' },
  { x: 0.825080872, y: 5.449718475, label: 2, color: '#c3b96f' },
  { x: 6.171979904, y: -3.971604347, label: 1, color: '#c23740' },
  { x: 5.266778946, y: -4.43875885, label: 1, color: '#c23740' },
  { x: 3.835906982, y: -5.236358643, label: 1, color: '#c23740' },
  { x: 6.155260086, y: -4.345977783, label: 1, color: '#c23740' },
  { x: 3.358839989, y: -5.414671898, label: 1, color: '#c23740' },
  { x: 0.480226517, y: 5.679489136, label: 2, color: '#c3b96f' },
  { x: 5.62057209, y: -3.925508499, label: 1, color: '#c23740' },
  { x: 6.127719879, y: -4.003664017, label: 1, color: '#c23740' },
  { x: 4.993636131, y: -4.210787773, label: 1, color: '#c23740' },
  { x: 5.628256798, y: -5.300221443, label: 1, color: '#c23740' },
  { x: 3.392105103, y: -5.428310394, label: 1, color: '#c23740' },
  { x: 0.408064842, y: 5.759284973, label: 2, color: '#c3b96f' },
  { x: 4.662876129, y: -5.597480774, label: 1, color: '#c23740' },
  { x: 5.747125626, y: -4.047942162, label: 1, color: '#c23740' },
  { x: 5.855431557, y: -3.869906425, label: 1, color: '#c23740' },
  { x: 4.319298744, y: -5.616815567, label: 1, color: '#c23740' },
  { x: 5.563035965, y: -3.712580681, label: 1, color: '#c23740' },
  { x: 6.121276855, y: -4.368432999, label: 1, color: '#c23740' },
  { x: 4.923548698, y: -3.898906708, label: 1, color: '#c23740' },
  { x: 5.617207527, y: -4.37372303, label: 1, color: '#c23740' },
  { x: 1.701680183, y: 6.249376297, label: 2, color: '#c3b96f' },
  { x: 6.027384758, y: -3.580636978, label: 1, color: '#c23740' },
  { x: 0.201910496, y: 5.864217758, label: 2, color: '#c3b96f' },
  { x: 0.634389877, y: 5.634293556, label: 2, color: '#c3b96f' },
  { x: 2.274520397, y: 6.810795784, label: 2, color: '#c3b96f' },
  { x: 1.288288116, y: 5.647443771, label: 2, color: '#c3b96f' },
  { x: 1.000189781, y: 5.507261276, label: 2, color: '#c3b96f' },
  { x: 1.024118423, y: 5.548886299, label: 2, color: '#c3b96f' },
  { x: 3.806962967, y: -5.471392632, label: 1, color: '#c23740' },
  { x: 5.573834419, y: -6.105899811, label: 1, color: '#c23740' },
  { x: 6.240339279, y: -4.424716949, label: 1, color: '#c23740' },
  { x: 5.71452713, y: -4.234015465, label: 1, color: '#c23740' },
  { x: 5.91119194, y: -5.236980438, label: 1, color: '#c23740' },
  { x: 3.919355392, y: -4.530467987, label: 1, color: '#c23740' },
  { x: 3.481258392, y: -5.376533508, label: 1, color: '#c23740' },
  { x: 5.966107368, y: -4.022770882, label: 1, color: '#c23740' },
  { x: 6.00733757, y: -3.45187664, label: 1, color: '#c23740' },
  { x: 4.868590355, y: -4.160503387, label: 1, color: '#c23740' },
  { x: 6.084591866, y: -4.831282616, label: 1, color: '#c23740' },
  { x: 5.539306641, y: -3.976164818, label: 1, color: '#c23740' },
  { x: 0.80984211, y: 5.468580246, label: 2, color: '#c3b96f' },
  { x: 3.759125233, y: -5.498823166, label: 1, color: '#c23740' },
  { x: 3.633150101, y: -5.185750008, label: 1, color: '#c23740' },
  { x: 1.471888542, y: 5.866085052, label: 2, color: '#c3b96f' },
  { x: 4.386305809, y: -4.682073593, label: 1, color: '#c23740' },
  { x: 0.4276371, y: 5.579216003, label: 2, color: '#c3b96f' },
  { x: 4.31897831, y: -4.666480064, label: 1, color: '#c23740' },
  { x: 3.699494362, y: -5.201820374, label: 1, color: '#c23740' },
  { x: 1.453232288, y: 6.647988319, label: 2, color: '#c3b96f' },
  { x: -0.010822296, y: 6.000125885, label: 2, color: '#c3b96f' },
  { x: 1.03841877, y: 5.551956177, label: 2, color: '#c3b96f' },
  { x: 1.381668568, y: 6.237645149, label: 2, color: '#c3b96f' },
  { x: 1.406849861, y: 5.817229271, label: 2, color: '#c3b96f' },
  { x: -0.005374908, y: 6.114984512, label: 2, color: '#c3b96f' },
  { x: 0.568470955, y: 5.763853073, label: 2, color: '#c3b96f' },
  { x: 0.916790009, y: 5.671661377, label: 2, color: '#c3b96f' },
  { x: 1.208406448, y: 5.890159607, label: 2, color: '#c3b96f' },
  { x: 6.083675385, y: -3.635098457, label: 1, color: '#c23740' },
  { x: 4.241968155, y: -6.189344406, label: 1, color: '#c23740' },
  { x: 3.977291107, y: -4.997999191, label: 1, color: '#c23740' },
  { x: 5.588636398, y: -4.472125053, label: 1, color: '#c23740' },
  { x: 1.913326263, y: 6.22209549, label: 2, color: '#c3b96f' },
  { x: 5.778831482, y: -4.794776917, label: 1, color: '#c23740' },
  { x: 4.130435944, y: -5.046693802, label: 1, color: '#c23740' },
  { x: 4.752955437, y: -5.931091309, label: 1, color: '#c23740' },
  { x: 0.015370369, y: 6.254646301, label: 2, color: '#c3b96f' },
  { x: 4.649301529, y: -5.26452446, label: 1, color: '#c23740' },
  { x: 5.656417847, y: -4.024151802, label: 1, color: '#c23740' },
  { x: 2.404060364, y: -6.590677261, label: 1, color: '#c23740' },
  { x: 5.402574539, y: -4.637107849, label: 1, color: '#c23740' },
  { x: 0.081993103, y: 6.338373184, label: 2, color: '#c3b96f' },
  { x: 3.553360462, y: -5.22531414, label: 1, color: '#c23740' },
  { x: 4.680389404, y: -5.098405838, label: 1, color: '#c23740' },
  { x: 4.153802872, y: -5.542720795, label: 1, color: '#c23740' },
  { x: 5.950332642, y: -3.822374344, label: 1, color: '#c23740' },
  { x: 2.055620193, y: 7.147987366, label: 2, color: '#c3b96f' },
  { x: 6.060155869, y: -5.134651184, label: 1, color: '#c23740' },
  { x: 5.835270882, y: -3.837158203, label: 1, color: '#c23740' },
  { x: 4.051656723, y: -5.90401268, label: 1, color: '#c23740' },
  { x: 6.353975296, y: -4.382650375, label: 1, color: '#c23740' },
  { x: 5.179617882, y: -4.45775795, label: 1, color: '#c23740' },
  { x: 5.522403717, y: -4.678024292, label: 1, color: '#c23740' },
  { x: 6.204865456, y: -5.114732742, label: 1, color: '#c23740' },
  { x: 0.180583954, y: 6.174631119, label: 2, color: '#c3b96f' },
  { x: 5.848628044, y: -5.200660706, label: 1, color: '#c23740' },
  { x: 4.587835312, y: -6.338238716, label: 1, color: '#c23740' },
  { x: 1.950784683, y: 6.480315208, label: 2, color: '#c3b96f' },
  { x: 5.24924469, y: -4.875007629, label: 1, color: '#c23740' },
  { x: 3.738546371, y: -4.745731354, label: 1, color: '#c23740' },
  { x: 5.729740143, y: -4.659357071, label: 1, color: '#c23740' },
  { x: 5.955112457, y: -3.726267815, label: 1, color: '#c23740' },
  { x: 3.996236801, y: -5.385278702, label: 1, color: '#c23740' },
  { x: 3.403541565, y: -5.554213524, label: 1, color: '#c23740' },
  { x: 1.336506844, y: 5.867282867, label: 2, color: '#c3b96f' },
  { x: 6.032725334, y: -4.061074257, label: 1, color: '#c23740' },
  { x: 2.258435726, y: -6.72191906, label: 1, color: '#c23740' },
  { x: 5.436507225, y: -4.177709579, label: 1, color: '#c23740' },
  { x: 6.070858955, y: -4.493629456, label: 1, color: '#c23740' },
  { x: 5.21546936, y: -5.693689346, label: 1, color: '#c23740' },
  { x: 2.122197151, y: 6.568702698, label: 2, color: '#c3b96f' },
  { x: 5.852648735, y: -4.081194878, label: 1, color: '#c23740' },
  { x: 4.787248611, y: -4.238983154, label: 1, color: '#c23740' },
  { x: 5.031088829, y: -6.515595436, label: 1, color: '#c23740' },
  { x: 6.528952599, y: -5.631872177, label: 1, color: '#c23740' },
  { x: 6.586044312, y: -4.822869301, label: 1, color: '#c23740' },
  { x: 4.465677261, y: -5.562498093, label: 1, color: '#c23740' },
  { x: 6.163806915, y: -4.253865242, label: 1, color: '#c23740' },
  { x: 1.87342453, y: 6.314775467, label: 2, color: '#c3b96f' },
  { x: 3.491827011, y: -5.357213974, label: 1, color: '#c23740' },
  { x: 5.146377563, y: -4.372678757, label: 1, color: '#c23740' },
  { x: 0.868676662, y: 5.517875671, label: 2, color: '#c3b96f' },
  { x: 3.365038872, y: -5.835899353, label: 1, color: '#c23740' },
  { x: 4.80253315, y: -4.182314873, label: 1, color: '#c23740' },
  { x: 4.709935188, y: -4.469094276, label: 1, color: '#c23740' },
  { x: 4.942895889, y: -4.727410316, label: 1, color: '#c23740' },
  { x: 5.205929756, y: -4.206539154, label: 1, color: '#c23740' },
  { x: 6.351398468, y: -4.301689148, label: 1, color: '#c23740' },
  { x: 0.18554163, y: 6.223036766, label: 2, color: '#c3b96f' },
  { x: 5.836772919, y: -3.950097084, label: 1, color: '#c23740' },
  { x: 6.271702766, y: -4.230240822, label: 1, color: '#c23740' },
  { x: 4.877934456, y: -4.577466965, label: 1, color: '#c23740' },
  { x: 3.877890587, y: -5.372623444, label: 1, color: '#c23740' },
  { x: 5.085636139, y: -4.251982689, label: 1, color: '#c23740' },
  { x: 5.365745544, y: -4.049957275, label: 1, color: '#c23740' },
  { x: 5.075452805, y: -5.065969467, label: 1, color: '#c23740' },
  { x: 3.064960003, y: -6.025351524, label: 1, color: '#c23740' },
  { x: 4.436990738, y: -4.709681511, label: 1, color: '#c23740' },
  { x: 0.310519218, y: 5.676974297, label: 2, color: '#c3b96f' },
  { x: 6.392812729, y: -4.549386024, label: 1, color: '#c23740' },
  { x: 5.097373962, y: -4.044651031, label: 1, color: '#c23740' },
  { x: 4.34548378, y: -4.789843559, label: 1, color: '#c23740' },
  { x: -0.191658497, y: 6.263544083, label: 2, color: '#c3b96f' },
  { x: 4.349931717, y: -5.843391418, label: 1, color: '#c23740' },
  { x: 5.392137527, y: -4.590890884, label: 1, color: '#c23740' },
  { x: 3.014908791, y: -5.950748444, label: 1, color: '#c23740' },
  { x: 5.321040154, y: -4.653701782, label: 1, color: '#c23740' },
  { x: 5.259782791, y: -5.181973457, label: 1, color: '#c23740' },
  { x: -0.091301918, y: 6.244616508, label: 2, color: '#c3b96f' },
  { x: 1.43536377, y: 5.781811714, label: 2, color: '#c3b96f' },
  { x: 4.645389557, y: -5.188323021, label: 1, color: '#c23740' },
  { x: 5.526481628, y: -6.112541199, label: 1, color: '#c23740' },
  { x: 5.641853333, y: -4.06535244, label: 1, color: '#c23740' },
  { x: 3.839647293, y: -5.431510925, label: 1, color: '#c23740' },
  { x: 5.717085838, y: -5.462481499, label: 1, color: '#c23740' },
  { x: 6.495859146, y: -4.970789909, label: 1, color: '#c23740' },
  { x: 5.862035751, y: -4.590755463, label: 1, color: '#c23740' },
  { x: 3.053787231, y: -6.99244976, label: 1, color: '#c23740' },
  { x: 2.02182579, y: -6.629116058, label: 1, color: '#c23740' },
  { x: 5.050645828, y: -4.053380966, label: 1, color: '#c23740' },
  { x: 1.403471947, y: 5.952228546, label: 2, color: '#c3b96f' },
  { x: 1.655128956, y: 6.460520744, label: 2, color: '#c3b96f' },
  { x: 5.139490128, y: -3.818244934, label: 1, color: '#c23740' },
  { x: 3.331157684, y: -5.509738922, label: 1, color: '#c23740' },
  { x: 1.578018188, y: 6.06082058, label: 2, color: '#c3b96f' },
  { x: 5.658169746, y: -3.75936985, label: 1, color: '#c23740' },
  { x: 0.756036758, y: 5.533447742, label: 2, color: '#c3b96f' },
  { x: 5.894608498, y: -4.174030304, label: 1, color: '#c23740' },
  { x: 4.661420822, y: -4.226284981, label: 1, color: '#c23740' },
  { x: 0.892793655, y: 5.473558426, label: 2, color: '#c3b96f' },
  { x: 5.284667015, y: -4.149894714, label: 1, color: '#c23740' },
  { x: 5.122559547, y: -5.140151978, label: 1, color: '#c23740' },
  { x: 0.817617893, y: 5.925897598, label: 2, color: '#c3b96f' },
  { x: 1.086050987, y: 5.466684341, label: 2, color: '#c3b96f' },
  { x: -0.246679306, y: 6.266524315, label: 2, color: '#c3b96f' },
  { x: 5.484215736, y: -5.237804413, label: 1, color: '#c23740' },
  { x: 1.411725998, y: 5.726357937, label: 2, color: '#c3b96f' },
  { x: 4.302045822, y: -4.621091843, label: 1, color: '#c23740' },
  { x: 5.367584229, y: -3.973011017, label: 1, color: '#c23740' },
  { x: 3.930553436, y: -4.825027466, label: 1, color: '#c23740' },
  { x: 0.693833351, y: 5.25295639, label: 2, color: '#c3b96f' },
  { x: 5.423138618, y: -4.025621414, label: 1, color: '#c23740' },
  { x: 4.095596313, y: -4.600440979, label: 1, color: '#c23740' },
  { x: 0.032079697, y: 6.669733047, label: 2, color: '#c3b96f' },
  { x: 2.425949097, y: -6.037349701, label: 1, color: '#c23740' },
  { x: 4.210578918, y: -4.333559036, label: 1, color: '#c23740' },
  { x: 5.010464668, y: -4.799518585, label: 1, color: '#c23740' },
  { x: 1.386695862, y: 5.995986938, label: 2, color: '#c3b96f' },
  { x: 1.081217766, y: 5.586129189, label: 2, color: '#c3b96f' },
  { x: 5.058401108, y: -3.81659317, label: 1, color: '#c23740' },
  { x: 5.43056488, y: -4.368853569, label: 1, color: '#c23740' },
  { x: 5.647191048, y: -4.278043747, label: 1, color: '#c23740' },
  { x: 4.163455963, y: -4.543536186, label: 1, color: '#c23740' },
  { x: 4.277249336, y: -4.814558029, label: 1, color: '#c23740' },
  { x: 5.710287094, y: -4.738358498, label: 1, color: '#c23740' },
  { x: 5.809614182, y: -4.230560303, label: 1, color: '#c23740' },
  { x: 4.277714729, y: -4.976359367, label: 1, color: '#c23740' },
  { x: 5.618806839, y: -3.607814789, label: 1, color: '#c23740' },
  { x: 5.736057281, y: -4.323982239, label: 1, color: '#c23740' },
  { x: 5.263008118, y: -4.076720238, label: 1, color: '#c23740' },
  { x: 3.760839462, y: -4.747260094, label: 1, color: '#c23740' },
  { x: 5.777175903, y: -3.83026123, label: 1, color: '#c23740' },
  { x: 6.057393074, y: -4.09951973, label: 1, color: '#c23740' },
  { x: 5.677695274, y: -3.767988205, label: 1, color: '#c23740' },
  { x: 5.289239883, y: -3.738048553, label: 1, color: '#c23740' },
  { x: 6.331318855, y: -4.029691696, label: 1, color: '#c23740' },
  { x: 3.737968445, y: -5.111751556, label: 1, color: '#c23740' },
  { x: 5.582548141, y: -3.70453167, label: 1, color: '#c23740' },
  { x: 4.204242706, y: -4.39761734, label: 1, color: '#c23740' },
  { x: 5.109531403, y: -5.150427818, label: 1, color: '#c23740' },
  { x: 5.035391808, y: -4.380357742, label: 1, color: '#c23740' },
  { x: 4.676917076, y: -4.514427185, label: 1, color: '#c23740' },
  { x: 5.505237579, y: -4.288431168, label: 1, color: '#c23740' },
  { x: 5.541161537, y: -3.767055511, label: 1, color: '#c23740' },
  { x: 5.932266235, y: -3.916484833, label: 1, color: '#c23740' },
  { x: 4.405702591, y: -5.54357338, label: 1, color: '#c23740' },
  { x: 4.693305969, y: -4.033313751, label: 1, color: '#c23740' },
  { x: 5.163188934, y: -4.661635399, label: 1, color: '#c23740' },
  { x: 6.002802849, y: -3.7833004, label: 1, color: '#c23740' },
  { x: 5.165272713, y: -4.410204887, label: 1, color: '#c23740' },
  { x: 4.998840332, y: -3.777700424, label: 1, color: '#c23740' },
  { x: 6.058343887, y: -3.644072533, label: 1, color: '#c23740' },
  { x: 6.255708694, y: -3.881811142, label: 1, color: '#c23740' },
  { x: 5.106555939, y: -4.955323219, label: 1, color: '#c23740' },
  { x: 5.655025482, y: -3.648327827, label: 1, color: '#c23740' },
  { x: 6.214710236, y: -6.217950821, label: 1, color: '#c23740' },
  { x: 6.164644241, y: -5.860209465, label: 1, color: '#c23740' },
  { x: 5.70158577, y: -6.446519852, label: 1, color: '#c23740' },
  { x: 5.070108414, y: -6.422889709, label: 1, color: '#c23740' },
  { x: 6.215557098, y: -6.005163193, label: 1, color: '#c23740' },
  { x: 5.726101875, y: -4.884317398, label: 1, color: '#c23740' },
  { x: 4.358951569, y: -6.687635422, label: 1, color: '#c23740' },
  { x: 3.293409348, y: -6.910053253, label: 1, color: '#c23740' },
  { x: 6.610271454, y: -5.38771534, label: 1, color: '#c23740' },
  { x: 5.38352108, y: -6.519313812, label: 1, color: '#c23740' },
  { x: 6.628754616, y: -5.981803894, label: 1, color: '#c23740' },
  { x: 6.276036263, y: -6.065695763, label: 1, color: '#c23740' },
  { x: 5.889434814, y: -5.334758759, label: 1, color: '#c23740' },
  { x: 4.026386261, y: -4.945220947, label: 1, color: '#c23740' },
  { x: 4.941289902, y: -4.372676849, label: 1, color: '#c23740' },
  { x: 6.630665779, y: -5.083773613, label: 1, color: '#c23740' },
  { x: 6.299757004, y: -6.124298096, label: 1, color: '#c23740' },
  { x: 3.411271095, y: -6.030101776, label: 1, color: '#c23740' },
  { x: 6.435130119, y: -4.937772751, label: 1, color: '#c23740' },
  { x: 5.862804413, y: -4.206075668, label: 1, color: '#c23740' },
  { x: 6.747926712, y: -5.694633484, label: 1, color: '#c23740' },
  { x: 4.456845284, y: -6.298826218, label: 1, color: '#c23740' },
  { x: 6.770175934, y: -5.787223816, label: 1, color: '#c23740' },
  { x: 3.065029144, y: -5.575820923, label: 1, color: '#c23740' },
  { x: 4.696329117, y: -6.724988937, label: 1, color: '#c23740' },
  { x: 6.635493279, y: -5.987817764, label: 1, color: '#c23740' },
  { x: 4.33993721, y: -4.268608093, label: 1, color: '#c23740' },
  { x: 3.109507561, y: -6.192381859, label: 1, color: '#c23740' },
  { x: 3.410690308, y: -6.190443993, label: 1, color: '#c23740' },
  { x: 0.00189209, y: 6.035259247, label: 2, color: '#c3b96f' },
  { x: 3.215265274, y: -6.014561653, label: 1, color: '#c23740' },
  { x: 6.392663002, y: -5.05139637, label: 1, color: '#c23740' },
  { x: 1.690646172, y: 6.568811417, label: 2, color: '#c3b96f' },
  { x: 6.356446266, y: -5.027291298, label: 1, color: '#c23740' },
  { x: 2.972024918, y: -5.913156509, label: 1, color: '#c23740' },
  { x: 3.486876488, y: -5.393920898, label: 1, color: '#c23740' },
  { x: 6.124042511, y: -5.015993118, label: 1, color: '#c23740' },
  { x: 2.934648514, y: -5.842148781, label: 1, color: '#c23740' },
  { x: 3.371345043, y: -5.871566772, label: 1, color: '#c23740' },
  { x: 3.123634338, y: -6.185531616, label: 1, color: '#c23740' },
  { x: 3.520933151, y: -6.649386406, label: 1, color: '#c23740' },
  { x: 2.811100006, y: -6.587533951, label: 1, color: '#c23740' },
  { x: 4.595721245, y: -5.295585632, label: 1, color: '#c23740' },
  { x: -0.040689945, y: 6.577795029, label: 2, color: '#c3b96f' },
  { x: 5.683394432, y: -6.22703743, label: 1, color: '#c23740' },
  { x: 6.444750786, y: -4.155463219, label: 1, color: '#c23740' },
  { x: 1.412370682, y: 6.031312943, label: 2, color: '#c3b96f' },
  { x: 5.033430099, y: -4.760728836, label: 1, color: '#c23740' },
  { x: 1.621440887, y: 6.284440041, label: 2, color: '#c3b96f' },
  { x: 4.673520088, y: -4.713201523, label: 1, color: '#c23740' },
  { x: 4.012489319, y: -5.594524384, label: 1, color: '#c23740' },
  { x: 5.671951294, y: -4.47988987, label: 1, color: '#c23740' },
  { x: 5.840000153, y: -3.65931797, label: 1, color: '#c23740' },
  { x: 4.82959938, y: -5.731842041, label: 1, color: '#c23740' },
  { x: 1.85928297, y: 6.032056808, label: 2, color: '#c3b96f' },
  { x: 2.775502205, y: -6.434091568, label: 1, color: '#c23740' },
  { x: 6.082567215, y: -3.8039217, label: 1, color: '#c23740' },
  { x: 3.68795681, y: -5.321405411, label: 1, color: '#c23740' },
  { x: 1.844543457, y: 6.432628632, label: 2, color: '#c3b96f' },
  { x: 4.592618942, y: -4.15794754, label: 1, color: '#c23740' },
  { x: 3.84076786, y: -6.241064072, label: 1, color: '#c23740' },
  { x: 3.832446098, y: -4.697042465, label: 1, color: '#c23740' },
  { x: 6.165594101, y: -4.614918709, label: 1, color: '#c23740' },
  { x: 6.291955948, y: -4.943980217, label: 1, color: '#c23740' },
  { x: 3.808801651, y: -5.720432281, label: 1, color: '#c23740' },
  { x: 0.857634068, y: 6.03576088, label: 2, color: '#c3b96f' },
  { x: 6.111243248, y: -4.598013878, label: 1, color: '#c23740' },
  { x: 4.066583633, y: -5.902866364, label: 1, color: '#c23740' },
  { x: 0.461499214, y: 5.831396103, label: 2, color: '#c3b96f' },
  { x: 5.341173172, y: -3.91289711, label: 1, color: '#c23740' },
  { x: 0.964351654, y: 5.745576859, label: 2, color: '#c3b96f' },
  { x: 6.080446243, y: -4.183834076, label: 1, color: '#c23740' },
  { x: 4.68085289, y: -3.981897354, label: 1, color: '#c23740' },
  { x: 3.45758152, y: -6.813537598, label: 1, color: '#c23740' },
  { x: 5.034467697, y: -4.426442146, label: 1, color: '#c23740' },
  { x: 4.961221695, y: -4.064128876, label: 1, color: '#c23740' },
  { x: 5.23737812, y: -5.117836952, label: 1, color: '#c23740' },
  { x: 6.299559593, y: -4.826591492, label: 1, color: '#c23740' },
  { x: 4.202791214, y: -6.244828224, label: 1, color: '#c23740' },
  { x: 2.731735229, y: -5.885126114, label: 1, color: '#c23740' },
  { x: 6.515686989, y: -5.323227882, label: 1, color: '#c23740' },
  { x: 5.744047165, y: -4.925350189, label: 1, color: '#c23740' },
  { x: 5.800815582, y: -4.407146454, label: 1, color: '#c23740' },
  { x: 6.415597916, y: -6.185162544, label: 1, color: '#c23740' },
  { x: 2.012055397, y: -6.829751968, label: 1, color: '#c23740' },
  { x: 2.458204269, y: 5.753678322, label: 2, color: '#c3b96f' },
  { x: 0.843355179, y: 5.88788414, label: 2, color: '#c3b96f' },
  { x: 4.711330414, y: -4.648833275, label: 1, color: '#c23740' },
  { x: 5.107807159, y: -4.301941872, label: 1, color: '#c23740' },
  { x: 3.653268814, y: -5.079392433, label: 1, color: '#c23740' },
  { x: 0.673994064, y: 5.686605453, label: 2, color: '#c3b96f' },
  { x: 2.428712845, y: -7.043754578, label: 1, color: '#c23740' },
  { x: 5.146177292, y: -6.324877739, label: 1, color: '#c23740' },
  { x: 4.282169342, y: -5.333645821, label: 1, color: '#c23740' },
  { x: 2.687606812, y: -6.25343895, label: 1, color: '#c23740' },
  { x: 3.551441193, y: -5.07418251, label: 1, color: '#c23740' },
  { x: 6.009967804, y: -5.658475876, label: 1, color: '#c23740' },
  { x: 5.853590965, y: -5.074625015, label: 1, color: '#c23740' },
  { x: 1.936983109, y: -6.680720329, label: 1, color: '#c23740' },
  { x: 2.959824562, y: -6.713773727, label: 1, color: '#c23740' },
  { x: 3.622344971, y: -5.331451416, label: 1, color: '#c23740' },
  { x: 6.157231331, y: -4.506519318, label: 1, color: '#c23740' },
  { x: 3.230594635, y: -5.179692268, label: 1, color: '#c23740' },
  { x: 1.740362167, y: 5.640478134, label: 2, color: '#c3b96f' },
  { x: 2.948217392, y: -5.552759171, label: 1, color: '#c23740' },
  { x: 1.688632011, y: 6.349503517, label: 2, color: '#c3b96f' },
  { x: 4.310380936, y: -4.821868896, label: 1, color: '#c23740' },
  { x: 5.998678207, y: -5.208741188, label: 1, color: '#c23740' },
  { x: 5.888399124, y: -5.645881653, label: 1, color: '#c23740' },
  { x: 3.007058144, y: -6.978408813, label: 1, color: '#c23740' },
  { x: 5.379244804, y: -5.318433762, label: 1, color: '#c23740' },
  { x: 3.191410065, y: -6.0815382, label: 1, color: '#c23740' },
  { x: 6.198159218, y: -5.280676842, label: 1, color: '#c23740' },
  { x: 6.564992905, y: -5.642518997, label: 1, color: '#c23740' },
  { x: 4.563987732, y: -6.216874123, label: 1, color: '#c23740' },
  { x: 3.417755127, y: -6.293790817, label: 1, color: '#c23740' },
  { x: 4.625757217, y: -6.254926682, label: 1, color: '#c23740' },
  { x: 3.760988235, y: -6.646853447, label: 1, color: '#c23740' },
  { x: 2.6639328, y: -6.627948761, label: 1, color: '#c23740' },
  { x: 0.803972244, y: 5.376272202, label: 2, color: '#c3b96f' },
  { x: 5.223150253, y: -6.068180084, label: 1, color: '#c23740' },
  { x: 6.249220848, y: -5.273030281, label: 1, color: '#c23740' },
  { x: 5.9628582, y: -3.679150581, label: 1, color: '#c23740' },
  { x: 2.910481453, y: -6.530388832, label: 1, color: '#c23740' },
  { x: 3.55012989, y: -6.888113022, label: 1, color: '#c23740' },
  { x: 3.140534401, y: -5.513908386, label: 1, color: '#c23740' },
  { x: 4.437799454, y: -6.355478287, label: 1, color: '#c23740' },
  { x: 3.874482155, y: -6.768520355, label: 1, color: '#c23740' },
  { x: 5.354388237, y: -5.835718155, label: 1, color: '#c23740' },
  { x: 3.311102867, y: -6.072999954, label: 1, color: '#c23740' },
  { x: 5.605238914, y: -5.216423035, label: 1, color: '#c23740' },
  { x: 4.909536362, y: -6.298681259, label: 1, color: '#c23740' },
  { x: 4.188876152, y: -4.91626358, label: 1, color: '#c23740' },
  { x: 4.584257126, y: -6.380016327, label: 1, color: '#c23740' },
  { x: 3.705791473, y: -5.592302322, label: 1, color: '#c23740' },
  { x: 4.574045181, y: -4.740351677, label: 1, color: '#c23740' },
  { x: 2.19729805, y: -6.689161301, label: 1, color: '#c23740' },
  { x: 6.10778141, y: -5.024711609, label: 1, color: '#c23740' },
  { x: 1.950111389, y: -6.906197548, label: 1, color: '#c23740' },
  { x: 4.64764595, y: -6.202783585, label: 1, color: '#c23740' },
  { x: 4.144108772, y: -6.555528641, label: 1, color: '#c23740' },
  { x: 4.487900734, y: -6.411201477, label: 1, color: '#c23740' },
  { x: 6.179779053, y: -5.07999897, label: 1, color: '#c23740' },
  { x: 4.151508331, y: -6.791615486, label: 1, color: '#c23740' },
  { x: 3.888776779, y: -6.819587708, label: 1, color: '#c23740' },
  { x: 0.097438812, y: 6.710538864, label: 2, color: '#c3b96f' },
  { x: 5.569626808, y: -4.440904617, label: 1, color: '#c23740' },
  { x: 3.856730461, y: -4.695066452, label: 1, color: '#c23740' },
  { x: 3.578355789, y: -6.820052147, label: 1, color: '#c23740' },
  { x: 3.871408463, y: -5.772029877, label: 1, color: '#c23740' },
  { x: 4.297541142, y: -6.639480591, label: 1, color: '#c23740' },
  { x: 4.296780586, y: -6.606998444, label: 1, color: '#c23740' },
  { x: 4.823474884, y: -6.289207458, label: 1, color: '#c23740' },
  { x: 5.61998558, y: -6.270120621, label: 1, color: '#c23740' },
  { x: 6.20740509, y: -4.944875717, label: 1, color: '#c23740' },
  { x: 3.894546509, y: -6.723457336, label: 1, color: '#c23740' },
  { x: 6.111595154, y: -5.873275757, label: 1, color: '#c23740' },
  { x: 3.351633072, y: -6.190466881, label: 1, color: '#c23740' },
  { x: 3.997057915, y: -6.070823669, label: 1, color: '#c23740' },
  { x: 5.165021896, y: -6.250212669, label: 1, color: '#c23740' },
  { x: 6.54914856, y: -5.27726841, label: 1, color: '#c23740' },
  { x: 5.400667191, y: -6.01836586, label: 1, color: '#c23740' },
  { x: 5.796786308, y: -6.293664932, label: 1, color: '#c23740' },
  { x: 2.416054726, y: -6.179285049, label: 1, color: '#c23740' },
  { x: 4.553082466, y: -6.448502541, label: 1, color: '#c23740' },
  { x: 5.049362183, y: -6.437288284, label: 1, color: '#c23740' },
  { x: 3.059928894, y: -6.962705612, label: 1, color: '#c23740' },
  { x: 3.911209106, y: -4.751766205, label: 1, color: '#c23740' },
  { x: 3.745113373, y: -5.109069824, label: 1, color: '#c23740' },
  { x: 6.059181213, y: -5.138710022, label: 1, color: '#c23740' },
  { x: 3.360198975, y: -6.483808517, label: 1, color: '#c23740' },
  { x: 3.938335419, y: -6.797196388, label: 1, color: '#c23740' },
  { x: 1.504792213, y: -7.12292099, label: 1, color: '#c23740' },
  { x: 4.068933487, y: -6.676160812, label: 1, color: '#c23740' },
  { x: 6.45911026, y: -5.865530014, label: 1, color: '#c23740' },
  { x: 4.686718941, y: -6.274440765, label: 1, color: '#c23740' },
  { x: 4.823349953, y: -6.319196701, label: 1, color: '#c23740' },
  { x: 6.845645905, y: -5.89353466, label: 1, color: '#c23740' },
  { x: 6.265964508, y: -5.93919754, label: 1, color: '#c23740' },
  { x: 2.472726345, y: -6.657810211, label: 1, color: '#c23740' },
  { x: 4.184630394, y: -4.815587044, label: 1, color: '#c23740' },
  { x: 3.844703674, y: -6.755962372, label: 1, color: '#c23740' },
  { x: 5.104115486, y: -6.228330612, label: 1, color: '#c23740' },
  { x: 5.911826134, y: -5.280076981, label: 1, color: '#c23740' },
  { x: 3.584669113, y: -6.862611771, label: 1, color: '#c23740' },
  { x: 5.074267387, y: -4.516891479, label: 1, color: '#c23740' },
  { x: 1.692308426, y: -6.904102325, label: 1, color: '#c23740' },
  { x: 6.748215675, y: -5.587795258, label: 1, color: '#c23740' },
  { x: 4.991530418, y: -6.112831116, label: 1, color: '#c23740' },
  { x: 5.731628418, y: -4.79986763, label: 1, color: '#c23740' },
  { x: 4.564014435, y: -4.404941559, label: 1, color: '#c23740' },
  { x: 5.059042931, y: -5.11797905, label: 1, color: '#c23740' },
  { x: 3.12681675, y: -5.902231216, label: 1, color: '#c23740' },
  { x: 3.744132996, y: -5.849433899, label: 1, color: '#c23740' },
  { x: 3.825725555, y: -5.700868607, label: 1, color: '#c23740' },
  { x: 3.24337101, y: -6.15577507, label: 1, color: '#c23740' },
  { x: 2.445682526, y: -6.578428268, label: 1, color: '#c23740' },
  { x: 2.02539444, y: -6.822235107, label: 1, color: '#c23740' },
  { x: 4.761898041, y: -6.247257233, label: 1, color: '#c23740' },
  { x: 3.148286819, y: -6.461507797, label: 1, color: '#c23740' },
  { x: 6.518201828, y: -4.408097267, label: 1, color: '#c23740' },
  { x: 4.57143116, y: -5.892180443, label: 1, color: '#c23740' },
  { x: 3.272451401, y: -6.230232239, label: 1, color: '#c23740' },
  { x: 5.308300018, y: -5.640295982, label: 1, color: '#c23740' },
  { x: 5.454259872, y: -6.011299133, label: 1, color: '#c23740' },
  { x: 2.920875549, y: -6.786075592, label: 1, color: '#c23740' },
  { x: 4.265305519, y: -5.477880478, label: 1, color: '#c23740' },
  { x: 2.750087738, y: -6.238414764, label: 1, color: '#c23740' },
  { x: 1.743844986, y: 5.873950005, label: 2, color: '#c3b96f' },
  { x: 5.359924316, y: -4.488599777, label: 1, color: '#c23740' },
  { x: 5.223846436, y: -5.245486259, label: 1, color: '#c23740' },
  { x: 2.75976944, y: -6.151603699, label: 1, color: '#c23740' },
  { x: 5.620615005, y: -4.492090225, label: 1, color: '#c23740' },
  { x: 4.542005539, y: -5.710515976, label: 1, color: '#c23740' },
  { x: 2.603738785, y: -6.557441711, label: 1, color: '#c23740' },
  { x: 3.294792175, y: -5.250673294, label: 1, color: '#c23740' },
  { x: 3.914039612, y: -4.814920425, label: 1, color: '#c23740' },
  { x: 3.873653412, y: -4.980968475, label: 1, color: '#c23740' },
  { x: 3.088285446, y: -5.568408012, label: 1, color: '#c23740' },
  { x: 3.119779587, y: -5.361083031, label: 1, color: '#c23740' },
  { x: 6.042354584, y: -5.295036316, label: 1, color: '#c23740' },
  { x: 3.497714996, y: -6.238373756, label: 1, color: '#c23740' },
  { x: 3.666172028, y: -5.207262039, label: 1, color: '#c23740' },
  { x: 2.808810234, y: -6.063097, label: 1, color: '#c23740' },
  { x: 1.754414558, y: -6.84461689, label: 1, color: '#c23740' },
  { x: 4.647407532, y: -4.483386993, label: 1, color: '#c23740' },
  { x: 4.72029686, y: -5.007019997, label: 1, color: '#c23740' },
  { x: 3.137016296, y: -6.159726143, label: 1, color: '#c23740' },
  { x: 3.128396988, y: -5.352519035, label: 1, color: '#c23740' },
  { x: 2.113382339, y: -6.796028137, label: 1, color: '#c23740' },
  { x: 4.980546951, y: -6.133491516, label: 1, color: '#c23740' },
  { x: 2.763999939, y: -6.203006744, label: 1, color: '#c23740' },
  { x: 2.575597763, y: -6.668451309, label: 1, color: '#c23740' },
  { x: 1.759620667, y: -6.968623161, label: 1, color: '#c23740' },
  { x: 3.967460632, y: -5.457669258, label: 1, color: '#c23740' },
  { x: 1.971009254, y: 5.971273422, label: 2, color: '#c3b96f' },
  { x: 2.736385345, y: -5.670763016, label: 1, color: '#c23740' },
  { x: 0.363246918, y: 6.149085999, label: 2, color: '#c3b96f' },
  { x: 4.474151611, y: -4.179236412, label: 1, color: '#c23740' },
  { x: 3.794996262, y: -4.935220718, label: 1, color: '#c23740' },
  { x: 3.383304596, y: -6.304143906, label: 1, color: '#c23740' },
  { x: 5.695080757, y: -4.888244629, label: 1, color: '#c23740' },
  { x: 5.011707306, y: -4.937141418, label: 1, color: '#c23740' },
  { x: 1.761851311, y: -6.826406479, label: 1, color: '#c23740' },
  { x: 2.878268719, y: -5.590257645, label: 1, color: '#c23740' },
  { x: 3.449913025, y: -5.297471046, label: 1, color: '#c23740' },
  { x: 2.94336319, y: -6.294412613, label: 1, color: '#c23740' },
  { x: 4.73293972, y: -4.19865799, label: 1, color: '#c23740' },
  { x: 2.721284866, y: -6.610926628, label: 1, color: '#c23740' },
  { x: 4.20238781, y: -5.378980637, label: 1, color: '#c23740' },
  { x: 1.915334702, y: -6.767694473, label: 1, color: '#c23740' },
  { x: 3.068240166, y: -5.34651947, label: 1, color: '#c23740' },
  { x: 2.747158051, y: -6.05573082, label: 1, color: '#c23740' },
  { x: 2.798837662, y: -6.610091209, label: 1, color: '#c23740' },
  { x: 3.268262863, y: -6.169672012, label: 1, color: '#c23740' },
  { x: 2.763097286, y: -6.383675575, label: 1, color: '#c23740' },
  { x: 3.141986847, y: -6.190594673, label: 1, color: '#c23740' },
  { x: 3.321662903, y: -5.807373047, label: 1, color: '#c23740' },
  { x: 4.471097946, y: -4.317663193, label: 1, color: '#c23740' },
  { x: 2.631063461, y: -6.018367767, label: 1, color: '#c23740' },
  { x: 3.768180847, y: -4.691803932, label: 1, color: '#c23740' },
  { x: 2.528082848, y: -6.065061569, label: 1, color: '#c23740' },
  { x: 2.790798187, y: 7.370445251, label: 2, color: '#c3b96f' },
  { x: 2.401893616, y: -6.347430229, label: 1, color: '#c23740' },
  { x: 3.622469902, y: -5.021743774, label: 1, color: '#c23740' },
  { x: 1.163196564, y: 5.556679726, label: 2, color: '#c3b96f' },
  { x: 3.875096321, y: -5.177822113, label: 1, color: '#c23740' },
  { x: 2.626055717, y: 7.373801231, label: 2, color: '#c3b96f' },
  { x: 3.524643898, y: -4.989264488, label: 1, color: '#c23740' },
  { x: 2.435155869, y: 6.976316452, label: 2, color: '#c3b96f' },
  { x: 3.571811676, y: -5.206618309, label: 1, color: '#c23740' },
  { x: 4.414047241, y: -4.175832748, label: 1, color: '#c23740' },
  { x: 5.351209641, y: -5.516592979, label: 1, color: '#c23740' },
  { x: 4.134888649, y: -4.578547478, label: 1, color: '#c23740' },
  { x: 2.15297699, y: -6.643191338, label: 1, color: '#c23740' },
  { x: 2.147863388, y: -6.40240097, label: 1, color: '#c23740' },
  { x: 1.719148636, y: 5.63855648, label: 2, color: '#c3b96f' },
  { x: 6.382805824, y: -5.166876793, label: 1, color: '#c23740' },
  { x: 1.963754654, y: -6.924302101, label: 1, color: '#c23740' },
  { x: 6.563825607, y: -4.841051102, label: 1, color: '#c23740' },
  { x: 2.573194027, y: 6.16584301, label: 2, color: '#c3b96f' },
  { x: 2.414513588, y: -6.457424164, label: 1, color: '#c23740' },
  { x: 2.428092957, y: -6.192734718, label: 1, color: '#c23740' },
  { x: 2.07144165, y: 6.034560204, label: 2, color: '#c3b96f' },
  { x: 3.692829132, y: -6.764146805, label: 1, color: '#c23740' },
  { x: 3.30208683, y: -6.353879929, label: 1, color: '#c23740' },
  { x: 5.124864578, y: -5.333346367, label: 1, color: '#c23740' },
  { x: 1.468676567, y: 5.951076508, label: 2, color: '#c3b96f' },
  { x: 3.716590881, y: -6.109433174, label: 1, color: '#c23740' },
  { x: 4.410627365, y: -6.484344482, label: 1, color: '#c23740' },
  { x: 2.471279144, y: -7.010004044, label: 1, color: '#c23740' },
  { x: 2.78686142, y: 7.577651024, label: 2, color: '#c3b96f' },
  { x: 4.740880013, y: -5.884443283, label: 1, color: '#c23740' },
  { x: 4.099740982, y: -5.535955429, label: 1, color: '#c23740' },
  { x: 2.138825417, y: -6.36459446, label: 1, color: '#c23740' },
  { x: 5.76411438, y: -6.182388306, label: 1, color: '#c23740' },
  { x: 5.494117737, y: -4.90196991, label: 1, color: '#c23740' },
  { x: 2.926644325, y: -5.430229187, label: 1, color: '#c23740' },
  { x: 2.245285034, y: 6.607428551, label: 2, color: '#c3b96f' },
  { x: 2.590639114, y: 6.432609558, label: 2, color: '#c3b96f' },
  { x: 5.984809875, y: -5.314052582, label: 1, color: '#c23740' },
  { x: 2.547687531, y: -6.793424606, label: 1, color: '#c23740' },
  { x: 2.664007187, y: 6.3195858, label: 2, color: '#c3b96f' },
  { x: 2.521697044, y: -6.185419083, label: 1, color: '#c23740' },
  { x: 4.356848717, y: -6.102813721, label: 1, color: '#c23740' },
  { x: 0.367166519, y: 6.451182365, label: 2, color: '#c3b96f' },
  { x: 1.99905014, y: 6.433364868, label: 2, color: '#c3b96f' },
  { x: 1.852940559, y: 6.393493652, label: 2, color: '#c3b96f' },
  { x: 1.813497543, y: -6.837785721, label: 1, color: '#c23740' },
  { x: 5.187812805, y: -5.845021248, label: 1, color: '#c23740' },
  { x: 3.653215408, y: -5.233369827, label: 1, color: '#c23740' },
  { x: 4.783136368, y: -5.763513565, label: 1, color: '#c23740' },
  { x: 2.483818054, y: 6.974468231, label: 2, color: '#c3b96f' },
  { x: 5.225582123, y: -4.646492958, label: 1, color: '#c23740' },
  { x: 3.336856842, y: -5.915637016, label: 1, color: '#c23740' },
  { x: 4.322943687, y: -4.893616676, label: 1, color: '#c23740' },
  { x: 2.506910324, y: 7.001060486, label: 2, color: '#c3b96f' },
  { x: 2.495594025, y: -6.382633209, label: 1, color: '#c23740' },
  { x: 2.076330185, y: 6.138043404, label: 2, color: '#c3b96f' },
  { x: 3.993013382, y: -6.17257309, label: 1, color: '#c23740' },
  { x: 3.471271992, y: -6.924045563, label: 1, color: '#c23740' },
  { x: 4.329504967, y: -6.62978363, label: 1, color: '#c23740' },
  { x: 5.818426132, y: -4.288380623, label: 1, color: '#c23740' },
  { x: 4.551714897, y: -5.242841721, label: 1, color: '#c23740' },
  { x: 5.906639099, y: -6.27261734, label: 1, color: '#c23740' },
  { x: 5.851453781, y: -4.625432968, label: 1, color: '#c23740' },
  { x: 4.023106575, y: -6.710443497, label: 1, color: '#c23740' },
  { x: 3.164440155, y: -5.441179276, label: 1, color: '#c23740' },
  { x: 3.711545944, y: -5.127250671, label: 1, color: '#c23740' },
  { x: 0.017735958, y: 6.631052971, label: 2, color: '#c3b96f' },
  { x: 4.326775551, y: -6.783163071, label: 1, color: '#c23740' },
  { x: 1.94538784, y: -6.718675613, label: 1, color: '#c23740' },
  { x: 2.479434967, y: -6.086210251, label: 1, color: '#c23740' },
  { x: 4.094044685, y: -6.837584496, label: 1, color: '#c23740' },
  { x: 3.245164871, y: -5.879119873, label: 1, color: '#c23740' },
  { x: 4.014487267, y: -5.044605255, label: 1, color: '#c23740' },
  { x: 6.394993782, y: -4.518435478, label: 1, color: '#c23740' },
  { x: 6.34646225, y: -4.7831707, label: 1, color: '#c23740' },
  { x: 5.548078537, y: -6.295526505, label: 1, color: '#c23740' },
  { x: 6.147643089, y: -6.118285179, label: 1, color: '#c23740' },
  { x: 6.805224419, y: -5.537182808, label: 1, color: '#c23740' },
  { x: 3.38594532, y: -4.960822105, label: 1, color: '#c23740' },
  { x: 6.288697243, y: -5.766996384, label: 1, color: '#c23740' },
  { x: 4.768761635, y: -6.287748337, label: 1, color: '#c23740' },
  { x: 2.07998848, y: 5.86721611, label: 2, color: '#c3b96f' },
  { x: 4.327698708, y: -6.384096146, label: 1, color: '#c23740' },
  { x: 3.856179237, y: -4.859482765, label: 1, color: '#c23740' },
  { x: 4.090668678, y: -4.754151344, label: 1, color: '#c23740' },
  { x: 5.502851486, y: -6.350802422, label: 1, color: '#c23740' },
  { x: 5.23577404, y: -6.441242218, label: 1, color: '#c23740' },
  { x: 3.74206543, y: -6.915569305, label: 1, color: '#c23740' },
  { x: 5.798159599, y: -5.591602325, label: 1, color: '#c23740' },
  { x: 6.421343803, y: -4.516224861, label: 1, color: '#c23740' },
  { x: 4.009364128, y: -4.987175941, label: 1, color: '#c23740' },
  { x: 2.165660858, y: 6.543812752, label: 2, color: '#c3b96f' },
  { x: 5.539077759, y: -6.426125526, label: 1, color: '#c23740' },
  { x: 6.487780571, y: -5.422496796, label: 1, color: '#c23740' },
  { x: 4.945148468, y: -6.473417282, label: 1, color: '#c23740' },
  { x: 4.361474037, y: -6.745966911, label: 1, color: '#c23740' },
  { x: 2.881315231, y: -5.543655396, label: 1, color: '#c23740' },
  { x: 2.093345642, y: 6.518634796, label: 2, color: '#c3b96f' },
  { x: 1.677604675, y: -7.153795242, label: 1, color: '#c23740' },
  { x: 4.31694603, y: -4.999365807, label: 1, color: '#c23740' },
  { x: 3.927467346, y: -5.626979828, label: 1, color: '#c23740' },
  { x: 3.103427887, y: -5.195901871, label: 1, color: '#c23740' },
  { x: 2.381418228, y: -6.44783783, label: 1, color: '#c23740' },
  { x: 3.43190527, y: -5.54570961, label: 1, color: '#c23740' },
  { x: 6.270441055, y: -4.011278152, label: 1, color: '#c23740' },
  { x: 6.740498543, y: -5.604856491, label: 1, color: '#c23740' },
  { x: 4.076154709, y: -4.459468842, label: 1, color: '#c23740' },
  { x: 4.430494308, y: -4.702884674, label: 1, color: '#c23740' },
  { x: 6.624001503, y: -5.359926224, label: 1, color: '#c23740' },
  { x: 6.11036396, y: -3.907762527, label: 1, color: '#c23740' },
  { x: 5.849070549, y: -3.544979095, label: 1, color: '#c23740' },
  { x: 6.538756371, y: -4.937190056, label: 1, color: '#c23740' },
  { x: 4.932764053, y: -6.048059464, label: 1, color: '#c23740' },
  { x: 5.026109695, y: -3.843974113, label: 1, color: '#c23740' },
  { x: 6.002706528, y: -4.070725441, label: 1, color: '#c23740' },
  { x: 6.227263451, y: -3.785131454, label: 1, color: '#c23740' },
  { x: 4.299515724, y: -6.167141914, label: 1, color: '#c23740' },
  { x: 0.914126396, y: 5.601041794, label: 2, color: '#c3b96f' },
  { x: 5.050788879, y: -5.398048401, label: 1, color: '#c23740' },
  { x: 6.306077957, y: -6.13061142, label: 1, color: '#c23740' },
  { x: 5.670843124, y: -4.832093239, label: 1, color: '#c23740' },
  { x: 0.898664474, y: 5.874612808, label: 2, color: '#c3b96f' },
  { x: 6.175859451, y: -6.155249596, label: 1, color: '#c23740' },
  { x: 1.228824615, y: 5.518381119, label: 2, color: '#c3b96f' },
  { x: 5.535673141, y: -3.94004631, label: 1, color: '#c23740' },
  { x: 6.82383728, y: -5.754095078, label: 1, color: '#c23740' },
  { x: 0.731753826, y: 5.482536316, label: 2, color: '#c3b96f' },
  { x: 4.840013504, y: -4.762459755, label: 1, color: '#c23740' },
  { x: 4.159386635, y: -4.799934387, label: 1, color: '#c23740' },
  { x: 3.743433952, y: -5.086495399, label: 1, color: '#c23740' },
  { x: 4.878967285, y: -5.230823517, label: 1, color: '#c23740' },
  { x: 5.934215546, y: -4.190155983, label: 1, color: '#c23740' },
  { x: 5.499011993, y: -3.883270264, label: 1, color: '#c23740' },
  { x: 6.667766571, y: -5.482440948, label: 1, color: '#c23740' },
  { x: 5.112895966, y: -4.254681587, label: 1, color: '#c23740' },
  { x: 5.412979126, y: -4.348896027, label: 1, color: '#c23740' },
  { x: 0.173243523, y: 5.89995718, label: 2, color: '#c3b96f' },
  { x: 6.613397598, y: -4.422505379, label: 1, color: '#c23740' },
  { x: 4.545248032, y: -5.58095932, label: 1, color: '#c23740' },
  { x: 6.213634491, y: -5.587770462, label: 1, color: '#c23740' },
  { x: 1.840341091, y: 6.250837326, label: 2, color: '#c3b96f' },
  { x: 4.252969742, y: -6.0374403, label: 1, color: '#c23740' },
  { x: 0.873931885, y: 5.350712299, label: 2, color: '#c3b96f' },
  { x: 6.74928093, y: -4.809007645, label: 1, color: '#c23740' },
  { x: 4.730839729, y: -4.704498291, label: 1, color: '#c23740' },
  { x: 6.66153717, y: -5.099159241, label: 1, color: '#c23740' },
  { x: 4.710414886, y: -4.491645813, label: 1, color: '#c23740' },
  { x: 5.041766167, y: -6.237317085, label: 1, color: '#c23740' },
  { x: 5.446923256, y: -4.287074089, label: 1, color: '#c23740' },
  { x: 4.289921761, y: -4.670952797, label: 1, color: '#c23740' },
  { x: 5.57116127, y: -5.97095108, label: 1, color: '#c23740' },
  { x: 4.342502594, y: -5.693752289, label: 1, color: '#c23740' },
  { x: 1.262804985, y: 5.856064796, label: 2, color: '#c3b96f' },
  { x: 1.449958801, y: 5.537370682, label: 2, color: '#c3b96f' },
  { x: 4.408567429, y: -4.621591568, label: 1, color: '#c23740' },
  { x: 0.856079102, y: 5.423751831, label: 2, color: '#c3b96f' },
  { x: 5.312925339, y: -5.406370163, label: 1, color: '#c23740' },
  { x: 6.389001846, y: -5.242390633, label: 1, color: '#c23740' },
  { x: 5.380703926, y: -4.661683083, label: 1, color: '#c23740' },
  { x: 5.902832985, y: -3.794953346, label: 1, color: '#c23740' },
  { x: 0.834677219, y: 6.270357132, label: 2, color: '#c3b96f' },
  { x: 5.160060883, y: -5.309425354, label: 1, color: '#c23740' },
  { x: 2.783083916, y: -5.636463165, label: 1, color: '#c23740' },
  { x: 2.474445343, y: -6.257033348, label: 1, color: '#c23740' },
  { x: 1.78911972, y: 6.454238892, label: 2, color: '#c3b96f' },
  { x: 3.272529602, y: -5.753196716, label: 1, color: '#c23740' },
  { x: 3.664642334, y: -5.553237915, label: 1, color: '#c23740' },
  { x: 3.765235901, y: -5.079912186, label: 1, color: '#c23740' },
  { x: 5.501325607, y: -4.997907639, label: 1, color: '#c23740' },
  { x: 4.771524429, y: -4.876901627, label: 1, color: '#c23740' },
  { x: 5.969451904, y: -3.920039177, label: 1, color: '#c23740' },
  { x: 3.532802582, y: -6.135528564, label: 1, color: '#c23740' },
  { x: 6.238660812, y: -5.132287025, label: 1, color: '#c23740' },
  { x: 5.094513893, y: -5.714744568, label: 1, color: '#c23740' },
  { x: 4.54496479, y: -4.335639954, label: 1, color: '#c23740' },
  { x: 5.462358475, y: -4.511219025, label: 1, color: '#c23740' },
  { x: 5.380389214, y: -4.205259323, label: 1, color: '#c23740' },
  { x: 3.876289368, y: -6.015034676, label: 1, color: '#c23740' },
  { x: 5.449481964, y: -6.086886406, label: 1, color: '#c23740' },
  { x: 5.693273544, y: -5.845427513, label: 1, color: '#c23740' },
  { x: 2.868652344, y: -5.544034958, label: 1, color: '#c23740' },
  { x: 0.786036491, y: 5.636283875, label: 2, color: '#c3b96f' },
  { x: 6.10398674, y: -3.968390465, label: 1, color: '#c23740' },
  { x: 4.446632385, y: -4.947292328, label: 1, color: '#c23740' },
  { x: 4.59515667, y: -6.544033051, label: 1, color: '#c23740' },
  { x: 5.975434303, y: -5.723558426, label: 1, color: '#c23740' },
  { x: 4.965579987, y: -3.853841782, label: 1, color: '#c23740' },
  { x: 4.952641487, y: -4.242153168, label: 1, color: '#c23740' },
  { x: 4.277490616, y: -4.292390823, label: 1, color: '#c23740' },
  { x: 4.331536293, y: -5.510519028, label: 1, color: '#c23740' },
  { x: 2.350478172, y: -6.50924015, label: 1, color: '#c23740' },
  { x: 5.53585434, y: -5.820643425, label: 1, color: '#c23740' },
  { x: 0.591445923, y: 5.670589447, label: 2, color: '#c3b96f' },
  { x: 5.879302979, y: -6.273938179, label: 1, color: '#c23740' },
  { x: 3.030815125, y: -5.665287018, label: 1, color: '#c23740' },
  { x: -0.124324799, y: 6.265434265, label: 2, color: '#c3b96f' },
  { x: 6.240711212, y: -5.61991024, label: 1, color: '#c23740' },
  { x: 6.35231781, y: -5.639320374, label: 1, color: '#c23740' },
  { x: 3.441022873, y: -5.407938004, label: 1, color: '#c23740' },
  { x: 5.930198669, y: -3.547996521, label: 1, color: '#c23740' },
  { x: 4.205706596, y: -5.725622177, label: 1, color: '#c23740' },
  { x: -0.158149242, y: 6.122944832, label: 2, color: '#c3b96f' },
  { x: 4.550775528, y: -4.327377319, label: 1, color: '#c23740' },
  { x: -0.112717628, y: 6.133356094, label: 2, color: '#c3b96f' },
  { x: 4.1300354, y: -5.065982819, label: 1, color: '#c23740' },
  { x: 2.943296909, y: -5.608398438, label: 1, color: '#c23740' },
  { x: 6.079105377, y: -4.80459404, label: 1, color: '#c23740' },
  { x: 6.218091965, y: -4.757752419, label: 1, color: '#c23740' },
  { x: 2.912784576, y: -5.744581223, label: 1, color: '#c23740' },
  { x: 4.424977303, y: -4.285234451, label: 1, color: '#c23740' },
  { x: 4.609359741, y: -4.550181389, label: 1, color: '#c23740' },
  { x: 5.374960899, y: -3.64499855, label: 1, color: '#c23740' },
  { x: 5.231848717, y: -3.976305008, label: 1, color: '#c23740' },
  { x: 1.132125854, y: 5.452288628, label: 2, color: '#c3b96f' },
  { x: 4.239681244, y: -4.333900452, label: 1, color: '#c23740' },
  { x: 4.537381172, y: -4.325366974, label: 1, color: '#c23740' },
  { x: 5.088963509, y: -4.190137863, label: 1, color: '#c23740' },
  { x: 6.310512543, y: -5.62136364, label: 1, color: '#c23740' },
  { x: 2.670947075, y: -5.917076111, label: 1, color: '#c23740' },
  { x: 5.947600365, y: -5.430913925, label: 1, color: '#c23740' },
  { x: 5.901205063, y: -3.818279266, label: 1, color: '#c23740' },
  { x: 2.864014626, y: -5.563928604, label: 1, color: '#c23740' },
  { x: 4.568641663, y: -4.23789978, label: 1, color: '#c23740' },
  { x: 5.945774078, y: -3.816934586, label: 1, color: '#c23740' },
  { x: 5.193983078, y: -3.654292107, label: 1, color: '#c23740' },
  { x: 0.946517944, y: 5.629516602, label: 2, color: '#c3b96f' },
  { x: 5.946547508, y: -3.745144844, label: 1, color: '#c23740' },
  { x: 3.782115936, y: -5.987167358, label: 1, color: '#c23740' },
  { x: 0.190458298, y: 6.395462036, label: 2, color: '#c3b96f' },
  { x: 5.024933815, y: -3.966485977, label: 1, color: '#c23740' },
  { x: 0.743755817, y: 5.770419121, label: 2, color: '#c3b96f' },
  { x: 3.397059441, y: -5.032295227, label: 1, color: '#c23740' },
  { x: 3.188038826, y: -5.217750549, label: 1, color: '#c23740' },
  { x: 5.223727226, y: -4.000221252, label: 1, color: '#c23740' },
  { x: 3.85733223, y: -4.894378662, label: 1, color: '#c23740' },
  { x: 5.171575546, y: -3.690674782, label: 1, color: '#c23740' },
  { x: 4.250936508, y: -6.377829552, label: 1, color: '#c23740' },
  { x: 1.401457787, y: 6.635538101, label: 2, color: '#c3b96f' },
  { x: 0.463068962, y: 6.1477108, label: 2, color: '#c3b96f' },
  { x: 1.008410454, y: 6.236227036, label: 2, color: '#c3b96f' },
  { x: 5.791584015, y: -3.86006546, label: 1, color: '#c23740' },
  { x: 0.096861839, y: 6.021817207, label: 2, color: '#c3b96f' },
  { x: 0.182773113, y: 6.015831947, label: 2, color: '#c3b96f' },
  { x: 6.322998047, y: -4.568881989, label: 1, color: '#c23740' },
  { x: 5.684986115, y: -3.796257973, label: 1, color: '#c23740' },
  { x: 6.347608566, y: -4.140426636, label: 1, color: '#c23740' },
  { x: -0.053603649, y: 6.13886261, label: 2, color: '#c3b96f' },
  { x: 5.93696022, y: -3.921297073, label: 1, color: '#c23740' },
  { x: 6.254160881, y: -4.818710327, label: 1, color: '#c23740' },
  { x: 5.966884613, y: -3.713356018, label: 1, color: '#c23740' },
  { x: 2.91976738, y: -5.834122658, label: 1, color: '#c23740' },
  { x: 3.93951416, y: -4.591584206, label: 1, color: '#c23740' },
  { x: 0.945973396, y: 5.316048622, label: 2, color: '#c3b96f' },
  { x: 5.461845398, y: -3.793998718, label: 1, color: '#c23740' },
  { x: 2.60259819, y: -6.513048172, label: 1, color: '#c23740' },
  { x: 4.497810364, y: -4.525466919, label: 1, color: '#c23740' },
  { x: 0.75667572, y: 5.458879471, label: 2, color: '#c3b96f' },
  { x: 1.945762634, y: 5.7964468, label: 2, color: '#c3b96f' },
  { x: 6.338788986, y: -4.455335617, label: 1, color: '#c23740' },
  { x: 1.033789635, y: 5.737105846, label: 2, color: '#c3b96f' },
  { x: 1.335716248, y: 5.672487259, label: 2, color: '#c3b96f' },
  { x: 2.654817581, y: 6.224188805, label: 2, color: '#c3b96f' },
  { x: 4.514498711, y: -4.606613159, label: 1, color: '#c23740' },
  { x: 1.071423531, y: 5.475160599, label: 2, color: '#c3b96f' },
  { x: 2.415672302, y: 6.538639069, label: 2, color: '#c3b96f' },
  { x: 4.091407776, y: -4.566217422, label: 1, color: '#c23740' },
  { x: 0.692964554, y: 5.484750748, label: 2, color: '#c3b96f' },
  { x: 6.341702461, y: -5.765363693, label: 1, color: '#c23740' },
  { x: 2.277781487, y: 5.91071701, label: 2, color: '#c3b96f' },
  { x: 4.319470406, y: -5.878440857, label: 1, color: '#c23740' },
  { x: 5.303255081, y: -5.690372467, label: 1, color: '#c23740' },
  { x: -0.275997639, y: 6.127861023, label: 2, color: '#c3b96f' },
  { x: 5.698137283, y: -3.659849167, label: 1, color: '#c23740' },
  { x: 4.837287903, y: -6.565893173, label: 1, color: '#c23740' },
  { x: 5.444229126, y: -3.843513489, label: 1, color: '#c23740' },
  { x: 5.190500259, y: -4.106766701, label: 1, color: '#c23740' },
  { x: 5.946352005, y: -3.763036728, label: 1, color: '#c23740' },
  { x: 4.959667206, y: -3.940327644, label: 1, color: '#c23740' },
  { x: 0.084288597, y: 6.323257446, label: 2, color: '#c3b96f' },
  { x: 5.303063393, y: -4.697402954, label: 1, color: '#c23740' },
  { x: 3.986753464, y: -5.163318634, label: 1, color: '#c23740' },
  { x: 6.23868084, y: -4.722237587, label: 1, color: '#c23740' },
  { x: 5.378749847, y: -4.387270927, label: 1, color: '#c23740' },
  { x: 3.120800018, y: -5.375232697, label: 1, color: '#c23740' },
  { x: 6.277658463, y: -3.766085148, label: 1, color: '#c23740' },
  { x: 1.764177322, y: 5.975003242, label: 2, color: '#c3b96f' },
  { x: 1.462877274, y: 6.080872536, label: 2, color: '#c3b96f' },
  { x: 5.9421978, y: -4.404474258, label: 1, color: '#c23740' },
  { x: 6.56139946, y: -6.074306488, label: 1, color: '#c23740' },
  { x: 5.849648476, y: -3.745132446, label: 1, color: '#c23740' },
  { x: 4.106082916, y: -4.612483025, label: 1, color: '#c23740' },
  { x: 4.855027199, y: -4.18621254, label: 1, color: '#c23740' },
  { x: 5.352098465, y: -3.926181793, label: 1, color: '#c23740' },
  { x: 2.618452072, y: -6.132400513, label: 1, color: '#c23740' },
  { x: 5.5210495, y: -4.102006912, label: 1, color: '#c23740' },
  { x: 2.378015518, y: -6.251149178, label: 1, color: '#c23740' },
  { x: 6.195734024, y: -3.758166313, label: 1, color: '#c23740' },
  { x: 5.800755501, y: -3.963967323, label: 1, color: '#c23740' },
  { x: 6.412761688, y: -4.544120789, label: 1, color: '#c23740' },
  { x: 2.593806267, y: -5.932872772, label: 1, color: '#c23740' },
  { x: 1.232278347, y: 5.616127968, label: 2, color: '#c3b96f' },
  { x: 4.378250122, y: -4.293898582, label: 1, color: '#c23740' },
  { x: 6.339167595, y: -6.050876617, label: 1, color: '#c23740' },
  { x: 5.31582737, y: -4.445774078, label: 1, color: '#c23740' },
  { x: 2.808324814, y: -6.321733475, label: 1, color: '#c23740' },
  { x: 4.848214149, y: -5.261748314, label: 1, color: '#c23740' },
  { x: 5.991600037, y: -5.575950623, label: 1, color: '#c23740' },
  { x: 3.584683418, y: -6.065731049, label: 1, color: '#c23740' },
  { x: 5.946606636, y: -3.983718872, label: 1, color: '#c23740' },
  { x: 4.535980225, y: -4.65760994, label: 1, color: '#c23740' },
  { x: 5.980666161, y: -5.338285446, label: 1, color: '#c23740' },
  { x: 3.889062881, y: -6.809286118, label: 1, color: '#c23740' },
  { x: 5.420257568, y: -5.232165337, label: 1, color: '#c23740' },
  { x: 6.050479889, y: -3.913391113, label: 1, color: '#c23740' },
  { x: 5.776313782, y: -5.501180649, label: 1, color: '#c23740' },
  { x: 3.226111412, y: -5.783223152, label: 1, color: '#c23740' },
  { x: 0.453752518, y: 6.899969101, label: 2, color: '#c3b96f' },
  { x: 4.778630257, y: -4.710298538, label: 1, color: '#c23740' },
  { x: 6.438071251, y: -4.677302361, label: 1, color: '#c23740' },
  { x: 6.862961769, y: -5.46267128, label: 1, color: '#c23740' },
  { x: 3.993733406, y: -6.05076313, label: 1, color: '#c23740' },
  { x: 4.53355217, y: -5.085400581, label: 1, color: '#c23740' },
  { x: 4.829896927, y: -6.710414886, label: 1, color: '#c23740' },
  { x: 5.944171906, y: -4.591674805, label: 1, color: '#c23740' },
  { x: 4.916669846, y: -4.985486984, label: 1, color: '#c23740' },
  { x: 5.079641342, y: -4.668540955, label: 1, color: '#c23740' },
  { x: 3.747865677, y: -6.243485451, label: 1, color: '#c23740' },
  { x: 5.170219421, y: -6.293605804, label: 1, color: '#c23740' },
  { x: 5.331907272, y: -5.854661942, label: 1, color: '#c23740' },
  { x: 6.044395447, y: -5.274951935, label: 1, color: '#c23740' },
  { x: 5.451008797, y: -6.351865768, label: 1, color: '#c23740' },
  { x: 5.938391685, y: -5.766645432, label: 1, color: '#c23740' },
  { x: 4.995357513, y: -6.470262527, label: 1, color: '#c23740' },
  { x: 4.947916985, y: -4.733644485, label: 1, color: '#c23740' },
  { x: 5.997777939, y: -5.819821358, label: 1, color: '#c23740' },
  { x: 5.156808853, y: -6.104076385, label: 1, color: '#c23740' },
  { x: 6.614473343, y: -4.823383331, label: 1, color: '#c23740' },
  { x: 5.057970047, y: -6.091226578, label: 1, color: '#c23740' },
  { x: 6.386575699, y: -6.169686317, label: 1, color: '#c23740' },
  { x: 6.604600906, y: -5.938325882, label: 1, color: '#c23740' },
  { x: 3.166284561, y: -6.973956108, label: 1, color: '#c23740' },
  { x: 4.011781693, y: -6.775119781, label: 1, color: '#c23740' },
  { x: 6.094347954, y: -4.916846275, label: 1, color: '#c23740' },
  { x: 4.459512711, y: -6.707724571, label: 1, color: '#c23740' },
  { x: 1.839445591, y: 6.652204514, label: 2, color: '#c3b96f' },
  { x: 5.502966881, y: -5.983205795, label: 1, color: '#c23740' },
  { x: 5.306533813, y: -5.821926117, label: 1, color: '#c23740' },
  { x: 6.735915184, y: -5.953762054, label: 1, color: '#c23740' },
  { x: 2.880383492, y: -6.859169006, label: 1, color: '#c23740' },
  { x: 2.862034798, y: -6.22499752, label: 1, color: '#c23740' },
  { x: 3.319629669, y: -6.868078232, label: 1, color: '#c23740' },
  { x: 5.111544609, y: -5.936909676, label: 1, color: '#c23740' },
  { x: 4.109741211, y: -6.79520607, label: 1, color: '#c23740' },
  { x: 6.023773193, y: -6.117601395, label: 1, color: '#c23740' },
  { x: 5.291477203, y: -6.511735916, label: 1, color: '#c23740' },
  { x: 4.679920197, y: -5.345508575, label: 1, color: '#c23740' },
  { x: 3.341669083, y: -6.712221146, label: 1, color: '#c23740' },
  { x: 4.71203804, y: -4.78764534, label: 1, color: '#c23740' },
  { x: 5.209334373, y: -4.821802139, label: 1, color: '#c23740' },
  { x: 5.072967529, y: -4.394365311, label: 1, color: '#c23740' },
  { x: 3.340300083, y: -6.560044289, label: 1, color: '#c23740' },
  { x: 2.955358505, y: -7.101070404, label: 1, color: '#c23740' },
  { x: 1.715000153, y: -6.999415398, label: 1, color: '#c23740' },
  { x: 6.255342484, y: -4.80963707, label: 1, color: '#c23740' },
  { x: 5.765280724, y: -6.260044098, label: 1, color: '#c23740' },
  { x: 3.655861855, y: -5.211216927, label: 1, color: '#c23740' },
  { x: 2.307538986, y: -6.586232185, label: 1, color: '#c23740' },
  { x: 5.772743225, y: -4.611753464, label: 1, color: '#c23740' },
  { x: 4.568645477, y: -5.015789032, label: 1, color: '#c23740' },
  { x: 4.644172668, y: -5.209833145, label: 1, color: '#c23740' },
  { x: 4.653341293, y: -6.578935623, label: 1, color: '#c23740' },
  { x: 6.742103577, y: -5.391695976, label: 1, color: '#c23740' },
  { x: 4.589543343, y: -6.5396595, label: 1, color: '#c23740' },
  { x: 1.849160194, y: 6.588579178, label: 2, color: '#c3b96f' },
  { x: 5.927194595, y: -3.880377293, label: 1, color: '#c23740' },
  { x: 3.54119873, y: -6.797355652, label: 1, color: '#c23740' },
  { x: 5.143779755, y: -4.993199348, label: 1, color: '#c23740' },
  { x: 6.52852726, y: -5.870881081, label: 1, color: '#c23740' },
  { x: 2.572491646, y: -6.476741791, label: 1, color: '#c23740' },
  { x: 6.457885742, y: -4.998130798, label: 1, color: '#c23740' },
  { x: 5.782338142, y: -3.894432068, label: 1, color: '#c23740' },
  { x: 6.756328583, y: -5.309000015, label: 1, color: '#c23740' },
  { x: 4.711004257, y: -6.529442787, label: 1, color: '#c23740' },
  { x: 3.591281891, y: -6.420387268, label: 1, color: '#c23740' },
  { x: 3.935613632, y: -6.616192818, label: 1, color: '#c23740' },
  { x: 6.370633125, y: -5.43337822, label: 1, color: '#c23740' },
  { x: 6.253461838, y: -5.137591362, label: 1, color: '#c23740' },
  { x: 2.933351517, y: -6.856303215, label: 1, color: '#c23740' },
  { x: 4.236209869, y: -6.759372711, label: 1, color: '#c23740' },
  { x: 4.665466309, y: -6.081242561, label: 1, color: '#c23740' },
  { x: 6.204549789, y: -5.465642929, label: 1, color: '#c23740' },
  { x: 2.812642097, y: -6.265113831, label: 1, color: '#c23740' },
  { x: 1.948005676, y: -7.134999275, label: 1, color: '#c23740' },
  { x: 4.277542114, y: -6.149847031, label: 1, color: '#c23740' },
  { x: 4.270553589, y: -5.259626389, label: 1, color: '#c23740' },
  { x: 5.751263618, y: -4.177143097, label: 1, color: '#c23740' },
  { x: 4.57917881, y: -4.426000595, label: 1, color: '#c23740' },
  { x: 6.520414352, y: -5.236027718, label: 1, color: '#c23740' },
  { x: 1.897415161, y: -6.806631088, label: 1, color: '#c23740' },
  { x: 3.475527763, y: -5.44648838, label: 1, color: '#c23740' },
  { x: 3.608007908, y: -4.969620705, label: 1, color: '#c23740' },
  { x: 5.626364708, y: -4.229473114, label: 1, color: '#c23740' },
  { x: 1.467809677, y: -7.160402298, label: 1, color: '#c23740' },
  { x: 3.134485245, y: -5.981539726, label: 1, color: '#c23740' },
  { x: 6.144683838, y: -4.236846924, label: 1, color: '#c23740' },
  { x: 3.613314629, y: -5.809329033, label: 1, color: '#c23740' },
  { x: 5.355047226, y: -5.756014824, label: 1, color: '#c23740' },
  { x: 2.23741436, y: -6.974514008, label: 1, color: '#c23740' },
  { x: 4.494477272, y: -4.884971619, label: 1, color: '#c23740' },
  { x: 1.568665504, y: -7.120883942, label: 1, color: '#c23740' },
  { x: 4.97672081, y: -5.82588768, label: 1, color: '#c23740' },
  { x: 2.935401917, y: -5.596818924, label: 1, color: '#c23740' },
  { x: 4.099084854, y: -4.590291023, label: 1, color: '#c23740' },
  { x: 4.508518219, y: -4.949694633, label: 1, color: '#c23740' },
  { x: 6.274089813, y: -6.089186668, label: 1, color: '#c23740' },
  { x: 6.19567585, y: -4.535182953, label: 1, color: '#c23740' },
  { x: 5.174365044, y: -3.74773407, label: 1, color: '#c23740' },
  { x: 4.439440727, y: -5.49338913, label: 1, color: '#c23740' },
  { x: 3.219759941, y: -5.882116318, label: 1, color: '#c23740' },
  { x: 6.110366821, y: -3.777931213, label: 1, color: '#c23740' },
  { x: 1.683870316, y: -6.98677063, label: 1, color: '#c23740' },
  { x: 5.971786499, y: -3.644130707, label: 1, color: '#c23740' },
  { x: 4.966310501, y: -5.514087677, label: 1, color: '#c23740' },
  { x: 4.789731979, y: -4.875440598, label: 1, color: '#c23740' },
  { x: 3.569497108, y: -5.299863815, label: 1, color: '#c23740' },
  { x: 3.079135895, y: -6.195392609, label: 1, color: '#c23740' },
  { x: 2.629349709, y: -6.085767746, label: 1, color: '#c23740' },
  { x: 3.308946609, y: -5.394943237, label: 1, color: '#c23740' },
  { x: 2.828380585, y: -5.775915146, label: 1, color: '#c23740' },
  { x: 3.720403671, y: -4.886806488, label: 1, color: '#c23740' },
  { x: 2.115137577, y: -6.581483841, label: 1, color: '#c23740' },
  { x: 2.30510807, y: -6.361235619, label: 1, color: '#c23740' },
  { x: 2.22145462, y: -6.472510338, label: 1, color: '#c23740' },
  { x: 4.047872543, y: -5.051504135, label: 1, color: '#c23740' },
  { x: 2.480047226, y: -5.987070084, label: 1, color: '#c23740' },
  { x: 1.958204269, y: -6.733333588, label: 1, color: '#c23740' },
  { x: 1.382438183, y: 5.630830765, label: 2, color: '#c3b96f' },
  { x: 5.416741371, y: -4.920948029, label: 1, color: '#c23740' },
  { x: 4.56493187, y: -5.212881088, label: 1, color: '#c23740' },
  { x: 3.962425232, y: -4.557759285, label: 1, color: '#c23740' },
  { x: 2.561878204, y: -5.966837883, label: 1, color: '#c23740' },
  { x: 4.782235146, y: -4.434997559, label: 1, color: '#c23740' },
  { x: -0.031850338, y: 6.135948181, label: 2, color: '#c3b96f' },
  { x: 3.542280197, y: -5.244592667, label: 1, color: '#c23740' },
  { x: 1.512716293, y: -7.133285522, label: 1, color: '#c23740' },
  { x: 2.453360558, y: 5.895970345, label: 2, color: '#c3b96f' },
  { x: 3.044588089, y: -5.865502357, label: 1, color: '#c23740' },
  { x: 2.814409733, y: -5.811083794, label: 1, color: '#c23740' },
  { x: 2.060905457, y: 5.860872269, label: 2, color: '#c3b96f' },
  { x: 5.65736866, y: -4.029410362, label: 1, color: '#c23740' },
  { x: 5.707149506, y: -3.978281498, label: 1, color: '#c23740' },
  { x: 4.280504227, y: -5.051419258, label: 1, color: '#c23740' },
  { x: 1.25554657, y: 5.913272858, label: 2, color: '#c3b96f' },
  { x: 6.035876274, y: -3.793861389, label: 1, color: '#c23740' },
  { x: 2.610279083, y: -6.317138672, label: 1, color: '#c23740' },
  { x: 1.80274868, y: -6.888767242, label: 1, color: '#c23740' },
  { x: 2.278770447, y: -6.430764198, label: 1, color: '#c23740' },
  { x: 4.71245575, y: -5.196674347, label: 1, color: '#c23740' },
  { x: 4.69405365, y: -4.213407516, label: 1, color: '#c23740' },
  { x: 2.460861206, y: 6.601964951, label: 2, color: '#c3b96f' },
  { x: 2.624340057, y: -6.066610336, label: 1, color: '#c23740' },
  { x: 3.757007599, y: -5.501321793, label: 1, color: '#c23740' },
  { x: 1.970589161, y: 6.601036072, label: 2, color: '#c3b96f' },
  { x: 2.462534904, y: 6.242643356, label: 2, color: '#c3b96f' },
  { x: 4.875724792, y: -4.348321915, label: 1, color: '#c23740' },
  { x: 6.070581436, y: -3.608454704, label: 1, color: '#c23740' },
  { x: 3.575536728, y: -5.445028305, label: 1, color: '#c23740' },
  { x: 2.238651276, y: 6.144195557, label: 2, color: '#c3b96f' },
  { x: 4.860561371, y: -6.595575333, label: 1, color: '#c23740' },
  { x: 4.929140091, y: -4.484583855, label: 1, color: '#c23740' },
  { x: 2.947617531, y: -5.543737411, label: 1, color: '#c23740' },
  { x: 2.671962738, y: 7.012570381, label: 2, color: '#c3b96f' },
  { x: 2.37564373, y: 6.0405159, label: 2, color: '#c3b96f' },
  { x: 1.694897175, y: 6.029209137, label: 2, color: '#c3b96f' },
  { x: 4.095585823, y: -5.051509857, label: 1, color: '#c23740' },
  { x: 4.118108749, y: -5.779247284, label: 1, color: '#c23740' },
  { x: 0.221040726, y: 6.222214699, label: 2, color: '#c3b96f' },
  { x: 2.373522758, y: 5.901330948, label: 2, color: '#c3b96f' },
  { x: 3.882494926, y: -4.961559296, label: 1, color: '#c23740' },
  { x: 1.957691193, y: -6.658617973, label: 1, color: '#c23740' },
  { x: 4.649131775, y: -5.287157059, label: 1, color: '#c23740' },
  { x: 3.601661682, y: -5.844661713, label: 1, color: '#c23740' },
  { x: 3.028129578, y: -5.464139938, label: 1, color: '#c23740' },
  { x: 4.75128746, y: -3.935666084, label: 1, color: '#c23740' },
  { x: 1.422617912, y: 5.800989151, label: 2, color: '#c3b96f' },
  { x: 2.634357452, y: 6.699993134, label: 2, color: '#c3b96f' },
  { x: 4.182606697, y: -4.542105675, label: 1, color: '#c23740' },
  { x: 4.872591019, y: -3.8561306, label: 1, color: '#c23740' },
  { x: 1.986160278, y: -7.032066345, label: 1, color: '#c23740' },
  { x: 4.997560501, y: -4.005561829, label: 1, color: '#c23740' },
  { x: 2.135047913, y: -6.450246811, label: 1, color: '#c23740' },
  { x: 4.002076149, y: -6.322813988, label: 1, color: '#c23740' },
  { x: 2.392303467, y: 6.485397339, label: 2, color: '#c3b96f' },
  { x: 2.739450455, y: -6.453962326, label: 1, color: '#c23740' },
  { x: 6.627782822, y: -4.883867264, label: 1, color: '#c23740' },
  { x: 2.723163605, y: 6.044930458, label: 2, color: '#c3b96f' },
  { x: 6.57453537, y: -5.407436371, label: 1, color: '#c23740' },
  { x: 3.613072395, y: -5.363147736, label: 1, color: '#c23740' },
  { x: 5.865823746, y: -6.197031021, label: 1, color: '#c23740' },
  { x: 3.208693504, y: -5.888176918, label: 1, color: '#c23740' },
  { x: 2.356276035, y: -6.304534912, label: 1, color: '#c23740' },
  { x: 2.62858963, y: -6.108533859, label: 1, color: '#c23740' },
  { x: 2.459644318, y: 6.204967499, label: 2, color: '#c3b96f' },
  { x: 2.127645493, y: 6.030529976, label: 2, color: '#c3b96f' },
  { x: 5.284740448, y: -3.882505417, label: 1, color: '#c23740' },
  { x: 2.668850899, y: 6.643426895, label: 2, color: '#c3b96f' },
  { x: 4.704250336, y: -4.250257492, label: 1, color: '#c23740' },
  { x: 2.507854462, y: 7.348724365, label: 2, color: '#c3b96f' },
  { x: 3.87780571, y: -5.740142822, label: 1, color: '#c23740' },
  { x: 2.498041153, y: -6.183235168, label: 1, color: '#c23740' },
  { x: 3.217996597, y: -7.009778976, label: 1, color: '#c23740' },
  { x: 3.012514114, y: -5.534240723, label: 1, color: '#c23740' },
  { x: 6.805383682, y: -5.022215843, label: 1, color: '#c23740' },
  { x: 2.562732697, y: -6.543043137, label: 1, color: '#c23740' },
  { x: 2.079838753, y: 6.212833405, label: 2, color: '#c3b96f' },
  { x: 2.373912811, y: -6.37156105, label: 1, color: '#c23740' },
  { x: 5.439846992, y: -4.140392303, label: 1, color: '#c23740' },
  { x: 6.584957123, y: -5.502396584, label: 1, color: '#c23740' },
  { x: 2.672823429, y: -5.988657951, label: 1, color: '#c23740' },
  { x: 4.264429092, y: -5.943597794, label: 1, color: '#c23740' },
  { x: 6.450112343, y: -4.530591965, label: 1, color: '#c23740' },
  { x: 2.601535797, y: 7.080430031, label: 2, color: '#c3b96f' },
  { x: 2.316438675, y: -7.022064209, label: 1, color: '#c23740' },
  { x: 4.991374969, y: -6.213959694, label: 1, color: '#c23740' },
  { x: 6.411086082, y: -4.856356621, label: 1, color: '#c23740' },
  { x: 1.627908707, y: 6.373730659, label: 2, color: '#c3b96f' },
  { x: 3.233652115, y: -5.698139191, label: 1, color: '#c23740' },
  { x: 4.655690193, y: -5.189651489, label: 1, color: '#c23740' },
  { x: 3.303806305, y: -5.85447979, label: 1, color: '#c23740' },
  { x: 5.185583115, y: -6.288862228, label: 1, color: '#c23740' },
  { x: 2.010065079, y: -6.764659882, label: 1, color: '#c23740' },
  { x: 3.079040527, y: -5.501556396, label: 1, color: '#c23740' },
  { x: 3.599464417, y: -6.806518555, label: 1, color: '#c23740' },
  { x: 6.204807281, y: -5.283631325, label: 1, color: '#c23740' },
  { x: 1.597458839, y: -7.022305489, label: 1, color: '#c23740' },
  { x: 5.597362518, y: -4.400902748, label: 1, color: '#c23740' },
  { x: 2.108816147, y: 7.007003784, label: 2, color: '#c3b96f' },
  { x: 0.054038525, y: 5.943887711, label: 2, color: '#c3b96f' },
  { x: 4.717384338, y: -6.312969208, label: 1, color: '#c23740' },
  { x: 2.938682556, y: -6.309251785, label: 1, color: '#c23740' },
  { x: 5.973423958, y: -5.354185104, label: 1, color: '#c23740' },
  { x: 4.503665924, y: -5.385457993, label: 1, color: '#c23740' },
  { x: 2.74833107, y: 6.373249054, label: 2, color: '#c3b96f' },
  { x: 3.044325352, y: -5.623397827, label: 1, color: '#c23740' },
  { x: 5.173726082, y: -5.909934044, label: 1, color: '#c23740' },
  { x: 2.890742302, y: -6.235461235, label: 1, color: '#c23740' },
  { x: 2.972827911, y: -5.975799561, label: 1, color: '#c23740' },
  { x: 6.502514839, y: -5.927611351, label: 1, color: '#c23740' },
  { x: 3.238857269, y: -6.725655556, label: 1, color: '#c23740' },
  { x: 2.632885933, y: 6.705528259, label: 2, color: '#c3b96f' },
  { x: 3.322711945, y: -6.157502174, label: 1, color: '#c23740' },
  { x: 6.664886475, y: -5.48431778, label: 1, color: '#c23740' },
  { x: 5.171440125, y: -5.905256271, label: 1, color: '#c23740' },
  { x: 4.073023796, y: -6.807269096, label: 1, color: '#c23740' },
  { x: 0.523694038, y: 6.20384407, label: 2, color: '#c3b96f' },
  { x: 4.192554474, y: -5.391306877, label: 1, color: '#c23740' },
  { x: 4.067328453, y: -4.609231949, label: 1, color: '#c23740' },
  { x: 6.961769104, y: -5.361652374, label: 1, color: '#c23740' },
  { x: 1.086379051, y: 6.026205063, label: 2, color: '#c3b96f' },
  { x: 3.204951286, y: -5.835326195, label: 1, color: '#c23740' },
  { x: 3.741607666, y: -4.891597748, label: 1, color: '#c23740' },
  { x: 3.707185745, y: -6.327931404, label: 1, color: '#c23740' },
  { x: 2.913141251, y: -5.577809334, label: 1, color: '#c23740' },
  { x: 1.400216103, y: 6.112495422, label: 2, color: '#c3b96f' },
  { x: 2.554168701, y: -6.142910004, label: 1, color: '#c23740' },
  { x: 2.495821953, y: 6.380827904, label: 2, color: '#c3b96f' },
  { x: 3.519720078, y: -5.143079758, label: 1, color: '#c23740' },
  { x: 0.582855701, y: 6.265995026, label: 2, color: '#c3b96f' },
  { x: 6.69100666, y: -4.726490021, label: 1, color: '#c23740' },
  { x: 3.708147049, y: -4.93161869, label: 1, color: '#c23740' },
  { x: 1.916355133, y: -6.703061104, label: 1, color: '#c23740' },
  { x: 4.027446747, y: -4.889827728, label: 1, color: '#c23740' },
  { x: -0.069497108, y: 6.771519661, label: 2, color: '#c3b96f' },
  { x: 2.525253296, y: 6.408053398, label: 2, color: '#c3b96f' },
  { x: 2.639333725, y: 6.985103607, label: 2, color: '#c3b96f' },
  { x: 6.045286179, y: -3.952085495, label: 1, color: '#c23740' },
  { x: 4.343851089, y: -4.722309113, label: 1, color: '#c23740' },
  { x: 2.163208008, y: 6.758687973, label: 2, color: '#c3b96f' },
  { x: 2.200021744, y: 6.659068108, label: 2, color: '#c3b96f' },
  { x: 2.786055565, y: 5.876155853, label: 2, color: '#c3b96f' },
  { x: 1.979200363, y: -6.608760834, label: 1, color: '#c23740' },
  { x: 2.326235771, y: -6.936727524, label: 1, color: '#c23740' },
  { x: 5.138760567, y: -4.473198891, label: 1, color: '#c23740' },
  { x: 2.328790665, y: 6.664338112, label: 2, color: '#c3b96f' },
  { x: 1.522366524, y: 6.246730804, label: 2, color: '#c3b96f' },
  { x: 2.472121239, y: 6.825239182, label: 2, color: '#c3b96f' },
  { x: 1.959943771, y: -6.606038094, label: 1, color: '#c23740' },
  { x: 2.441843033, y: 6.799598694, label: 2, color: '#c3b96f' },
  { x: 4.345496178, y: -4.905058861, label: 1, color: '#c23740' },
  { x: 1.294111252, y: 5.853895187, label: 2, color: '#c3b96f' },
  { x: 2.961306572, y: -6.088236809, label: 1, color: '#c23740' },
  { x: 4.801067352, y: -6.479487419, label: 1, color: '#c23740' },
  { x: 6.06560421, y: -3.54058075, label: 1, color: '#c23740' },
  { x: 4.388523102, y: -6.680083275, label: 1, color: '#c23740' },
  { x: 4.52436924, y: -4.957826614, label: 1, color: '#c23740' },
  { x: 4.615974426, y: -5.855775833, label: 1, color: '#c23740' },
  { x: 2.258550644, y: 6.230852127, label: 2, color: '#c3b96f' },
  { x: 2.806395531, y: 6.870268822, label: 2, color: '#c3b96f' },
  { x: 2.394277573, y: -6.64815712, label: 1, color: '#c23740' },
  { x: 4.195249557, y: -5.747414589, label: 1, color: '#c23740' },
  { x: 3.362060547, y: -6.688776016, label: 1, color: '#c23740' },
  { x: 0.582245827, y: 6.330625534, label: 2, color: '#c3b96f' },
  { x: 4.790998459, y: -6.60140419, label: 1, color: '#c23740' },
  { x: 5.852111816, y: -4.134740829, label: 1, color: '#c23740' },
  { x: 3.63878727, y: -6.890022278, label: 1, color: '#c23740' },
  { x: 2.225664139, y: 6.223449707, label: 2, color: '#c3b96f' },
  { x: 5.948371887, y: -4.083208084, label: 1, color: '#c23740' },
  { x: 6.692286491, y: -5.88101387, label: 1, color: '#c23740' },
  { x: 5.481642723, y: -4.581433296, label: 1, color: '#c23740' },
  { x: 5.167764664, y: -5.852394104, label: 1, color: '#c23740' },
  { x: 3.660004616, y: -5.229996681, label: 1, color: '#c23740' },
  { x: 6.262343407, y: -5.108517647, label: 1, color: '#c23740' },
  { x: 5.375374794, y: -5.515079498, label: 1, color: '#c23740' },
  { x: 6.151123047, y: -3.898672104, label: 1, color: '#c23740' },
  { x: 5.597547531, y: -4.075773239, label: 1, color: '#c23740' },
  { x: 6.249172211, y: -4.695981026, label: 1, color: '#c23740' },
  { x: 5.518434525, y: -3.582159996, label: 1, color: '#c23740' },
  { x: 5.193827629, y: -3.79908371, label: 1, color: '#c23740' },
  { x: 5.186585426, y: -6.592281342, label: 1, color: '#c23740' },
  { x: 5.255050659, y: -4.321628571, label: 1, color: '#c23740' },
  { x: 5.292297363, y: -4.749940872, label: 1, color: '#c23740' },
  { x: 4.909700394, y: -4.042645454, label: 1, color: '#c23740' },
  { x: 5.974505424, y: -3.623514175, label: 1, color: '#c23740' },
  { x: 6.558428764, y: -4.992668152, label: 1, color: '#c23740' },
  { x: 5.38276577, y: -4.397083282, label: 1, color: '#c23740' },
  { x: 5.493452072, y: -3.884124756, label: 1, color: '#c23740' },
  { x: 6.579738617, y: -5.350631714, label: 1, color: '#c23740' },
  { x: 4.455684662, y: -6.205253601, label: 1, color: '#c23740' },
  { x: 5.210206985, y: -4.237095833, label: 1, color: '#c23740' },
  { x: 5.155879021, y: -4.767873764, label: 1, color: '#c23740' },
  { x: 6.266370773, y: -5.880405426, label: 1, color: '#c23740' },
  { x: 6.064697266, y: -3.898597717, label: 1, color: '#c23740' },
  { x: 4.872468948, y: -4.356134415, label: 1, color: '#c23740' },
  { x: 6.346876144, y: -4.501083374, label: 1, color: '#c23740' },
  { x: 5.323900223, y: -3.978811264, label: 1, color: '#c23740' },
  { x: 4.81562233, y: -4.484107971, label: 1, color: '#c23740' },
  { x: 4.124858856, y: -5.405706406, label: 1, color: '#c23740' },
  { x: 6.433232307, y: -4.730858326, label: 1, color: '#c23740' },
  { x: 6.39974308, y: -4.837979317, label: 1, color: '#c23740' },
  { x: 5.809316635, y: -4.027981758, label: 1, color: '#c23740' },
  { x: 5.67847538, y: -4.662068367, label: 1, color: '#c23740' },
  { x: 6.156229019, y: -5.341740608, label: 1, color: '#c23740' },
  { x: 6.602087021, y: -5.604578018, label: 1, color: '#c23740' },
  { x: 6.531093597, y: -4.471993446, label: 1, color: '#c23740' },
  { x: 6.713984489, y: -5.8317976, label: 1, color: '#c23740' },
  { x: 5.682315826, y: -4.458872795, label: 1, color: '#c23740' },
  { x: 0.66769886, y: 5.628465652, label: 2, color: '#c3b96f' },
  { x: 4.594857216, y: -5.178494453, label: 1, color: '#c23740' },
  { x: 5.195350647, y: -5.34253788, label: 1, color: '#c23740' },
  { x: 5.47413063, y: -5.066856384, label: 1, color: '#c23740' },
  { x: 5.81504631, y: -4.098964691, label: 1, color: '#c23740' },
  { x: 3.7436409, y: -5.030292511, label: 1, color: '#c23740' },
  { x: 4.006462097, y: -5.622578621, label: 1, color: '#c23740' },
  { x: 4.874415398, y: -6.169645309, label: 1, color: '#c23740' },
  { x: 6.000179291, y: -4.481445313, label: 1, color: '#c23740' },
  { x: 5.210211754, y: -5.589338303, label: 1, color: '#c23740' },
  { x: 5.31784153, y: -5.190710068, label: 1, color: '#c23740' },
  { x: 3.35370636, y: -5.890467644, label: 1, color: '#c23740' },
  { x: 3.79483223, y: -5.643234253, label: 1, color: '#c23740' },
  { x: 6.00014019, y: -4.662842751, label: 1, color: '#c23740' },
  { x: 5.180933952, y: -5.894548416, label: 1, color: '#c23740' },
  { x: 4.296801567, y: -5.728208542, label: 1, color: '#c23740' },
  { x: 6.21559906, y: -3.922430038, label: 1, color: '#c23740' },
  { x: 6.385728836, y: -4.722976685, label: 1, color: '#c23740' },
  { x: 6.253430367, y: -4.516026497, label: 1, color: '#c23740' },
  { x: 5.667836189, y: -4.789422035, label: 1, color: '#c23740' },
  { x: 5.014122009, y: -5.522705078, label: 1, color: '#c23740' },
  { x: 6.096138, y: -5.868257523, label: 1, color: '#c23740' },
  { x: 6.550217628, y: -5.401679039, label: 1, color: '#c23740' },
  { x: 6.807844162, y: -5.217198372, label: 1, color: '#c23740' },
  { x: 6.207092285, y: -5.768654823, label: 1, color: '#c23740' },
  { x: 4.286577225, y: -5.95255661, label: 1, color: '#c23740' },
  { x: 6.545536995, y: -5.583271027, label: 1, color: '#c23740' },
  { x: 4.225845337, y: -6.178627968, label: 1, color: '#c23740' },
  { x: 5.033157349, y: -5.360505104, label: 1, color: '#c23740' },
  { x: 4.284742355, y: -6.793896675, label: 1, color: '#c23740' },
  { x: 4.018568993, y: -6.779001236, label: 1, color: '#c23740' },
  { x: 4.478520393, y: -5.735990524, label: 1, color: '#c23740' },
  { x: 0.484016418, y: 5.699975967, label: 2, color: '#c3b96f' },
  { x: 6.364729881, y: -5.185461044, label: 1, color: '#c23740' },
  { x: 6.625972748, y: -5.642311096, label: 1, color: '#c23740' },
  { x: 4.740251541, y: -6.482730865, label: 1, color: '#c23740' },
  { x: 5.952226639, y: -3.957901001, label: 1, color: '#c23740' },
  { x: 6.706352234, y: -5.775205612, label: 1, color: '#c23740' },
  { x: 6.665914536, y: -4.294698715, label: 1, color: '#c23740' },
  { x: 5.204238892, y: -5.391059875, label: 1, color: '#c23740' },
  { x: 6.073783875, y: -6.25661087, label: 1, color: '#c23740' },
  { x: 6.051568985, y: -5.293510437, label: 1, color: '#c23740' },
  { x: 4.280656815, y: -5.600419998, label: 1, color: '#c23740' },
  { x: -0.123944283, y: 6.095533371, label: 2, color: '#c3b96f' },
  { x: 6.225547791, y: -4.096529007, label: 1, color: '#c23740' },
  { x: 3.610083103, y: -5.860679626, label: 1, color: '#c23740' },
  { x: 0.364650726, y: 5.756845474, label: 2, color: '#c3b96f' },
  { x: 5.873151779, y: -4.496152878, label: 1, color: '#c23740' },
  { x: 3.505313873, y: -5.743603706, label: 1, color: '#c23740' },
  { x: 6.218869209, y: -3.988752365, label: 1, color: '#c23740' },
  { x: 5.388843536, y: -6.179338455, label: 1, color: '#c23740' },
  { x: 6.492519379, y: -4.82824707, label: 1, color: '#c23740' },
  { x: 4.981987953, y: -6.588752747, label: 1, color: '#c23740' },
  { x: 0.834001541, y: 5.585115433, label: 2, color: '#c3b96f' },
  { x: -0.212505341, y: 6.128074646, label: 2, color: '#c3b96f' },
  { x: 6.865790367, y: -5.407879829, label: 1, color: '#c23740' },
  { x: 3.521717072, y: -5.305774689, label: 1, color: '#c23740' },
  { x: 4.241837502, y: -4.866419792, label: 1, color: '#c23740' },
  { x: 0.923700333, y: 5.598352432, label: 2, color: '#c3b96f' },
  { x: 4.941585541, y: -6.509906769, label: 1, color: '#c23740' },
  { x: 5.833409309, y: -5.427786827, label: 1, color: '#c23740' },
  { x: 4.270055771, y: -4.6979599, label: 1, color: '#c23740' },
  { x: 6.073238373, y: -4.81962204, label: 1, color: '#c23740' },
  { x: 4.885004044, y: -4.43497467, label: 1, color: '#c23740' },
  { x: 3.476492882, y: -5.515760422, label: 1, color: '#c23740' },
  { x: 2.112474442, y: 5.646847248, label: 2, color: '#c3b96f' },
  { x: 3.389324188, y: -5.684553146, label: 1, color: '#c23740' },
  { x: 5.953115463, y: -5.930253983, label: 1, color: '#c23740' },
  { x: 5.224347115, y: -6.620967865, label: 1, color: '#c23740' },
  { x: 0.264923096, y: 6.213168144, label: 2, color: '#c3b96f' },
  { x: 6.28591156, y: -5.203464508, label: 1, color: '#c23740' },
  { x: -0.259736538, y: 6.326263428, label: 2, color: '#c3b96f' },
  { x: 3.325750351, y: -5.032905579, label: 1, color: '#c23740' },
  { x: 5.824037552, y: -3.714363098, label: 1, color: '#c23740' },
  { x: 5.153873444, y: -5.845763206, label: 1, color: '#c23740' },
  { x: 3.39359951, y: -5.246972084, label: 1, color: '#c23740' },
  { x: 4.824696541, y: -4.194948196, label: 1, color: '#c23740' },
  { x: 6.610830307, y: -5.38079071, label: 1, color: '#c23740' },
  { x: 3.081830025, y: -6.37788105, label: 1, color: '#c23740' },
  { x: 6.352385521, y: -5.487678528, label: 1, color: '#c23740' },
  { x: 5.882972717, y: -4.36630249, label: 1, color: '#c23740' },
  { x: 5.309958458, y: -4.230962753, label: 1, color: '#c23740' },
  { x: 5.035563469, y: -4.961015701, label: 1, color: '#c23740' },
  { x: 6.758062363, y: -5.589668274, label: 1, color: '#c23740' },
  { x: 5.079763412, y: -4.23201561, label: 1, color: '#c23740' },
  { x: 5.923528671, y: -5.189175606, label: 1, color: '#c23740' },
  { x: 4.70930481, y: -4.00283432, label: 1, color: '#c23740' },
  { x: 1.944019318, y: -6.777967453, label: 1, color: '#c23740' },
  { x: 3.948057175, y: -4.438488007, label: 1, color: '#c23740' },
  { x: 6.734601021, y: -5.799100876, label: 1, color: '#c23740' },
  { x: 5.141857147, y: -3.871759415, label: 1, color: '#c23740' },
  { x: 5.771627426, y: -4.242416382, label: 1, color: '#c23740' },
  { x: 5.61434269, y: -3.82852459, label: 1, color: '#c23740' },
  { x: 2.001715183, y: -6.675601959, label: 1, color: '#c23740' },
  { x: 3.054146767, y: -5.163619041, label: 1, color: '#c23740' },
  { x: 5.949263573, y: -3.745346069, label: 1, color: '#c23740' },
  { x: 0.009202003, y: 6.135329247, label: 2, color: '#c3b96f' },
  { x: 3.538197041, y: -5.124085426, label: 1, color: '#c23740' },
  { x: 3.418548584, y: -5.413948059, label: 1, color: '#c23740' },
  { x: 0.847570419, y: 5.499625206, label: 2, color: '#c3b96f' },
  { x: 0.688738823, y: 5.53899765, label: 2, color: '#c3b96f' },
  { x: 5.813029289, y: -5.2548666, label: 1, color: '#c23740' },
  { x: 0.422632217, y: 5.386134624, label: 2, color: '#c3b96f' },
  { x: 4.073763847, y: -6.452802658, label: 1, color: '#c23740' },
  { x: 6.363681793, y: -4.647984505, label: 1, color: '#c23740' },
  { x: 5.740998268, y: -6.247123718, label: 1, color: '#c23740' },
  { x: 4.186227798, y: -4.931933403, label: 1, color: '#c23740' },
  { x: 5.970819473, y: -3.704721451, label: 1, color: '#c23740' },
  { x: 4.279572487, y: -6.106764793, label: 1, color: '#c23740' },
  { x: 0.102952957, y: 6.018354416, label: 2, color: '#c3b96f' },
  { x: 4.566578865, y: -4.719944954, label: 1, color: '#c23740' },
  { x: 0.766887665, y: 5.318548203, label: 2, color: '#c3b96f' },
  { x: 5.258390427, y: -4.747310638, label: 1, color: '#c23740' },
  { x: 5.505773544, y: -5.617526054, label: 1, color: '#c23740' },
  { x: 4.056306839, y: -4.850894928, label: 1, color: '#c23740' },
  { x: 5.187517166, y: -4.069229126, label: 1, color: '#c23740' },
  { x: 5.549531937, y: -4.213952065, label: 1, color: '#c23740' },
  { x: 4.119745255, y: -4.569412231, label: 1, color: '#c23740' },
  { x: 4.100034714, y: -5.242652893, label: 1, color: '#c23740' },
  { x: 6.017682076, y: -3.753873825, label: 1, color: '#c23740' },
  { x: 5.828029633, y: -3.494019508, label: 1, color: '#c23740' },
  { x: 5.972902298, y: -3.797446251, label: 1, color: '#c23740' },
  { x: 6.049549103, y: -4.546920776, label: 1, color: '#c23740' },
  { x: 3.221870422, y: -5.951194763, label: 1, color: '#c23740' },
  { x: 5.530529976, y: -3.930192947, label: 1, color: '#c23740' },
  { x: 4.635058403, y: -4.114364624, label: 1, color: '#c23740' },
  { x: 5.841409683, y: -4.143960953, label: 1, color: '#c23740' },
  { x: 4.036746979, y: -4.557136536, label: 1, color: '#c23740' },
  { x: 0.79892683, y: 5.41787529, label: 2, color: '#c3b96f' },
  { x: 4.296525955, y: -4.634870529, label: 1, color: '#c23740' },
  { x: 3.976241112, y: -4.661588669, label: 1, color: '#c23740' },
  { x: 0.586688042, y: 5.581185341, label: 2, color: '#c3b96f' },
  { x: 3.624850273, y: -5.352136612, label: 1, color: '#c23740' },
  { x: 0.998465538, y: 5.424879551, label: 2, color: '#c3b96f' },
  { x: 5.494950294, y: -3.688056946, label: 1, color: '#c23740' },
  { x: 3.684446335, y: -5.235023499, label: 1, color: '#c23740' },
  { x: 4.666891098, y: -4.428666115, label: 1, color: '#c23740' },
  { x: -0.297375202, y: 6.263339996, label: 2, color: '#c3b96f' },
  { x: 3.469332695, y: -4.851482391, label: 1, color: '#c23740' },
  { x: 0.150792599, y: 6.041595459, label: 2, color: '#c3b96f' },
  { x: 0.227277756, y: 6.329787254, label: 2, color: '#c3b96f' },
  { x: 5.373427391, y: -3.898702621, label: 1, color: '#c23740' },
  { x: 4.962552071, y: -4.070859909, label: 1, color: '#c23740' },
  { x: 1.56362915, y: 5.70909214, label: 2, color: '#c3b96f' },
  { x: 2.248702049, y: 5.829125404, label: 2, color: '#c3b96f' },
  { x: 1.948505402, y: 5.949466705, label: 2, color: '#c3b96f' },
  { x: 4.487818718, y: -4.941303253, label: 1, color: '#c23740' },
  { x: 4.888118744, y: -3.942565918, label: 1, color: '#c23740' },
  { x: 2.07788372, y: 5.732394218, label: 2, color: '#c3b96f' },
  { x: 4.082145691, y: -4.706840515, label: 1, color: '#c23740' },
  { x: 1.841346741, y: 5.857732773, label: 2, color: '#c3b96f' },
  { x: 6.234512329, y: -3.856109619, label: 1, color: '#c23740' },
  { x: -0.102419853, y: 6.3250494, label: 2, color: '#c3b96f' },
  { x: 4.305664063, y: -4.981956482, label: 1, color: '#c23740' },
  { x: 3.306505203, y: -6.086986542, label: 1, color: '#c23740' },
  { x: 2.778261185, y: -5.903017998, label: 1, color: '#c23740' },
  { x: 5.742324829, y: -3.708524704, label: 1, color: '#c23740' },
  { x: 2.626431465, y: 7.550790787, label: 2, color: '#c3b96f' },
  { x: 3.021178246, y: -5.429826736, label: 1, color: '#c23740' },
  { x: 3.945203781, y: -4.885194778, label: 1, color: '#c23740' },
  { x: 5.39449501, y: -4.496417046, label: 1, color: '#c23740' },
  { x: 6.201145172, y: -4.052418232, label: 1, color: '#c23740' },
  { x: 1.374582291, y: 6.008313179, label: 2, color: '#c3b96f' },
  { x: 1.973981857, y: 6.644889832, label: 2, color: '#c3b96f' },
  { x: 4.89561367, y: -4.104096413, label: 1, color: '#c23740' },
  { x: 1.758530617, y: 5.685428619, label: 2, color: '#c3b96f' },
  { x: 5.987333298, y: -4.196101189, label: 1, color: '#c23740' },
  { x: 4.682696342, y: -4.919353485, label: 1, color: '#c23740' },
  { x: 2.071350098, y: 6.657198906, label: 2, color: '#c3b96f' },
  { x: 6.051553726, y: -4.241974831, label: 1, color: '#c23740' },
  { x: 1.258440018, y: 6.309479713, label: 2, color: '#c3b96f' },
  { x: 2.586502075, y: 5.813999176, label: 2, color: '#c3b96f' },
  { x: 5.950147629, y: -3.80368042, label: 1, color: '#c23740' },
  { x: 5.699397087, y: -3.829626083, label: 1, color: '#c23740' },
  { x: 1.705186844, y: 5.76896286, label: 2, color: '#c3b96f' },
  { x: 5.472907066, y: -4.688058853, label: 1, color: '#c23740' },
  { x: 5.656745911, y: -3.59462738, label: 1, color: '#c23740' },
  { x: 2.386292458, y: 6.315131187, label: 2, color: '#c3b96f' },
  { x: 2.677950859, y: 7.380326271, label: 2, color: '#c3b96f' },
  { x: 5.255915642, y: -3.859633446, label: 1, color: '#c23740' },
  { x: 5.304502487, y: -4.319034576, label: 1, color: '#c23740' },
  { x: 4.441462517, y: -5.375465393, label: 1, color: '#c23740' },
  { x: 5.106653214, y: -3.843956947, label: 1, color: '#c23740' },
  { x: 4.786231995, y: -3.930990219, label: 1, color: '#c23740' },
  { x: 5.065910339, y: -4.352199554, label: 1, color: '#c23740' },
  { x: 4.629167557, y: -4.40511322, label: 1, color: '#c23740' },
  { x: 6.25371933, y: -3.852842331, label: 1, color: '#c23740' },
  { x: 2.850593567, y: 5.912116051, label: 2, color: '#c3b96f' },
  { x: 4.153225899, y: -4.485687256, label: 1, color: '#c23740' },
  { x: 3.925014496, y: -4.716513634, label: 1, color: '#c23740' },
  { x: 2.460419655, y: 7.148785591, label: 2, color: '#c3b96f' },
  { x: 4.154634476, y: -5.674604416, label: 1, color: '#c23740' },
  { x: 5.901453018, y: -4.767647743, label: 1, color: '#c23740' },
  { x: 3.153962135, y: -5.860901833, label: 1, color: '#c23740' },
  { x: 6.219232559, y: -4.110331535, label: 1, color: '#c23740' },
  { x: 1.761717796, y: 5.736460686, label: 2, color: '#c3b96f' },
  { x: 2.546396255, y: 7.004426956, label: 2, color: '#c3b96f' },
  { x: 3.513662338, y: -6.006422997, label: 1, color: '#c23740' },
  { x: 5.172649384, y: -4.148635864, label: 1, color: '#c23740' },
  { x: 4.444566727, y: -4.646417618, label: 1, color: '#c23740' },
  { x: -8.792182922, y: 2.576872826, label: 3, color: '#35f870' },
  { x: 1.235430717, y: 5.761800766, label: 2, color: '#c3b96f' },
  { x: 3.41147995, y: -6.102365494, label: 1, color: '#c23740' },
  { x: 6.574795723, y: -4.967216492, label: 1, color: '#c23740' },
  { x: 5.122489929, y: -4.340339661, label: 1, color: '#c23740' },
  { x: 5.958005905, y: -4.445381165, label: 1, color: '#c23740' },
  { x: 3.016370773, y: -5.894620895, label: 1, color: '#c23740' },
  { x: 5.816753387, y: -3.949999809, label: 1, color: '#c23740' },
  { x: 4.222138405, y: -4.719871521, label: 1, color: '#c23740' },
  { x: 2.488883018, y: 6.491250038, label: 2, color: '#c3b96f' },
  { x: 4.591942787, y: -5.413434982, label: 1, color: '#c23740' },
  { x: 4.827959061, y: -4.541324615, label: 1, color: '#c23740' },
  { x: 3.636303902, y: -4.844985962, label: 1, color: '#c23740' },
  { x: 4.923941612, y: -4.62670517, label: 1, color: '#c23740' },
  { x: 6.224017143, y: -4.670906067, label: 1, color: '#c23740' },
  { x: 5.531569481, y: -5.196939468, label: 1, color: '#c23740' },
  { x: 1.16850853, y: 5.874209404, label: 2, color: '#c3b96f' },
  { x: 4.220006943, y: -4.701179504, label: 1, color: '#c23740' },
  { x: 6.205430984, y: -4.311922073, label: 1, color: '#c23740' },
  { x: 4.017940521, y: -4.802565575, label: 1, color: '#c23740' },
  { x: 2.783238411, y: -5.849953651, label: 1, color: '#c23740' },
  { x: 4.521238327, y: -4.870271683, label: 1, color: '#c23740' },
  { x: 4.510947227, y: -4.438022614, label: 1, color: '#c23740' },
  { x: 2.608883858, y: -5.92376709, label: 1, color: '#c23740' },
  { x: 6.285792351, y: -4.473509789, label: 1, color: '#c23740' },
  { x: 5.850571632, y: -3.642663956, label: 1, color: '#c23740' },
  { x: 5.615850449, y: -3.604089737, label: 1, color: '#c23740' },
  { x: 1.626812935, y: 6.107815742, label: 2, color: '#c3b96f' },
  { x: 4.751407623, y: -4.22884655, label: 1, color: '#c23740' },
  { x: 5.730581284, y: -3.580278397, label: 1, color: '#c23740' },
  { x: 6.283520699, y: -4.103170395, label: 1, color: '#c23740' },
  { x: 6.135634422, y: -5.337637901, label: 1, color: '#c23740' },
  { x: 5.030118942, y: -3.783238411, label: 1, color: '#c23740' },
  { x: 6.726039886, y: -5.604406357, label: 1, color: '#c23740' },
  { x: 2.16411972, y: 6.523384094, label: 2, color: '#c3b96f' },
  { x: 4.958360672, y: -4.119649887, label: 1, color: '#c23740' },
  { x: 5.432307243, y: -3.774927139, label: 1, color: '#c23740' },
  { x: 6.247789383, y: -4.248952866, label: 1, color: '#c23740' },
  { x: 3.182497978, y: 5.826313972, label: 2, color: '#c3b96f' },
  { x: 1.093193054, y: 5.727911949, label: 2, color: '#c3b96f' },
  { x: 0.05694294, y: 6.08072567, label: 2, color: '#c3b96f' },
  { x: 2.032054901, y: 6.895717621, label: 2, color: '#c3b96f' },
  { x: 2.991369247, y: 5.876972198, label: 2, color: '#c3b96f' },
  { x: 6.083285332, y: -3.134448051, label: 1, color: '#c23740' },
  { x: -0.06978035, y: 6.175595284, label: 2, color: '#c3b96f' },
  { x: 3.79713726, y: -4.63785553, label: 1, color: '#c23740' },
  { x: 3.132457733, y: 5.854909897, label: 2, color: '#c3b96f' },
  { x: 1.607297897, y: 5.736493111, label: 2, color: '#c3b96f' },
  { x: 3.023770332, y: 6.035678864, label: 2, color: '#c3b96f' },
  { x: 6.021459579, y: -5.10778904, label: 1, color: '#c23740' },
  { x: 2.384560585, y: -6.169079781, label: 1, color: '#c23740' },
  { x: 2.513910294, y: 6.008264542, label: 2, color: '#c3b96f' },
  { x: 3.108491898, y: -5.30355835, label: 1, color: '#c23740' },
  { x: 5.324094772, y: -4.34736824, label: 1, color: '#c23740' },
  { x: 5.442560196, y: -4.765687943, label: 1, color: '#c23740' },
  { x: 2.099839211, y: 5.791184425, label: 2, color: '#c3b96f' },
  { x: 2.348249435, y: 6.491073608, label: 2, color: '#c3b96f' },
  { x: 4.580420494, y: -4.74706459, label: 1, color: '#c23740' },
  { x: 4.736724854, y: -4.896438599, label: 1, color: '#c23740' },
  { x: 2.205190659, y: 5.827974319, label: 2, color: '#c3b96f' },
  { x: 3.259471893, y: 5.774972916, label: 2, color: '#c3b96f' },
  { x: 5.727684021, y: -4.918136597, label: 1, color: '#c23740' },
  { x: 2.124396324, y: -6.431455612, label: 1, color: '#c23740' },
  { x: 2.072480202, y: -7.174636841, label: 1, color: '#c23740' },
  { x: 5.153398514, y: -4.318741798, label: 1, color: '#c23740' },
  { x: 2.202589989, y: 5.941942215, label: 2, color: '#c3b96f' },
  { x: 3.027996063, y: -5.635726929, label: 1, color: '#c23740' },
  { x: 3.087471962, y: 5.88511467, label: 2, color: '#c3b96f' },
  { x: 2.569383621, y: -6.128678322, label: 1, color: '#c23740' },
  { x: 2.728169441, y: -5.727020264, label: 1, color: '#c23740' },
  { x: 2.401368141, y: 5.994155884, label: 2, color: '#c3b96f' },
  { x: 5.859045029, y: -3.560340881, label: 1, color: '#c23740' },
  { x: 2.638270378, y: 6.020400047, label: 2, color: '#c3b96f' },
  { x: 2.778619766, y: 6.014526367, label: 2, color: '#c3b96f' },
  { x: 2.667086601, y: 7.048892975, label: 2, color: '#c3b96f' },
  { x: 2.522113323, y: 6.416885376, label: 2, color: '#c3b96f' },
  { x: 3.157505035, y: 5.838102341, label: 2, color: '#c3b96f' },
  { x: 2.939915657, y: 5.948898315, label: 2, color: '#c3b96f' },
  { x: 2.384346008, y: 5.853124619, label: 2, color: '#c3b96f' },
  { x: 2.256666183, y: 6.673273087, label: 2, color: '#c3b96f' },
  { x: 3.392598152, y: -5.084485054, label: 1, color: '#c23740' },
  { x: 1.737312794, y: 5.717479706, label: 2, color: '#c3b96f' },
  { x: 2.934418678, y: 7.678180695, label: 2, color: '#c3b96f' },
  { x: 5.08608532, y: -5.938596725, label: 1, color: '#c23740' },
  { x: 2.749166489, y: 5.917455673, label: 2, color: '#c3b96f' },
  { x: 3.024834633, y: 5.984615326, label: 2, color: '#c3b96f' },
  { x: 5.470050812, y: -3.612882614, label: 1, color: '#c23740' },
  { x: 2.379610062, y: -6.385909081, label: 1, color: '#c23740' },
  { x: 6.412099838, y: -4.33894825, label: 1, color: '#c23740' },
  { x: 4.793732643, y: -5.235702515, label: 1, color: '#c23740' },
  { x: 2.014220238, y: 5.743391037, label: 2, color: '#c3b96f' },
  { x: 2.764831543, y: 5.945832253, label: 2, color: '#c3b96f' },
  { x: 2.522821426, y: 6.105220795, label: 2, color: '#c3b96f' },
  { x: 5.681333542, y: -3.653268814, label: 1, color: '#c23740' },
  { x: 6.414089203, y: -4.939353943, label: 1, color: '#c23740' },
  { x: 2.75466156, y: 5.91740036, label: 2, color: '#c3b96f' },
  { x: 3.163326263, y: -5.228149414, label: 1, color: '#c23740' },
  { x: 5.160679817, y: -3.88352108, label: 1, color: '#c23740' },
  { x: 2.077510834, y: 5.781387329, label: 2, color: '#c3b96f' },
  { x: 2.769790649, y: 5.955949783, label: 2, color: '#c3b96f' },
  { x: 2.84001255, y: 5.991295815, label: 2, color: '#c3b96f' },
  { x: 3.051250458, y: -5.842106819, label: 1, color: '#c23740' },
  { x: 3.744192123, y: -6.523393631, label: 1, color: '#c23740' },
  { x: 5.379888535, y: -6.059705734, label: 1, color: '#c23740' },
  { x: 4.444499969, y: -4.402414322, label: 1, color: '#c23740' },
  { x: 0.184141159, y: 6.192982674, label: 2, color: '#c3b96f' },
  { x: 3.087505341, y: 5.84749794, label: 2, color: '#c3b96f' },
  { x: 2.355511665, y: -7.122817039, label: 1, color: '#c23740' },
  { x: 3.158374786, y: 5.842957497, label: 2, color: '#c3b96f' },
  { x: 3.157657623, y: 5.889193535, label: 2, color: '#c3b96f' },
  { x: 2.84734726, y: 5.911597252, label: 2, color: '#c3b96f' },
  { x: 1.85376358, y: 5.903533936, label: 2, color: '#c3b96f' },
  { x: 2.650620461, y: -6.92737484, label: 1, color: '#c23740' },
  { x: 4.030361176, y: -5.533226013, label: 1, color: '#c23740' },
  { x: 2.104438782, y: 5.891201973, label: 2, color: '#c3b96f' },
  { x: 3.234013557, y: 5.829687119, label: 2, color: '#c3b96f' },
  { x: 2.715685844, y: 6.491750717, label: 2, color: '#c3b96f' },
  { x: 5.658503532, y: -3.699436188, label: 1, color: '#c23740' },
  { x: 1.959512711, y: 6.082443237, label: 2, color: '#c3b96f' },
  { x: 2.747192383, y: 6.965547562, label: 2, color: '#c3b96f' },
  { x: 2.186045647, y: 5.795080185, label: 2, color: '#c3b96f' },
  { x: 5.853960991, y: -3.632579803, label: 1, color: '#c23740' },
  { x: 3.551280975, y: -4.991745949, label: 1, color: '#c23740' },
  { x: 2.937693596, y: 5.962790489, label: 2, color: '#c3b96f' },
  { x: 3.313941956, y: -5.672079086, label: 1, color: '#c23740' },
  { x: 2.497055054, y: 5.939959526, label: 2, color: '#c3b96f' },
  { x: 2.643845558, y: 5.965527534, label: 2, color: '#c3b96f' },
  { x: 3.116351128, y: 5.803347588, label: 2, color: '#c3b96f' },
  { x: 3.144430161, y: 5.84059906, label: 2, color: '#c3b96f' },
  { x: 2.581586838, y: 6.103218079, label: 2, color: '#c3b96f' },
  { x: 2.850379944, y: 5.964281082, label: 2, color: '#c3b96f' },
  { x: 3.13627243, y: 5.895831108, label: 2, color: '#c3b96f' },
  { x: 1.252423286, y: 7.034017563, label: 2, color: '#c3b96f' },
  { x: 2.454400063, y: 6.074829102, label: 2, color: '#c3b96f' },
  { x: 2.582101822, y: 6.031507492, label: 2, color: '#c3b96f' },
  { x: 2.797948837, y: 6.011460304, label: 2, color: '#c3b96f' },
  { x: 4.565981865, y: -4.140830994, label: 1, color: '#c23740' },
  { x: 2.898100853, y: -5.402601242, label: 1, color: '#c23740' },
  { x: 2.485610008, y: 6.226371765, label: 2, color: '#c3b96f' },
  { x: 6.207066536, y: -3.959299088, label: 1, color: '#c23740' },
  { x: 2.896972656, y: 6.571977615, label: 2, color: '#c3b96f' },
  { x: 5.549395561, y: -4.205760956, label: 1, color: '#c23740' },
  { x: 2.522354126, y: 6.860015869, label: 2, color: '#c3b96f' },
  { x: 2.541030884, y: 6.906143188, label: 2, color: '#c3b96f' },
  { x: 2.611674309, y: 6.112926483, label: 2, color: '#c3b96f' },
  { x: 2.684785843, y: 5.87922287, label: 2, color: '#c3b96f' },
  { x: 2.409959793, y: 6.051204681, label: 2, color: '#c3b96f' },
  { x: 2.724225998, y: 6.017194748, label: 2, color: '#c3b96f' },
  { x: 4.261809349, y: -5.113101959, label: 1, color: '#c23740' },
  { x: 2.604572296, y: 5.902659416, label: 2, color: '#c3b96f' },
  { x: 3.210249424, y: -6.172149658, label: 1, color: '#c23740' },
  { x: 2.62611866, y: 7.215723038, label: 2, color: '#c3b96f' },
  { x: 2.497739792, y: 6.899111748, label: 2, color: '#c3b96f' },
  { x: 6.018516541, y: -3.619372368, label: 1, color: '#c23740' },
  { x: 1.838872433, y: 6.184913635, label: 2, color: '#c3b96f' },
  { x: 2.448030472, y: 6.074701309, label: 2, color: '#c3b96f' },
  { x: 2.589622498, y: 7.100546837, label: 2, color: '#c3b96f' },
  { x: 1.38112402, y: 6.78192997, label: 2, color: '#c3b96f' },
  { x: 3.193217278, y: 8.084154129, label: 2, color: '#c3b96f' },
  { x: 2.916496277, y: 6.062580109, label: 2, color: '#c3b96f' },
  { x: 2.65094471, y: 6.501057625, label: 2, color: '#c3b96f' },
  { x: 2.129922867, y: 6.093427658, label: 2, color: '#c3b96f' },
  { x: 2.402114868, y: 6.33972168, label: 2, color: '#c3b96f' },
  { x: 2.536693573, y: 6.429544449, label: 2, color: '#c3b96f' },
  { x: 4.16727829, y: -4.637360573, label: 1, color: '#c23740' },
  { x: 2.532481194, y: 6.413986206, label: 2, color: '#c3b96f' },
  { x: 2.697360992, y: 7.102805138, label: 2, color: '#c3b96f' },
  { x: 2.771100044, y: 6.019347191, label: 2, color: '#c3b96f' },
  { x: 5.419517517, y: -3.760288239, label: 1, color: '#c23740' },
  { x: 2.229609013, y: 6.242401123, label: 2, color: '#c3b96f' },
  { x: 2.455714226, y: 6.028659821, label: 2, color: '#c3b96f' },
  { x: 2.110879898, y: 6.353092194, label: 2, color: '#c3b96f' },
  { x: 1.325710773, y: 6.100542068, label: 2, color: '#c3b96f' },
  { x: 2.500770569, y: 6.227293015, label: 2, color: '#c3b96f' },
  { x: 2.534158707, y: 6.417980194, label: 2, color: '#c3b96f' },
  { x: -0.045173645, y: 6.369441032, label: 2, color: '#c3b96f' },
  { x: 1.935986519, y: 6.145769119, label: 2, color: '#c3b96f' },
  { x: 2.589788437, y: 5.799998283, label: 2, color: '#c3b96f' },
  { x: 6.064153671, y: -3.583487511, label: 1, color: '#c23740' },
  { x: 2.420101166, y: 6.5201931, label: 2, color: '#c3b96f' },
  { x: 1.761915207, y: 6.150813103, label: 2, color: '#c3b96f' },
  { x: 4.856225967, y: -5.268908501, label: 1, color: '#c23740' },
  { x: 2.234976768, y: 5.981531143, label: 2, color: '#c3b96f' },
  { x: 5.347590446, y: -6.11529541, label: 1, color: '#c23740' },
  { x: 1.848515987, y: 6.069442749, label: 2, color: '#c3b96f' },
  { x: 3.310200691, y: -5.399679184, label: 1, color: '#c23740' },
  { x: 3.089076042, y: 8.363109589, label: 2, color: '#c3b96f' },
  { x: 2.562413216, y: 6.764619827, label: 2, color: '#c3b96f' },
  { x: 5.376899719, y: -5.51845932, label: 1, color: '#c23740' },
  { x: 1.908973694, y: 6.718717575, label: 2, color: '#c3b96f' },
  { x: 4.325140953, y: -5.637536049, label: 1, color: '#c23740' },
  { x: 5.990337372, y: -3.68846035, label: 1, color: '#c23740' },
  { x: 2.534311295, y: -7.002589226, label: 1, color: '#c23740' },
  { x: 1.956762314, y: -6.906727791, label: 1, color: '#c23740' },
  { x: 2.66848278, y: -6.026154518, label: 1, color: '#c23740' },
  { x: 3.857336044, y: -6.279065132, label: 1, color: '#c23740' },
  { x: 6.53926754, y: -5.089623451, label: 1, color: '#c23740' },
  { x: 1.390600204, y: 5.539670467, label: 2, color: '#c3b96f' },
  { x: 3.595827103, y: -4.946489334, label: 1, color: '#c23740' },
  { x: 6.067128181, y: -3.703635216, label: 1, color: '#c23740' },
  { x: 3.415677071, y: -5.78633213, label: 1, color: '#c23740' },
  { x: 1.730282784, y: 6.27986908, label: 2, color: '#c3b96f' },
  { x: 3.219725609, y: -5.550509453, label: 1, color: '#c23740' },
  { x: 2.0511446, y: -6.886423111, label: 1, color: '#c23740' },
  { x: 2.695823669, y: -5.778633118, label: 1, color: '#c23740' },
  { x: 2.870779991, y: -5.781938553, label: 1, color: '#c23740' },
  { x: 5.248315811, y: -3.745357513, label: 1, color: '#c23740' },
  { x: 1.929033279, y: -6.658302307, label: 1, color: '#c23740' },
  { x: 5.964916229, y: -4.519106865, label: 1, color: '#c23740' },
  { x: 5.255832672, y: -5.555363655, label: 1, color: '#c23740' },
  { x: 3.971891403, y: -4.719641685, label: 1, color: '#c23740' },
  { x: 4.468147278, y: -5.438816071, label: 1, color: '#c23740' },
  { x: 4.663311005, y: -5.83996582, label: 1, color: '#c23740' },
  { x: 4.623334885, y: -4.821781158, label: 1, color: '#c23740' },
  { x: 4.964046478, y: -5.990722656, label: 1, color: '#c23740' },
  { x: 5.477890015, y: -5.49902153, label: 1, color: '#c23740' },
  { x: 4.797456741, y: -4.321706772, label: 1, color: '#c23740' },
  { x: 5.378487587, y: -5.039609909, label: 1, color: '#c23740' },
  { x: 3.974727631, y: -5.87902832, label: 1, color: '#c23740' },
  { x: 5.999921799, y: -5.552303314, label: 1, color: '#c23740' },
  { x: 3.610145569, y: -6.157327652, label: 1, color: '#c23740' },
  { x: 5.086589813, y: -5.704296112, label: 1, color: '#c23740' },
  { x: 2.000650883, y: -6.538495064, label: 1, color: '#c23740' },
  { x: 2.490153313, y: -6.597389221, label: 1, color: '#c23740' },
  { x: 6.249915123, y: -4.706130981, label: 1, color: '#c23740' },
  { x: 4.968144417, y: -5.803572655, label: 1, color: '#c23740' },
  { x: 4.404522896, y: -5.884108543, label: 1, color: '#c23740' },
  { x: 4.594926834, y: -5.364870071, label: 1, color: '#c23740' },
  { x: 2.902477264, y: -5.869950294, label: 1, color: '#c23740' },
  { x: 5.561918259, y: -5.683416367, label: 1, color: '#c23740' },
  { x: 2.786992073, y: -6.179158211, label: 1, color: '#c23740' },
  { x: 4.492793083, y: -5.227579117, label: 1, color: '#c23740' },
  { x: 3.659938812, y: -5.753790855, label: 1, color: '#c23740' },
  { x: 6.439533234, y: -5.266636848, label: 1, color: '#c23740' },
  { x: 4.096971512, y: -6.331478119, label: 1, color: '#c23740' },
  { x: 6.109647751, y: -4.128555298, label: 1, color: '#c23740' },
  { x: 1.874387741, y: -6.923001289, label: 1, color: '#c23740' },
  { x: 4.088644981, y: -6.19879055, label: 1, color: '#c23740' },
  { x: 3.248886108, y: -6.347374916, label: 1, color: '#c23740' },
  { x: 6.164582253, y: -5.00374794, label: 1, color: '#c23740' },
  { x: 5.838701248, y: -4.854917526, label: 1, color: '#c23740' },
  { x: 3.917664528, y: -5.173330307, label: 1, color: '#c23740' },
  { x: 6.660736084, y: -5.532468796, label: 1, color: '#c23740' },
  { x: 3.73100853, y: -6.013825417, label: 1, color: '#c23740' },
  { x: 5.420196533, y: -5.197796822, label: 1, color: '#c23740' },
  { x: 5.773479462, y: -6.009984016, label: 1, color: '#c23740' },
  { x: 6.404636383, y: -5.573644638, label: 1, color: '#c23740' },
  { x: 2.703752518, y: -6.20411396, label: 1, color: '#c23740' },
  { x: 3.78385067, y: -6.460475922, label: 1, color: '#c23740' },
  { x: 5.204159737, y: -5.051849365, label: 1, color: '#c23740' },
  { x: 4.604028702, y: -6.518407822, label: 1, color: '#c23740' },
  { x: 3.359088898, y: -6.820611954, label: 1, color: '#c23740' },
  { x: 5.156066895, y: -4.638349533, label: 1, color: '#c23740' },
  { x: 4.503726959, y: -5.91424942, label: 1, color: '#c23740' },
  { x: 4.715911865, y: -5.423881531, label: 1, color: '#c23740' },
  { x: 3.563556671, y: -6.934606552, label: 1, color: '#c23740' },
  { x: 6.468553543, y: -5.859383583, label: 1, color: '#c23740' },
  { x: 6.261458397, y: -4.448923111, label: 1, color: '#c23740' },
  { x: 6.544629097, y: -5.456964493, label: 1, color: '#c23740' },
  { x: 4.833084106, y: -5.661284447, label: 1, color: '#c23740' },
  { x: 4.672267914, y: -5.230045319, label: 1, color: '#c23740' },
  { x: 3.943222046, y: -5.577283859, label: 1, color: '#c23740' },
  { x: 3.455084801, y: -6.037721634, label: 1, color: '#c23740' },
  { x: 2.465223312, y: -6.478924751, label: 1, color: '#c23740' },
  { x: 5.357713699, y: -6.279949188, label: 1, color: '#c23740' },
  { x: 3.435818672, y: -6.855596542, label: 1, color: '#c23740' },
  { x: 6.606334686, y: -5.008170128, label: 1, color: '#c23740' },
  { x: 6.519615173, y: -5.831504822, label: 1, color: '#c23740' },
  { x: 3.139017105, y: -6.080857277, label: 1, color: '#c23740' },
  { x: 4.92214489, y: -6.691560745, label: 1, color: '#c23740' },
  { x: 5.126070023, y: -6.094585419, label: 1, color: '#c23740' },
  { x: 3.407283783, y: -5.987508774, label: 1, color: '#c23740' },
  { x: 6.527061462, y: -6.010400772, label: 1, color: '#c23740' },
  { x: 2.795254707, y: -5.966890335, label: 1, color: '#c23740' },
  { x: 6.070605278, y: -6.187976837, label: 1, color: '#c23740' },
  { x: 3.15357399, y: -7.012386322, label: 1, color: '#c23740' },
  { x: 3.742045403, y: -6.430727005, label: 1, color: '#c23740' },
  { x: 2.263637543, y: -7.034801483, label: 1, color: '#c23740' },
  { x: 3.549523354, y: -6.855020523, label: 1, color: '#c23740' },
  { x: 5.641655922, y: -6.537227631, label: 1, color: '#c23740' },
  { x: 5.438304901, y: -3.637878418, label: 1, color: '#c23740' },
  { x: 4.71248436, y: -4.296182632, label: 1, color: '#c23740' },
  { x: 6.37485981, y: -5.771583557, label: 1, color: '#c23740' },
  { x: 2.779597282, y: -6.909896851, label: 1, color: '#c23740' },
  { x: 6.916817665, y: -5.356957436, label: 1, color: '#c23740' },
  { x: 6.75347805, y: -5.846656799, label: 1, color: '#c23740' },
  { x: 5.104471207, y: -5.869135857, label: 1, color: '#c23740' },
  { x: 5.305278778, y: -5.590568542, label: 1, color: '#c23740' },
  { x: 3.235900879, y: -6.643214226, label: 1, color: '#c23740' },
  { x: 2.52074337, y: -6.475297928, label: 1, color: '#c23740' },
  { x: 6.556441307, y: -4.753417969, label: 1, color: '#c23740' },
  { x: 6.660871506, y: -4.741707802, label: 1, color: '#c23740' },
  { x: 4.915193558, y: -5.44948101, label: 1, color: '#c23740' },
  { x: 3.482328415, y: -6.523868561, label: 1, color: '#c23740' },
  { x: 4.154941559, y: -6.74325943, label: 1, color: '#c23740' },
  { x: 6.344013214, y: -5.784978867, label: 1, color: '#c23740' },
  { x: 4.748298645, y: -5.58336544, label: 1, color: '#c23740' },
  { x: 3.130506516, y: -5.95633316, label: 1, color: '#c23740' },
  { x: 5.984060287, y: -5.106533051, label: 1, color: '#c23740' },
  { x: 6.312298775, y: -5.347381592, label: 1, color: '#c23740' },
  { x: 3.946321487, y: -6.29388237, label: 1, color: '#c23740' },
  { x: 4.255976677, y: -6.795850754, label: 1, color: '#c23740' },
  { x: 6.04838562, y: -5.993778229, label: 1, color: '#c23740' },
  { x: 3.972079277, y: -4.778839111, label: 1, color: '#c23740' },
  { x: 2.256469727, y: -6.6796875, label: 1, color: '#c23740' },
  { x: 6.73536396, y: -5.389633179, label: 1, color: '#c23740' },
  { x: 3.786952972, y: -6.84882164, label: 1, color: '#c23740' },
  { x: 5.45394516, y: -5.330820084, label: 1, color: '#c23740' },
  { x: 5.464113235, y: -5.942144394, label: 1, color: '#c23740' },
  { x: 4.370994568, y: -4.722335815, label: 1, color: '#c23740' },
  { x: 6.607862473, y: -5.786409378, label: 1, color: '#c23740' },
  { x: 2.429340363, y: -6.47118187, label: 1, color: '#c23740' },
  { x: 3.411822319, y: -6.190285683, label: 1, color: '#c23740' },
  { x: 4.88309288, y: -6.211593628, label: 1, color: '#c23740' },
  { x: 2.505559921, y: -6.95246315, label: 1, color: '#c23740' },
  { x: 6.701217651, y: -5.606473923, label: 1, color: '#c23740' },
  { x: 6.354570389, y: -5.824680328, label: 1, color: '#c23740' },
  { x: 4.107667923, y: -6.773199081, label: 1, color: '#c23740' },
  { x: 6.06960392, y: -6.195795059, label: 1, color: '#c23740' },
  { x: 4.675994873, y: -6.604117393, label: 1, color: '#c23740' },
  { x: 6.461161613, y: -5.620739937, label: 1, color: '#c23740' },
  { x: 5.660755157, y: -4.324726105, label: 1, color: '#c23740' },
  { x: 4.488977432, y: -5.217858315, label: 1, color: '#c23740' },
  { x: 4.678355217, y: -5.518121719, label: 1, color: '#c23740' },
  { x: 3.470678329, y: -5.29380703, label: 1, color: '#c23740' },
  { x: 4.504440308, y: -5.053055763, label: 1, color: '#c23740' },
  { x: 5.568833351, y: -4.841971397, label: 1, color: '#c23740' },
  { x: 3.600000381, y: -6.573770523, label: 1, color: '#c23740' },
  { x: 2.98572731, y: -5.67416954, label: 1, color: '#c23740' },
  { x: 4.357401848, y: -4.911570549, label: 1, color: '#c23740' },
  { x: 4.661785126, y: -6.715351105, label: 1, color: '#c23740' },
  { x: 6.172887802, y: -5.068941116, label: 1, color: '#c23740' },
  { x: 1.708199501, y: -6.913017273, label: 1, color: '#c23740' },
  { x: 2.844729424, y: -6.330949783, label: 1, color: '#c23740' },
  { x: 5.984035492, y: -6.141009331, label: 1, color: '#c23740' },
  { x: 6.952886581, y: -5.010848045, label: 1, color: '#c23740' },
  { x: 3.727743149, y: -6.926031113, label: 1, color: '#c23740' },
  { x: 4.38035202, y: -5.790344238, label: 1, color: '#c23740' },
  { x: 3.820762634, y: -6.052633286, label: 1, color: '#c23740' },
  { x: 3.896360397, y: -5.375904083, label: 1, color: '#c23740' },
  { x: 4.920119286, y: -6.533815384, label: 1, color: '#c23740' },
  { x: 1.765595436, y: 6.362988472, label: 2, color: '#c3b96f' },
  { x: 6.646102905, y: -5.822488785, label: 1, color: '#c23740' },
  { x: 4.354668617, y: -4.888530731, label: 1, color: '#c23740' },
  { x: 6.85118103, y: -5.754057884, label: 1, color: '#c23740' },
  { x: 2.530697823, y: 6.514561653, label: 2, color: '#c3b96f' },
  { x: 4.963745117, y: -6.074213982, label: 1, color: '#c23740' },
  { x: 6.528589249, y: -5.981510162, label: 1, color: '#c23740' },
  { x: 2.994829178, y: -6.482938766, label: 1, color: '#c23740' },
  { x: 6.405873299, y: -5.25194931, label: 1, color: '#c23740' },
  { x: 2.771711349, y: -6.031600952, label: 1, color: '#c23740' },
  { x: 6.299180984, y: -5.24055481, label: 1, color: '#c23740' },
  { x: 5.584704399, y: -5.5509758, label: 1, color: '#c23740' },
  { x: 2.029882431, y: -6.787949562, label: 1, color: '#c23740' },
  { x: 6.768788338, y: -5.560815811, label: 1, color: '#c23740' },
  { x: 5.947588921, y: -6.340313911, label: 1, color: '#c23740' },
  { x: 6.260166168, y: -5.550855637, label: 1, color: '#c23740' },
  { x: 2.947660446, y: -6.708947182, label: 1, color: '#c23740' },
  { x: 5.128879547, y: -5.080691338, label: 1, color: '#c23740' },
  { x: 5.826307297, y: -3.585378647, label: 1, color: '#c23740' },
  { x: 3.885390282, y: -4.603273392, label: 1, color: '#c23740' },
  { x: 1.420291901, y: 6.188391685, label: 2, color: '#c3b96f' },
  { x: 3.591121674, y: -4.869055748, label: 1, color: '#c23740' },
  { x: 3.86967659, y: -4.753053665, label: 1, color: '#c23740' },
  { x: 6.052509308, y: -3.724423409, label: 1, color: '#c23740' },
  { x: 4.182564735, y: -5.092048645, label: 1, color: '#c23740' },
  { x: 5.784147263, y: -3.535261154, label: 1, color: '#c23740' },
  { x: 5.387960434, y: -3.673048019, label: 1, color: '#c23740' },
  { x: 2.929718018, y: 6.093929291, label: 2, color: '#c3b96f' },
  { x: 5.764386177, y: -3.771471024, label: 1, color: '#c23740' },
  { x: -0.230172157, y: 6.257176399, label: 2, color: '#c3b96f' },
  { x: 5.398462296, y: -4.33697319, label: 1, color: '#c23740' },
  { x: 5.049531937, y: -3.787454605, label: 1, color: '#c23740' },
  { x: 2.51193428, y: 5.939043045, label: 2, color: '#c3b96f' },
  { x: 3.179485321, y: -5.226776123, label: 1, color: '#c23740' },
  { x: 1.90861702, y: 6.253234863, label: 2, color: '#c3b96f' },
  { x: 5.293571472, y: -5.61404705, label: 1, color: '#c23740' },
  { x: 4.597301483, y: -4.875232697, label: 1, color: '#c23740' },
  { x: 0.376496792, y: 5.59889698, label: 2, color: '#c3b96f' },
  { x: 6.419206619, y: -4.707870483, label: 1, color: '#c23740' },
  { x: 4.844654083, y: -4.477247238, label: 1, color: '#c23740' },
  { x: 4.441608429, y: -4.596269608, label: 1, color: '#c23740' },
  { x: 2.258882523, y: 5.959890366, label: 2, color: '#c3b96f' },
  { x: 2.448797226, y: -5.964385986, label: 1, color: '#c23740' },
  { x: 4.498918533, y: -4.249139786, label: 1, color: '#c23740' },
  { x: 1.439962387, y: 5.718848228, label: 2, color: '#c3b96f' },
  { x: 2.181515694, y: 5.960010529, label: 2, color: '#c3b96f' },
  { x: 4.844479561, y: -4.238991737, label: 1, color: '#c23740' },
  { x: 2.390219688, y: 6.022096634, label: 2, color: '#c3b96f' },
  { x: 4.464519501, y: -4.24033165, label: 1, color: '#c23740' },
  { x: 0.689923763, y: 5.239704132, label: 2, color: '#c3b96f' },
  { x: 4.598567963, y: -4.577610016, label: 1, color: '#c23740' },
  { x: 6.825396538, y: -5.041303635, label: 1, color: '#c23740' },
  { x: 5.439050674, y: -4.037723541, label: 1, color: '#c23740' },
  { x: 4.023891449, y: -4.730490685, label: 1, color: '#c23740' },
  { x: 5.539057732, y: -4.034198761, label: 1, color: '#c23740' },
  { x: 2.526094437, y: 5.997205734, label: 2, color: '#c3b96f' },
  { x: -6.530396461, y: 1.086812496, label: 3, color: '#35f870' },
  { x: -7.087036133, y: -1.196375847, label: 3, color: '#35f870' },
  { x: -7.280093193, y: -1.952727318, label: 3, color: '#35f870' },
  { x: -7.300775528, y: -1.933381081, label: 3, color: '#35f870' },
  { x: -7.26321125, y: -1.814065933, label: 3, color: '#35f870' },
  { x: -7.238183975, y: -0.36869812, label: 3, color: '#35f870' },
  { x: -7.066200256, y: -1.840784073, label: 3, color: '#35f870' },
  { x: -7.204519272, y: -1.505773544, label: 3, color: '#35f870' },
  { x: -7.135347366, y: -1.827054024, label: 3, color: '#35f870' },
  { x: -7.181631088, y: -1.72688961, label: 3, color: '#35f870' },
  { x: -6.635425568, y: 0.860121727, label: 3, color: '#35f870' },
  { x: -7.340901375, y: -2.043221474, label: 3, color: '#35f870' },
  { x: -6.160640717, y: 1.350668907, label: 3, color: '#35f870' },
  { x: -7.092996597, y: -1.805364609, label: 3, color: '#35f870' },
  { x: -5.775130272, y: 2.036090851, label: 3, color: '#35f870' },
  { x: -7.094148636, y: -1.922289848, label: 3, color: '#35f870' },
  { x: -5.976486206, y: 1.455405712, label: 3, color: '#35f870' },
  { x: -6.97162056, y: -1.132351875, label: 3, color: '#35f870' },
  { x: -7.329048157, y: -1.599269867, label: 3, color: '#35f870' },
  { x: -6.983498573, y: -1.215301514, label: 3, color: '#35f870' },
  { x: -7.155718803, y: -1.748226166, label: 3, color: '#35f870' },
  { x: -6.909434319, y: -1.445180416, label: 3, color: '#35f870' },
  { x: -7.196565628, y: 2.100534439, label: 3, color: '#35f870' },
  { x: -7.042615891, y: -1.874106407, label: 3, color: '#35f870' },
  { x: -8.154976845, y: 2.670660973, label: 3, color: '#35f870' },
  { x: -7.200761795, y: -1.729671478, label: 3, color: '#35f870' },
  { x: -6.898868561, y: -1.995341778, label: 3, color: '#35f870' },
  { x: -7.288489342, y: -1.731564999, label: 3, color: '#35f870' },
  { x: -7.083881378, y: -2.021305084, label: 3, color: '#35f870' },
  { x: -7.234643936, y: -1.786334991, label: 3, color: '#35f870' },
  { x: -7.3022995, y: -1.516075611, label: 3, color: '#35f870' },
  { x: -7.02414608, y: -1.587669373, label: 3, color: '#35f870' },
  { x: -6.075714111, y: 1.341916084, label: 3, color: '#35f870' },
  { x: -7.162002563, y: -1.925559998, label: 3, color: '#35f870' },
  { x: -7.278388977, y: -0.364780426, label: 3, color: '#35f870' },
  { x: -6.785140991, y: 0.545629025, label: 3, color: '#35f870' },
  { x: -5.776078224, y: 1.370977402, label: 3, color: '#35f870' },
  { x: -7.025939941, y: -0.001703262, label: 3, color: '#35f870' },
  { x: -7.099628448, y: -2.026081085, label: 3, color: '#35f870' },
  { x: -7.863437653, y: 1.539619446, label: 3, color: '#35f870' },
  { x: -7.08846283, y: -1.587841988, label: 3, color: '#35f870' },
  { x: -7.009422302, y: -1.746041775, label: 3, color: '#35f870' },
  { x: -6.780658722, y: -1.527005672, label: 3, color: '#35f870' },
  { x: 9.560674667, y: 3.556005478, label: 6, color: '#4386df' },
  { x: -7.313579559, y: -1.758472443, label: 3, color: '#35f870' },
  { x: -6.963996887, y: -1.764612198, label: 3, color: '#35f870' },
  { x: -7.302886009, y: -1.737817764, label: 3, color: '#35f870' },
  { x: -6.508115768, y: 0.550254822, label: 3, color: '#35f870' },
  { x: -7.019607544, y: -1.309731483, label: 3, color: '#35f870' },
  { x: -7.22010994, y: -1.927626133, label: 3, color: '#35f870' },
  { x: -6.89342308, y: -1.261414528, label: 3, color: '#35f870' },
  { x: -6.933615685, y: -1.697348595, label: 3, color: '#35f870' },
  { x: -7.218466759, y: -1.415330887, label: 3, color: '#35f870' },
  { x: -7.339582443, y: -1.770498276, label: 3, color: '#35f870' },
  { x: -7.099403381, y: -1.610286713, label: 3, color: '#35f870' },
  { x: -7.180204391, y: -1.922273636, label: 3, color: '#35f870' },
  { x: -6.805070877, y: -1.672166824, label: 3, color: '#35f870' },
  { x: -6.972881317, y: -0.288991928, label: 3, color: '#35f870' },
  { x: -7.038568497, y: -1.886688232, label: 3, color: '#35f870' },
  { x: -7.095962524, y: -1.985262871, label: 3, color: '#35f870' },
  { x: -6.921339989, y: -1.282763481, label: 3, color: '#35f870' },
  { x: -7.159114838, y: -1.537626266, label: 3, color: '#35f870' },
  { x: -7.107591629, y: -0.507037163, label: 3, color: '#35f870' },
  { x: -6.998102188, y: -2.032532692, label: 3, color: '#35f870' },
  { x: -6.850739479, y: -1.393830299, label: 3, color: '#35f870' },
  { x: -7.37833786, y: -0.788297653, label: 3, color: '#35f870' },
  { x: -7.432037354, y: -1.998605728, label: 3, color: '#35f870' },
  { x: -8.047153473, y: 2.471851349, label: 3, color: '#35f870' },
  { x: -7.036466599, y: -1.927825928, label: 3, color: '#35f870' },
  { x: -6.58063221, y: 0.638451576, label: 3, color: '#35f870' },
  { x: -7.39827919, y: -1.813232422, label: 3, color: '#35f870' },
  { x: -6.886985779, y: -1.858438492, label: 3, color: '#35f870' },
  { x: -7.498721123, y: 0.801558495, label: 3, color: '#35f870' },
  { x: -7.175409317, y: -1.709915161, label: 3, color: '#35f870' },
  { x: -7.168793678, y: -0.695615768, label: 3, color: '#35f870' },
  { x: -6.874715805, y: -1.889149189, label: 3, color: '#35f870' },
  { x: -6.83826828, y: -1.314304352, label: 3, color: '#35f870' },
  { x: -6.450223923, y: 0.988001823, label: 3, color: '#35f870' },
  { x: -7.401140213, y: -1.715782166, label: 3, color: '#35f870' },
  { x: -7.046302795, y: -1.682426453, label: 3, color: '#35f870' },
  { x: -7.163422585, y: -1.095777512, label: 3, color: '#35f870' },
  { x: -7.036903381, y: -1.843951225, label: 3, color: '#35f870' },
  { x: -7.294502258, y: -1.608221054, label: 3, color: '#35f870' },
  { x: -7.372849464, y: -1.869836807, label: 3, color: '#35f870' },
  { x: -6.280408859, y: 1.626983643, label: 3, color: '#35f870' },
  { x: -7.385982513, y: -1.993549347, label: 3, color: '#35f870' },
  { x: -7.259830475, y: -1.154368401, label: 3, color: '#35f870' },
  { x: -7.011528015, y: -1.052307606, label: 3, color: '#35f870' },
  { x: -7.406723022, y: -1.277730942, label: 3, color: '#35f870' },
  { x: -6.92647171, y: -1.638916016, label: 3, color: '#35f870' },
  { x: -8.05656147, y: 2.455012321, label: 3, color: '#35f870' },
  { x: -6.997890472, y: -1.834367752, label: 3, color: '#35f870' },
  { x: -6.377570152, y: 1.617119789, label: 3, color: '#35f870' },
  { x: 8.769258499, y: 4.587277412, label: 6, color: '#4386df' },
  { x: 9.625553131, y: 3.530030727, label: 6, color: '#4386df' },
  { x: -5.257955551, y: 2.661209106, label: 3, color: '#35f870' },
  { x: -7.187963486, y: -1.346675873, label: 3, color: '#35f870' },
  { x: -7.002911568, y: -1.473101139, label: 3, color: '#35f870' },
  { x: -6.914615631, y: -1.518190384, label: 3, color: '#35f870' },
  { x: -7.155118942, y: -1.883938789, label: 3, color: '#35f870' },
  { x: -7.398733139, y: -0.98135376, label: 3, color: '#35f870' },
  { x: -7.74892807, y: 1.999223709, label: 3, color: '#35f870' },
  { x: -7.060625076, y: -1.300231934, label: 3, color: '#35f870' },
  { x: -6.972193718, y: -1.638094902, label: 3, color: '#35f870' },
  { x: -6.393662453, y: 1.177206993, label: 3, color: '#35f870' },
  { x: -7.210893631, y: -1.957848549, label: 3, color: '#35f870' },
  { x: -6.898881912, y: 1.369743347, label: 3, color: '#35f870' },
  { x: -7.003257751, y: -1.516399384, label: 3, color: '#35f870' },
  { x: -5.642780304, y: 1.451992989, label: 3, color: '#35f870' },
  { x: -7.134713173, y: -1.655237198, label: 3, color: '#35f870' },
  { x: -6.917892456, y: -1.541917801, label: 3, color: '#35f870' },
  { x: -7.154714584, y: -1.959634781, label: 3, color: '#35f870' },
  { x: -7.087985992, y: -1.267237663, label: 3, color: '#35f870' },
  { x: -8.017327309, y: 2.258128166, label: 3, color: '#35f870' },
  { x: -7.599721909, y: 1.351307869, label: 3, color: '#35f870' },
  { x: -7.380476952, y: -1.330573559, label: 3, color: '#35f870' },
  { x: -6.815719604, y: 0.813954353, label: 3, color: '#35f870' },
  { x: -7.766651154, y: 2.612848282, label: 3, color: '#35f870' },
  { x: -6.327041626, y: 1.124707699, label: 3, color: '#35f870' },
  { x: -7.282045364, y: -2.007624626, label: 3, color: '#35f870' },
  { x: -7.094266891, y: -1.736530304, label: 3, color: '#35f870' },
  { x: -7.328138351, y: -1.629816055, label: 3, color: '#35f870' },
  { x: -7.207639694, y: -1.625868797, label: 3, color: '#35f870' },
  { x: -6.956672668, y: -1.762379646, label: 3, color: '#35f870' },
  { x: -7.15414238, y: -1.89342308, label: 3, color: '#35f870' },
  { x: -6.130596161, y: 1.346178055, label: 3, color: '#35f870' },
  { x: -6.285964966, y: 1.588986397, label: 3, color: '#35f870' },
  { x: -7.391439438, y: -1.615519047, label: 3, color: '#35f870' },
  { x: -7.263035774, y: -1.203954697, label: 3, color: '#35f870' },
  { x: -7.317234993, y: -1.311898232, label: 3, color: '#35f870' },
  { x: -6.736557007, y: -0.486198425, label: 3, color: '#35f870' },
  { x: -7.297739029, y: -1.598640442, label: 3, color: '#35f870' },
  { x: -7.529806137, y: -1.582312584, label: 3, color: '#35f870' },
  { x: -7.142700195, y: -1.852191925, label: 3, color: '#35f870' },
  { x: -6.810873032, y: 1.085289001, label: 3, color: '#35f870' },
  { x: -7.365440369, y: -2.047269821, label: 3, color: '#35f870' },
  { x: -6.963701248, y: -1.462166309, label: 3, color: '#35f870' },
  { x: -5.973351479, y: 1.716626167, label: 3, color: '#35f870' },
  { x: -7.150943756, y: -1.989994049, label: 3, color: '#35f870' },
  { x: -6.987817764, y: -1.813293457, label: 3, color: '#35f870' },
  { x: -6.244140625, y: 1.210497856, label: 3, color: '#35f870' },
  { x: -6.742886543, y: 1.291236401, label: 3, color: '#35f870' },
  { x: -8.001328468, y: 2.246840954, label: 3, color: '#35f870' },
  { x: 10.68987465, y: 4.633138657, label: 6, color: '#4386df' },
  { x: -7.341424942, y: -0.825117111, label: 3, color: '#35f870' },
  { x: -5.613416672, y: 1.436966896, label: 3, color: '#35f870' },
  { x: -7.080731392, y: -1.898310661, label: 3, color: '#35f870' },
  { x: -7.121710777, y: -0.810494423, label: 3, color: '#35f870' },
  { x: -6.185080528, y: 1.299708366, label: 3, color: '#35f870' },
  { x: -7.352020264, y: -1.508021355, label: 3, color: '#35f870' },
  { x: -6.737911224, y: -1.648996353, label: 3, color: '#35f870' },
  { x: -6.489825249, y: 1.177256584, label: 3, color: '#35f870' },
  { x: -7.236058235, y: -1.476412773, label: 3, color: '#35f870' },
  { x: -6.958341599, y: -1.747030258, label: 3, color: '#35f870' },
  { x: -7.012237549, y: -1.661647797, label: 3, color: '#35f870' },
  { x: -7.048477173, y: -1.021327972, label: 3, color: '#35f870' },
  { x: -7.057098389, y: -1.228211403, label: 3, color: '#35f870' },
  { x: 9.111305237, y: 4.320572853, label: 6, color: '#4386df' },
  { x: -7.163094521, y: -1.190893173, label: 3, color: '#35f870' },
  { x: -7.342933655, y: -1.454494476, label: 3, color: '#35f870' },
  { x: -7.610521317, y: -0.754749298, label: 3, color: '#35f870' },
  { x: -7.373540878, y: -1.453585625, label: 3, color: '#35f870' },
  { x: -7.283507347, y: -1.479728699, label: 3, color: '#35f870' },
  { x: -7.304176331, y: -1.807563782, label: 3, color: '#35f870' },
  { x: -6.918762207, y: -1.355639935, label: 3, color: '#35f870' },
  { x: -5.871450424, y: 1.757595062, label: 3, color: '#35f870' },
  { x: -7.151447296, y: -1.616416931, label: 3, color: '#35f870' },
  { x: -7.161574364, y: -1.901031494, label: 3, color: '#35f870' },
  { x: -7.201779366, y: -0.828210831, label: 3, color: '#35f870' },
  { x: -7.472681046, y: -1.514492989, label: 3, color: '#35f870' },
  { x: -7.561435699, y: -1.368829727, label: 3, color: '#35f870' },
  { x: -6.717372894, y: -1.642831802, label: 3, color: '#35f870' },
  { x: -6.947714806, y: -1.851831436, label: 3, color: '#35f870' },
  { x: -7.243741035, y: -1.951499939, label: 3, color: '#35f870' },
  { x: 9.698143005, y: 3.469821453, label: 6, color: '#4386df' },
  { x: -7.938609123, y: 2.044625282, label: 3, color: '#35f870' },
  { x: -7.013290405, y: 0.625890732, label: 3, color: '#35f870' },
  { x: -6.797021866, y: -1.639890671, label: 3, color: '#35f870' },
  { x: -7.817041397, y: 2.12638092, label: 3, color: '#35f870' },
  { x: -7.311835289, y: -1.58047533, label: 3, color: '#35f870' },
  { x: -7.131746292, y: -1.705544472, label: 3, color: '#35f870' },
  { x: -6.837930679, y: -1.846793175, label: 3, color: '#35f870' },
  { x: -7.382657051, y: -1.535803795, label: 3, color: '#35f870' },
  { x: -7.211910248, y: -1.595794678, label: 3, color: '#35f870' },
  { x: 8.363245964, y: 5.185045242, label: 6, color: '#4386df' },
  { x: -7.303955078, y: -1.479554176, label: 3, color: '#35f870' },
  { x: 8.862524033, y: 4.305517197, label: 6, color: '#4386df' },
  { x: 8.493177414, y: 4.899321556, label: 6, color: '#4386df' },
  { x: -7.326929092, y: -0.221510887, label: 3, color: '#35f870' },
  { x: -7.256631851, y: -1.464741707, label: 3, color: '#35f870' },
  { x: -8.154891968, y: 2.416968346, label: 3, color: '#35f870' },
  { x: -6.960924149, y: -0.228884697, label: 3, color: '#35f870' },
  { x: -6.918434143, y: 0.109487534, label: 3, color: '#35f870' },
  { x: 8.31957531, y: 5.22977829, label: 6, color: '#4386df' },
  { x: -7.112747192, y: -1.480297089, label: 3, color: '#35f870' },
  { x: -7.909722328, y: 2.276390076, label: 3, color: '#35f870' },
  { x: -7.351102829, y: -1.452829838, label: 3, color: '#35f870' },
  { x: -7.159830093, y: -1.589841843, label: 3, color: '#35f870' },
  { x: -8.17111969, y: 2.533943176, label: 3, color: '#35f870' },
  { x: -7.149296761, y: -0.543551922, label: 3, color: '#35f870' },
  { x: -7.019731522, y: -1.529763222, label: 3, color: '#35f870' },
  { x: 10.53599644, y: 3.619432449, label: 6, color: '#4386df' },
  { x: 10.02145386, y: 3.507577896, label: 6, color: '#4386df' },
  { x: -8.165037155, y: 2.473367214, label: 3, color: '#35f870' },
  { x: -6.796340942, y: -1.405978203, label: 3, color: '#35f870' },
  { x: -7.348617554, y: -1.283429146, label: 3, color: '#35f870' },
  { x: -7.122251511, y: -1.171773911, label: 3, color: '#35f870' },
  { x: 10.26727772, y: 3.495654106, label: 6, color: '#4386df' },
  { x: -8.053390503, y: 2.253951073, label: 3, color: '#35f870' },
  { x: -8.124601364, y: 1.974651337, label: 3, color: '#35f870' },
  { x: -6.82818985, y: -1.792681694, label: 3, color: '#35f870' },
  { x: -7.089434624, y: -1.743085861, label: 3, color: '#35f870' },
  { x: -7.231360435, y: -1.461796761, label: 3, color: '#35f870' },
  { x: -6.95400238, y: -1.813001633, label: 3, color: '#35f870' },
  { x: -6.920713425, y: -1.730251312, label: 3, color: '#35f870' },
  { x: 10.52958488, y: 3.696551323, label: 6, color: '#4386df' },
  { x: -7.018270493, y: -1.711427689, label: 3, color: '#35f870' },
  { x: -7.201482773, y: -1.788946629, label: 3, color: '#35f870' },
  { x: -6.908642769, y: 1.063955307, label: 3, color: '#35f870' },
  { x: -6.783977509, y: -1.877758026, label: 3, color: '#35f870' },
  { x: -7.006542206, y: 0.320029736, label: 3, color: '#35f870' },
  { x: -7.364206314, y: 0.095572472, label: 3, color: '#35f870' },
  { x: -7.09457016, y: -1.743686676, label: 3, color: '#35f870' },
  { x: -8.177252769, y: 2.307037354, label: 3, color: '#35f870' },
  { x: -7.397514343, y: 0.348293304, label: 3, color: '#35f870' },
  { x: -7.015884399, y: -1.475780487, label: 3, color: '#35f870' },
  { x: 9.400634766, y: 3.736263275, label: 6, color: '#4386df' },
  { x: 10.50627422, y: 3.60634613, label: 6, color: '#4386df' },
  { x: -7.469385147, y: -1.483203888, label: 3, color: '#35f870' },
  { x: -7.340547562, y: -1.714073181, label: 3, color: '#35f870' },
  { x: -7.078239441, y: -1.183161736, label: 3, color: '#35f870' },
  { x: -7.092336655, y: -1.173587799, label: 3, color: '#35f870' },
  { x: -7.121250153, y: 0.263173103, label: 3, color: '#35f870' },
  { x: -6.83590126, y: 0.918831348, label: 3, color: '#35f870' },
  { x: -8.178570747, y: 2.439279556, label: 3, color: '#35f870' },
  { x: -7.076065063, y: -1.287031174, label: 3, color: '#35f870' },
  { x: -7.163780212, y: -1.706544876, label: 3, color: '#35f870' },
  { x: -7.125226974, y: -1.188061714, label: 3, color: '#35f870' },
  { x: 8.366145134, y: 4.871419907, label: 6, color: '#4386df' },
  { x: -6.745576859, y: 0.576014996, label: 3, color: '#35f870' },
  { x: -7.074065208, y: -1.624046326, label: 3, color: '#35f870' },
  { x: -6.583747864, y: 0.50137043, label: 3, color: '#35f870' },
  { x: 10.62683868, y: 3.814350128, label: 6, color: '#4386df' },
  { x: -6.842653275, y: -1.567542076, label: 3, color: '#35f870' },
  { x: -6.79974556, y: -1.775671005, label: 3, color: '#35f870' },
  { x: -7.427755356, y: 1.196990967, label: 3, color: '#35f870' },
  { x: -6.98167038, y: 1.881487846, label: 3, color: '#35f870' },
  { x: -6.601335526, y: -1.114521027, label: 3, color: '#35f870' },
  { x: -7.114122391, y: -1.775922775, label: 3, color: '#35f870' },
  { x: -7.055023193, y: -1.121424675, label: 3, color: '#35f870' },
  { x: -7.305398941, y: 0.443964005, label: 3, color: '#35f870' },
  { x: -7.037485123, y: -1.626214027, label: 3, color: '#35f870' },
  { x: -6.654382706, y: -1.959204674, label: 3, color: '#35f870' },
  { x: -7.098058701, y: -1.461812973, label: 3, color: '#35f870' },
  { x: -7.09458828, y: 1.428016663, label: 3, color: '#35f870' },
  { x: -7.206396103, y: -1.867595673, label: 3, color: '#35f870' },
  { x: -7.224002838, y: -1.460850716, label: 3, color: '#35f870' },
  { x: -8.003295898, y: 1.709352493, label: 3, color: '#35f870' },
  { x: -6.827812195, y: -1.694932938, label: 3, color: '#35f870' },
  { x: -7.18956089, y: 0.212656498, label: 3, color: '#35f870' },
  { x: -7.071927071, y: -1.407504082, label: 3, color: '#35f870' },
  { x: 10.6343956, y: 3.71191597, label: 6, color: '#4386df' },
  { x: -6.957275391, y: 0.431388378, label: 3, color: '#35f870' },
  { x: -6.98962307, y: -1.585754871, label: 3, color: '#35f870' },
  { x: -7.196536064, y: -1.068717957, label: 3, color: '#35f870' },
  { x: -7.605155945, y: 2.086771011, label: 3, color: '#35f870' },
  { x: -8.073379517, y: 2.398263931, label: 3, color: '#35f870' },
  { x: -7.224099159, y: -0.52344799, label: 3, color: '#35f870' },
  { x: -7.102434158, y: -1.057009697, label: 3, color: '#35f870' },
  { x: -7.261117935, y: -0.740039825, label: 3, color: '#35f870' },
  { x: -7.082738876, y: -1.914096832, label: 3, color: '#35f870' },
  { x: -6.866678238, y: -1.570638657, label: 3, color: '#35f870' },
  { x: -7.958192825, y: 2.202415943, label: 3, color: '#35f870' },
  { x: -6.867134094, y: -1.701271057, label: 3, color: '#35f870' },
  { x: -6.854682922, y: -1.695451736, label: 3, color: '#35f870' },
  { x: -7.065527916, y: -0.055171967, label: 3, color: '#35f870' },
  { x: -6.793132782, y: -1.513147354, label: 3, color: '#35f870' },
  { x: -6.994093895, y: -1.421916962, label: 3, color: '#35f870' },
  { x: -7.054676056, y: -1.082685947, label: 3, color: '#35f870' },
  { x: -8.04142189, y: 2.22857523, label: 3, color: '#35f870' },
  { x: -7.21714592, y: -0.427474022, label: 3, color: '#35f870' },
  { x: -6.797367096, y: 1.779117584, label: 3, color: '#35f870' },
  { x: -7.118331909, y: -1.493000984, label: 3, color: '#35f870' },
  { x: -6.710166931, y: -1.725123882, label: 3, color: '#35f870' },
  { x: -6.854628563, y: -1.589864731, label: 3, color: '#35f870' },
  { x: -6.902210236, y: -1.713438988, label: 3, color: '#35f870' },
  { x: -7.201557159, y: -1.776076317, label: 3, color: '#35f870' },
  { x: -7.034407616, y: 0.695253372, label: 3, color: '#35f870' },
  { x: -7.20071888, y: -1.153899193, label: 3, color: '#35f870' },
  { x: -6.645196915, y: -1.675734043, label: 3, color: '#35f870' },
  { x: -7.331484795, y: 1.833352089, label: 3, color: '#35f870' },
  { x: -7.184981346, y: -0.594359398, label: 3, color: '#35f870' },
  { x: -6.767409325, y: -1.691905975, label: 3, color: '#35f870' },
  { x: -6.58240509, y: 1.126114845, label: 3, color: '#35f870' },
  { x: -6.854631424, y: 0.286359787, label: 3, color: '#35f870' },
  { x: -6.757909775, y: 0.285109997, label: 3, color: '#35f870' },
  { x: -7.828644753, y: 2.346880913, label: 3, color: '#35f870' },
  { x: -7.026039124, y: -1.303212166, label: 3, color: '#35f870' },
  { x: -6.859239578, y: -1.10761261, label: 3, color: '#35f870' },
  { x: -7.091768265, y: -1.596092224, label: 3, color: '#35f870' },
  { x: -7.21856308, y: -1.695195675, label: 3, color: '#35f870' },
  { x: -7.213560104, y: -1.01573658, label: 3, color: '#35f870' },
  { x: -7.378083229, y: -1.238283157, label: 3, color: '#35f870' },
  { x: -6.937319756, y: -1.742052078, label: 3, color: '#35f870' },
  { x: 8.586504936, y: 4.708584785, label: 6, color: '#4386df' },
  { x: -7.858751297, y: 2.035104752, label: 3, color: '#35f870' },
  { x: -6.939338684, y: -1.393536568, label: 3, color: '#35f870' },
  { x: -7.496528625, y: 0.820526123, label: 3, color: '#35f870' },
  { x: -8.270379066, y: 2.329526901, label: 3, color: '#35f870' },
  { x: 9.500009537, y: 3.657182693, label: 6, color: '#4386df' },
  { x: -7.328009605, y: -0.286329269, label: 3, color: '#35f870' },
  { x: -7.246948242, y: -0.485440731, label: 3, color: '#35f870' },
  { x: -7.573988914, y: -0.998748302, label: 3, color: '#35f870' },
  { x: -7.094640732, y: -1.223735809, label: 3, color: '#35f870' },
  { x: -7.374576569, y: -1.212726593, label: 3, color: '#35f870' },
  { x: -7.024543762, y: -1.752607346, label: 3, color: '#35f870' },
  { x: -6.902835846, y: 1.020051956, label: 3, color: '#35f870' },
  { x: -6.940550804, y: -1.550980091, label: 3, color: '#35f870' },
  { x: -7.156906128, y: -1.583765984, label: 3, color: '#35f870' },
  { x: -7.287836075, y: -1.500114441, label: 3, color: '#35f870' },
  { x: -7.172805786, y: -1.676101685, label: 3, color: '#35f870' },
  { x: -7.788572311, y: 1.969815254, label: 3, color: '#35f870' },
  { x: -6.935493469, y: -1.40640831, label: 3, color: '#35f870' },
  { x: -8.122307777, y: 2.388206482, label: 3, color: '#35f870' },
  { x: -6.945939064, y: -1.207942009, label: 3, color: '#35f870' },
  { x: 9.403049469, y: 3.533628464, label: 6, color: '#4386df' },
  { x: -7.107656479, y: -1.555877686, label: 3, color: '#35f870' },
  { x: -7.236825943, y: 0.480944633, label: 3, color: '#35f870' },
  { x: -6.897171021, y: -1.511353493, label: 3, color: '#35f870' },
  { x: -7.152742386, y: -1.199636459, label: 3, color: '#35f870' },
  { x: -7.402975082, y: -0.754707336, label: 3, color: '#35f870' },
  { x: -7.237239838, y: -1.619289398, label: 3, color: '#35f870' },
  { x: -7.038345337, y: -1.771894455, label: 3, color: '#35f870' },
  { x: -7.95854187, y: 1.635149002, label: 3, color: '#35f870' },
  { x: -6.917131424, y: -1.821889877, label: 3, color: '#35f870' },
  { x: -7.218068123, y: -1.278485775, label: 3, color: '#35f870' },
  { x: -6.889690399, y: -1.722050667, label: 3, color: '#35f870' },
  { x: -6.860563278, y: -0.894361496, label: 3, color: '#35f870' },
  { x: -6.711925507, y: -1.650818825, label: 3, color: '#35f870' },
  { x: -7.135842323, y: -1.746943474, label: 3, color: '#35f870' },
  { x: -6.859672546, y: -1.413588047, label: 3, color: '#35f870' },
  { x: -6.812612534, y: 0.81378746, label: 3, color: '#35f870' },
  { x: -7.38997364, y: -1.215929031, label: 3, color: '#35f870' },
  { x: -6.821588516, y: -1.60447216, label: 3, color: '#35f870' },
  { x: -8.002283096, y: 2.323702812, label: 3, color: '#35f870' },
  { x: 9.796784401, y: 3.540267944, label: 6, color: '#4386df' },
  { x: -7.353170395, y: -1.701398849, label: 3, color: '#35f870' },
  { x: -7.260313988, y: -1.288097382, label: 3, color: '#35f870' },
  { x: -7.924331665, y: 2.447151184, label: 3, color: '#35f870' },
  { x: -7.197512627, y: -1.428435326, label: 3, color: '#35f870' },
  { x: -7.034320831, y: -1.432972908, label: 3, color: '#35f870' },
  { x: -7.169563293, y: -1.785973072, label: 3, color: '#35f870' },
  { x: -6.940273285, y: -1.65682888, label: 3, color: '#35f870' },
  { x: -7.291027069, y: -0.869809151, label: 3, color: '#35f870' },
  { x: -6.743427277, y: -1.003782272, label: 3, color: '#35f870' },
  { x: 9.291147232, y: 3.741662979, label: 6, color: '#4386df' },
  { x: -7.236096382, y: 1.628383636, label: 3, color: '#35f870' },
  { x: -7.320376396, y: -1.577293396, label: 3, color: '#35f870' },
  { x: -7.049796104, y: -0.48538065, label: 3, color: '#35f870' },
  { x: -7.394223213, y: 0.393231392, label: 3, color: '#35f870' },
  { x: -7.082788467, y: -1.64789772, label: 3, color: '#35f870' },
  { x: -7.168029785, y: -1.72700119, label: 3, color: '#35f870' },
  { x: -7.23713398, y: -1.547063828, label: 3, color: '#35f870' },
  { x: -6.892518044, y: -1.271158695, label: 3, color: '#35f870' },
  { x: -7.212591171, y: -0.88733387, label: 3, color: '#35f870' },
  { x: -7.248552322, y: -1.351251602, label: 3, color: '#35f870' },
  { x: -7.446921349, y: -1.322211266, label: 3, color: '#35f870' },
  { x: -6.83759594, y: -1.597806931, label: 3, color: '#35f870' },
  { x: -6.914277077, y: -1.719122887, label: 3, color: '#35f870' },
  { x: -7.342840195, y: -1.386743546, label: 3, color: '#35f870' },
  { x: -7.019784927, y: -1.642723083, label: 3, color: '#35f870' },
  { x: -7.171582222, y: -1.791207314, label: 3, color: '#35f870' },
  { x: -7.317922592, y: -1.114607811, label: 3, color: '#35f870' },
  { x: -7.18032074, y: -1.489057541, label: 3, color: '#35f870' },
  { x: -6.952635765, y: -1.601532459, label: 3, color: '#35f870' },
  { x: -7.080112457, y: -1.391188622, label: 3, color: '#35f870' },
  { x: -6.910770416, y: -1.731891632, label: 3, color: '#35f870' },
  { x: -7.022420883, y: -1.842821121, label: 3, color: '#35f870' },
  { x: -7.202757835, y: -0.022451878, label: 3, color: '#35f870' },
  { x: -7.214200974, y: -0.272647858, label: 3, color: '#35f870' },
  { x: -7.073413849, y: -1.59217453, label: 3, color: '#35f870' },
  { x: -7.219009399, y: -0.832216263, label: 3, color: '#35f870' },
  { x: -6.90811348, y: -0.93027401, label: 3, color: '#35f870' },
  { x: -5.74484539, y: 1.448205948, label: 3, color: '#35f870' },
  { x: -6.91765976, y: -1.184037209, label: 3, color: '#35f870' },
  { x: -8.096095085, y: 2.320201874, label: 3, color: '#35f870' },
  { x: 11.02075195, y: 4.903963089, label: 6, color: '#4386df' },
  { x: -6.35978508, y: 1.186504364, label: 3, color: '#35f870' },
  { x: -6.693321228, y: -1.712146759, label: 3, color: '#35f870' },
  { x: -6.877743721, y: -1.556303024, label: 3, color: '#35f870' },
  { x: -7.319206238, y: -1.388498306, label: 3, color: '#35f870' },
  { x: -6.980966568, y: -1.778727531, label: 3, color: '#35f870' },
  { x: -7.186130524, y: -1.467657089, label: 3, color: '#35f870' },
  { x: -6.582609177, y: 0.320729256, label: 3, color: '#35f870' },
  { x: -7.240663528, y: -0.242683411, label: 3, color: '#35f870' },
  { x: -7.385606766, y: -1.490501404, label: 3, color: '#35f870' },
  { x: -6.887393951, y: -1.260220528, label: 3, color: '#35f870' },
  { x: -6.698955536, y: -1.694063187, label: 3, color: '#35f870' },
  { x: -7.210009575, y: -1.850763321, label: 3, color: '#35f870' },
  { x: -7.655132294, y: 2.092164993, label: 3, color: '#35f870' },
  { x: 10.73261547, y: 3.402326584, label: 6, color: '#4386df' },
  { x: -7.870567322, y: 2.13892746, label: 3, color: '#35f870' },
  { x: -7.336587906, y: -1.855195999, label: 3, color: '#35f870' },
  { x: -7.581912994, y: 2.084886551, label: 3, color: '#35f870' },
  { x: -8.124952316, y: 2.329021454, label: 3, color: '#35f870' },
  { x: -7.173631668, y: -1.497262955, label: 3, color: '#35f870' },
  { x: -6.870096207, y: -1.384533882, label: 3, color: '#35f870' },
  { x: -6.976337433, y: -1.460285187, label: 3, color: '#35f870' },
  { x: -6.615346909, y: 0.503900528, label: 3, color: '#35f870' },
  { x: -6.913993835, y: -1.569187164, label: 3, color: '#35f870' },
  { x: -5.909659386, y: 1.473099709, label: 3, color: '#35f870' },
  { x: -7.065877914, y: -1.520341873, label: 3, color: '#35f870' },
  { x: -7.144302368, y: -1.468840599, label: 3, color: '#35f870' },
  { x: -7.205720901, y: -0.150436401, label: 3, color: '#35f870' },
  { x: -7.789354324, y: 1.467257977, label: 3, color: '#35f870' },
  { x: -6.330373764, y: 1.169776917, label: 3, color: '#35f870' },
  { x: -6.980703354, y: -1.572317123, label: 3, color: '#35f870' },
  { x: -7.995693207, y: 2.454361916, label: 3, color: '#35f870' },
  { x: -7.206925392, y: -1.275762558, label: 3, color: '#35f870' },
  { x: -7.008565903, y: -1.681179047, label: 3, color: '#35f870' },
  { x: -7.059688568, y: -1.780889511, label: 3, color: '#35f870' },
  { x: -7.042340279, y: -1.371330261, label: 3, color: '#35f870' },
  { x: -6.97877121, y: -1.728863239, label: 3, color: '#35f870' },
  { x: -7.075595856, y: -1.629491806, label: 3, color: '#35f870' },
  { x: -6.887440681, y: -1.764243126, label: 3, color: '#35f870' },
  { x: -7.051105499, y: -1.832291126, label: 3, color: '#35f870' },
  { x: -7.271226883, y: -1.682794571, label: 3, color: '#35f870' },
  { x: -7.068868637, y: -1.490785122, label: 3, color: '#35f870' },
  { x: 9.826774597, y: 3.225645065, label: 6, color: '#4386df' },
  { x: -7.937221527, y: 2.244293213, label: 3, color: '#35f870' },
  { x: -6.760861397, y: -1.275807381, label: 3, color: '#35f870' },
  { x: -8.040475845, y: 2.387420654, label: 3, color: '#35f870' },
  { x: -6.884811401, y: -1.377876759, label: 3, color: '#35f870' },
  { x: -7.047725677, y: -1.629296303, label: 3, color: '#35f870' },
  { x: -6.832369804, y: -1.261170864, label: 3, color: '#35f870' },
  { x: -6.878864288, y: -1.553396702, label: 3, color: '#35f870' },
  { x: -7.19033432, y: -1.313415527, label: 3, color: '#35f870' },
  { x: -6.739873886, y: -1.49969101, label: 3, color: '#35f870' },
  { x: -6.930244446, y: -1.356348991, label: 3, color: '#35f870' },
  { x: -7.22936821, y: -1.175677299, label: 3, color: '#35f870' },
  { x: -6.956979752, y: -0.108699799, label: 3, color: '#35f870' },
  { x: -7.012735367, y: -1.186126709, label: 3, color: '#35f870' },
  { x: -7.280110359, y: -0.849153519, label: 3, color: '#35f870' },
  { x: -6.789081573, y: -1.379600525, label: 3, color: '#35f870' },
  { x: -7.232694626, y: -1.558986664, label: 3, color: '#35f870' },
  { x: -7.052909851, y: -0.915706635, label: 3, color: '#35f870' },
  { x: -7.42871666, y: -1.43938446, label: 3, color: '#35f870' },
  { x: -7.279918671, y: -1.427024841, label: 3, color: '#35f870' },
  { x: -7.079750061, y: -1.826211929, label: 3, color: '#35f870' },
  { x: -7.173855782, y: -1.595869064, label: 3, color: '#35f870' },
  { x: -7.092975616, y: -1.287676811, label: 3, color: '#35f870' },
  { x: -7.202501297, y: -1.212192535, label: 3, color: '#35f870' },
  { x: -6.927776337, y: -1.698882103, label: 3, color: '#35f870' },
  { x: -6.81926918, y: -1.135557175, label: 3, color: '#35f870' },
  { x: -7.100600243, y: -1.351071835, label: 3, color: '#35f870' },
  { x: -8.203564644, y: 2.444896698, label: 3, color: '#35f870' },
  { x: -7.204082489, y: -1.291277885, label: 3, color: '#35f870' },
  { x: -7.062312126, y: -1.644255638, label: 3, color: '#35f870' },
  { x: -6.39336586, y: 1.172209263, label: 3, color: '#35f870' },
  { x: -7.148700714, y: -0.876166344, label: 3, color: '#35f870' },
  { x: -7.144916534, y: -1.547572136, label: 3, color: '#35f870' },
  { x: -6.912004471, y: -1.663599014, label: 3, color: '#35f870' },
  { x: -7.029811859, y: -1.128917694, label: 3, color: '#35f870' },
  { x: -6.86964035, y: -1.823080063, label: 3, color: '#35f870' },
  { x: -6.317615509, y: 1.214504719, label: 3, color: '#35f870' },
  { x: -7.097284317, y: -1.725881577, label: 3, color: '#35f870' },
  { x: -7.795238495, y: 2.306675911, label: 3, color: '#35f870' },
  { x: -7.209386826, y: -1.344573975, label: 3, color: '#35f870' },
  { x: 9.339388847, y: 3.704242706, label: 6, color: '#4386df' },
  { x: -6.954137802, y: -1.549716473, label: 3, color: '#35f870' },
  { x: -7.017501831, y: -1.340477943, label: 3, color: '#35f870' },
  { x: -7.136335373, y: -1.681004524, label: 3, color: '#35f870' },
  { x: -7.193416595, y: -1.085132599, label: 3, color: '#35f870' },
  { x: -7.024864197, y: -1.264266968, label: 3, color: '#35f870' },
  { x: -6.647957802, y: 0.740525723, label: 3, color: '#35f870' },
  { x: -7.116063118, y: -1.460121155, label: 3, color: '#35f870' },
  { x: -7.082294464, y: -1.618619919, label: 3, color: '#35f870' },
  { x: -6.956113815, y: -1.666137695, label: 3, color: '#35f870' },
  { x: -7.111127853, y: -1.667628288, label: 3, color: '#35f870' },
  { x: -7.229694366, y: -0.898139954, label: 3, color: '#35f870' },
  { x: -7.065040588, y: -0.757565498, label: 3, color: '#35f870' },
  { x: -6.98634243, y: -1.597442627, label: 3, color: '#35f870' },
  { x: 13.885952, y: 1.176685333, label: 6, color: '#4386df' },
  { x: 10.3357811, y: 3.502737999, label: 6, color: '#4386df' },
  { x: 8.441497803, y: 5.043115616, label: 6, color: '#4386df' },
  { x: -6.970692635, y: 1.5198102, label: 3, color: '#35f870' },
  { x: -6.811595917, y: 0.426448822, label: 3, color: '#35f870' },
  { x: -7.306144714, y: -0.824655533, label: 3, color: '#35f870' },
  { x: -6.84239769, y: 0.457992554, label: 3, color: '#35f870' },
  { x: -6.879739761, y: -1.622132301, label: 3, color: '#35f870' },
  { x: -6.856143951, y: 0.580301285, label: 3, color: '#35f870' },
  { x: 10.89625931, y: 3.873332977, label: 6, color: '#4386df' },
  { x: -7.108197212, y: -1.693603516, label: 3, color: '#35f870' },
  { x: -6.924663544, y: -1.727808952, label: 3, color: '#35f870' },
  { x: -7.799057007, y: 2.337284088, label: 3, color: '#35f870' },
  { x: -7.089454651, y: -1.525629044, label: 3, color: '#35f870' },
  { x: -7.238087654, y: -1.406830788, label: 3, color: '#35f870' },
  { x: -7.950059891, y: 2.487381935, label: 3, color: '#35f870' },
  { x: -7.232727051, y: -1.24511528, label: 3, color: '#35f870' },
  { x: -5.634990692, y: 1.948378563, label: 3, color: '#35f870' },
  { x: -7.039147377, y: -1.729284286, label: 3, color: '#35f870' },
  { x: 8.493343353, y: 4.543546677, label: 6, color: '#4386df' },
  { x: -7.645960808, y: 2.064898968, label: 3, color: '#35f870' },
  { x: -7.771698952, y: 2.38765192, label: 3, color: '#35f870' },
  { x: -6.966243744, y: -1.180746078, label: 3, color: '#35f870' },
  { x: -5.85525322, y: 1.338567734, label: 3, color: '#35f870' },
  { x: -7.091575623, y: -1.740393639, label: 3, color: '#35f870' },
  { x: -7.686670303, y: 1.007307053, label: 3, color: '#35f870' },
  { x: -6.906031609, y: -1.626091957, label: 3, color: '#35f870' },
  { x: -6.980096817, y: -1.517519951, label: 3, color: '#35f870' },
  { x: -7.087030411, y: -1.489673615, label: 3, color: '#35f870' },
  { x: -6.948782921, y: -1.556464195, label: 3, color: '#35f870' },
  { x: -7.04846096, y: -1.77060318, label: 3, color: '#35f870' },
  { x: -6.858469963, y: -1.36471653, label: 3, color: '#35f870' },
  { x: -6.718935013, y: -1.548768044, label: 3, color: '#35f870' },
  { x: -6.805202484, y: -1.399213791, label: 3, color: '#35f870' },
  { x: -7.009904861, y: -1.826562881, label: 3, color: '#35f870' },
  { x: 8.265504837, y: 5.390600204, label: 6, color: '#4386df' },
  { x: -7.102157593, y: -1.786776543, label: 3, color: '#35f870' },
  { x: -7.111240387, y: -1.284606934, label: 3, color: '#35f870' },
  { x: -6.858122826, y: -1.70225811, label: 3, color: '#35f870' },
  { x: -6.889456749, y: -0.805575371, label: 3, color: '#35f870' },
  { x: -7.025687218, y: -1.406276703, label: 3, color: '#35f870' },
  { x: -6.665540695, y: -1.530470848, label: 3, color: '#35f870' },
  { x: -6.847963333, y: -1.481685638, label: 3, color: '#35f870' },
  { x: -7.316207886, y: 0.874204636, label: 3, color: '#35f870' },
  { x: -7.013546944, y: -1.18603611, label: 3, color: '#35f870' },
  { x: -6.983758926, y: -1.687896252, label: 3, color: '#35f870' },
  { x: -8.062244415, y: 2.480897903, label: 3, color: '#35f870' },
  { x: -7.154521942, y: -1.543107986, label: 3, color: '#35f870' },
  { x: -7.621395111, y: 2.080843925, label: 3, color: '#35f870' },
  { x: -6.730703354, y: -1.5799613, label: 3, color: '#35f870' },
  { x: -7.713960648, y: 2.24991703, label: 3, color: '#35f870' },
  { x: -6.98041153, y: 0.938968658, label: 3, color: '#35f870' },
  { x: -7.649658203, y: 2.20368433, label: 3, color: '#35f870' },
  { x: -6.896229744, y: -1.712100983, label: 3, color: '#35f870' },
  { x: -7.904601097, y: 1.860458374, label: 3, color: '#35f870' },
  { x: -6.894433975, y: -1.630487442, label: 3, color: '#35f870' },
  { x: -7.229103088, y: -1.228813171, label: 3, color: '#35f870' },
  { x: -7.295581818, y: -0.934994698, label: 3, color: '#35f870' },
  { x: -6.710075378, y: -1.50227356, label: 3, color: '#35f870' },
  { x: -7.719814301, y: 2.07775116, label: 3, color: '#35f870' },
  { x: -7.066228867, y: -1.877631664, label: 3, color: '#35f870' },
  { x: -6.836894989, y: -1.692747116, label: 3, color: '#35f870' },
  { x: -6.937095642, y: 0.83595705, label: 3, color: '#35f870' },
  { x: 9.504395485, y: 3.611127853, label: 6, color: '#4386df' },
  { x: -6.959172249, y: -1.616714001, label: 3, color: '#35f870' },
  { x: -6.838514328, y: -1.747945786, label: 3, color: '#35f870' },
  { x: -7.140075684, y: -1.540977478, label: 3, color: '#35f870' },
  { x: -7.682649612, y: 2.111003876, label: 3, color: '#35f870' },
  { x: -7.146042824, y: 0.183594704, label: 3, color: '#35f870' },
  { x: -6.717068672, y: -1.510508537, label: 3, color: '#35f870' },
  { x: -6.721426964, y: -1.809115887, label: 3, color: '#35f870' },
  { x: -6.857601166, y: 0.744482994, label: 3, color: '#35f870' },
  { x: -6.936676025, y: -1.434865952, label: 3, color: '#35f870' },
  { x: -6.728431702, y: 0.976335526, label: 3, color: '#35f870' },
  { x: -6.977775574, y: -0.66946125, label: 3, color: '#35f870' },
  { x: -7.752485275, y: 2.302246571, label: 3, color: '#35f870' },
  { x: -7.10564518, y: -0.805971622, label: 3, color: '#35f870' },
  { x: -7.104345322, y: -1.588768005, label: 3, color: '#35f870' },
  { x: -7.271198273, y: -1.499538422, label: 3, color: '#35f870' },
  { x: -6.972335815, y: -1.299496651, label: 3, color: '#35f870' },
  { x: -6.837293625, y: 0.545222759, label: 3, color: '#35f870' },
  { x: -6.998739243, y: -1.657432556, label: 3, color: '#35f870' },
  { x: -7.006840706, y: -1.288109779, label: 3, color: '#35f870' },
  { x: -7.221684456, y: -1.415561676, label: 3, color: '#35f870' },
  { x: -7.012207031, y: -1.150231361, label: 3, color: '#35f870' },
  { x: -7.819005013, y: 2.336751938, label: 3, color: '#35f870' },
  { x: -7.000025749, y: -1.121496201, label: 3, color: '#35f870' },
  { x: -7.742070198, y: 2.294542313, label: 3, color: '#35f870' },
  { x: -6.862733841, y: -1.654569626, label: 3, color: '#35f870' },
  { x: -6.979447365, y: -1.504657745, label: 3, color: '#35f870' },
  { x: -8.114988327, y: 2.099404812, label: 3, color: '#35f870' },
  { x: -6.978678703, y: -1.315061569, label: 3, color: '#35f870' },
  { x: -6.942996979, y: -1.514658928, label: 3, color: '#35f870' },
  { x: -7.198182106, y: -1.441478729, label: 3, color: '#35f870' },
  { x: -7.062824249, y: -1.136735916, label: 3, color: '#35f870' },
  { x: -7.01693821, y: -1.372806549, label: 3, color: '#35f870' },
  { x: -7.100711823, y: -1.718463898, label: 3, color: '#35f870' },
  { x: -7.158800125, y: -1.400268555, label: 3, color: '#35f870' },
  { x: -7.748415947, y: 2.325018883, label: 3, color: '#35f870' },
  { x: -7.049748421, y: -1.468003273, label: 3, color: '#35f870' },
  { x: -6.783073425, y: 0.917823792, label: 3, color: '#35f870' },
  { x: -7.290225983, y: -0.830749035, label: 3, color: '#35f870' },
  { x: -6.739385605, y: 0.662195206, label: 3, color: '#35f870' },
  { x: -6.796016693, y: -1.721534729, label: 3, color: '#35f870' },
  { x: -7.135531425, y: -1.192861557, label: 3, color: '#35f870' },
  { x: -6.971770287, y: -1.263917923, label: 3, color: '#35f870' },
  { x: 10.61927605, y: 3.633560181, label: 6, color: '#4386df' },
  { x: -6.924575806, y: -1.325137138, label: 3, color: '#35f870' },
  { x: -7.053900719, y: -1.333004951, label: 3, color: '#35f870' },
  { x: -7.294273376, y: -1.052024841, label: 3, color: '#35f870' },
  { x: -6.852266312, y: -1.59803772, label: 3, color: '#35f870' },
  { x: -7.926264763, y: 2.367601395, label: 3, color: '#35f870' },
  { x: -7.017683983, y: -1.585428238, label: 3, color: '#35f870' },
  { x: 9.094204903, y: 3.989282608, label: 6, color: '#4386df' },
  { x: -7.647846222, y: 2.221765518, label: 3, color: '#35f870' },
  { x: -7.137708664, y: -1.180823803, label: 3, color: '#35f870' },
  { x: -7.220846176, y: -1.216341019, label: 3, color: '#35f870' },
  { x: -7.01671505, y: -1.494550705, label: 3, color: '#35f870' },
  { x: -7.32936573, y: -1.551932335, label: 3, color: '#35f870' },
  { x: -6.960514069, y: -1.557422638, label: 3, color: '#35f870' },
  { x: -7.099750519, y: -0.651660919, label: 3, color: '#35f870' },
  { x: -7.083843231, y: -1.218770981, label: 3, color: '#35f870' },
  { x: -7.589954376, y: 2.062559128, label: 3, color: '#35f870' },
  { x: 9.573224068, y: 3.564678192, label: 6, color: '#4386df' },
  { x: -7.401763916, y: -0.558938026, label: 3, color: '#35f870' },
  { x: 9.11652565, y: 3.884518623, label: 6, color: '#4386df' },
  { x: -7.295877457, y: -0.927749634, label: 3, color: '#35f870' },
  { x: -7.153424263, y: -1.761156559, label: 3, color: '#35f870' },
  { x: -6.893339157, y: -1.803426743, label: 3, color: '#35f870' },
  { x: -7.195593834, y: -1.756767273, label: 3, color: '#35f870' },
  { x: -7.257164001, y: -1.658800125, label: 3, color: '#35f870' },
  { x: -6.874223709, y: -1.539595604, label: 3, color: '#35f870' },
  { x: -7.006458282, y: -1.623022079, label: 3, color: '#35f870' },
  { x: -6.667110443, y: -1.718330383, label: 3, color: '#35f870' },
  { x: -7.124194145, y: -1.400371552, label: 3, color: '#35f870' },
  { x: -7.146652222, y: -1.70202446, label: 3, color: '#35f870' },
  { x: -6.911963463, y: -1.800891876, label: 3, color: '#35f870' },
  { x: -6.955364227, y: -1.816646576, label: 3, color: '#35f870' },
  { x: -7.268892288, y: -1.835460663, label: 3, color: '#35f870' },
  { x: -6.779980659, y: -1.825321198, label: 3, color: '#35f870' },
  { x: -6.851899147, y: -1.709813118, label: 3, color: '#35f870' },
  { x: -7.084793091, y: -1.365306854, label: 3, color: '#35f870' },
  { x: -6.950829506, y: -1.81733799, label: 3, color: '#35f870' },
  { x: -6.750463486, y: -1.79506588, label: 3, color: '#35f870' },
  { x: -7.227666855, y: -1.665329933, label: 3, color: '#35f870' },
  { x: -7.190485001, y: -1.168796539, label: 3, color: '#35f870' },
  { x: 8.584166527, y: 4.481603622, label: 6, color: '#4386df' },
  { x: -7.323972702, y: -1.667655945, label: 3, color: '#35f870' },
  { x: -6.88786602, y: -1.558773994, label: 3, color: '#35f870' },
  { x: -7.088608742, y: -1.391521454, label: 3, color: '#35f870' },
  { x: -6.783963203, y: -1.717245102, label: 3, color: '#35f870' },
  { x: -7.056593895, y: -1.476273537, label: 3, color: '#35f870' },
  { x: -6.72445488, y: -1.666898727, label: 3, color: '#35f870' },
  { x: -8.058599472, y: 2.411959171, label: 3, color: '#35f870' },
  { x: -6.94521904, y: -1.583864212, label: 3, color: '#35f870' },
  { x: -7.166973114, y: -1.563899994, label: 3, color: '#35f870' },
  { x: -7.221583366, y: -1.500349998, label: 3, color: '#35f870' },
  { x: -7.126340866, y: -1.632735252, label: 3, color: '#35f870' },
  { x: 9.508177757, y: 3.591827393, label: 6, color: '#4386df' },
  { x: -8.076260567, y: 2.450336456, label: 3, color: '#35f870' },
  { x: -7.102296829, y: -1.80797863, label: 3, color: '#35f870' },
  { x: -7.207574844, y: -1.825696945, label: 3, color: '#35f870' },
  { x: -7.458053589, y: -1.207615852, label: 3, color: '#35f870' },
  { x: -7.036882401, y: -1.097598076, label: 3, color: '#35f870' },
  { x: -7.151391983, y: -1.599840641, label: 3, color: '#35f870' },
  { x: -7.14846611, y: -1.215963364, label: 3, color: '#35f870' },
  { x: -7.103531837, y: -0.914556503, label: 3, color: '#35f870' },
  { x: -7.034622192, y: -1.872185707, label: 3, color: '#35f870' },
  { x: -6.862075806, y: -1.455245972, label: 3, color: '#35f870' },
  { x: -7.062643051, y: -1.649346828, label: 3, color: '#35f870' },
  { x: -6.740353584, y: -1.576478481, label: 3, color: '#35f870' },
  { x: -7.046753883, y: -1.606340408, label: 3, color: '#35f870' },
  { x: -7.180635452, y: -1.508586884, label: 3, color: '#35f870' },
  { x: -6.936489105, y: -1.586112976, label: 3, color: '#35f870' },
  { x: -7.230886459, y: -1.169998169, label: 3, color: '#35f870' },
  { x: -7.929555893, y: 2.372351646, label: 3, color: '#35f870' },
  { x: -7.137729645, y: -1.511370182, label: 3, color: '#35f870' },
  { x: -7.949322701, y: 2.402285576, label: 3, color: '#35f870' },
  { x: -6.844144821, y: -1.70126152, label: 3, color: '#35f870' },
  { x: -6.818058014, y: -1.27554512, label: 3, color: '#35f870' },
  { x: -7.129024506, y: -1.171595573, label: 3, color: '#35f870' },
  { x: -6.476488113, y: 1.101548195, label: 3, color: '#35f870' },
  { x: -6.972341537, y: -1.228534698, label: 3, color: '#35f870' },
  { x: -6.897594452, y: -1.773733139, label: 3, color: '#35f870' },
  { x: -6.920681953, y: -1.787220001, label: 3, color: '#35f870' },
  { x: -8.074327469, y: 2.47782135, label: 3, color: '#35f870' },
  { x: 10.00251198, y: 3.390879631, label: 6, color: '#4386df' },
  { x: -7.041595459, y: -1.283695221, label: 3, color: '#35f870' },
  { x: -7.387989044, y: 0.628786087, label: 3, color: '#35f870' },
  { x: -7.514775276, y: -1.260064125, label: 3, color: '#35f870' },
  { x: -7.233084679, y: -1.334972858, label: 3, color: '#35f870' },
  { x: -8.112715721, y: 2.431512833, label: 3, color: '#35f870' },
  { x: 9.896982193, y: 3.413578987, label: 6, color: '#4386df' },
  { x: 10.66177368, y: 3.612926483, label: 6, color: '#4386df' },
  { x: -7.891140938, y: 2.239173412, label: 3, color: '#35f870' },
  { x: -7.068956375, y: -1.674844742, label: 3, color: '#35f870' },
  { x: -7.862215042, y: 1.826706886, label: 3, color: '#35f870' },
  { x: -6.951372147, y: -1.799602985, label: 3, color: '#35f870' },
  { x: -6.978047371, y: -1.44382143, label: 3, color: '#35f870' },
  { x: -6.99359417, y: -1.652432442, label: 3, color: '#35f870' },
  { x: -6.745992661, y: -1.664476395, label: 3, color: '#35f870' },
  { x: -6.769350052, y: -1.726242065, label: 3, color: '#35f870' },
  { x: -6.901869774, y: -1.91101265, label: 3, color: '#35f870' },
  { x: -6.867370605, y: 0.306711197, label: 3, color: '#35f870' },
  { x: -6.853075981, y: -1.404998779, label: 3, color: '#35f870' },
  { x: -6.858839989, y: 0.697832584, label: 3, color: '#35f870' },
  { x: -5.932910919, y: 1.416967392, label: 3, color: '#35f870' },
  { x: -6.932128906, y: -1.585098743, label: 3, color: '#35f870' },
  { x: -6.93684864, y: -1.790443897, label: 3, color: '#35f870' },
  { x: 10.46483326, y: 4.139303207, label: 6, color: '#4386df' },
  { x: -7.208506584, y: -1.168684959, label: 3, color: '#35f870' },
  { x: -7.85737896, y: 1.792960167, label: 3, color: '#35f870' },
  { x: -6.717578888, y: -1.615644455, label: 3, color: '#35f870' },
  { x: -7.615415573, y: 1.100837708, label: 3, color: '#35f870' },
  { x: -7.237512589, y: -1.735369205, label: 3, color: '#35f870' },
  { x: -7.243828773, y: -1.438832283, label: 3, color: '#35f870' },
  { x: -7.108335495, y: -1.533754349, label: 3, color: '#35f870' },
  { x: -7.059119225, y: -1.203077316, label: 3, color: '#35f870' },
  { x: -6.748696327, y: -1.675403595, label: 3, color: '#35f870' },
  { x: -7.171598434, y: -1.71381855, label: 3, color: '#35f870' },
  { x: -6.886188507, y: -1.539693832, label: 3, color: '#35f870' },
  { x: -7.973361969, y: 2.273887634, label: 3, color: '#35f870' },
  { x: -7.946412086, y: 1.345900536, label: 3, color: '#35f870' },
  { x: -7.061151505, y: -1.327893257, label: 3, color: '#35f870' },
  { x: -6.707130432, y: -1.781877518, label: 3, color: '#35f870' },
  { x: -7.092079163, y: -0.648621559, label: 3, color: '#35f870' },
  { x: -7.140111923, y: -1.194297791, label: 3, color: '#35f870' },
  { x: -7.000246048, y: -1.374893188, label: 3, color: '#35f870' },
  { x: -7.679502487, y: 1.508317947, label: 3, color: '#35f870' },
  { x: -7.293304443, y: -1.120465279, label: 3, color: '#35f870' },
  { x: -8.037302017, y: 2.408098698, label: 3, color: '#35f870' },
  { x: -6.908383369, y: -1.656509399, label: 3, color: '#35f870' },
  { x: -7.06258297, y: -1.794460773, label: 3, color: '#35f870' },
  { x: -7.937088013, y: 2.414079666, label: 3, color: '#35f870' },
  { x: -6.808169365, y: -1.698831558, label: 3, color: '#35f870' },
  { x: 9.466198921, y: 3.640824318, label: 6, color: '#4386df' },
  { x: -6.67762661, y: -1.707660675, label: 3, color: '#35f870' },
  { x: -4.789168835, y: 2.608493805, label: 3, color: '#35f870' },
  { x: -7.788887024, y: 1.979802608, label: 3, color: '#35f870' },
  { x: -6.949875832, y: -0.925647736, label: 3, color: '#35f870' },
  { x: -7.014462471, y: -1.684000969, label: 3, color: '#35f870' },
  { x: -5.026834488, y: 2.718886375, label: 3, color: '#35f870' },
  { x: -6.849603653, y: -1.537257195, label: 3, color: '#35f870' },
  { x: -7.907783508, y: 2.021864891, label: 3, color: '#35f870' },
  { x: 9.362477303, y: 3.834012985, label: 6, color: '#4386df' },
  { x: -7.185228348, y: -1.590952396, label: 3, color: '#35f870' },
  { x: -6.57966423, y: 1.081341743, label: 3, color: '#35f870' },
  { x: -7.253126144, y: -1.088711262, label: 3, color: '#35f870' },
  { x: -6.226999283, y: 1.210115433, label: 3, color: '#35f870' },
  { x: -7.918874741, y: 2.208125591, label: 3, color: '#35f870' },
  { x: -6.699584961, y: -1.201224327, label: 3, color: '#35f870' },
  { x: -7.077943802, y: -1.99267292, label: 3, color: '#35f870' },
  { x: 9.969797134, y: 3.349876404, label: 6, color: '#4386df' },
  { x: -7.215982437, y: -1.421699524, label: 3, color: '#35f870' },
  { x: -7.042183876, y: -1.011302948, label: 3, color: '#35f870' },
  { x: -7.122986794, y: -0.975259781, label: 3, color: '#35f870' },
  { x: -7.011238098, y: -1.746189117, label: 3, color: '#35f870' },
  { x: -6.810317993, y: -1.383777618, label: 3, color: '#35f870' },
  { x: -6.583250046, y: -0.741969109, label: 3, color: '#35f870' },
  { x: -7.025244713, y: -1.125747681, label: 3, color: '#35f870' },
  { x: -6.842043877, y: -1.486815453, label: 3, color: '#35f870' },
  { x: -6.629285812, y: -1.231669426, label: 3, color: '#35f870' },
  { x: -6.986941338, y: -1.305542946, label: 3, color: '#35f870' },
  { x: -7.099466324, y: -1.945679665, label: 3, color: '#35f870' },
  { x: -7.637235641, y: 0.91478157, label: 3, color: '#35f870' },
  { x: -6.942363739, y: -1.73097229, label: 3, color: '#35f870' },
  { x: -7.068452835, y: -1.460373878, label: 3, color: '#35f870' },
  { x: 9.614131927, y: 3.591267586, label: 6, color: '#4386df' },
  { x: -6.809915543, y: -1.672269821, label: 3, color: '#35f870' },
  { x: -6.551589966, y: -0.439032078, label: 3, color: '#35f870' },
  { x: -7.076693535, y: -1.759281158, label: 3, color: '#35f870' },
  { x: -7.195201874, y: -0.84337616, label: 3, color: '#35f870' },
  { x: 10.18993759, y: 3.222777367, label: 6, color: '#4386df' },
  { x: -6.934303284, y: -1.238914967, label: 3, color: '#35f870' },
  { x: -6.600989342, y: -1.266202927, label: 3, color: '#35f870' },
  { x: -5.244721413, y: 2.572677612, label: 3, color: '#35f870' },
  { x: -7.04887867, y: -1.739795685, label: 3, color: '#35f870' },
  { x: -6.921754837, y: 0.807709694, label: 3, color: '#35f870' },
  { x: -6.824764252, y: -1.562550545, label: 3, color: '#35f870' },
  { x: -6.932590485, y: -1.592409134, label: 3, color: '#35f870' },
  { x: -6.874705315, y: -1.537758827, label: 3, color: '#35f870' },
  { x: -6.73166275, y: -1.811175823, label: 3, color: '#35f870' },
  { x: -6.871511459, y: -1.257551193, label: 3, color: '#35f870' },
  { x: -6.940390587, y: -1.121959686, label: 3, color: '#35f870' },
  { x: -6.964086533, y: -1.580996513, label: 3, color: '#35f870' },
  { x: -6.901292801, y: -1.656047821, label: 3, color: '#35f870' },
  { x: -6.685142517, y: -1.8314991, label: 3, color: '#35f870' },
  { x: -6.66818428, y: -1.414607048, label: 3, color: '#35f870' },
  { x: -6.644386292, y: -1.550657272, label: 3, color: '#35f870' },
  { x: -6.706330299, y: -1.548721313, label: 3, color: '#35f870' },
  { x: -7.307523727, y: -1.234534264, label: 3, color: '#35f870' },
  { x: -6.923314095, y: 0.016004562, label: 3, color: '#35f870' },
  { x: -7.155099869, y: -1.272928238, label: 3, color: '#35f870' },
  { x: -6.843611717, y: -1.506411552, label: 3, color: '#35f870' },
  { x: -6.834816933, y: -1.485199928, label: 3, color: '#35f870' },
  { x: -7.309612274, y: -0.922203064, label: 3, color: '#35f870' },
  { x: -7.800303459, y: 2.325610161, label: 3, color: '#35f870' },
  { x: -6.875591278, y: -1.470422745, label: 3, color: '#35f870' },
  { x: -6.753806114, y: -1.732532501, label: 3, color: '#35f870' },
  { x: -6.910687447, y: -1.578625679, label: 3, color: '#35f870' },
  { x: -6.778289795, y: -1.424934387, label: 3, color: '#35f870' },
  { x: -7.332408905, y: -0.754329681, label: 3, color: '#35f870' },
  { x: -6.846933365, y: -1.682420731, label: 3, color: '#35f870' },
  { x: -6.913473129, y: -1.390948772, label: 3, color: '#35f870' },
  { x: -6.932466507, y: -1.406777382, label: 3, color: '#35f870' },
  { x: -7.027562141, y: -1.381184578, label: 3, color: '#35f870' },
  { x: -6.803938866, y: -1.566697121, label: 3, color: '#35f870' },
  { x: -6.829776764, y: -1.630179405, label: 3, color: '#35f870' },
  { x: -6.108553886, y: 1.213871002, label: 3, color: '#35f870' },
  { x: -6.768520355, y: -1.451178551, label: 3, color: '#35f870' },
  { x: 9.409368515, y: 3.575712204, label: 6, color: '#4386df' },
  { x: -6.966960907, y: -1.387417793, label: 3, color: '#35f870' },
  { x: -6.881836891, y: -1.728248596, label: 3, color: '#35f870' },
  { x: -7.012529373, y: -1.377273083, label: 3, color: '#35f870' },
  { x: -7.103578568, y: -1.520107269, label: 3, color: '#35f870' },
  { x: -7.809490204, y: 1.194681168, label: 3, color: '#35f870' },
  { x: -7.746299744, y: 2.204384327, label: 3, color: '#35f870' },
  { x: -7.194236755, y: -1.614431381, label: 3, color: '#35f870' },
  { x: -5.96774292, y: 1.346043587, label: 3, color: '#35f870' },
  { x: -7.227380753, y: -1.537250519, label: 3, color: '#35f870' },
  { x: 10.19886589, y: 3.397604942, label: 6, color: '#4386df' },
  { x: -6.905319214, y: -1.382823944, label: 3, color: '#35f870' },
  { x: -7.616913795, y: 2.076048851, label: 3, color: '#35f870' },
  { x: -6.910896301, y: -1.199284077, label: 3, color: '#35f870' },
  { x: -7.128669739, y: -0.583851814, label: 3, color: '#35f870' },
  { x: -7.415185928, y: 0.144048691, label: 3, color: '#35f870' },
  { x: 10.76345062, y: 3.263624191, label: 6, color: '#4386df' },
  { x: -6.184610367, y: -0.753656387, label: 3, color: '#35f870' },
  { x: -6.173149109, y: -0.589488983, label: 3, color: '#35f870' },
  { x: -6.205114365, y: -0.796316147, label: 3, color: '#35f870' },
  { x: -6.202856064, y: -0.741546631, label: 3, color: '#35f870' },
  { x: -6.547065735, y: 0.354447365, label: 3, color: '#35f870' },
  { x: -7.650146484, y: 1.896722794, label: 3, color: '#35f870' },
  { x: -7.436266899, y: 1.627158165, label: 3, color: '#35f870' },
  { x: -6.24549675, y: -0.834379196, label: 3, color: '#35f870' },
  { x: -7.425794601, y: 1.43556881, label: 3, color: '#35f870' },
  { x: -6.128287315, y: -0.498939037, label: 3, color: '#35f870' },
  { x: -6.135138512, y: -0.734847069, label: 3, color: '#35f870' },
  { x: -6.243080139, y: -0.711877823, label: 3, color: '#35f870' },
  { x: -6.201603889, y: -0.868862152, label: 3, color: '#35f870' },
  { x: -6.153511047, y: -0.748366833, label: 3, color: '#35f870' },
  { x: -6.136826515, y: -0.756835461, label: 3, color: '#35f870' },
  { x: -6.146746635, y: -0.678366661, label: 3, color: '#35f870' },
  { x: -6.128379822, y: -0.688929558, label: 3, color: '#35f870' },
  { x: -6.196485519, y: -0.741186619, label: 3, color: '#35f870' },
  { x: -6.133162498, y: -0.761089325, label: 3, color: '#35f870' },
  { x: -6.213113785, y: -0.718707085, label: 3, color: '#35f870' },
  { x: 10.79258537, y: 3.239379883, label: 6, color: '#4386df' },
  { x: -6.158823013, y: -0.81360054, label: 3, color: '#35f870' },
  { x: -6.198409081, y: -0.817473412, label: 3, color: '#35f870' },
  { x: 10.74978065, y: 3.334747314, label: 6, color: '#4386df' },
  { x: -6.147161484, y: -0.875997543, label: 3, color: '#35f870' },
  { x: -6.158789635, y: -0.82717514, label: 3, color: '#35f870' },
  { x: -6.290275574, y: -0.820177078, label: 3, color: '#35f870' },
  { x: -6.156051636, y: -0.719142914, label: 3, color: '#35f870' },
  { x: 10.55333233, y: 3.526262283, label: 6, color: '#4386df' },
  { x: -6.291904449, y: -0.927279472, label: 3, color: '#35f870' },
  { x: -6.160311699, y: -0.69797039, label: 3, color: '#35f870' },
  { x: 15.14576149, y: -2.731256485, label: 6, color: '#4386df' },
  { x: -6.169924736, y: -0.825520515, label: 3, color: '#35f870' },
  { x: 10.86060143, y: 3.141561985, label: 6, color: '#4386df' },
  { x: -6.309789658, y: -0.880905151, label: 3, color: '#35f870' },
  { x: -6.142852783, y: -0.740520477, label: 3, color: '#35f870' },
  { x: -6.151004791, y: -0.732934952, label: 3, color: '#35f870' },
  { x: -6.207483292, y: -0.858754158, label: 3, color: '#35f870' },
  { x: -6.14484024, y: -0.786102295, label: 3, color: '#35f870' },
  { x: -6.185376167, y: -0.78068161, label: 3, color: '#35f870' },
  { x: 9.805549622, y: 3.443575859, label: 6, color: '#4386df' },
  { x: -6.18196106, y: -0.829788208, label: 3, color: '#35f870' },
  { x: -6.157222748, y: -0.66094017, label: 3, color: '#35f870' },
  { x: -6.169212341, y: -0.657271385, label: 3, color: '#35f870' },
  { x: -6.463472366, y: -0.648023605, label: 3, color: '#35f870' },
  { x: 10.09102535, y: 3.377080441, label: 6, color: '#4386df' },
  { x: -6.186722755, y: -0.669645309, label: 3, color: '#35f870' },
  { x: -7.693887711, y: 2.036157608, label: 3, color: '#35f870' },
  { x: -6.140148163, y: 1.037578106, label: 3, color: '#35f870' },
  { x: 9.236988068, y: 4.389026642, label: 6, color: '#4386df' },
  { x: -6.219718933, y: -0.915318489, label: 3, color: '#35f870' },
  { x: -6.158948898, y: -0.802219391, label: 3, color: '#35f870' },
  { x: -6.143621445, y: -0.838138103, label: 3, color: '#35f870' },
  { x: -6.177419662, y: -0.764734268, label: 3, color: '#35f870' },
  { x: -7.714979172, y: 1.908421516, label: 3, color: '#35f870' },
  { x: -6.471160889, y: 0.215013504, label: 3, color: '#35f870' },
  { x: 10.42500877, y: 3.636982918, label: 6, color: '#4386df' },
  { x: 10.63535786, y: 3.313114166, label: 6, color: '#4386df' },
  { x: -6.14696312, y: -0.787692547, label: 3, color: '#35f870' },
  { x: -6.15790081, y: -0.743243217, label: 3, color: '#35f870' },
  { x: -7.59677887, y: 1.401186943, label: 3, color: '#35f870' },
  { x: 10.79063797, y: 3.237384796, label: 6, color: '#4386df' },
  { x: -6.146892548, y: -0.65602684, label: 3, color: '#35f870' },
  { x: -6.994109154, y: 0.942931175, label: 3, color: '#35f870' },
  { x: -6.139698982, y: -0.732853413, label: 3, color: '#35f870' },
  { x: -6.225041389, y: -0.620306969, label: 3, color: '#35f870' },
  { x: -6.200143814, y: -0.500683784, label: 3, color: '#35f870' },
  { x: -6.243945122, y: 0.156686783, label: 3, color: '#35f870' },
  { x: -7.6033535, y: 1.706952572, label: 3, color: '#35f870' },
  { x: -6.166611671, y: -0.73842144, label: 3, color: '#35f870' },
  { x: -6.117616653, y: -0.808288574, label: 3, color: '#35f870' },
  { x: -6.173569679, y: 0.329454899, label: 3, color: '#35f870' },
  { x: -6.143476486, y: -0.513175964, label: 3, color: '#35f870' },
  { x: -6.166867256, y: -0.841691017, label: 3, color: '#35f870' },
  { x: -6.084724426, y: 0.413162231, label: 3, color: '#35f870' },
  { x: -6.706082344, y: -1.359188557, label: 3, color: '#35f870' },
  { x: -6.446273804, y: -1.266895294, label: 3, color: '#35f870' },
  { x: -6.259545326, y: -0.879342079, label: 3, color: '#35f870' },
  { x: -6.561038971, y: -1.408230782, label: 3, color: '#35f870' },
  { x: 10.14653873, y: 3.91724205, label: 6, color: '#4386df' },
  { x: 15.20455933, y: -2.814069748, label: 6, color: '#4386df' },
  { x: -6.758483887, y: -1.471178055, label: 3, color: '#35f870' },
  { x: 15.17021561, y: -2.873277664, label: 6, color: '#4386df' },
  { x: 10.57151985, y: 3.488174438, label: 6, color: '#4386df' },
  { x: -6.304618835, y: -1.334272385, label: 3, color: '#35f870' },
  { x: -6.25636673, y: -1.207488537, label: 3, color: '#35f870' },
  { x: -6.236049652, y: -1.205020905, label: 3, color: '#35f870' },
  { x: -6.238887787, y: -1.246635437, label: 3, color: '#35f870' },
  { x: -6.279249191, y: -1.321056366, label: 3, color: '#35f870' },
  { x: -6.384908676, y: -1.081679821, label: 3, color: '#35f870' },
  { x: -7.213436127, y: -1.592907429, label: 3, color: '#35f870' },
  { x: -7.136407852, y: -1.164407253, label: 3, color: '#35f870' },
  { x: -6.852974892, y: -1.820607185, label: 3, color: '#35f870' },
  { x: -6.924404144, y: -1.833551407, label: 3, color: '#35f870' },
  { x: -8.054358482, y: 2.239146233, label: 3, color: '#35f870' },
  { x: -7.145084381, y: -1.011741638, label: 3, color: '#35f870' },
  { x: -7.0235672, y: -1.667779922, label: 3, color: '#35f870' },
  { x: -6.332485199, y: 1.164373398, label: 3, color: '#35f870' },
  { x: -7.028352737, y: 0.866055489, label: 3, color: '#35f870' },
  { x: -7.116943359, y: -1.030422211, label: 3, color: '#35f870' },
  { x: -8.013568878, y: 2.343069553, label: 3, color: '#35f870' },
  { x: -8.039615631, y: 0.817399979, label: 3, color: '#35f870' },
  { x: -7.355457306, y: -1.316291809, label: 3, color: '#35f870' },
  { x: -7.062297821, y: -1.603638649, label: 3, color: '#35f870' },
  { x: -6.942554474, y: -1.500570297, label: 3, color: '#35f870' },
  { x: -6.98434639, y: -1.815425873, label: 3, color: '#35f870' },
  { x: -6.864202499, y: -1.788360596, label: 3, color: '#35f870' },
  { x: 8.985976219, y: 6.381911278, label: 6, color: '#4386df' },
  { x: -6.852905273, y: -1.329695702, label: 3, color: '#35f870' },
  { x: -7.292484283, y: -1.805903435, label: 3, color: '#35f870' },
  { x: -7.439628601, y: 1.64632225, label: 3, color: '#35f870' },
  { x: -6.739685059, y: 0.838640213, label: 3, color: '#35f870' },
  { x: -7.133183479, y: -1.198160172, label: 3, color: '#35f870' },
  { x: -7.106521606, y: -1.887836456, label: 3, color: '#35f870' },
  { x: -7.271398544, y: -1.939002037, label: 3, color: '#35f870' },
  { x: -7.038877487, y: -1.578966141, label: 3, color: '#35f870' },
  { x: -8.004447937, y: 2.155907631, label: 3, color: '#35f870' },
  { x: -6.995171547, y: -1.643911362, label: 3, color: '#35f870' },
  { x: -7.136541367, y: -1.677618027, label: 3, color: '#35f870' },
  { x: -6.886016846, y: -1.869215012, label: 3, color: '#35f870' },
  { x: -6.028715134, y: -0.263277054, label: 3, color: '#35f870' },
  { x: -7.999482155, y: 2.144127846, label: 3, color: '#35f870' },
  { x: 14.17942047, y: -0.43409729, label: 6, color: '#4386df' },
  { x: -7.127403259, y: -1.634709358, label: 3, color: '#35f870' },
  { x: 9.546835899, y: 3.541644096, label: 6, color: '#4386df' },
  { x: 9.814886093, y: 4.306685448, label: 6, color: '#4386df' },
  { x: 14.18987274, y: -0.427400589, label: 6, color: '#4386df' },
  { x: 10.60190773, y: 3.472034454, label: 6, color: '#4386df' },
  { x: 14.12062454, y: -0.37557888, label: 6, color: '#4386df' },
  { x: -6.962005615, y: -1.545543671, label: 3, color: '#35f870' },
  { x: 10.61456394, y: 3.568926811, label: 6, color: '#4386df' },
  { x: -7.037388802, y: -1.730766296, label: 3, color: '#35f870' },
  { x: -7.02314949, y: -1.588882446, label: 3, color: '#35f870' },
  { x: -6.333994865, y: 1.158632278, label: 3, color: '#35f870' },
  { x: -7.030543327, y: -1.406890869, label: 3, color: '#35f870' },
  { x: -6.433092117, y: 0.649144173, label: 3, color: '#35f870' },
  { x: -7.437943459, y: -0.764558792, label: 3, color: '#35f870' },
  { x: -7.458319664, y: -1.644648552, label: 3, color: '#35f870' },
  { x: 14.50583458, y: 0.084444046, label: 6, color: '#4386df' },
  { x: -7.491758347, y: -0.038823605, label: 3, color: '#35f870' },
  { x: -7.216823578, y: -1.078159332, label: 3, color: '#35f870' },
  { x: -7.347442627, y: -0.481883049, label: 3, color: '#35f870' },
  { x: -7.250402451, y: -1.881752014, label: 3, color: '#35f870' },
  { x: -6.018893242, y: 1.477254868, label: 3, color: '#35f870' },
  { x: -7.559343338, y: -0.666339397, label: 3, color: '#35f870' },
  { x: -7.152420044, y: -1.601023674, label: 3, color: '#35f870' },
  { x: -7.402698517, y: -1.661187172, label: 3, color: '#35f870' },
  { x: 10.62870026, y: 3.388814926, label: 6, color: '#4386df' },
  { x: -7.218196869, y: 1.207961082, label: 3, color: '#35f870' },
  { x: -7.111374855, y: -1.614364624, label: 3, color: '#35f870' },
  { x: 8.528630257, y: 4.304351807, label: 6, color: '#4386df' },
  { x: -6.915218353, y: -1.810411453, label: 3, color: '#35f870' },
  { x: 9.291274071, y: 3.837100029, label: 6, color: '#4386df' },
  { x: -7.993613243, y: 1.866819382, label: 3, color: '#35f870' },
  { x: -7.967926979, y: -0.424342155, label: 3, color: '#35f870' },
  { x: -7.212003708, y: -0.892094612, label: 3, color: '#35f870' },
  { x: -6.962593079, y: -1.685507774, label: 3, color: '#35f870' },
  { x: -7.131242752, y: -1.52365303, label: 3, color: '#35f870' },
  { x: -7.879300117, y: 2.267971992, label: 3, color: '#35f870' },
  { x: -6.996059418, y: -1.712675095, label: 3, color: '#35f870' },
  { x: -7.219835281, y: -1.539117813, label: 3, color: '#35f870' },
  { x: -7.91599369, y: 2.004504204, label: 3, color: '#35f870' },
  { x: -8.166318893, y: 2.383464813, label: 3, color: '#35f870' },
  { x: -6.921065331, y: -1.820957661, label: 3, color: '#35f870' },
  { x: -6.898775101, y: -1.748333931, label: 3, color: '#35f870' },
  { x: -7.178397179, y: -1.341020584, label: 3, color: '#35f870' },
  { x: -7.212855339, y: -1.804965973, label: 3, color: '#35f870' },
  { x: -7.162209511, y: -1.119610786, label: 3, color: '#35f870' },
  { x: -7.210753441, y: -1.47241497, label: 3, color: '#35f870' },
  { x: -6.975464821, y: -1.723736763, label: 3, color: '#35f870' },
  { x: -7.247940063, y: -0.715027809, label: 3, color: '#35f870' },
  { x: -6.764141083, y: -1.807586193, label: 3, color: '#35f870' },
  { x: -7.390169144, y: -1.707198143, label: 3, color: '#35f870' },
  { x: -7.219183922, y: -1.7576828, label: 3, color: '#35f870' },
  { x: -6.797990799, y: -1.401703835, label: 3, color: '#35f870' },
  { x: -7.032054901, y: 0.222016335, label: 3, color: '#35f870' },
  { x: -7.07285881, y: -0.899914742, label: 3, color: '#35f870' },
  { x: -5.040878296, y: 2.79621315, label: 3, color: '#35f870' },
  { x: -6.841783524, y: -1.66784668, label: 3, color: '#35f870' },
  { x: -7.043465614, y: -1.86191082, label: 3, color: '#35f870' },
  { x: -7.238773346, y: 0.143608093, label: 3, color: '#35f870' },
  { x: -7.286679268, y: -0.719139099, label: 3, color: '#35f870' },
  { x: -7.299115181, y: -1.317690849, label: 3, color: '#35f870' },
  { x: 9.136600494, y: 4.026707649, label: 6, color: '#4386df' },
  { x: -6.849803925, y: -1.709239006, label: 3, color: '#35f870' },
  { x: -7.039123535, y: -1.652086258, label: 3, color: '#35f870' },
  { x: -6.138656616, y: -0.383115768, label: 3, color: '#35f870' },
  { x: -6.037700653, y: -0.301978111, label: 3, color: '#35f870' },
  { x: -6.889365196, y: -1.597515106, label: 3, color: '#35f870' },
  { x: -6.921426773, y: -1.575556755, label: 3, color: '#35f870' },
  { x: -7.287042618, y: -0.615282059, label: 3, color: '#35f870' },
  { x: -6.660560608, y: -1.635803223, label: 3, color: '#35f870' },
  { x: -7.85137558, y: 1.904932022, label: 3, color: '#35f870' },
  { x: -7.133863449, y: -1.780166626, label: 3, color: '#35f870' },
  { x: -7.08603096, y: -1.370547295, label: 3, color: '#35f870' },
  { x: -7.198168755, y: -0.467189789, label: 3, color: '#35f870' },
  { x: -6.760223389, y: -1.499788284, label: 3, color: '#35f870' },
  { x: -8.026329041, y: 2.041696548, label: 3, color: '#35f870' },
  { x: -6.894585609, y: -1.624742508, label: 3, color: '#35f870' },
  { x: -6.928325653, y: -1.714681625, label: 3, color: '#35f870' },
  { x: -7.316551208, y: -1.394535065, label: 3, color: '#35f870' },
  { x: -7.032961845, y: -1.494310379, label: 3, color: '#35f870' },
  { x: -7.188346863, y: -0.889306068, label: 3, color: '#35f870' },
  { x: 8.973064423, y: 3.78237915, label: 6, color: '#4386df' },
  { x: -7.235343933, y: -1.11479187, label: 3, color: '#35f870' },
  { x: -7.542142868, y: -1.050193787, label: 3, color: '#35f870' },
  { x: -7.036882401, y: -1.423095703, label: 3, color: '#35f870' },
  { x: -7.138893127, y: -1.112588882, label: 3, color: '#35f870' },
  { x: -7.18903923, y: -1.581176758, label: 3, color: '#35f870' },
  { x: -6.842118263, y: -1.69288063, label: 3, color: '#35f870' },
  { x: -7.415063858, y: -1.450435638, label: 3, color: '#35f870' },
  { x: 10.4038353, y: 3.616838455, label: 6, color: '#4386df' },
  { x: 9.394015312, y: 3.65312767, label: 6, color: '#4386df' },
  { x: -7.058177948, y: -1.746483803, label: 3, color: '#35f870' },
  { x: -7.259559631, y: -1.30216217, label: 3, color: '#35f870' },
  { x: -7.19081974, y: -1.609749794, label: 3, color: '#35f870' },
  { x: -6.487520218, y: 1.084173203, label: 3, color: '#35f870' },
  { x: -7.135393143, y: -1.193158627, label: 3, color: '#35f870' },
  { x: -6.783260345, y: -1.518342018, label: 3, color: '#35f870' },
  { x: -7.601833344, y: -1.056399822, label: 3, color: '#35f870' },
  { x: -5.480464935, y: 2.579445362, label: 3, color: '#35f870' },
  { x: -6.428040504, y: 0.968712807, label: 3, color: '#35f870' },
  { x: -6.770065308, y: -1.692070007, label: 3, color: '#35f870' },
  { x: -6.968613625, y: -1.518971443, label: 3, color: '#35f870' },
  { x: -7.213500977, y: -1.341538906, label: 3, color: '#35f870' },
  { x: -7.286436081, y: -0.738360405, label: 3, color: '#35f870' },
  { x: -6.902288437, y: -0.033908844, label: 3, color: '#35f870' },
  { x: -7.311105728, y: -0.540429115, label: 3, color: '#35f870' },
  { x: -6.915715218, y: -1.501505375, label: 3, color: '#35f870' },
  { x: -7.513799667, y: 0.492744446, label: 3, color: '#35f870' },
  { x: -7.115661621, y: -1.368167877, label: 3, color: '#35f870' },
  { x: -7.411973, y: -0.725278854, label: 3, color: '#35f870' },
  { x: -6.995567322, y: -1.085431099, label: 3, color: '#35f870' },
  { x: -8.002288818, y: 2.255577087, label: 3, color: '#35f870' },
  { x: -6.74508667, y: -1.705852509, label: 3, color: '#35f870' },
  { x: -6.766448021, y: -1.747411728, label: 3, color: '#35f870' },
  { x: -7.214292526, y: -1.887792587, label: 3, color: '#35f870' },
  { x: -6.820520401, y: -1.758008957, label: 3, color: '#35f870' },
  { x: -7.013172626, y: -1.494752407, label: 3, color: '#35f870' },
  { x: -7.453219414, y: -2.02170372, label: 3, color: '#35f870' },
  { x: 9.577438354, y: 3.562996387, label: 6, color: '#4386df' },
  { x: -6.026478767, y: -0.242155075, label: 3, color: '#35f870' },
  { x: -6.949199677, y: 0.236344337, label: 3, color: '#35f870' },
  { x: -6.886173248, y: -1.423907757, label: 3, color: '#35f870' },
  { x: -8.163638115, y: 2.390210152, label: 3, color: '#35f870' },
  { x: -7.800182343, y: 2.311362267, label: 3, color: '#35f870' },
  { x: -7.066309929, y: -1.022952557, label: 3, color: '#35f870' },
  { x: -7.9126091, y: 2.245487213, label: 3, color: '#35f870' },
  { x: -7.136790276, y: -1.448048592, label: 3, color: '#35f870' },
  { x: -8.088723183, y: 2.27603054, label: 3, color: '#35f870' },
  { x: -6.698181152, y: 0.464725494, label: 3, color: '#35f870' },
  { x: -6.918740273, y: -1.697733879, label: 3, color: '#35f870' },
  { x: -6.815191269, y: -1.725528717, label: 3, color: '#35f870' },
  { x: -6.792438507, y: -1.828977585, label: 3, color: '#35f870' },
  { x: 9.836626053, y: 3.48439312, label: 6, color: '#4386df' },
  { x: -7.811771393, y: 2.396003723, label: 3, color: '#35f870' },
  { x: -6.811189651, y: -1.620125771, label: 3, color: '#35f870' },
  { x: -6.836191177, y: -1.862472534, label: 3, color: '#35f870' },
  { x: -7.458536148, y: 2.071140766, label: 3, color: '#35f870' },
  { x: -7.196649551, y: -1.373810768, label: 3, color: '#35f870' },
  { x: -7.295516014, y: -1.831523895, label: 3, color: '#35f870' },
  { x: -6.77802372, y: -1.650882721, label: 3, color: '#35f870' },
  { x: -6.87871933, y: -1.595074177, label: 3, color: '#35f870' },
  { x: -7.170717239, y: -1.352165699, label: 3, color: '#35f870' },
  { x: -7.049621582, y: -1.661205292, label: 3, color: '#35f870' },
  { x: -7.216397285, y: -1.244898796, label: 3, color: '#35f870' },
  { x: -6.914300919, y: 0.604539871, label: 3, color: '#35f870' },
  { x: -6.903939247, y: -1.653831482, label: 3, color: '#35f870' },
  { x: -8.043455124, y: 2.449251175, label: 3, color: '#35f870' },
  { x: -7.170433044, y: -1.193065643, label: 3, color: '#35f870' },
  { x: -6.022504807, y: 1.307910919, label: 3, color: '#35f870' },
  { x: 8.531798363, y: 4.867804527, label: 6, color: '#4386df' },
  { x: -7.165914536, y: -1.003928185, label: 3, color: '#35f870' },
  { x: -6.636693954, y: 0.926071167, label: 3, color: '#35f870' },
  { x: -6.805617332, y: 0.422909737, label: 3, color: '#35f870' },
  { x: -7.254036903, y: -0.505418301, label: 3, color: '#35f870' },
  { x: -7.763210297, y: 2.118972778, label: 3, color: '#35f870' },
  { x: -7.23100853, y: -0.07147789, label: 3, color: '#35f870' },
  { x: -7.173815727, y: -0.704849243, label: 3, color: '#35f870' },
  { x: -6.895902634, y: -1.374041557, label: 3, color: '#35f870' },
  { x: -6.854572296, y: -1.693758011, label: 3, color: '#35f870' },
  { x: -6.971928596, y: -1.788843155, label: 3, color: '#35f870' },
  { x: -6.873805046, y: -1.742856979, label: 3, color: '#35f870' },
  { x: -6.855361938, y: -1.697451591, label: 3, color: '#35f870' },
  { x: -6.789604187, y: -1.652412415, label: 3, color: '#35f870' },
  { x: -7.221955299, y: -1.065117836, label: 3, color: '#35f870' },
  { x: -7.664321899, y: 2.199388027, label: 3, color: '#35f870' },
  { x: -7.112014771, y: -1.319479942, label: 3, color: '#35f870' },
  { x: -7.341139793, y: -1.166564941, label: 3, color: '#35f870' },
  { x: -8.001606941, y: 2.229182243, label: 3, color: '#35f870' },
  { x: -7.115356445, y: -1.240795135, label: 3, color: '#35f870' },
  { x: -6.595700264, y: 0.995446682, label: 3, color: '#35f870' },
  { x: -7.038286209, y: -1.406906128, label: 3, color: '#35f870' },
  { x: -7.811386108, y: 2.127382278, label: 3, color: '#35f870' },
  { x: 9.991918564, y: 3.453985214, label: 6, color: '#4386df' },
  { x: -7.062263489, y: -1.033295631, label: 3, color: '#35f870' },
  { x: -8.067285538, y: 2.171007156, label: 3, color: '#35f870' },
  { x: -7.084740639, y: -1.143140793, label: 3, color: '#35f870' },
  { x: -7.366780281, y: 0.116799355, label: 3, color: '#35f870' },
  { x: -6.949246407, y: -1.479811668, label: 3, color: '#35f870' },
  { x: -6.686096191, y: -1.790042877, label: 3, color: '#35f870' },
  { x: -6.777105331, y: -1.665644646, label: 3, color: '#35f870' },
  { x: 8.451858521, y: 4.791784286, label: 6, color: '#4386df' },
  { x: -7.008496284, y: -1.211868286, label: 3, color: '#35f870' },
  { x: -6.784589767, y: -1.727672577, label: 3, color: '#35f870' },
  { x: -6.882320404, y: -1.707120895, label: 3, color: '#35f870' },
  { x: -7.480859756, y: -1.048145294, label: 3, color: '#35f870' },
  { x: -6.621759415, y: -1.590598106, label: 3, color: '#35f870' },
  { x: -7.128503799, y: -1.564902306, label: 3, color: '#35f870' },
  { x: -6.895702362, y: -1.37054348, label: 3, color: '#35f870' },
  { x: -6.69140625, y: -1.714089394, label: 3, color: '#35f870' },
  { x: -6.811958313, y: -1.472745895, label: 3, color: '#35f870' },
  { x: -6.870741844, y: -1.603461266, label: 3, color: '#35f870' },
  { x: -7.011395454, y: -1.49362278, label: 3, color: '#35f870' },
  { x: -7.163099289, y: -0.817142487, label: 3, color: '#35f870' },
  { x: -7.802343369, y: 1.807113647, label: 3, color: '#35f870' },
  { x: -7.056564331, y: -1.512974739, label: 3, color: '#35f870' },
  { x: -6.931101799, y: -1.505437851, label: 3, color: '#35f870' },
  { x: -6.654826164, y: -1.722238541, label: 3, color: '#35f870' },
  { x: -6.711708069, y: -1.494288445, label: 3, color: '#35f870' },
  { x: -6.848880768, y: -1.336638451, label: 3, color: '#35f870' },
  { x: -7.004939079, y: -1.326555729, label: 3, color: '#35f870' },
  { x: -6.829436302, y: -1.358778477, label: 3, color: '#35f870' },
  { x: -6.39355278, y: 1.027833939, label: 3, color: '#35f870' },
  { x: 8.469110489, y: 4.752315521, label: 6, color: '#4386df' },
  { x: -6.891464233, y: -1.558798313, label: 3, color: '#35f870' },
  { x: -6.268184662, y: 1.158031464, label: 3, color: '#35f870' },
  { x: -7.018655777, y: -1.333939552, label: 3, color: '#35f870' },
  { x: -6.772254944, y: -1.686464787, label: 3, color: '#35f870' },
  { x: -6.633042336, y: -1.685706139, label: 3, color: '#35f870' },
  { x: -6.089502335, y: 1.253629684, label: 3, color: '#35f870' },
  { x: -6.211670876, y: 1.302425385, label: 3, color: '#35f870' },
  { x: 10.59028339, y: 3.53810215, label: 6, color: '#4386df' },
  { x: -6.609851837, y: -1.633520126, label: 3, color: '#35f870' },
  { x: -6.882640839, y: -1.592449188, label: 3, color: '#35f870' },
  { x: -7.026157379, y: -1.679834366, label: 3, color: '#35f870' },
  { x: -7.670027733, y: 2.019342422, label: 3, color: '#35f870' },
  { x: -6.847360611, y: -1.400531769, label: 3, color: '#35f870' },
  { x: 9.321398735, y: 3.834877968, label: 6, color: '#4386df' },
  { x: -6.777819633, y: -1.343204498, label: 3, color: '#35f870' },
  { x: -6.761134148, y: -1.656997681, label: 3, color: '#35f870' },
  { x: -7.009197235, y: -1.225982666, label: 3, color: '#35f870' },
  { x: -7.814585686, y: 1.839248657, label: 3, color: '#35f870' },
  { x: -6.801375389, y: -1.552061081, label: 3, color: '#35f870' },
  { x: -6.519360542, y: 1.180583, label: 3, color: '#35f870' },
  { x: -7.112071991, y: -1.725801468, label: 3, color: '#35f870' },
  { x: -6.915267944, y: -1.774529457, label: 3, color: '#35f870' },
  { x: -6.755994797, y: -1.529337883, label: 3, color: '#35f870' },
  { x: -6.815925598, y: -1.64041996, label: 3, color: '#35f870' },
  { x: -6.958405495, y: -1.214850426, label: 3, color: '#35f870' },
  { x: -6.904045105, y: -1.385935783, label: 3, color: '#35f870' },
  { x: -6.741094589, y: -1.664512634, label: 3, color: '#35f870' },
  { x: -7.26429081, y: -1.407439232, label: 3, color: '#35f870' },
  { x: -7.622374535, y: 2.259290695, label: 3, color: '#35f870' },
  { x: -7.215900421, y: -1.394779205, label: 3, color: '#35f870' },
  { x: -6.829615593, y: -1.452824593, label: 3, color: '#35f870' },
  { x: -6.823739052, y: -1.45923996, label: 3, color: '#35f870' },
  { x: -6.996448517, y: -1.642589569, label: 3, color: '#35f870' },
  { x: -6.909860611, y: -1.484664917, label: 3, color: '#35f870' },
  { x: -6.868829727, y: -1.746845245, label: 3, color: '#35f870' },
  { x: -6.864794731, y: -1.588850021, label: 3, color: '#35f870' },
  { x: -7.240163803, y: -1.517864227, label: 3, color: '#35f870' },
  { x: -6.863130569, y: -1.499370575, label: 3, color: '#35f870' },
  { x: -8.091620445, y: 1.932697296, label: 3, color: '#35f870' },
  { x: -6.884957314, y: -1.304647446, label: 3, color: '#35f870' },
  { x: -7.2322855, y: -1.180786133, label: 3, color: '#35f870' },
  { x: -6.911811829, y: -1.559936047, label: 3, color: '#35f870' },
  { x: -7.173857689, y: -1.062573433, label: 3, color: '#35f870' },
  { x: -7.201570511, y: -1.148885727, label: 3, color: '#35f870' },
  { x: -6.987812042, y: -1.324645042, label: 3, color: '#35f870' },
  { x: -6.886118889, y: -1.820511818, label: 3, color: '#35f870' },
  { x: -6.861524582, y: -1.496721268, label: 3, color: '#35f870' },
  { x: -6.719860077, y: -1.594695091, label: 3, color: '#35f870' },
  { x: -6.547142029, y: 0.412114143, label: 3, color: '#35f870' },
  { x: -7.218042374, y: -0.758407593, label: 3, color: '#35f870' },
  { x: -5.201936722, y: 2.664937019, label: 3, color: '#35f870' },
  { x: -7.64837265, y: 2.159579277, label: 3, color: '#35f870' },
  { x: -6.383852005, y: 1.152436733, label: 3, color: '#35f870' },
  { x: -6.492729187, y: 0.916810989, label: 3, color: '#35f870' },
  { x: -6.917224884, y: -0.60478878, label: 3, color: '#35f870' },
  { x: -6.996834755, y: -1.714036942, label: 3, color: '#35f870' },
  { x: -6.970788956, y: -1.491735458, label: 3, color: '#35f870' },
  { x: -6.658439636, y: 0.224647522, label: 3, color: '#35f870' },
  { x: -6.503956795, y: -1.146333694, label: 3, color: '#35f870' },
  { x: -6.532697678, y: -1.390533447, label: 3, color: '#35f870' },
  { x: -7.697799683, y: 2.184924603, label: 3, color: '#35f870' },
  { x: -6.447302818, y: -1.22097683, label: 3, color: '#35f870' },
  { x: -6.325679779, y: -1.386490822, label: 3, color: '#35f870' },
  { x: -6.362930298, y: -1.385598183, label: 3, color: '#35f870' },
  { x: -6.304171562, y: -1.398493767, label: 3, color: '#35f870' },
  { x: -6.249509811, y: -1.40011692, label: 3, color: '#35f870' },
  { x: -7.768470764, y: 2.163617134, label: 3, color: '#35f870' },
  { x: -6.36000824, y: -1.400531769, label: 3, color: '#35f870' },
  { x: -6.520051003, y: 0.183626175, label: 3, color: '#35f870' },
  { x: -6.471879005, y: -1.45706749, label: 3, color: '#35f870' },
  { x: -6.86410141, y: -0.925593376, label: 3, color: '#35f870' },
  { x: -7.199944496, y: -1.5459795, label: 3, color: '#35f870' },
  { x: -6.359618187, y: -1.418639183, label: 3, color: '#35f870' },
  { x: -6.003450394, y: -0.297088623, label: 3, color: '#35f870' },
  { x: -6.161169052, y: -0.72673893, label: 3, color: '#35f870' },
  { x: -7.223669052, y: 1.483186722, label: 3, color: '#35f870' },
  { x: -6.557540894, y: -1.534676552, label: 3, color: '#35f870' },
  { x: -6.546450615, y: -1.541998863, label: 3, color: '#35f870' },
  { x: -6.427582741, y: -1.391349792, label: 3, color: '#35f870' },
  { x: -6.307461739, y: -1.368542671, label: 3, color: '#35f870' },
  { x: -6.032688141, y: -0.249705315, label: 3, color: '#35f870' },
  { x: -7.338248253, y: 1.685031891, label: 3, color: '#35f870' },
  { x: -6.071228027, y: -0.174528122, label: 3, color: '#35f870' },
  { x: -5.43997097, y: 0.888446808, label: 3, color: '#35f870' },
  { x: -6.664704323, y: 1.441753387, label: 3, color: '#35f870' },
  { x: -11.56672096, y: -1.845479012, label: 5, color: '#b5787f' },
  { x: -7.055265427, y: 0.394873142, label: 3, color: '#35f870' },
  { x: -11.90307236, y: -1.194000244, label: 5, color: '#b5787f' },
  { x: -6.847827911, y: 0.443248749, label: 3, color: '#35f870' },
  { x: 15.02431297, y: -2.463682175, label: 6, color: '#4386df' },
  { x: -6.01124382, y: -0.351951599, label: 3, color: '#35f870' },
  { x: -11.35544395, y: -1.827890396, label: 5, color: '#b5787f' },
  { x: -11.60979176, y: -2.195989609, label: 5, color: '#b5787f' },
  { x: -11.52974606, y: -2.724632263, label: 5, color: '#b5787f' },
  { x: -11.40704536, y: -1.443676472, label: 5, color: '#b5787f' },
  { x: -7.750459671, y: 0.108380795, label: 3, color: '#35f870' },
  { x: -11.79457283, y: -2.014501572, label: 5, color: '#b5787f' },
  { x: -7.987800598, y: -0.006103992, label: 3, color: '#35f870' },
  { x: -7.21730423, y: 0.359239578, label: 3, color: '#35f870' },
  { x: -7.776462555, y: 0.0847435, label: 3, color: '#35f870' },
  { x: -12.14712143, y: -1.940550804, label: 5, color: '#b5787f' },
  { x: -9.53360939, y: -0.644033432, label: 3, color: '#35f870' },
  { x: -5.94258213, y: 0.698291779, label: 3, color: '#35f870' },
  { x: -9.354394913, y: -0.539793015, label: 3, color: '#35f870' },
  { x: 10.66113663, y: 5.069453239, label: 6, color: '#4386df' },
  { x: -9.39144516, y: -0.577871323, label: 3, color: '#35f870' },
  { x: -7.796883583, y: 0.098837376, label: 3, color: '#35f870' },
  { x: -12.19143867, y: -0.938062668, label: 5, color: '#b5787f' },
  { x: -8.389418602, y: 0.086065292, label: 3, color: '#35f870' },
  { x: 13.51643372, y: -0.261996269, label: 6, color: '#4386df' },
  { x: 12.91770554, y: 1.567762375, label: 6, color: '#4386df' },
  { x: -7.764482498, y: 0.073848724, label: 3, color: '#35f870' },
  { x: 10.31523514, y: 4.742351532, label: 6, color: '#4386df' },
  { x: -7.942682266, y: -6.769451141, label: 4, color: '#754cba' },
  { x: 9.987457275, y: 5.639167786, label: 6, color: '#4386df' },
  { x: 9.926332474, y: 4.99805069, label: 6, color: '#4386df' },
  { x: -7.910316467, y: 0.021423817, label: 3, color: '#35f870' },
  { x: -5.476764679, y: 1.208187103, label: 3, color: '#35f870' },
  { x: 12.82015038, y: 1.519269466, label: 6, color: '#4386df' },
  { x: -9.267558098, y: -0.489858627, label: 3, color: '#35f870' },
  { x: 12.36741161, y: 1.844388962, label: 6, color: '#4386df' },
  { x: 10.04998493, y: 3.744327545, label: 6, color: '#4386df' },
  { x: -8.488952637, y: -0.194778919, label: 3, color: '#35f870' },
  { x: -4.883748055, y: 2.668861389, label: 3, color: '#35f870' },
  { x: 12.9405632, y: 0.758581161, label: 6, color: '#4386df' },
  { x: 11.07758713, y: 5.199127197, label: 6, color: '#4386df' },
  { x: 9.270837784, y: 7.6139431, label: 6, color: '#4386df' },
  { x: -10.04616356, y: -0.674085617, label: 3, color: '#35f870' },
  { x: 10.49152756, y: 3.669174194, label: 6, color: '#4386df' },
  { x: 15.81368065, y: -1.697828293, label: 6, color: '#4386df' },
  { x: -13.07938004, y: -0.235563755, label: 5, color: '#b5787f' },
  { x: 9.098165512, y: 6.02326107, label: 6, color: '#4386df' },
  { x: 9.41470623, y: 4.164975166, label: 6, color: '#4386df' },
  { x: 9.08417511, y: 5.171718597, label: 6, color: '#4386df' },
  { x: 11.23921108, y: 4.115304947, label: 6, color: '#4386df' },
  { x: 11.28516769, y: 2.620464802, label: 6, color: '#4386df' },
  { x: -11.66874695, y: -0.955842018, label: 3, color: '#35f870' },
  { x: 13.87401009, y: 0.485328674, label: 6, color: '#4386df' },
  { x: 9.896354675, y: 4.399913788, label: 6, color: '#4386df' },
  { x: -9.88906002, y: -0.748831749, label: 3, color: '#35f870' },
  { x: -6.742536545, y: 0.69549942, label: 3, color: '#35f870' },
  { x: -10.40577698, y: -0.824342728, label: 3, color: '#35f870' },
  { x: 9.950656891, y: 5.768849373, label: 6, color: '#4386df' },
  { x: 8.801834106, y: 5.604784966, label: 6, color: '#4386df' },
  { x: 9.382205009, y: 5.820543289, label: 6, color: '#4386df' },
  { x: -12.26474571, y: -1.089607239, label: 5, color: '#b5787f' },
  { x: 10.45462513, y: 3.657644272, label: 6, color: '#4386df' },
  { x: 15.40496254, y: -2.496584415, label: 6, color: '#4386df' },
  { x: 8.953702927, y: 5.888084412, label: 6, color: '#4386df' },
  { x: 11.11704445, y: 2.538168907, label: 6, color: '#4386df' },
  { x: -7.398658752, y: 0.243386745, label: 3, color: '#35f870' },
  { x: 14.00880814, y: 0.788704872, label: 6, color: '#4386df' },
  { x: 10.30060291, y: 5.334000587, label: 6, color: '#4386df' },
  { x: -4.790205956, y: 2.27052021, label: 3, color: '#35f870' },
  { x: 13.30455208, y: 0.455840111, label: 6, color: '#4386df' },
  { x: 9.752249718, y: 6.097937584, label: 6, color: '#4386df' },
  { x: 9.177567482, y: 5.004940033, label: 6, color: '#4386df' },
  { x: 10.61603355, y: 2.929354668, label: 6, color: '#4386df' },
  { x: 10.33588219, y: 3.845872879, label: 6, color: '#4386df' },
  { x: -5.945394516, y: 1.333136559, label: 3, color: '#35f870' },
  { x: 10.90607452, y: 3.486700058, label: 6, color: '#4386df' },
  { x: 11.63834476, y: 2.3014431, label: 6, color: '#4386df' },
  { x: 12.25164795, y: 1.762240887, label: 6, color: '#4386df' },
  { x: 9.835751534, y: 4.171377659, label: 6, color: '#4386df' },
  { x: 13.0175724, y: 0.445607185, label: 6, color: '#4386df' },
  { x: 10.06845284, y: 5.564839363, label: 6, color: '#4386df' },
  { x: 8.95677948, y: 4.794897079, label: 6, color: '#4386df' },
  { x: -8.442622185, y: -0.749166489, label: 3, color: '#35f870' },
  { x: 9.574047089, y: 6.143266678, label: 6, color: '#4386df' },
  { x: -13.11598206, y: -1.774007797, label: 5, color: '#b5787f' },
  { x: 10.12789917, y: 4.095076561, label: 6, color: '#4386df' },
  { x: -10.12985039, y: -0.719884872, label: 3, color: '#35f870' },
  { x: 13.2647934, y: 0.943844795, label: 6, color: '#4386df' },
  { x: 13.56207275, y: 0.730909824, label: 6, color: '#4386df' },
  { x: -9.832675934, y: -0.711936474, label: 3, color: '#35f870' },
  { x: 12.69090557, y: 1.304737091, label: 6, color: '#4386df' },
  { x: 14.55185699, y: -0.666524887, label: 6, color: '#4386df' },
  { x: -12.73967266, y: -1.594304562, label: 5, color: '#b5787f' },
  { x: -7.989064217, y: 1.62409687, label: 3, color: '#35f870' },
  { x: 14.01627922, y: 0.329852104, label: 6, color: '#4386df' },
  { x: -13.70990372, y: -1.68983078, label: 5, color: '#b5787f' },
  { x: 10.57901096, y: 3.342041969, label: 6, color: '#4386df' },
  { x: -7.848155975, y: 0.074108124, label: 3, color: '#35f870' },
  { x: 13.09178352, y: 0.567044258, label: 6, color: '#4386df' },
  { x: -7.771839142, y: 0.550415039, label: 3, color: '#35f870' },
  { x: 14.69025803, y: -0.594345093, label: 6, color: '#4386df' },
  { x: -7.511295319, y: 0.65796566, label: 3, color: '#35f870' },
  { x: -11.43285751, y: -1.109572411, label: 5, color: '#b5787f' },
  { x: 13.34731483, y: 0.502365112, label: 6, color: '#4386df' },
  { x: -9.230413437, y: -0.39740181, label: 3, color: '#35f870' },
  { x: 10.79737759, y: 3.265803337, label: 6, color: '#4386df' },
  { x: -6.142108917, y: 0.566713333, label: 3, color: '#35f870' },
  { x: 11.36534691, y: 2.565443993, label: 6, color: '#4386df' },
  { x: -11.47458267, y: -1.179674149, label: 3, color: '#35f870' },
  { x: 9.960590363, y: 5.925209045, label: 6, color: '#4386df' },
  { x: -10.87255096, y: -1.212776184, label: 3, color: '#35f870' },
  { x: -7.3004179, y: 1.797125816, label: 3, color: '#35f870' },
  { x: 10.73903847, y: 5.167650223, label: 6, color: '#4386df' },
  { x: 11.27594757, y: 2.533441544, label: 6, color: '#4386df' },
  { x: 10.36013508, y: 5.376094818, label: 6, color: '#4386df' },
  { x: 9.807202339, y: 4.644713402, label: 6, color: '#4386df' },
  { x: 15.31828499, y: -0.442706108, label: 6, color: '#4386df' },
  { x: -10.92580032, y: -0.920211792, label: 3, color: '#35f870' },
  { x: -9.658782005, y: -0.582077026, label: 3, color: '#35f870' },
  { x: 10.31586456, y: 4.316792488, label: 6, color: '#4386df' },
  { x: 11.12059498, y: 5.100111008, label: 6, color: '#4386df' },
  { x: 13.03329849, y: 0.849753857, label: 6, color: '#4386df' },
  { x: 15.43356895, y: -1.981583118, label: 6, color: '#4386df' },
  { x: 10.788867, y: 4.65933609, label: 6, color: '#4386df' },
  { x: 15.2670517, y: -0.979802132, label: 6, color: '#4386df' },
  { x: -6.383274078, y: 0.563342094, label: 3, color: '#35f870' },
  { x: -10.1672678, y: -0.844929218, label: 3, color: '#35f870' },
  { x: -6.393868446, y: 0.863574028, label: 3, color: '#35f870' },
  { x: 8.621557236, y: 6.997478485, label: 6, color: '#4386df' },
  { x: -5.183609962, y: 0.884554863, label: 3, color: '#35f870' },
  { x: 15.15771294, y: 0.072425365, label: 6, color: '#4386df' },
  { x: -8.3487463, y: 1.920359612, label: 3, color: '#35f870' },
  { x: -7.423916817, y: 1.033750534, label: 3, color: '#35f870' },
  { x: -9.606824875, y: -0.614993095, label: 3, color: '#35f870' },
  { x: 9.678839684, y: 4.835460663, label: 6, color: '#4386df' },
  { x: -12.81083202, y: -1.0232234, label: 5, color: '#b5787f' },
  { x: 13.72361946, y: 0.138149261, label: 6, color: '#4386df' },
  { x: -13.08367538, y: -1.354111671, label: 5, color: '#b5787f' },
  { x: -8.588349342, y: 1.202315331, label: 3, color: '#35f870' },
  { x: 9.689815521, y: 4.233751297, label: 6, color: '#4386df' },
  { x: 9.834949493, y: 5.892446518, label: 6, color: '#4386df' },
  { x: 15.29816818, y: -5.302881241, label: 6, color: '#4386df' },
  { x: -9.738401413, y: -0.685264587, label: 3, color: '#35f870' },
  { x: 10.5892086, y: 3.8627491, label: 6, color: '#4386df' },
  { x: 10.51616764, y: 3.191008091, label: 6, color: '#4386df' },
  { x: -12.3822813, y: -1.616408825, label: 5, color: '#b5787f' },
  { x: 10.0085659, y: 4.998095512, label: 6, color: '#4386df' },
  { x: 11.58762169, y: 2.619263649, label: 6, color: '#4386df' },
  { x: 11.36282158, y: 3.501244068, label: 6, color: '#4386df' },
  { x: 10.99224281, y: 2.927103043, label: 6, color: '#4386df' },
  { x: 14.01267624, y: 0.5122509, label: 6, color: '#4386df' },
  { x: 9.88060379, y: 5.365683556, label: 6, color: '#4386df' },
  { x: 9.138494492, y: 4.962546349, label: 6, color: '#4386df' },
  { x: 11.10174656, y: 3.95301342, label: 6, color: '#4386df' },
  { x: 9.910229683, y: 5.698663712, label: 6, color: '#4386df' },
  { x: 9.357823372, y: 4.852457047, label: 6, color: '#4386df' },
  { x: 11.03112793, y: 3.679801941, label: 6, color: '#4386df' },
  { x: -9.773424149, y: -0.707048416, label: 3, color: '#35f870' },
  { x: 13.36052322, y: 0.116674423, label: 6, color: '#4386df' },
  { x: 8.729906082, y: 5.758443832, label: 6, color: '#4386df' },
  { x: -8.482430458, y: -0.067734718, label: 3, color: '#35f870' },
  { x: 15.50142097, y: -4.729803085, label: 6, color: '#4386df' },
  { x: -9.485212326, y: -0.636094093, label: 3, color: '#35f870' },
  { x: -9.81306839, y: -0.688254356, label: 3, color: '#35f870' },
  { x: 10.2216959, y: 3.805503845, label: 6, color: '#4386df' },
  { x: -8.662793159, y: -0.287302494, label: 3, color: '#35f870' },
  { x: 13.48812485, y: 0.852817535, label: 6, color: '#4386df' },
  { x: -10.85016632, y: -1.469168663, label: 5, color: '#b5787f' },
  { x: -8.4316082, y: -0.153107166, label: 3, color: '#35f870' },
  { x: -8.946109772, y: -0.740131378, label: 3, color: '#35f870' },
  { x: 9.863584518, y: 5.981284142, label: 6, color: '#4386df' },
  { x: 14.06077576, y: 0.134464264, label: 6, color: '#4386df' },
  { x: 13.51625729, y: 1.055092812, label: 6, color: '#4386df' },
  { x: -5.674991608, y: 0.867143631, label: 3, color: '#35f870' },
  { x: 11.30540371, y: 2.306373596, label: 6, color: '#4386df' },
  { x: -5.113042831, y: 1.06346941, label: 3, color: '#35f870' },
  { x: 9.361932755, y: 4.192171097, label: 6, color: '#4386df' },
  { x: 11.07984352, y: 2.566059113, label: 6, color: '#4386df' },
  { x: 9.271948814, y: 6.077018738, label: 6, color: '#4386df' },
  { x: 9.28245163, y: 5.242084503, label: 6, color: '#4386df' },
  { x: -6.834339142, y: 1.554171562, label: 3, color: '#35f870' },
  { x: -7.533192635, y: 0.211247921, label: 3, color: '#35f870' },
  { x: 9.382341385, y: 4.104744911, label: 6, color: '#4386df' },
  { x: -10.31844807, y: -0.411729813, label: 3, color: '#35f870' },
  { x: 9.446778297, y: 3.995319366, label: 6, color: '#4386df' },
  { x: 8.923561096, y: 5.625049591, label: 6, color: '#4386df' },
  { x: 14.98200035, y: -6.602817535, label: 6, color: '#4386df' },
  { x: 12.63224411, y: 0.527010918, label: 6, color: '#4386df' },
  { x: -5.391466141, y: 0.742564201, label: 3, color: '#35f870' },
  { x: -12.71650219, y: -1.501284599, label: 5, color: '#b5787f' },
  { x: 9.487251282, y: 4.714148521, label: 6, color: '#4386df' },
  { x: 10.87601471, y: 4.820819855, label: 6, color: '#4386df' },
  { x: 12.70734406, y: 0.420188904, label: 6, color: '#4386df' },
  { x: 9.204713821, y: 6.011379242, label: 6, color: '#4386df' },
  { x: 10.87597847, y: 4.64011097, label: 6, color: '#4386df' },
  { x: 8.850976944, y: 5.324449539, label: 6, color: '#4386df' },
  { x: -5.759135246, y: 0.892402172, label: 3, color: '#35f870' },
  { x: 13.61946678, y: 0.667448997, label: 6, color: '#4386df' },
  { x: 12.81756401, y: 0.272942543, label: 6, color: '#4386df' },
  { x: 10.98936844, y: 3.753435135, label: 6, color: '#4386df' },
  { x: 14.9616642, y: -0.809190273, label: 6, color: '#4386df' },
  { x: -4.993237495, y: 2.100851059, label: 3, color: '#35f870' },
  { x: -5.006813049, y: 0.949901581, label: 3, color: '#35f870' },
  { x: 13.74518776, y: -0.710531235, label: 6, color: '#4386df' },
  { x: 11.04618359, y: 3.81212616, label: 6, color: '#4386df' },
  { x: 10.90113926, y: 5.357187271, label: 6, color: '#4386df' },
  { x: 13.35513306, y: 0.76255703, label: 6, color: '#4386df' },
  { x: -9.804771423, y: -0.739245892, label: 3, color: '#35f870' },
  { x: 10.65893364, y: 4.567759514, label: 6, color: '#4386df' },
  { x: 13.60610294, y: 0.835357666, label: 6, color: '#4386df' },
  { x: 15.08498955, y: -2.3548069, label: 6, color: '#4386df' },
  { x: 9.54590416, y: 6.247659683, label: 6, color: '#4386df' },
  { x: 12.82213783, y: 1.072315216, label: 6, color: '#4386df' },
  { x: 10.91197681, y: 4.078269958, label: 6, color: '#4386df' },
  { x: 11.10697746, y: 4.177406311, label: 6, color: '#4386df' },
  { x: -8.085030556, y: 1.965628624, label: 3, color: '#35f870' },
  { x: -10.0922966, y: -0.791337967, label: 3, color: '#35f870' },
  { x: -6.14418602, y: 1.120221138, label: 3, color: '#35f870' },
  { x: -6.209723473, y: 1.029950142, label: 3, color: '#35f870' },
  { x: 11.14387894, y: 3.049035072, label: 6, color: '#4386df' },
  { x: 8.622293472, y: 6.162023544, label: 6, color: '#4386df' },
  { x: 10.96858692, y: 4.098895073, label: 6, color: '#4386df' },
  { x: -8.123975754, y: 1.419139862, label: 3, color: '#35f870' },
  { x: -12.79349709, y: -0.696461678, label: 5, color: '#b5787f' },
  { x: -9.65325737, y: -0.650527, label: 3, color: '#35f870' },
  { x: -8.400566101, y: -0.148418427, label: 3, color: '#35f870' },
  { x: 11.00400352, y: 3.984508514, label: 6, color: '#4386df' },
  { x: -7.496400833, y: 0.166986942, label: 3, color: '#35f870' },
  { x: -10.18494511, y: -0.353385925, label: 3, color: '#35f870' },
  { x: 15.42941666, y: -2.487319469, label: 6, color: '#4386df' },
  { x: 13.13494873, y: -0.115539551, label: 6, color: '#4386df' },
  { x: -10.0702486, y: -0.705806732, label: 3, color: '#35f870' },
  { x: -11.89187145, y: -1.557355881, label: 5, color: '#b5787f' },
  { x: 13.60435486, y: 0.618219376, label: 6, color: '#4386df' },
  { x: 13.50432968, y: 0.652024269, label: 6, color: '#4386df' },
  { x: 9.853435516, y: 4.098608971, label: 6, color: '#4386df' },
  { x: 15.4432888, y: -1.457019806, label: 6, color: '#4386df' },
  { x: -7.29160881, y: 0.825442791, label: 3, color: '#35f870' },
  { x: -10.78592396, y: -1.108176231, label: 3, color: '#35f870' },
  { x: 9.378166199, y: 4.710252762, label: 6, color: '#4386df' },
  { x: -7.673351288, y: 1.383619308, label: 3, color: '#35f870' },
  { x: 10.4026556, y: 5.4617033, label: 6, color: '#4386df' },
  { x: -11.78361511, y: -1.011525154, label: 5, color: '#b5787f' },
  { x: -8.49783802, y: 2.109683037, label: 3, color: '#35f870' },
  { x: 12.78977394, y: 0.283712387, label: 6, color: '#4386df' },
  { x: 13.25638676, y: 0.436504364, label: 6, color: '#4386df' },
  { x: -12.02326393, y: -1.13629055, label: 5, color: '#b5787f' },
  { x: -7.229349136, y: 0.279109955, label: 3, color: '#35f870' },
  { x: 13.63839149, y: 0.744807243, label: 6, color: '#4386df' },
  { x: -6.786361694, y: 0.468803406, label: 3, color: '#35f870' },
  { x: -12.21953011, y: -1.319478035, label: 5, color: '#b5787f' },
  { x: -8.577674866, y: -0.218849182, label: 3, color: '#35f870' },
  { x: 9.039772034, y: 4.995409012, label: 6, color: '#4386df' },
  { x: -11.00503922, y: -1.151031971, label: 5, color: '#b5787f' },
  { x: -12.15052032, y: -1.105953217, label: 5, color: '#b5787f' },
  { x: -9.619316101, y: -0.675199509, label: 3, color: '#35f870' },
  { x: 11.54078865, y: 2.479101181, label: 6, color: '#4386df' },
  { x: -7.350158691, y: 1.388154507, label: 3, color: '#35f870' },
  { x: 9.366005898, y: 4.803963661, label: 6, color: '#4386df' },
  { x: -12.54608536, y: -1.27855444, label: 5, color: '#b5787f' },
  { x: -10.10580444, y: -0.852083206, label: 3, color: '#35f870' },
  { x: -8.189454079, y: 2.087900162, label: 3, color: '#35f870' },
  { x: 10.18317699, y: 3.952444077, label: 6, color: '#4386df' },
  { x: -8.131490707, y: 2.347801685, label: 3, color: '#35f870' },
  { x: -8.72127533, y: -0.159127235, label: 3, color: '#35f870' },
  { x: 15.10194588, y: -2.441293716, label: 6, color: '#4386df' },
  { x: -11.67459202, y: -1.037804127, label: 3, color: '#35f870' },
  { x: 9.389238358, y: 4.890514374, label: 6, color: '#4386df' },
  { x: -10.30277538, y: -0.918002129, label: 3, color: '#35f870' },
  { x: 11.07640553, y: 3.708552361, label: 6, color: '#4386df' },
  { x: 9.54904747, y: 4.819220543, label: 6, color: '#4386df' },
  { x: -5.877338409, y: 1.265153408, label: 3, color: '#35f870' },
  { x: 9.191403389, y: 4.850369453, label: 6, color: '#4386df' },
  { x: 10.59806442, y: 4.547420502, label: 6, color: '#4386df' },
  { x: -5.166399002, y: 0.951173306, label: 3, color: '#35f870' },
  { x: 9.270511627, y: 5.413667679, label: 6, color: '#4386df' },
  { x: -13.02068138, y: -1.484836578, label: 5, color: '#b5787f' },
  { x: 10.94778824, y: 2.787647247, label: 6, color: '#4386df' },
  { x: 10.39899635, y: 3.853824615, label: 6, color: '#4386df' },
  { x: -7.360151291, y: 0.270289421, label: 3, color: '#35f870' },
  { x: -9.426812172, y: -0.463070869, label: 3, color: '#35f870' },
  { x: -7.846576691, y: 0.031585693, label: 3, color: '#35f870' },
  { x: -5.478193283, y: 1.230901718, label: 3, color: '#35f870' },
  { x: 8.58247757, y: 6.150210381, label: 6, color: '#4386df' },
  { x: 9.140890121, y: 4.346534729, label: 6, color: '#4386df' },
  { x: 10.80225086, y: 4.049240112, label: 6, color: '#4386df' },
  { x: 9.343945503, y: 5.18924427, label: 6, color: '#4386df' },
  { x: -7.653057098, y: 0.142232895, label: 3, color: '#35f870' },
  { x: 13.34528351, y: 0.037654877, label: 6, color: '#4386df' },
  { x: 12.92429733, y: 1.193083763, label: 6, color: '#4386df' },
  { x: 11.23545456, y: 2.764126778, label: 6, color: '#4386df' },
  { x: -6.235141754, y: 0.594743729, label: 3, color: '#35f870' },
  { x: 11.32386589, y: 2.674211502, label: 6, color: '#4386df' },
  { x: 9.789268494, y: 4.298549652, label: 6, color: '#4386df' },
  { x: -7.501613617, y: 0.837457657, label: 3, color: '#35f870' },
  { x: 11.51857376, y: 2.550146103, label: 6, color: '#4386df' },
  { x: -6.175301552, y: 0.573617935, label: 3, color: '#35f870' },
  { x: -10.45547485, y: -0.699887276, label: 3, color: '#35f870' },
  { x: -5.542297363, y: 1.132068634, label: 3, color: '#35f870' },
  { x: -10.42205334, y: -0.510058403, label: 3, color: '#35f870' },
  { x: 13.40830612, y: 0.7132864, label: 6, color: '#4386df' },
  { x: 9.68273735, y: 6.233165741, label: 6, color: '#4386df' },
  { x: 10.75277424, y: 5.521889687, label: 6, color: '#4386df' },
  { x: -5.773458481, y: 0.701369286, label: 3, color: '#35f870' },
  { x: -8.748896599, y: 1.097129822, label: 3, color: '#35f870' },
  { x: -12.43719482, y: -0.788898468, label: 5, color: '#b5787f' },
  { x: 11.25886631, y: 4.853068352, label: 6, color: '#4386df' },
  { x: 13.16794682, y: 0.908047676, label: 6, color: '#4386df' },
  { x: -4.968708038, y: 1.231219292, label: 3, color: '#35f870' },
  { x: 10.280303, y: 4.655091286, label: 6, color: '#4386df' },
  { x: -5.216239929, y: 1.003093719, label: 3, color: '#35f870' },
  { x: -5.023628235, y: 0.987304688, label: 3, color: '#35f870' },
  { x: -7.680997849, y: 0.104074001, label: 3, color: '#35f870' },
  { x: -4.675854683, y: 2.672231674, label: 3, color: '#35f870' },
  { x: -5.432312012, y: 1.722759247, label: 3, color: '#35f870' },
  { x: 11.25563145, y: 2.813201904, label: 6, color: '#4386df' },
  { x: 11.58388138, y: 2.553007126, label: 6, color: '#4386df' },
  { x: -7.560102463, y: 1.452030182, label: 3, color: '#35f870' },
  { x: -7.42070961, y: 0.207146645, label: 3, color: '#35f870' },
  { x: -10.01070213, y: -0.811481476, label: 3, color: '#35f870' },
  { x: 10.97508907, y: 5.14490509, label: 6, color: '#4386df' },
  { x: -11.72006702, y: -1.075686455, label: 5, color: '#b5787f' },
  { x: -7.815763474, y: 1.355875969, label: 3, color: '#35f870' },
  { x: -13.01932907, y: -1.722005367, label: 5, color: '#b5787f' },
  { x: -7.480295181, y: 0.971765518, label: 3, color: '#35f870' },
  { x: -8.447257042, y: -0.145187378, label: 3, color: '#35f870' },
  { x: 12.96331024, y: 0.932861328, label: 6, color: '#4386df' },
  { x: -6.066199303, y: 0.573908806, label: 3, color: '#35f870' },
  { x: 13.48707008, y: 0.780506134, label: 6, color: '#4386df' },
  { x: -7.308414459, y: 0.46559, label: 3, color: '#35f870' },
  { x: 9.651880264, y: 4.495957375, label: 6, color: '#4386df' },
  { x: 10.90088272, y: 5.320928574, label: 6, color: '#4386df' },
  { x: 9.8099823, y: 4.524618149, label: 6, color: '#4386df' },
  { x: -12.11916351, y: -1.319695473, label: 5, color: '#b5787f' },
  { x: -11.88910484, y: -1.126325607, label: 5, color: '#b5787f' },
  { x: -12.27365875, y: -1.456717491, label: 5, color: '#b5787f' },
  { x: 13.47376823, y: 0.648857117, label: 6, color: '#4386df' },
  { x: 10.80947685, y: 4.316599846, label: 6, color: '#4386df' },
  { x: 11.34074306, y: 4.171772003, label: 6, color: '#4386df' },
  { x: 9.901212692, y: 4.216266632, label: 6, color: '#4386df' },
  { x: -11.99978828, y: -1.931889534, label: 5, color: '#b5787f' },
  { x: 9.99786377, y: 4.29122448, label: 6, color: '#4386df' },
  { x: -5.639339447, y: 0.659653664, label: 3, color: '#35f870' },
  { x: -10.86864471, y: -1.49835968, label: 3, color: '#35f870' },
  { x: -9.411579132, y: -0.537624359, label: 3, color: '#35f870' },
  { x: -10.88828945, y: -1.248655796, label: 5, color: '#b5787f' },
  { x: 8.863171577, y: 5.497091293, label: 6, color: '#4386df' },
  { x: -10.90559196, y: -1.070568085, label: 3, color: '#35f870' },
  { x: 11.54474926, y: 2.427821159, label: 6, color: '#4386df' },
  { x: 10.16754532, y: 4.58442688, label: 6, color: '#4386df' },
  { x: 11.09344864, y: 4.552253723, label: 6, color: '#4386df' },
  { x: 11.06400871, y: 5.223107338, label: 6, color: '#4386df' },
  { x: -5.715171814, y: 0.713461876, label: 3, color: '#35f870' },
  { x: -5.51641655, y: 0.751903534, label: 3, color: '#35f870' },
  { x: 12.64919472, y: 0.479427338, label: 6, color: '#4386df' },
  { x: -4.753932953, y: 2.681887627, label: 3, color: '#35f870' },
  { x: -6.475165367, y: 1.498389244, label: 3, color: '#35f870' },
  { x: -5.030452728, y: 0.900268555, label: 3, color: '#35f870' },
  { x: -12.38989258, y: -0.918875217, label: 5, color: '#b5787f' },
  { x: -10.39241982, y: -1.011750221, label: 3, color: '#35f870' },
  { x: -11.37598038, y: -1.105219841, label: 3, color: '#35f870' },
  { x: 13.96892929, y: -0.902925014, label: 6, color: '#4386df' },
  { x: -7.080055237, y: 0.381246567, label: 3, color: '#35f870' },
  { x: -11.63823509, y: -1.106033325, label: 3, color: '#35f870' },
  { x: -11.98853779, y: -0.963661194, label: 5, color: '#b5787f' },
  { x: 10.50194931, y: 3.268165588, label: 6, color: '#4386df' },
  { x: 9.927938461, y: 4.909076691, label: 6, color: '#4386df' },
  { x: -5.037042618, y: 0.939883232, label: 3, color: '#35f870' },
  { x: 11.02839851, y: 4.490573883, label: 6, color: '#4386df' },
  { x: 9.32000351, y: 4.890374184, label: 6, color: '#4386df' },
  { x: 9.182229042, y: 4.988656521, label: 6, color: '#4386df' },
  { x: -10.37600136, y: -0.470837593, label: 3, color: '#35f870' },
  { x: -10.63212395, y: -0.556529999, label: 5, color: '#b5787f' },
  { x: 12.65557957, y: 0.336911201, label: 6, color: '#4386df' },
  { x: -8.438099861, y: -0.085759163, label: 3, color: '#35f870' },
  { x: 12.75850964, y: 0.456207275, label: 6, color: '#4386df' },
  { x: -7.720715523, y: 0.557163239, label: 3, color: '#35f870' },
  { x: 11.06934071, y: 4.78433609, label: 6, color: '#4386df' },
  { x: -7.500134468, y: 1.047912598, label: 3, color: '#35f870' },
  { x: 9.763755798, y: 4.079987526, label: 6, color: '#4386df' },
  { x: 14.15444756, y: 0.20249176, label: 6, color: '#4386df' },
  { x: -6.937404633, y: 0.623073578, label: 3, color: '#35f870' },
  { x: -9.486865997, y: -0.575416565, label: 3, color: '#35f870' },
  { x: 13.15622902, y: -0.17768383, label: 6, color: '#4386df' },
  { x: -10.70625305, y: -0.911269188, label: 3, color: '#35f870' },
  { x: -8.164899826, y: 0.1967659, label: 3, color: '#35f870' },
  { x: 14.3207283, y: -1.477310181, label: 6, color: '#4386df' },
  { x: 14.43476868, y: -1.850802422, label: 6, color: '#4386df' },
  { x: -13.06215858, y: -0.407589912, label: 5, color: '#b5787f' },
  { x: 8.968497276, y: 6.919942856, label: 6, color: '#4386df' },
  { x: -6.480818748, y: 0.818377972, label: 3, color: '#35f870' },
  { x: 10.03807163, y: 4.25528717, label: 6, color: '#4386df' },
  { x: 9.512094498, y: 5.27077961, label: 6, color: '#4386df' },
  { x: -10.17848396, y: -0.488375664, label: 3, color: '#35f870' },
  { x: 9.951806068, y: 4.053743362, label: 6, color: '#4386df' },
  { x: -8.668819427, y: 1.11751318, label: 3, color: '#35f870' },
  { x: -5.395568848, y: 1.245323181, label: 3, color: '#35f870' },
  { x: 10.80761719, y: 3.313560486, label: 6, color: '#4386df' },
  { x: 11.04812527, y: 3.813558578, label: 6, color: '#4386df' },
  { x: -7.367904663, y: 0.229550838, label: 3, color: '#35f870' },
  { x: 10.87543774, y: 3.743070602, label: 6, color: '#4386df' },
  { x: 10.01454353, y: 4.256605148, label: 6, color: '#4386df' },
  { x: 9.420623779, y: 5.563007355, label: 6, color: '#4386df' },
  { x: -10.67084312, y: -0.952334881, label: 3, color: '#35f870' },
  { x: -6.616083145, y: 1.549968719, label: 3, color: '#35f870' },
  { x: -5.342762947, y: 1.206724167, label: 3, color: '#35f870' },
  { x: 11.19839382, y: 4.135723114, label: 6, color: '#4386df' },
  { x: 10.92854881, y: 4.697717667, label: 6, color: '#4386df' },
  { x: -5.218297005, y: 0.841119766, label: 3, color: '#35f870' },
  { x: -5.387738228, y: 1.203748703, label: 3, color: '#35f870' },
  { x: -5.203172684, y: 0.943551064, label: 3, color: '#35f870' },
  { x: -11.77880287, y: -1.564805984, label: 5, color: '#b5787f' },
  { x: 10.29105759, y: 4.050489426, label: 6, color: '#4386df' },
  { x: 12.12971401, y: 1.819107056, label: 6, color: '#4386df' },
  { x: -6.075814247, y: 1.474567413, label: 3, color: '#35f870' },
  { x: 10.23965454, y: 4.587337494, label: 6, color: '#4386df' },
  { x: 11.65960884, y: 2.272932053, label: 6, color: '#4386df' },
  { x: -11.00613785, y: -1.029747009, label: 3, color: '#35f870' },
  { x: 11.14294624, y: 4.017894745, label: 6, color: '#4386df' },
  { x: -7.807647705, y: 0.083350182, label: 3, color: '#35f870' },
  { x: -9.331877708, y: -0.483873844, label: 3, color: '#35f870' },
  { x: -9.786530495, y: -0.701120377, label: 3, color: '#35f870' },
  { x: -12.93754959, y: -1.391158104, label: 5, color: '#b5787f' },
  { x: 10.33020496, y: 5.387542725, label: 6, color: '#4386df' },
  { x: 12.61292267, y: 1.524054527, label: 6, color: '#4386df' },
  { x: 9.843645096, y: 5.682714462, label: 6, color: '#4386df' },
  { x: 10.74547005, y: 3.428420067, label: 6, color: '#4386df' },
  { x: 13.12222672, y: 1.530302048, label: 6, color: '#4386df' },
  { x: 10.9061451, y: 3.286607742, label: 6, color: '#4386df' },
  { x: -10.32054806, y: -0.898460388, label: 3, color: '#35f870' },
  { x: 9.408226013, y: 5.288599014, label: 6, color: '#4386df' },
  { x: 10.35519981, y: 3.227552414, label: 6, color: '#4386df' },
  { x: 9.546701431, y: 6.234455109, label: 6, color: '#4386df' },
  { x: -10.19553947, y: -0.703509808, label: 3, color: '#35f870' },
  { x: -5.266061783, y: 1.428514481, label: 3, color: '#35f870' },
  { x: 10.87457657, y: 4.313214302, label: 6, color: '#4386df' },
  { x: 8.677410126, y: 6.421743393, label: 6, color: '#4386df' },
  { x: 11.1918087, y: 2.673841476, label: 6, color: '#4386df' },
  { x: 13.93809319, y: 0.265209198, label: 6, color: '#4386df' },
  { x: 11.3213501, y: 2.494653702, label: 6, color: '#4386df' },
  { x: 10.02783489, y: 5.342769623, label: 6, color: '#4386df' },
  { x: 10.01478195, y: 5.731301308, label: 6, color: '#4386df' },
  { x: 9.218957901, y: 6.824007034, label: 6, color: '#4386df' },
  { x: 13.57608986, y: 0.250909805, label: 6, color: '#4386df' },
  { x: -13.01433754, y: -1.399632454, label: 5, color: '#b5787f' },
  { x: 13.35041809, y: 0.083117485, label: 6, color: '#4386df' },
  { x: -7.416524887, y: 0.21013546, label: 3, color: '#35f870' },
  { x: 9.73957634, y: 4.13054657, label: 6, color: '#4386df' },
  { x: 12.71740532, y: 0.627598763, label: 6, color: '#4386df' },
  { x: 9.738046646, y: 6.145312309, label: 6, color: '#4386df' },
  { x: 10.1879015, y: 5.265035629, label: 6, color: '#4386df' },
  { x: 10.46135426, y: 5.24505043, label: 6, color: '#4386df' },
  { x: 10.98378372, y: 3.06492281, label: 6, color: '#4386df' },
  { x: 10.37817287, y: 4.451965332, label: 6, color: '#4386df' },
  { x: 13.58263588, y: 0.157626152, label: 6, color: '#4386df' },
  { x: 10.01985836, y: 4.788366318, label: 6, color: '#4386df' },
  { x: -4.970925331, y: 0.908325195, label: 3, color: '#35f870' },
  { x: -10.63469124, y: -0.772637367, label: 3, color: '#35f870' },
  { x: 10.29112625, y: 5.292577744, label: 6, color: '#4386df' },
  { x: -12.95438957, y: -1.790252686, label: 5, color: '#b5787f' },
  { x: -10.55000401, y: -0.949757576, label: 3, color: '#35f870' },
  { x: 8.998165131, y: 5.506809711, label: 6, color: '#4386df' },
  { x: 10.10161495, y: 5.090389252, label: 6, color: '#4386df' },
  { x: 10.60826397, y: 5.292613983, label: 6, color: '#4386df' },
  { x: 11.41349983, y: 2.14803791, label: 6, color: '#4386df' },
  { x: -9.391700745, y: -0.550591469, label: 3, color: '#35f870' },
  { x: 15.29469872, y: -2.457305908, label: 6, color: '#4386df' },
  { x: 9.421413422, y: 4.649969101, label: 6, color: '#4386df' },
  { x: 13.91659546, y: 0.203682899, label: 6, color: '#4386df' },
  { x: 13.13756943, y: 0.729744911, label: 6, color: '#4386df' },
  { x: 11.2941246, y: 2.620055199, label: 6, color: '#4386df' },
  { x: -9.338344574, y: -0.512244701, label: 3, color: '#35f870' },
  { x: 14.97068214, y: -5.83978653, label: 6, color: '#4386df' },
  { x: 15.34339714, y: -2.125192642, label: 6, color: '#4386df' },
  { x: 9.958944321, y: 5.820857048, label: 6, color: '#4386df' },
  { x: 15.38462639, y: -2.813699722, label: 6, color: '#4386df' },
  { x: 13.44532394, y: 0.074022293, label: 6, color: '#4386df' },
  { x: 10.54328156, y: 5.225692749, label: 6, color: '#4386df' },
  { x: -10.1843605, y: -0.82908535, label: 3, color: '#35f870' },
  { x: -9.670318604, y: -0.630491257, label: 3, color: '#35f870' },
  { x: -9.452064514, y: -0.567182541, label: 3, color: '#35f870' },
  { x: 10.32902813, y: 4.250169754, label: 6, color: '#4386df' },
  { x: -9.899073601, y: -0.658120155, label: 3, color: '#35f870' },
  { x: 11.1011219, y: 2.646274567, label: 6, color: '#4386df' },
  { x: 11.22265434, y: 4.986235619, label: 6, color: '#4386df' },
  { x: -5.700240135, y: 1.069009781, label: 3, color: '#35f870' },
  { x: 8.770601273, y: 5.731891632, label: 6, color: '#4386df' },
  { x: 9.226875305, y: 5.92768383, label: 6, color: '#4386df' },
  { x: -10.68242073, y: -0.823369026, label: 3, color: '#35f870' },
  { x: 10.77843857, y: 4.510653496, label: 6, color: '#4386df' },
  { x: -5.360877991, y: 0.757501602, label: 3, color: '#35f870' },
  { x: 10.00913143, y: 4.26967144, label: 6, color: '#4386df' },
  { x: -7.217287064, y: 0.28793335, label: 3, color: '#35f870' },
  { x: 10.43115234, y: 4.239320755, label: 6, color: '#4386df' },
  { x: 10.68120575, y: 3.246953964, label: 6, color: '#4386df' },
  { x: -13.41440964, y: -1.188097477, label: 5, color: '#b5787f' },
  { x: 11.00357151, y: 4.429646492, label: 6, color: '#4386df' },
  { x: 10.33147049, y: 3.882853508, label: 6, color: '#4386df' },
  { x: 13.64530754, y: 0.756523132, label: 6, color: '#4386df' },
  { x: 12.49407387, y: 1.303955078, label: 6, color: '#4386df' },
  { x: 14.2379303, y: -0.561490536, label: 6, color: '#4386df' },
  { x: -13.69442368, y: -1.357213974, label: 5, color: '#b5787f' },
  { x: 11.46229935, y: 2.66754818, label: 6, color: '#4386df' },
  { x: 13.50275993, y: 0.923892021, label: 6, color: '#4386df' },
  { x: 13.09290123, y: 0.565378189, label: 6, color: '#4386df' },
  { x: 15.69039536, y: -0.837721825, label: 6, color: '#4386df' },
  { x: -5.791490555, y: 1.479923248, label: 3, color: '#35f870' },
  { x: -7.627303123, y: 1.764214516, label: 3, color: '#35f870' },
  { x: -9.881545067, y: -0.755722523, label: 3, color: '#35f870' },
  { x: 11.16866589, y: 5.00047493, label: 6, color: '#4386df' },
  { x: 11.21231842, y: 2.759313583, label: 6, color: '#4386df' },
  { x: 10.55766869, y: 3.326097488, label: 6, color: '#4386df' },
  { x: 10.27153683, y: 3.897520065, label: 6, color: '#4386df' },
  { x: -7.011814117, y: 0.87997818, label: 3, color: '#35f870' },
  { x: 11.15099907, y: 4.022922516, label: 6, color: '#4386df' },
  { x: 9.568523407, y: 6.888594627, label: 6, color: '#4386df' },
  { x: 10.64984417, y: 3.364652634, label: 6, color: '#4386df' },
  { x: 13.00879478, y: 1.046343803, label: 6, color: '#4386df' },
  { x: 10.11051369, y: 5.100259781, label: 6, color: '#4386df' },
  { x: -12.23054886, y: -0.887314796, label: 5, color: '#b5787f' },
  { x: -10.27333164, y: -0.88611269, label: 3, color: '#35f870' },
  { x: 9.332845688, y: 6.136652946, label: 6, color: '#4386df' },
  { x: -6.533205986, y: 0.693422318, label: 3, color: '#35f870' },
  { x: 11.07756233, y: 4.552005768, label: 6, color: '#4386df' },
  { x: -4.945753098, y: 0.874845505, label: 3, color: '#35f870' },
  { x: -6.774430275, y: 1.404574871, label: 3, color: '#35f870' },
  { x: 9.022665977, y: 6.175314903, label: 6, color: '#4386df' },
  { x: 13.27473068, y: -0.183641434, label: 6, color: '#4386df' },
  { x: -5.75787735, y: 0.740231514, label: 3, color: '#35f870' },
  { x: -7.100161552, y: 0.442134857, label: 3, color: '#35f870' },
  { x: 11.04804134, y: 4.382910728, label: 6, color: '#4386df' },
  { x: -9.221820831, y: -0.277876377, label: 3, color: '#35f870' },
  { x: -6.011959076, y: 0.864661217, label: 3, color: '#35f870' },
  { x: 9.549642563, y: 4.45804882, label: 6, color: '#4386df' },
  { x: 14.86593246, y: -2.482694626, label: 6, color: '#4386df' },
  { x: -7.445890427, y: 0.238647938, label: 3, color: '#35f870' },
  { x: 10.04348755, y: 5.314970016, label: 6, color: '#4386df' },
  { x: 10.21873283, y: 5.446735382, label: 6, color: '#4386df' },
  { x: -7.34262085, y: 0.865367889, label: 3, color: '#35f870' },
  { x: -7.024196625, y: 1.713863373, label: 3, color: '#35f870' },
  { x: 13.99556732, y: 0.007450104, label: 6, color: '#4386df' },
  { x: -10.0396452, y: -0.766571045, label: 3, color: '#35f870' },
  { x: 10.85633087, y: 2.752947807, label: 6, color: '#4386df' },
  { x: 15.88427925, y: -1.10159111, label: 6, color: '#4386df' },
  { x: 12.96092129, y: 1.338763714, label: 6, color: '#4386df' },
  { x: 14.33884811, y: 0.262339115, label: 6, color: '#4386df' },
  { x: -9.930244446, y: -0.759635925, label: 3, color: '#35f870' },
  { x: 15.67631912, y: -3.036448479, label: 6, color: '#4386df' },
  { x: -9.658651352, y: -0.642017841, label: 3, color: '#35f870' },
  { x: 10.11494827, y: 4.22309494, label: 6, color: '#4386df' },
  { x: -7.7411623, y: 0.086683273, label: 3, color: '#35f870' },
  { x: 14.71094131, y: -7.198840141, label: 6, color: '#4386df' },
  { x: 13.97861862, y: -0.682712555, label: 6, color: '#4386df' },
  { x: 9.768553734, y: 5.949263573, label: 6, color: '#4386df' },
  { x: -8.140219688, y: -0.099918365, label: 3, color: '#35f870' },
  { x: 13.45010948, y: -0.210686684, label: 6, color: '#4386df' },
  { x: 10.31110382, y: 4.491073608, label: 6, color: '#4386df' },
  { x: -7.15187645, y: 1.642774582, label: 3, color: '#35f870' },
  { x: -12.58766937, y: -0.664768219, label: 5, color: '#b5787f' },
  { x: 10.22113228, y: 4.137616158, label: 6, color: '#4386df' },
  { x: 9.532491684, y: 4.34277153, label: 6, color: '#4386df' },
  { x: 9.849473953, y: 4.20539093, label: 6, color: '#4386df' },
  { x: 14.74250603, y: -0.579950333, label: 6, color: '#4386df' },
  { x: 10.03433323, y: 5.304338455, label: 6, color: '#4386df' },
  { x: 13.42212391, y: 0.846683502, label: 6, color: '#4386df' },
  { x: 13.77506638, y: 0.447072029, label: 6, color: '#4386df' },
  { x: -11.33708763, y: -0.976835728, label: 5, color: '#b5787f' },
  { x: -7.220655441, y: 0.775508881, label: 3, color: '#35f870' },
  { x: 15.3821373, y: -4.978179932, label: 6, color: '#4386df' },
  { x: 12.99682236, y: 1.415278435, label: 6, color: '#4386df' },
  { x: 11.54950714, y: 2.370980263, label: 6, color: '#4386df' },
  { x: -8.814746857, y: -0.18106699, label: 3, color: '#35f870' },
  { x: 10.94252586, y: 3.036612511, label: 6, color: '#4386df' },
  { x: -14.04735374, y: -1.510065079, label: 5, color: '#b5787f' },
  { x: 10.74481964, y: 4.923583984, label: 6, color: '#4386df' },
  { x: 13.54641342, y: 0.679486275, label: 6, color: '#4386df' },
  { x: 12.86456013, y: 0.646315575, label: 6, color: '#4386df' },
  { x: -5.389102936, y: 0.919723511, label: 3, color: '#35f870' },
  { x: 11.94599915, y: 2.028461456, label: 6, color: '#4386df' },
  { x: 15.23833275, y: -2.572134972, label: 6, color: '#4386df' },
  { x: 9.951946259, y: 5.589134216, label: 6, color: '#4386df' },
  { x: 10.28254604, y: 4.342865944, label: 6, color: '#4386df' },
  { x: 11.18936729, y: 2.436495781, label: 6, color: '#4386df' },
  { x: -7.018476486, y: 0.373355389, label: 3, color: '#35f870' },
  { x: -9.809405327, y: -0.821757317, label: 3, color: '#35f870' },
  { x: 15.3141861, y: -2.612756729, label: 6, color: '#4386df' },
  { x: 10.27862453, y: 3.913639069, label: 6, color: '#4386df' },
  { x: 15.34589386, y: -2.670887947, label: 6, color: '#4386df' },
  { x: 12.85230446, y: 0.905323029, label: 6, color: '#4386df' },
  { x: 15.4122467, y: -2.393155098, label: 6, color: '#4386df' },
  { x: -5.631493568, y: 0.722549438, label: 3, color: '#35f870' },
  { x: -5.177324295, y: 1.035951614, label: 3, color: '#35f870' },
  { x: 10.25976372, y: 4.523611069, label: 6, color: '#4386df' },
  { x: -5.371079445, y: 0.907637596, label: 3, color: '#35f870' },
  { x: -5.467107773, y: 0.790714264, label: 3, color: '#35f870' },
  { x: 13.09824181, y: 0.145729065, label: 6, color: '#4386df' },
  { x: 12.91217422, y: 0.206393242, label: 6, color: '#4386df' },
  { x: 15.19945908, y: -2.661704063, label: 6, color: '#4386df' },
  { x: -11.1224947, y: -1.204194069, label: 3, color: '#35f870' },
  { x: -6.221638203, y: 1.18454361, label: 3, color: '#35f870' },
  { x: 9.930481911, y: 4.222133636, label: 6, color: '#4386df' },
  { x: 11.12848854, y: 4.469527245, label: 6, color: '#4386df' },
  { x: -4.754249573, y: 2.328830719, label: 3, color: '#35f870' },
  { x: 9.91620636, y: 4.330327034, label: 6, color: '#4386df' },
  { x: -7.689161301, y: 0.254321098, label: 3, color: '#35f870' },
  { x: 9.997890472, y: 4.214168549, label: 6, color: '#4386df' },
  { x: -7.903822899, y: 0.019123077, label: 3, color: '#35f870' },
  { x: 13.11795044, y: -0.098064423, label: 6, color: '#4386df' },
  { x: 12.79393864, y: 0.474916458, label: 6, color: '#4386df' },
  { x: -7.908383369, y: 0.028923035, label: 3, color: '#35f870' },
  { x: 12.05327797, y: 1.757745743, label: 6, color: '#4386df' },
  { x: -8.17007637, y: -0.094725609, label: 3, color: '#35f870' },
  { x: -5.208744049, y: 2.239451408, label: 3, color: '#35f870' },
  { x: 10.96434593, y: 4.878742218, label: 6, color: '#4386df' },
  { x: 13.33214951, y: 0.73476553, label: 6, color: '#4386df' },
  { x: 14.62503815, y: -1.175886154, label: 6, color: '#4386df' },
  { x: 10.90707111, y: 4.645875931, label: 6, color: '#4386df' },
  { x: 10.69415092, y: 3.009570599, label: 6, color: '#4386df' },
  { x: -9.983728409, y: -0.88175869, label: 3, color: '#35f870' },
  { x: 13.10162449, y: -0.073452473, label: 6, color: '#4386df' },
  { x: 11.06687737, y: 4.856027603, label: 6, color: '#4386df' },
  { x: -4.968149185, y: 0.837680817, label: 3, color: '#35f870' },
  { x: -4.772015572, y: 2.504110336, label: 3, color: '#35f870' },
  { x: 13.20610046, y: -0.168569565, label: 6, color: '#4386df' },
  { x: -5.388344288, y: 1.199602127, label: 3, color: '#35f870' },
  { x: -5.179456711, y: 0.941823959, label: 3, color: '#35f870' },
  { x: -9.745473862, y: -0.715326309, label: 3, color: '#35f870' },
  { x: 13.16539001, y: 0.946705341, label: 6, color: '#4386df' },
  { x: 9.351484299, y: 5.23008728, label: 6, color: '#4386df' },
  { x: -7.55396843, y: 0.188418388, label: 3, color: '#35f870' },
  { x: 9.073030472, y: 4.938772202, label: 6, color: '#4386df' },
  { x: 11.20224476, y: 4.858129501, label: 6, color: '#4386df' },
  { x: -5.153253555, y: 0.923439026, label: 3, color: '#35f870' },
  { x: -10.5176506, y: -1.175890923, label: 3, color: '#35f870' },
  { x: -10.22994995, y: -1.022024155, label: 3, color: '#35f870' },
  { x: -10.54846191, y: -0.968115807, label: 3, color: '#35f870' },
  { x: -5.273517609, y: 1.091928482, label: 3, color: '#35f870' },
  { x: 9.130708694, y: 5.055932999, label: 6, color: '#4386df' },
  { x: -7.696388245, y: 0.109741211, label: 3, color: '#35f870' },
  { x: -9.129656792, y: -0.466209412, label: 3, color: '#35f870' },
  { x: 9.976900101, y: 4.299754143, label: 6, color: '#4386df' },
  { x: 11.15132713, y: 4.90296936, label: 6, color: '#4386df' },
  { x: -7.075563431, y: 0.281097412, label: 3, color: '#35f870' },
  { x: -5.106093407, y: 0.900914669, label: 3, color: '#35f870' },
  { x: 13.94126511, y: 0.109378815, label: 6, color: '#4386df' },
  { x: -5.354113579, y: 1.311761856, label: 3, color: '#35f870' },
  { x: 11.3097496, y: 2.657673836, label: 6, color: '#4386df' },
  { x: 11.06512547, y: 4.496343613, label: 6, color: '#4386df' },
  { x: 13.71940041, y: -0.129434586, label: 6, color: '#4386df' },
  { x: 9.638347626, y: 6.449279785, label: 6, color: '#4386df' },
  { x: -10.75349808, y: -0.830162048, label: 3, color: '#35f870' },
  { x: 11.41611385, y: 2.53420639, label: 6, color: '#4386df' },
  { x: -10.5857563, y: -1.229858398, label: 3, color: '#35f870' },
  { x: -10.44152641, y: -1.000407219, label: 3, color: '#35f870' },
  { x: 9.974750519, y: 5.69797039, label: 6, color: '#4386df' },
  { x: -10.63070393, y: -1.13492918, label: 3, color: '#35f870' },
  { x: 11.30015564, y: 2.679496765, label: 6, color: '#4386df' },
  { x: 10.50857353, y: 4.352231979, label: 6, color: '#4386df' },
  { x: -10.38202763, y: -0.990921021, label: 3, color: '#35f870' },
  { x: -4.958427429, y: 0.799313545, label: 3, color: '#35f870' },
  { x: 9.548363686, y: 6.371210098, label: 6, color: '#4386df' },
  { x: -10.81610394, y: -1.263384819, label: 3, color: '#35f870' },
  { x: 9.788959503, y: 5.854400635, label: 6, color: '#4386df' },
  { x: -10.34265518, y: -1.074426651, label: 3, color: '#35f870' },
  { x: 9.407038689, y: 5.521061897, label: 6, color: '#4386df' },
  { x: -6.67939949, y: 0.659633636, label: 3, color: '#35f870' },
  { x: 13.42875481, y: 0.091885567, label: 6, color: '#4386df' },
  { x: -6.810854912, y: 1.638018608, label: 3, color: '#35f870' },
  { x: 15.34653091, y: -2.583119392, label: 6, color: '#4386df' },
  { x: 14.43297577, y: -0.127397537, label: 6, color: '#4386df' },
  { x: -5.230893135, y: 0.695009232, label: 3, color: '#35f870' },
  { x: -10.91781425, y: -1.436341286, label: 3, color: '#35f870' },
  { x: 9.257443428, y: 5.192268372, label: 6, color: '#4386df' },
  { x: 11.66101265, y: 2.305987358, label: 6, color: '#4386df' },
  { x: 14.61199951, y: -0.444410801, label: 6, color: '#4386df' },
  { x: 13.32687378, y: 0.147590637, label: 6, color: '#4386df' },
  { x: -7.538967133, y: 0.14356041, label: 3, color: '#35f870' },
  { x: 10.17434216, y: 4.500386238, label: 6, color: '#4386df' },
  { x: -12.52499199, y: -0.751662731, label: 3, color: '#35f870' },
  { x: -7.896660805, y: 1.506912231, label: 3, color: '#35f870' },
  { x: 13.23956299, y: -0.165039063, label: 6, color: '#4386df' },
  { x: -6.625317574, y: 1.141073227, label: 3, color: '#35f870' },
  { x: 10.89749527, y: 5.137163162, label: 6, color: '#4386df' },
  { x: 9.373245239, y: 5.246323586, label: 6, color: '#4386df' },
  { x: -6.542736053, y: 1.293964386, label: 3, color: '#35f870' },
  { x: 10.13988018, y: 4.887415409, label: 6, color: '#4386df' },
  { x: -9.660164833, y: -0.580698013, label: 3, color: '#35f870' },
  { x: 10.10430241, y: 4.667819977, label: 6, color: '#4386df' },
  { x: 9.629396439, y: 5.467485428, label: 6, color: '#4386df' },
  { x: -9.70458889, y: -0.637060642, label: 3, color: '#35f870' },
  { x: -8.980472565, y: -0.710630417, label: 3, color: '#35f870' },
  { x: 10.01352692, y: 5.909096718, label: 6, color: '#4386df' },
  { x: 12.14134407, y: 1.811450958, label: 6, color: '#4386df' },
  { x: 12.90606689, y: 1.010574818, label: 6, color: '#4386df' },
  { x: -8.129407883, y: -0.059944153, label: 3, color: '#35f870' },
  { x: -5.929233551, y: 0.716423988, label: 3, color: '#35f870' },
  { x: -6.950181961, y: 0.313383102, label: 3, color: '#35f870' },
  { x: 9.863637924, y: 4.107297897, label: 6, color: '#4386df' },
  { x: 10.93271065, y: 4.285180092, label: 6, color: '#4386df' },
  { x: 10.6889782, y: 4.419956207, label: 6, color: '#4386df' },
  { x: 11.2450695, y: 4.00432682, label: 6, color: '#4386df' },
  { x: -6.156786919, y: 1.266823769, label: 3, color: '#35f870' },
  { x: -5.084863663, y: 0.95939064, label: 3, color: '#35f870' },
  { x: 12.74056435, y: 0.348335266, label: 6, color: '#4386df' },
  { x: -4.927427292, y: 0.875159264, label: 3, color: '#35f870' },
  { x: -5.100605011, y: 0.940500259, label: 3, color: '#35f870' },
  { x: -4.988636017, y: 0.901957512, label: 3, color: '#35f870' },
  { x: 12.57792854, y: 0.579771996, label: 6, color: '#4386df' },
  { x: -5.514070511, y: 0.705658913, label: 3, color: '#35f870' },
  { x: -9.611629486, y: -0.638175011, label: 3, color: '#35f870' },
  { x: 11.2129364, y: 4.934817314, label: 6, color: '#4386df' },
  { x: 9.578461647, y: 5.208698273, label: 6, color: '#4386df' },
  { x: 9.62400341, y: 4.163759232, label: 6, color: '#4386df' },
  { x: -4.968235016, y: 0.944786072, label: 3, color: '#35f870' },
  { x: 9.923604012, y: 3.952466011, label: 6, color: '#4386df' },
  { x: 13.56015015, y: 0.440014839, label: 6, color: '#4386df' },
  { x: -13.12981224, y: -1.614625931, label: 5, color: '#b5787f' },
  { x: -9.958597183, y: -0.779087067, label: 3, color: '#35f870' },
  { x: 9.597775459, y: 6.084712982, label: 6, color: '#4386df' },
  { x: 11.01426697, y: 5.1979599, label: 6, color: '#4386df' },
  { x: 13.08947754, y: -0.093738556, label: 6, color: '#4386df' },
  { x: -8.145202637, y: 0.671331882, label: 3, color: '#35f870' },
  { x: -6.576725006, y: 1.19889307, label: 3, color: '#35f870' },
  { x: -12.35528374, y: -0.763536453, label: 5, color: '#b5787f' },
  { x: -11.23478317, y: -1.529219627, label: 5, color: '#b5787f' },
  { x: 11.04277229, y: 3.703464508, label: 6, color: '#4386df' },
  { x: -9.21540451, y: -0.464582443, label: 3, color: '#35f870' },
  { x: -10.12787819, y: -0.896348953, label: 3, color: '#35f870' },
  { x: 14.39824677, y: -1.674823761, label: 6, color: '#4386df' },
  { x: 10.95815372, y: 4.019504547, label: 6, color: '#4386df' },
  { x: 13.57485008, y: 0.872799873, label: 6, color: '#4386df' },
  { x: -5.616841316, y: 1.005646706, label: 3, color: '#35f870' },
  { x: -7.719727516, y: 1.879502773, label: 3, color: '#35f870' },
  { x: -5.142008781, y: 1.036057472, label: 3, color: '#35f870' },
  { x: 13.58909607, y: -0.341985703, label: 6, color: '#4386df' },
  { x: 11.20094204, y: 5.003623962, label: 6, color: '#4386df' },
  { x: -6.500099182, y: 0.495933533, label: 3, color: '#35f870' },
  { x: 13.91710281, y: 0.360457897, label: 6, color: '#4386df' },
  { x: 13.84161758, y: 1.080163002, label: 6, color: '#4386df' },
  { x: 10.00958443, y: 5.202579498, label: 6, color: '#4386df' },
  { x: 10.18457603, y: 4.481784821, label: 6, color: '#4386df' },
  { x: -10.3331213, y: -0.773694038, label: 3, color: '#35f870' },
  { x: 10.38956261, y: 4.237179756, label: 6, color: '#4386df' },
  { x: 15.34798813, y: -5.225903511, label: 6, color: '#4386df' },
  { x: 13.09111977, y: 0.134749413, label: 6, color: '#4386df' },
  { x: -9.580630302, y: -0.585816383, label: 3, color: '#35f870' },
  { x: 9.800226212, y: 4.893982887, label: 6, color: '#4386df' },
  { x: 10.41348076, y: 4.983482361, label: 6, color: '#4386df' },
  { x: 11.15961075, y: 2.87398243, label: 6, color: '#4386df' },
  { x: -7.236804008, y: 0.383466721, label: 3, color: '#35f870' },
  { x: 9.696048737, y: 5.076568604, label: 6, color: '#4386df' },
  { x: -7.610732079, y: 1.727523327, label: 3, color: '#35f870' },
  { x: -5.716750145, y: 1.303854942, label: 3, color: '#35f870' },
  { x: 9.989107132, y: 5.77746582, label: 6, color: '#4386df' },
  { x: 12.78978729, y: 1.11644268, label: 6, color: '#4386df' },
  { x: 15.60375595, y: -1.77398777, label: 6, color: '#4386df' },
  { x: 15.61614609, y: -1.572573662, label: 6, color: '#4386df' },
  { x: -9.737354279, y: -0.672275543, label: 3, color: '#35f870' },
  { x: 10.27198219, y: 4.496088028, label: 6, color: '#4386df' },
  { x: 13.31970024, y: -0.020702362, label: 6, color: '#4386df' },
  { x: -13.5582695, y: -0.0505867, label: 5, color: '#b5787f' },
  { x: -9.694187164, y: -0.679022312, label: 3, color: '#35f870' },
  { x: -5.433216095, y: 1.17868042, label: 3, color: '#35f870' },
  { x: 11.51586533, y: 2.41386795, label: 6, color: '#4386df' },
  { x: 14.82793236, y: -0.649368286, label: 6, color: '#4386df' },
  { x: 11.05734253, y: 3.935588837, label: 6, color: '#4386df' },
  { x: -8.147747993, y: 1.929701805, label: 3, color: '#35f870' },
  { x: 13.1529808, y: 0.849039078, label: 6, color: '#4386df' },
  { x: 15.38631248, y: -2.424881935, label: 6, color: '#4386df' },
  { x: 15.74577141, y: -1.622253418, label: 6, color: '#4386df' },
  { x: 13.97542953, y: -0.406852722, label: 6, color: '#4386df' },
  { x: 11.37668037, y: 2.645150185, label: 6, color: '#4386df' },
  { x: 10.32494259, y: 5.361976624, label: 6, color: '#4386df' },
  { x: 13.45611572, y: 0.784399033, label: 6, color: '#4386df' },
  { x: 13.77378273, y: 0.153971672, label: 6, color: '#4386df' },
  { x: -10.22979736, y: -0.886899948, label: 3, color: '#35f870' },
  { x: -13.1853056, y: -2.214327335, label: 5, color: '#b5787f' },
  { x: -5.282903671, y: 0.834857941, label: 3, color: '#35f870' },
  { x: -6.873466492, y: 1.23645401, label: 3, color: '#35f870' },
  { x: 10.99814987, y: 3.669075012, label: 6, color: '#4386df' },
  { x: 10.44516373, y: 5.334468842, label: 6, color: '#4386df' },
  { x: 10.0968895, y: 4.381966591, label: 6, color: '#4386df' },
  { x: 9.436005592, y: 4.223083496, label: 6, color: '#4386df' },
  { x: -4.934965134, y: 0.878906727, label: 3, color: '#35f870' },
  { x: -5.875579834, y: 0.785173416, label: 3, color: '#35f870' },
  { x: 13.363554, y: -0.302547455, label: 6, color: '#4386df' },
  { x: -5.505582809, y: 0.699046135, label: 3, color: '#35f870' },
  { x: -5.214633942, y: 0.867955208, label: 3, color: '#35f870' },
  { x: 13.53567505, y: -0.23075676, label: 6, color: '#4386df' },
  { x: -7.703542709, y: 0.069503784, label: 3, color: '#35f870' },
  { x: 13.49644279, y: -0.33422184, label: 6, color: '#4386df' },
  { x: 10.76290226, y: 3.705010414, label: 6, color: '#4386df' },
  { x: 9.25217247, y: 5.836408615, label: 6, color: '#4386df' },
  { x: 15.08157539, y: -2.621763229, label: 6, color: '#4386df' },
  { x: 11.35690784, y: 4.654191971, label: 6, color: '#4386df' },
  { x: 10.37322998, y: 5.1969347, label: 6, color: '#4386df' },
  { x: -7.249900818, y: 0.292549133, label: 3, color: '#35f870' },
  { x: 11.51836872, y: 2.496807098, label: 6, color: '#4386df' },
  { x: -9.573609352, y: -0.615767479, label: 3, color: '#35f870' },
  { x: 10.95537663, y: 3.451467514, label: 6, color: '#4386df' },
  { x: -7.99366951, y: -0.054101944, label: 3, color: '#35f870' },
  { x: 10.45954037, y: 3.69332695, label: 6, color: '#4386df' },
  { x: 9.737775803, y: 4.463014603, label: 6, color: '#4386df' },
  { x: -9.9165802, y: -0.691547394, label: 3, color: '#35f870' },
  { x: 10.28677177, y: 5.452692032, label: 6, color: '#4386df' },
  { x: 9.566606522, y: 6.185043335, label: 6, color: '#4386df' },
  { x: 12.92719078, y: 0.684513092, label: 6, color: '#4386df' },
  { x: -10.75323105, y: -1.232240677, label: 3, color: '#35f870' },
  { x: 14.03428459, y: 0.080434799, label: 6, color: '#4386df' },
  { x: -6.885773659, y: 0.358298779, label: 3, color: '#35f870' },
  { x: 15.24538803, y: -1.233217239, label: 6, color: '#4386df' },
  { x: 10.23304367, y: 4.857728958, label: 6, color: '#4386df' },
  { x: 12.54463959, y: 0.876319885, label: 6, color: '#4386df' },
  { x: 10.17032337, y: 4.33055687, label: 6, color: '#4386df' },
  { x: 10.92234707, y: 2.674571991, label: 6, color: '#4386df' },
  { x: -10.10592651, y: -0.795778275, label: 3, color: '#35f870' },
  { x: -6.776470184, y: 1.56785965, label: 3, color: '#35f870' },
  { x: -4.709204674, y: 2.627246857, label: 3, color: '#35f870' },
  { x: 10.93834496, y: 4.475649834, label: 6, color: '#4386df' },
  { x: 9.701527596, y: 4.129327774, label: 6, color: '#4386df' },
  { x: -5.830184937, y: 0.718304634, label: 3, color: '#35f870' },
  { x: 12.67285538, y: 0.49154377, label: 6, color: '#4386df' },
  { x: 12.90763474, y: 0.762429714, label: 6, color: '#4386df' },
  { x: -5.731312752, y: 0.834071636, label: 3, color: '#35f870' },
  { x: 12.68284607, y: 0.430505753, label: 6, color: '#4386df' },
  { x: -6.951309204, y: 0.435100555, label: 3, color: '#35f870' },
  { x: 11.13982964, y: 3.058469772, label: 6, color: '#4386df' },
  { x: -7.898387909, y: 0.084850311, label: 3, color: '#35f870' },
  { x: 13.51107025, y: 0.913171768, label: 6, color: '#4386df' },
  { x: 9.637331009, y: 5.508684158, label: 6, color: '#4386df' },
  { x: 9.9009161, y: 4.766770363, label: 6, color: '#4386df' },
  { x: -8.333155632, y: 2.335463524, label: 3, color: '#35f870' },
  { x: 9.972798347, y: 4.742873192, label: 6, color: '#4386df' },
  { x: -5.082561493, y: 0.975283623, label: 3, color: '#35f870' },
  { x: -7.716068268, y: 0.13867569, label: 3, color: '#35f870' },
  { x: -5.231121063, y: 1.091729164, label: 3, color: '#35f870' },
  { x: -5.133636475, y: 1.073350906, label: 3, color: '#35f870' },
  { x: -9.660494804, y: -0.632379532, label: 3, color: '#35f870' },
  { x: -5.343595505, y: 1.327130318, label: 3, color: '#35f870' },
  { x: -5.402318954, y: 1.476784706, label: 3, color: '#35f870' },
  { x: -5.363012314, y: 1.424901962, label: 3, color: '#35f870' },
  { x: -10.74141598, y: -1.126294136, label: 3, color: '#35f870' },
  { x: 12.59866142, y: 0.611945152, label: 6, color: '#4386df' },
  { x: -4.932600021, y: 0.761642456, label: 3, color: '#35f870' },
  { x: 12.90119553, y: 0.671192646, label: 6, color: '#4386df' },
  { x: -5.313886642, y: 0.931495667, label: 3, color: '#35f870' },
  { x: -6.963492393, y: 0.370812416, label: 3, color: '#35f870' },
  { x: -5.207638741, y: 2.42058754, label: 3, color: '#35f870' },
  { x: 11.2249918, y: 4.358031273, label: 6, color: '#4386df' },
  { x: 15.21204758, y: -2.607132912, label: 6, color: '#4386df' },
  { x: -4.990358353, y: 1.213083267, label: 3, color: '#35f870' },
  { x: -9.755519867, y: -0.646867275, label: 3, color: '#35f870' },
  { x: -5.724901199, y: 0.883737564, label: 3, color: '#35f870' },
  { x: -7.708803177, y: 1.72236824, label: 3, color: '#35f870' },
  { x: -10.73410606, y: -0.993434906, label: 3, color: '#35f870' },
  { x: 11.8506422, y: 1.970805645, label: 6, color: '#4386df' },
  { x: 12.42882442, y: 0.844159126, label: 6, color: '#4386df' },
  { x: 11.14267063, y: 5.087072372, label: 6, color: '#4386df' },
  { x: -7.616111755, y: 1.715034485, label: 3, color: '#35f870' },
  { x: -5.600658417, y: 1.515958786, label: 3, color: '#35f870' },
  { x: -5.393676758, y: 1.347541809, label: 3, color: '#35f870' },
  { x: -5.193109512, y: 1.024873257, label: 3, color: '#35f870' },
  { x: -5.971011162, y: 1.652092934, label: 3, color: '#35f870' },
  { x: -4.993011475, y: 0.792327881, label: 3, color: '#35f870' },
  { x: 9.8813591, y: 4.804491997, label: 6, color: '#4386df' },
  { x: 10.04981613, y: 5.108076096, label: 6, color: '#4386df' },
  { x: 13.69235134, y: 0.531030655, label: 6, color: '#4386df' },
  { x: 9.411828041, y: 4.802501678, label: 6, color: '#4386df' },
  { x: -6.566784859, y: 1.240859985, label: 3, color: '#35f870' },
  { x: 10.87029076, y: 5.391881943, label: 6, color: '#4386df' },
  { x: -7.493702888, y: 0.18061161, label: 3, color: '#35f870' },
  { x: 12.85304451, y: 0.165122986, label: 6, color: '#4386df' },
  { x: 9.856744766, y: 5.646780968, label: 6, color: '#4386df' },
  { x: 10.95176697, y: 4.685259819, label: 6, color: '#4386df' },
  { x: -10.03992748, y: -0.810884476, label: 3, color: '#35f870' },
  { x: 13.63630676, y: -0.612317085, label: 6, color: '#4386df' },
  { x: -7.478555679, y: 0.677724838, label: 3, color: '#35f870' },
  { x: 12.6193161, y: 1.259933472, label: 6, color: '#4386df' },
  { x: 11.3167572, y: 2.690425873, label: 6, color: '#4386df' },
  { x: -5.082342148, y: 2.597441196, label: 3, color: '#35f870' },
  { x: 9.79114151, y: 5.964244843, label: 6, color: '#4386df' },
  { x: -5.006081581, y: 0.855015755, label: 3, color: '#35f870' },
  { x: -9.099244118, y: -0.494998932, label: 3, color: '#35f870' },
  { x: 9.688108444, y: 5.166857719, label: 6, color: '#4386df' },
  { x: 12.79783058, y: 0.864593029, label: 6, color: '#4386df' },
  { x: 9.934469223, y: 4.26639843, label: 6, color: '#4386df' },
  { x: 10.16173935, y: 4.783845901, label: 6, color: '#4386df' },
  { x: 11.37745762, y: 4.73143959, label: 6, color: '#4386df' },
  { x: 10.7581768, y: 4.481572151, label: 6, color: '#4386df' },
  { x: -5.481842041, y: 0.936090946, label: 3, color: '#35f870' },
  { x: 12.59093475, y: 1.414702892, label: 6, color: '#4386df' },
  { x: 13.30557537, y: 1.205126286, label: 6, color: '#4386df' },
  { x: 13.07884216, y: -0.089168549, label: 6, color: '#4386df' },
  { x: 10.53627014, y: 3.844472885, label: 6, color: '#4386df' },
  { x: -9.62997818, y: -0.66781044, label: 3, color: '#35f870' },
  { x: 15.45833397, y: -2.565816879, label: 6, color: '#4386df' },
  { x: -5.328361511, y: 0.897262573, label: 3, color: '#35f870' },
  { x: -6.242557526, y: 1.431134701, label: 3, color: '#35f870' },
  { x: 10.40116215, y: 3.578894615, label: 6, color: '#4386df' },
  { x: 10.66879272, y: 3.397287369, label: 6, color: '#4386df' },
  { x: -5.304562569, y: 1.417313576, label: 3, color: '#35f870' },
  { x: -4.950037003, y: 0.869430542, label: 3, color: '#35f870' },
  { x: 12.68833923, y: 0.396186829, label: 6, color: '#4386df' },
  { x: 13.96629333, y: -0.985762596, label: 6, color: '#4386df' },
  { x: 11.11781502, y: 5.096884727, label: 6, color: '#4386df' },
  { x: 13.40332794, y: 0.7272048, label: 6, color: '#4386df' },
  { x: -8.727737427, y: 1.105957985, label: 3, color: '#35f870' },
  { x: -11.79964447, y: -1.832344055, label: 5, color: '#b5787f' },
  { x: 9.50697422, y: 5.079606056, label: 6, color: '#4386df' },
  { x: 15.29323959, y: -2.082684517, label: 6, color: '#4386df' },
  { x: 15.61750031, y: -1.025272846, label: 6, color: '#4386df' },
  { x: 12.99711609, y: 1.103050232, label: 6, color: '#4386df' },
  { x: 11.61502171, y: 2.055155754, label: 6, color: '#4386df' },
  { x: -11.21228409, y: -1.677185535, label: 5, color: '#b5787f' },
  { x: 9.447145462, y: 5.821075439, label: 6, color: '#4386df' },
  { x: 9.322946548, y: 5.946043968, label: 6, color: '#4386df' },
  { x: -6.646694183, y: 0.637929916, label: 3, color: '#35f870' },
  { x: 8.94295311, y: 5.640099525, label: 6, color: '#4386df' },
  { x: 9.496387482, y: 5.083145142, label: 6, color: '#4386df' },
  { x: -11.54065895, y: -2.198415756, label: 5, color: '#b5787f' },
  { x: 9.328674316, y: 6.482586861, label: 6, color: '#4386df' },
  { x: -8.743839264, y: 2.096003532, label: 3, color: '#35f870' },
  { x: 9.500832558, y: 6.010708809, label: 6, color: '#4386df' },
  { x: 9.423715591, y: 7.815917969, label: 6, color: '#4386df' },
  { x: 13.48306084, y: 0.737085342, label: 6, color: '#4386df' },
  { x: 10.6187439, y: 5.028695107, label: 6, color: '#4386df' },
  { x: 9.719717979, y: 3.708932877, label: 6, color: '#4386df' },
  { x: 13.11834621, y: 1.379874229, label: 6, color: '#4386df' },
  { x: 10.42325306, y: 2.864042282, label: 6, color: '#4386df' },
  { x: 15.69337463, y: -3.353872299, label: 6, color: '#4386df' },
  { x: 9.420923233, y: 6.775192261, label: 6, color: '#4386df' },
  { x: 9.562660217, y: 4.434267998, label: 6, color: '#4386df' },
  { x: 9.36661911, y: 4.437681198, label: 6, color: '#4386df' },
  { x: -8.419139862, y: 1.518856049, label: 3, color: '#35f870' },
  { x: 9.375366211, y: 5.840278625, label: 6, color: '#4386df' },
  { x: 9.533666611, y: 5.105009079, label: 6, color: '#4386df' },
  { x: 9.816669464, y: 3.533368111, label: 6, color: '#4386df' },
  { x: -11.29458523, y: -1.583104134, label: 5, color: '#b5787f' },
  { x: 9.570789337, y: 4.257989883, label: 6, color: '#4386df' },
  { x: 9.854572296, y: 3.160208702, label: 6, color: '#4386df' },
  { x: -11.50175667, y: -1.91179657, label: 5, color: '#b5787f' },
  { x: 14.01154709, y: 0.161146641, label: 6, color: '#4386df' },
  { x: 10.03316975, y: 3.207643509, label: 6, color: '#4386df' },
  { x: 14.58656311, y: -2.295660019, label: 6, color: '#4386df' },
  { x: 9.640810013, y: 7.328842163, label: 6, color: '#4386df' },
  { x: 9.701969147, y: 3.784145355, label: 6, color: '#4386df' },
  { x: 13.42518425, y: 0.880703926, label: 6, color: '#4386df' },
  { x: 14.66838837, y: -0.460522652, label: 6, color: '#4386df' },
  { x: -11.66422462, y: -1.870762825, label: 5, color: '#b5787f' },
  { x: 15.31398201, y: -2.674529076, label: 6, color: '#4386df' },
  { x: 9.742708206, y: 3.799525261, label: 6, color: '#4386df' },
  { x: 11.39730644, y: 2.644105911, label: 6, color: '#4386df' },
  { x: 15.24433708, y: -1.47632122, label: 6, color: '#4386df' },
  { x: 9.6379776, y: 7.83491993, label: 6, color: '#4386df' },
  { x: -7.852326393, y: 1.696540833, label: 3, color: '#35f870' },
  { x: 9.613352776, y: 4.016142845, label: 6, color: '#4386df' },
  { x: -11.83389664, y: -1.802167892, label: 5, color: '#b5787f' },
  { x: 9.602027893, y: 7.19625473, label: 6, color: '#4386df' },
  { x: -11.94042015, y: -1.534467697, label: 5, color: '#b5787f' },
  { x: -11.85640621, y: -1.812028885, label: 5, color: '#b5787f' },
  { x: 9.55025959, y: 4.485463142, label: 6, color: '#4386df' },
  { x: 9.564746857, y: 7.127967834, label: 6, color: '#4386df' },
  { x: 9.56980896, y: 7.436903, label: 6, color: '#4386df' },
  { x: 10.19718933, y: 3.087177277, label: 6, color: '#4386df' },
  { x: 9.536363602, y: 7.163412094, label: 6, color: '#4386df' },
  { x: 9.464331627, y: 6.482765198, label: 6, color: '#4386df' },
  { x: 11.00117874, y: 2.464663506, label: 6, color: '#4386df' },
  { x: 13.65600967, y: 0.501808167, label: 6, color: '#4386df' },
  { x: -11.9152565, y: -2.307268143, label: 5, color: '#b5787f' },
  { x: -12.38465881, y: -2.083780289, label: 5, color: '#b5787f' },
  { x: 9.408109665, y: 5.942584038, label: 6, color: '#4386df' },
  { x: 14.06050873, y: 0.126922607, label: 6, color: '#4386df' },
  { x: -11.64054871, y: -1.618766785, label: 5, color: '#b5787f' },
  { x: 9.487422943, y: 6.891672134, label: 6, color: '#4386df' },
  { x: -11.82644653, y: -2.053572178, label: 5, color: '#b5787f' },
  { x: 9.139985085, y: 5.136838913, label: 6, color: '#4386df' },
  { x: -11.33608913, y: -2.130777359, label: 5, color: '#b5787f' },
  { x: -11.23052883, y: -1.842578888, label: 5, color: '#b5787f' },
  { x: 9.597143173, y: 6.957775116, label: 6, color: '#4386df' },
  { x: 9.469504356, y: 6.868800163, label: 6, color: '#4386df' },
  { x: 9.96515274, y: 3.324174881, label: 6, color: '#4386df' },
  { x: 14.78965569, y: -0.7539711, label: 6, color: '#4386df' },
  { x: 9.52684021, y: 7.740974426, label: 6, color: '#4386df' },
  { x: 9.483598709, y: 6.550661087, label: 6, color: '#4386df' },
  { x: -11.77455902, y: -2.578510284, label: 5, color: '#b5787f' },
  { x: 9.549455643, y: 7.144170761, label: 6, color: '#4386df' },
  { x: -11.76064587, y: -2.607507706, label: 5, color: '#b5787f' },
  { x: -12.47120571, y: -2.294145107, label: 5, color: '#b5787f' },
  { x: 12.47825241, y: 1.475430489, label: 6, color: '#4386df' },
  { x: 15.16234207, y: -1.476350784, label: 6, color: '#4386df' },
  { x: 9.823105812, y: 3.632562637, label: 6, color: '#4386df' },
  { x: -13.59673882, y: -0.488773823, label: 5, color: '#b5787f' },
  { x: -11.77998447, y: -1.671340942, label: 5, color: '#b5787f' },
  { x: -11.67266464, y: -2.452222824, label: 5, color: '#b5787f' },
  { x: -12.54712677, y: -0.975757599, label: 5, color: '#b5787f' },
  { x: 9.836313248, y: 3.573773861, label: 6, color: '#4386df' },
  { x: 9.482319832, y: 4.657723427, label: 6, color: '#4386df' },
  { x: 9.39494133, y: 5.510516167, label: 6, color: '#4386df' },
  { x: 9.623212814, y: 4.161623001, label: 6, color: '#4386df' },
  { x: 9.430386543, y: 5.590405464, label: 6, color: '#4386df' },
  { x: 10.98955727, y: 4.217259407, label: 6, color: '#4386df' },
  { x: -5.287866592, y: 2.226302624, label: 3, color: '#35f870' },
  { x: 9.427254677, y: 4.266869068, label: 6, color: '#4386df' },
  { x: 9.629367828, y: 7.388832092, label: 6, color: '#4386df' },
  { x: 15.17147827, y: -1.031509399, label: 6, color: '#4386df' },
  { x: 13.79949951, y: -0.824317932, label: 6, color: '#4386df' },
  { x: -11.32475185, y: -2.324081421, label: 5, color: '#b5787f' },
  { x: 11.08972168, y: 2.378468513, label: 6, color: '#4386df' },
  { x: -12.88862419, y: -2.090389252, label: 5, color: '#b5787f' },
  { x: -11.35921764, y: -1.370771408, label: 5, color: '#b5787f' },
  { x: 12.84610748, y: 1.056242943, label: 6, color: '#4386df' },
  { x: 14.27996254, y: -0.055727005, label: 6, color: '#4386df' },
  { x: -11.58485985, y: -1.705360413, label: 5, color: '#b5787f' },
  { x: 10.57417488, y: 2.771539688, label: 6, color: '#4386df' },
  { x: 15.08400917, y: -1.35046196, label: 6, color: '#4386df' },
  { x: 10.00214958, y: 3.330093384, label: 6, color: '#4386df' },
  { x: 14.13384438, y: 0.151145458, label: 6, color: '#4386df' },
  { x: -12.0956192, y: -1.591460228, label: 5, color: '#b5787f' },
  { x: 9.521841049, y: 6.745961189, label: 6, color: '#4386df' },
  { x: 12.97081947, y: 0.939029694, label: 6, color: '#4386df' },
  { x: 9.242629051, y: 4.80324173, label: 6, color: '#4386df' },
  { x: -12.716362, y: -2.208820343, label: 5, color: '#b5787f' },
  { x: -11.15703392, y: -1.852344513, label: 5, color: '#b5787f' },
  { x: -12.35284615, y: -1.720121384, label: 5, color: '#b5787f' },
  { x: 9.683223724, y: 3.982008457, label: 6, color: '#4386df' },
  { x: 9.916244507, y: 3.29854393, label: 6, color: '#4386df' },
  { x: 11.04076385, y: 4.333067894, label: 6, color: '#4386df' },
  { x: 11.73646545, y: 1.936287403, label: 6, color: '#4386df' },
  { x: -11.54103088, y: -2.183632851, label: 5, color: '#b5787f' },
  { x: -11.66766834, y: -1.969551086, label: 5, color: '#b5787f' },
  { x: 9.372515678, y: 5.555776596, label: 6, color: '#4386df' },
  { x: -11.10552979, y: -1.828457832, label: 5, color: '#b5787f' },
  { x: 9.497850418, y: 4.34939003, label: 6, color: '#4386df' },
  { x: 10.35567188, y: 2.946108818, label: 6, color: '#4386df' },
  { x: 13.5016098, y: 0.728574753, label: 6, color: '#4386df' },
  { x: -11.1872406, y: -2.023805618, label: 5, color: '#b5787f' },
  { x: 11.00694561, y: 2.436124802, label: 6, color: '#4386df' },
  { x: -11.14877892, y: -1.948112488, label: 5, color: '#b5787f' },
  { x: 13.4046545, y: 0.626011848, label: 6, color: '#4386df' },
  { x: -11.37995625, y: -2.247011185, label: 5, color: '#b5787f' },
  { x: -11.55460358, y: -1.93503046, label: 5, color: '#b5787f' },
  { x: 9.475439072, y: 6.410123825, label: 6, color: '#4386df' },
  { x: 9.272584915, y: 5.915030479, label: 6, color: '#4386df' },
  { x: 10.32441711, y: 2.992748737, label: 6, color: '#4386df' },
  { x: 9.617116928, y: 4.322965622, label: 6, color: '#4386df' },
  { x: 12.76192093, y: 1.13975811, label: 6, color: '#4386df' },
  { x: -11.31904221, y: -2.211697578, label: 5, color: '#b5787f' },
  { x: -11.62591553, y: -1.748931885, label: 5, color: '#b5787f' },
  { x: 9.377092361, y: 6.720234871, label: 6, color: '#4386df' },
  { x: 15.37697601, y: -1.614171982, label: 6, color: '#4386df' },
  { x: 9.432563782, y: 6.098115921, label: 6, color: '#4386df' },
  { x: -6.248222351, y: 1.213633537, label: 3, color: '#35f870' },
  { x: 13.12686825, y: 0.855142593, label: 6, color: '#4386df' },
  { x: -11.27086067, y: -1.689827919, label: 5, color: '#b5787f' },
  { x: 11.25395489, y: 2.278429985, label: 6, color: '#4386df' },
  { x: -11.12342739, y: -1.592564106, label: 5, color: '#b5787f' },
  { x: 9.601712227, y: 4.106437683, label: 6, color: '#4386df' },
  { x: -11.9945612, y: -1.781774521, label: 5, color: '#b5787f' },
  { x: 9.791866302, y: 3.84544754, label: 6, color: '#4386df' },
  { x: 12.05367088, y: 1.70425415, label: 6, color: '#4386df' },
  { x: 9.552732468, y: 6.739341736, label: 6, color: '#4386df' },
  { x: 9.279230118, y: 5.644205093, label: 6, color: '#4386df' },
  { x: 13.6769886, y: 0.532221317, label: 6, color: '#4386df' },
  { x: -11.46399498, y: -2.200634003, label: 5, color: '#b5787f' },
  { x: -11.36416245, y: -2.204055786, label: 5, color: '#b5787f' },
  { x: -12.63594913, y: -2.371908188, label: 5, color: '#b5787f' },
  { x: 9.498443604, y: 7.228795052, label: 6, color: '#4386df' },
  { x: 13.39875412, y: 0.766820908, label: 6, color: '#4386df' },
  { x: 9.572969437, y: 4.412074089, label: 6, color: '#4386df' },
  { x: -11.71590996, y: -2.260831833, label: 5, color: '#b5787f' },
  { x: -11.5854187, y: -1.865028381, label: 5, color: '#b5787f' },
  { x: -11.82889748, y: -1.613887787, label: 5, color: '#b5787f' },
  { x: 9.458692551, y: 6.187480927, label: 6, color: '#4386df' },
  { x: 11.01322556, y: 3.949270248, label: 6, color: '#4386df' },
  { x: 9.460921288, y: 4.965112686, label: 6, color: '#4386df' },
  { x: 14.73736763, y: -0.670284748, label: 6, color: '#4386df' },
  { x: -11.59817696, y: -1.974802971, label: 5, color: '#b5787f' },
  { x: 13.07723808, y: 0.969890594, label: 6, color: '#4386df' },
  { x: -12.94818115, y: -2.53464365, label: 5, color: '#b5787f' },
  { x: 9.805912018, y: 3.552598953, label: 6, color: '#4386df' },
  { x: 9.467617989, y: 6.436981201, label: 6, color: '#4386df' },
  { x: 9.553486824, y: 6.709973335, label: 6, color: '#4386df' },
  { x: 13.66506004, y: 0.425014496, label: 6, color: '#4386df' },
  { x: 13.48195553, y: 0.706362724, label: 6, color: '#4386df' },
  { x: 9.659605026, y: 7.762145996, label: 6, color: '#4386df' },
  { x: 9.44852829, y: 5.9168787, label: 6, color: '#4386df' },
  { x: 13.51580238, y: -0.249112129, label: 6, color: '#4386df' },
  { x: 9.543445587, y: 4.584582806, label: 6, color: '#4386df' },
  { x: 14.34823799, y: -0.201049805, label: 6, color: '#4386df' },
  { x: 13.26293182, y: -0.132637978, label: 6, color: '#4386df' },
  { x: 9.85751152, y: 3.395107269, label: 6, color: '#4386df' },
  { x: -11.37038326, y: -1.726801872, label: 5, color: '#b5787f' },
  { x: -5.306598663, y: 2.306275368, label: 3, color: '#35f870' },
  { x: -11.0443325, y: -1.715778351, label: 5, color: '#b5787f' },
  { x: -11.13873005, y: -1.814377308, label: 5, color: '#b5787f' },
  { x: -11.51649666, y: -2.032680511, label: 5, color: '#b5787f' },
  { x: 13.29027081, y: 1.059381008, label: 6, color: '#4386df' },
  { x: 11.00016403, y: 4.083358765, label: 6, color: '#4386df' },
  { x: -11.05545425, y: -1.565263748, label: 5, color: '#b5787f' },
  { x: -11.58694077, y: -1.836495399, label: 5, color: '#b5787f' },
  { x: -12.10797787, y: -1.005946636, label: 5, color: '#b5787f' },
  { x: 9.59545517, y: 3.966439247, label: 6, color: '#4386df' },
  { x: 15.42620277, y: -3.080223083, label: 6, color: '#4386df' },
  { x: -6.162910461, y: 1.056089401, label: 3, color: '#35f870' },
  { x: -11.15832138, y: -1.699671745, label: 5, color: '#b5787f' },
  { x: -11.94836235, y: -2.407265663, label: 5, color: '#b5787f' },
  { x: 10.99435711, y: 3.165493965, label: 6, color: '#4386df' },
  { x: 12.51748657, y: 1.307073593, label: 6, color: '#4386df' },
  { x: 9.586824417, y: 4.051100731, label: 6, color: '#4386df' },
  { x: -11.64578819, y: -1.817076683, label: 5, color: '#b5787f' },
  { x: 9.397711754, y: 6.799715996, label: 6, color: '#4386df' },
  { x: -12.01496124, y: -2.132147789, label: 5, color: '#b5787f' },
  { x: -11.30259895, y: -1.430263996, label: 5, color: '#b5787f' },
  { x: 9.582935333, y: 4.314874649, label: 6, color: '#4386df' },
  { x: -11.13903427, y: -2.017318726, label: 5, color: '#b5787f' },
  { x: 10.61310768, y: 2.67728281, label: 6, color: '#4386df' },
  { x: 14.21612549, y: -0.108733654, label: 6, color: '#4386df' },
  { x: 10.56925869, y: 2.808476925, label: 6, color: '#4386df' },
  { x: 9.497242928, y: 4.441991806, label: 6, color: '#4386df' },
  { x: 9.797473907, y: 3.818087578, label: 6, color: '#4386df' },
  { x: -11.12407684, y: -1.781446457, label: 5, color: '#b5787f' },
  { x: 14.47813034, y: -0.372674942, label: 6, color: '#4386df' },
  { x: 10.92446423, y: 2.588096619, label: 6, color: '#4386df' },
  { x: 11.63455105, y: 2.016319275, label: 6, color: '#4386df' },
  { x: 12.46099091, y: 1.309026718, label: 6, color: '#4386df' },
  { x: -5.975126266, y: 0.85024929, label: 3, color: '#35f870' },
  { x: 9.536388397, y: 7.18730545, label: 6, color: '#4386df' },
  { x: 12.86665535, y: 1.076877594, label: 6, color: '#4386df' },
  { x: -11.22766876, y: -1.70252037, label: 5, color: '#b5787f' },
  { x: 9.457809448, y: 5.919098854, label: 6, color: '#4386df' },
  { x: 9.317417145, y: 4.647613525, label: 6, color: '#4386df' },
  { x: 13.53542519, y: 0.631465912, label: 6, color: '#4386df' },
  { x: 9.14512825, y: 6.388181686, label: 6, color: '#4386df' },
  { x: 9.436328888, y: 5.925956726, label: 6, color: '#4386df' },
  { x: -11.3569603, y: -1.910995483, label: 5, color: '#b5787f' },
  { x: 15.43633461, y: -2.938528061, label: 6, color: '#4386df' },
  { x: 11.38025856, y: 2.209071636, label: 6, color: '#4386df' },
  { x: -11.22870255, y: -1.824625969, label: 5, color: '#b5787f' },
  { x: -11.53489304, y: -2.024689674, label: 5, color: '#b5787f' },
  { x: 13.43460846, y: -0.20026207, label: 6, color: '#4386df' },
  { x: 12.11881256, y: 1.637095451, label: 6, color: '#4386df' },
  { x: 9.439947128, y: 5.33430481, label: 6, color: '#4386df' },
  { x: 9.509840012, y: 4.734418869, label: 6, color: '#4386df' },
  { x: 13.35015488, y: 0.683021545, label: 6, color: '#4386df' },
  { x: -11.59804535, y: -2.204514503, label: 5, color: '#b5787f' },
  { x: 10.86650848, y: 3.986103058, label: 6, color: '#4386df' },
  { x: 9.4429245, y: 5.589118958, label: 6, color: '#4386df' },
  { x: 9.380785942, y: 5.634063721, label: 6, color: '#4386df' },
  { x: 13.13908768, y: 1.218463898, label: 6, color: '#4386df' },
  { x: 9.808242798, y: 3.72682476, label: 6, color: '#4386df' },
  { x: 9.3729105, y: 6.097760201, label: 6, color: '#4386df' },
  { x: -11.73895454, y: -1.961536407, label: 5, color: '#b5787f' },
  { x: -11.52779961, y: -2.439102173, label: 5, color: '#b5787f' },
  { x: -11.17997551, y: -1.88226223, label: 5, color: '#b5787f' },
  { x: 11.29559708, y: 2.265111923, label: 6, color: '#4386df' },
  { x: -11.53269863, y: -1.54474926, label: 5, color: '#b5787f' },
  { x: -12.14679718, y: -1.808568954, label: 5, color: '#b5787f' },
  { x: 9.273788452, y: 4.407300949, label: 6, color: '#4386df' },
  { x: 15.12743187, y: -4.988846779, label: 6, color: '#4386df' },
  { x: 10.15293503, y: 3.158891678, label: 6, color: '#4386df' },
  { x: -11.81603432, y: -1.762174606, label: 5, color: '#b5787f' },
  { x: 9.533720016, y: 4.307087898, label: 6, color: '#4386df' },
  { x: 9.529827118, y: 6.93448925, label: 6, color: '#4386df' },
  { x: -11.24413204, y: -1.621942997, label: 5, color: '#b5787f' },
  { x: 9.372886658, y: 5.57037735, label: 6, color: '#4386df' },
  { x: 12.6897068, y: 1.087284088, label: 6, color: '#4386df' },
  { x: 9.474908829, y: 6.658638, label: 6, color: '#4386df' },
  { x: 10.54811859, y: 2.776842117, label: 6, color: '#4386df' },
  { x: 9.356219292, y: 5.379805565, label: 6, color: '#4386df' },
  { x: 10.48428535, y: 2.837456703, label: 6, color: '#4386df' },
  { x: -11.22012997, y: -2.059006691, label: 5, color: '#b5787f' },
  { x: 9.595907211, y: 4.350357056, label: 6, color: '#4386df' },
  { x: 9.639375687, y: 7.221839905, label: 6, color: '#4386df' },
  { x: 9.460184097, y: 6.702927589, label: 6, color: '#4386df' },
  { x: 11.87988663, y: 1.921050072, label: 6, color: '#4386df' },
  { x: 12.11555481, y: 1.480382442, label: 6, color: '#4386df' },
  { x: 14.00758171, y: 0.113324642, label: 6, color: '#4386df' },
  { x: -11.48310947, y: -2.110025883, label: 5, color: '#b5787f' },
  { x: -11.12068462, y: -1.545947075, label: 5, color: '#b5787f' },
  { x: 9.591481209, y: 3.983224869, label: 6, color: '#4386df' },
  { x: 9.47705555, y: 4.531141281, label: 6, color: '#4386df' },
  { x: 9.9643116, y: 3.495840073, label: 6, color: '#4386df' },
  { x: 9.648452759, y: 7.577222824, label: 6, color: '#4386df' },
  { x: 11.11906624, y: 2.407987595, label: 6, color: '#4386df' },
  { x: -11.51418686, y: -1.802763939, label: 5, color: '#b5787f' },
  { x: -11.96661949, y: -1.742576599, label: 5, color: '#b5787f' },
  { x: -11.1546278, y: -1.997842789, label: 5, color: '#b5787f' },
  { x: -11.39162254, y: -2.252511978, label: 5, color: '#b5787f' },
  { x: 13.64064026, y: 0.545064926, label: 6, color: '#4386df' },
  { x: -11.76225662, y: -1.768088341, label: 5, color: '#b5787f' },
  { x: 9.577531815, y: 7.083877563, label: 6, color: '#4386df' },
  { x: 9.541118622, y: 4.185785294, label: 6, color: '#4386df' },
  { x: -11.56012344, y: -2.399266243, label: 5, color: '#b5787f' },
  { x: -11.4173975, y: -1.506160259, label: 5, color: '#b5787f' },
  { x: 15.13546944, y: -4.734333992, label: 6, color: '#4386df' },
  { x: 9.787014008, y: 3.656579018, label: 6, color: '#4386df' },
  { x: -11.2900753, y: -1.559352875, label: 5, color: '#b5787f' },
  { x: 9.41058445, y: 4.442354679, label: 6, color: '#4386df' },
  { x: -11.6530962, y: -2.319951534, label: 5, color: '#b5787f' },
  { x: 9.60059166, y: 4.108801842, label: 6, color: '#4386df' },
  { x: -11.90642738, y: -1.732887268, label: 5, color: '#b5787f' },
  { x: -12.11195374, y: -0.915125847, label: 5, color: '#b5787f' },
  { x: 12.02601624, y: 1.639205933, label: 6, color: '#4386df' },
  { x: 10.30189705, y: 3.064218521, label: 6, color: '#4386df' },
  { x: 9.372202873, y: 5.640681267, label: 6, color: '#4386df' },
  { x: 9.583981514, y: 7.930691719, label: 6, color: '#4386df' },
  { x: -11.7107563, y: -1.549508095, label: 5, color: '#b5787f' },
  { x: 13.7154007, y: 0.403590679, label: 6, color: '#4386df' },
  { x: 14.52423477, y: -0.413175583, label: 6, color: '#4386df' },
  { x: 11.62820625, y: 2.004964352, label: 6, color: '#4386df' },
  { x: 10.82035351, y: 2.614883423, label: 6, color: '#4386df' },
  { x: 10.92832375, y: 4.492501259, label: 6, color: '#4386df' },
  { x: 13.67402267, y: 0.649873734, label: 6, color: '#4386df' },
  { x: 10.61198044, y: 2.762712479, label: 6, color: '#4386df' },
  { x: -11.43896484, y: -1.43995285, label: 5, color: '#b5787f' },
  { x: -11.36370468, y: -2.002283096, label: 5, color: '#b5787f' },
  { x: 9.517343521, y: 6.901327133, label: 6, color: '#4386df' },
  { x: 13.6425209, y: 0.663649559, label: 6, color: '#4386df' },
  { x: 11.5861311, y: 2.071994781, label: 6, color: '#4386df' },
  { x: 11.93099213, y: 1.749074936, label: 6, color: '#4386df' },
  { x: 9.46583271, y: 6.525248528, label: 6, color: '#4386df' },
  { x: 14.7936058, y: -6.64037323, label: 6, color: '#4386df' },
  { x: 9.629232407, y: 7.179437637, label: 6, color: '#4386df' },
  { x: 9.601625443, y: 3.913224697, label: 6, color: '#4386df' },
  { x: -11.17758179, y: -1.847085953, label: 5, color: '#b5787f' },
  { x: -11.34355736, y: -1.749527931, label: 5, color: '#b5787f' },
  { x: -11.20793152, y: -1.814208031, label: 5, color: '#b5787f' },
  { x: 12.83856773, y: 1.070487976, label: 6, color: '#4386df' },
  { x: 9.871513367, y: 3.345935822, label: 6, color: '#4386df' },
  { x: -11.64679718, y: -2.252925873, label: 5, color: '#b5787f' },
  { x: 10.88379002, y: 4.920688629, label: 6, color: '#4386df' },
  { x: 9.654838562, y: 3.991542816, label: 6, color: '#4386df' },
  { x: 15.4808712, y: -2.858140945, label: 6, color: '#4386df' },
  { x: 10.71328163, y: 2.717439651, label: 6, color: '#4386df' },
  { x: -11.3391819, y: -1.985451698, label: 5, color: '#b5787f' },
  { x: 9.053796768, y: 4.176629066, label: 6, color: '#4386df' },
  { x: 10.65968037, y: 2.708362579, label: 6, color: '#4386df' },
  { x: -11.1794672, y: -1.74473381, label: 5, color: '#b5787f' },
  { x: 8.98998642, y: 5.419548035, label: 6, color: '#4386df' },
  { x: 9.197908401, y: 4.879908562, label: 6, color: '#4386df' },
  { x: 9.540410995, y: 7.99949646, label: 6, color: '#4386df' },
  { x: 10.08781815, y: 3.281467438, label: 6, color: '#4386df' },
  { x: -11.97469139, y: -1.935067177, label: 5, color: '#b5787f' },
  { x: 8.806708336, y: 3.8869524, label: 6, color: '#4386df' },
  { x: 11.16802788, y: 2.391598225, label: 6, color: '#4386df' },
  { x: 9.337461472, y: 5.689724922, label: 6, color: '#4386df' },
  { x: 12.74872589, y: 1.217587471, label: 6, color: '#4386df' },
  { x: -11.57373428, y: -1.935316086, label: 5, color: '#b5787f' },
  { x: -12.22585106, y: -1.894762993, label: 5, color: '#b5787f' },
  { x: 15.38832474, y: -2.269503593, label: 6, color: '#4386df' },
  { x: -11.55726624, y: -1.791944027, label: 5, color: '#b5787f' },
  { x: 9.512001038, y: 6.146980286, label: 6, color: '#4386df' },
  { x: 9.60094738, y: 4.631931305, label: 6, color: '#4386df' },
  { x: -11.31698227, y: -1.650251389, label: 5, color: '#b5787f' },
  { x: 9.353363037, y: 6.760410309, label: 6, color: '#4386df' },
  { x: 9.363044739, y: 5.584002495, label: 6, color: '#4386df' },
  { x: 9.562572479, y: 4.467796326, label: 6, color: '#4386df' },
  { x: 10.14845467, y: 2.753351212, label: 6, color: '#4386df' },
  { x: 10.93589878, y: 2.519045353, label: 6, color: '#4386df' },
  { x: 9.628856659, y: 3.974527359, label: 6, color: '#4386df' },
  { x: 13.99181175, y: 0.301368713, label: 6, color: '#4386df' },
  { x: -11.76548767, y: -1.584682465, label: 5, color: '#b5787f' },
  { x: -5.66994381, y: 1.561138153, label: 3, color: '#35f870' },
  { x: 13.76908493, y: 0.050675392, label: 6, color: '#4386df' },
  { x: 11.91575527, y: 1.780525684, label: 6, color: '#4386df' },
  { x: 9.615179062, y: 4.258609772, label: 6, color: '#4386df' },
  { x: 14.30651855, y: -0.06201458, label: 6, color: '#4386df' },
  { x: -11.78676796, y: -1.853016853, label: 5, color: '#b5787f' },
  { x: 10.21722412, y: 3.036910057, label: 6, color: '#4386df' },
  { x: 12.93287659, y: 1.086378098, label: 6, color: '#4386df' },
  { x: 9.529163361, y: 6.963540077, label: 6, color: '#4386df' },
  { x: 10.55744457, y: 3.68248558, label: 6, color: '#4386df' },
  { x: 9.687748909, y: 3.708335876, label: 6, color: '#4386df' },
  { x: 10.48719215, y: 2.84122467, label: 6, color: '#4386df' },
  { x: 9.433377266, y: 4.883090973, label: 6, color: '#4386df' },
  { x: -11.12371445, y: -1.593069077, label: 5, color: '#b5787f' },
  { x: 9.286752701, y: 5.305025101, label: 6, color: '#4386df' },
  { x: 9.324012756, y: 5.527009964, label: 6, color: '#4386df' },
  { x: -11.20528412, y: -1.904244423, label: 5, color: '#b5787f' },
  { x: 11.5834446, y: 2.005041122, label: 6, color: '#4386df' },
  { x: -12.87157059, y: -0.629534721, label: 5, color: '#b5787f' },
  { x: 8.7930336, y: 6.059322357, label: 6, color: '#4386df' },
  { x: -13.68489838, y: 0.276727676, label: 5, color: '#b5787f' },
  { x: 9.436079025, y: 5.092340469, label: 6, color: '#4386df' },
  { x: 9.356861115, y: 5.580947876, label: 6, color: '#4386df' },
  { x: 10.69746971, y: 2.726881981, label: 6, color: '#4386df' },
  { x: -12.26662731, y: -1.453542233, label: 5, color: '#b5787f' },
  { x: -11.84700966, y: -1.302155495, label: 5, color: '#b5787f' },
  { x: -11.45218372, y: -1.631168365, label: 5, color: '#b5787f' },
  { x: -11.68561172, y: -2.269012451, label: 5, color: '#b5787f' },
  { x: -7.654051781, y: 0.601759434, label: 3, color: '#35f870' },
  { x: 14.76305962, y: -0.732400417, label: 6, color: '#4386df' },
  { x: -11.11651421, y: -1.705263138, label: 5, color: '#b5787f' },
  { x: 14.83294678, y: -1.005569458, label: 6, color: '#4386df' },
  { x: 10.09647942, y: 3.212517738, label: 6, color: '#4386df' },
  { x: -11.46297836, y: -1.962481499, label: 5, color: '#b5787f' },
  { x: 9.496306419, y: 5.535297394, label: 6, color: '#4386df' },
  { x: -5.887138367, y: 0.666464806, label: 3, color: '#35f870' },
  { x: -11.61162186, y: -1.518751144, label: 5, color: '#b5787f' },
  { x: -11.13084793, y: -1.845965385, label: 5, color: '#b5787f' },
  { x: 11.81651211, y: 1.71980381, label: 6, color: '#4386df' },
  { x: 8.981153488, y: 5.254365921, label: 6, color: '#4386df' },
  { x: -11.33516407, y: -2.122881889, label: 5, color: '#b5787f' },
  { x: 12.59297943, y: 0.771018982, label: 6, color: '#4386df' },
  { x: -11.30006027, y: -2.074169159, label: 5, color: '#b5787f' },
  { x: -11.02172375, y: -1.613435745, label: 5, color: '#b5787f' },
  { x: -11.97368526, y: -2.187162399, label: 5, color: '#b5787f' },
  { x: 9.613698006, y: 4.0763731, label: 6, color: '#4386df' },
  { x: 9.528812408, y: 4.575233459, label: 6, color: '#4386df' },
  { x: -11.8154583, y: -1.705615997, label: 5, color: '#b5787f' },
  { x: 12.86950493, y: 1.014492035, label: 6, color: '#4386df' },
  { x: 9.917444229, y: 3.445893288, label: 6, color: '#4386df' },
  { x: 9.405899048, y: 6.82465744, label: 6, color: '#4386df' },
  { x: -11.59770584, y: -1.940734863, label: 5, color: '#b5787f' },
  { x: -11.60163689, y: -1.68637085, label: 5, color: '#b5787f' },
  { x: 11.47585869, y: 2.087666512, label: 6, color: '#4386df' },
  { x: 15.15119553, y: -1.440755844, label: 6, color: '#4386df' },
  { x: 10.5132885, y: 2.847317219, label: 6, color: '#4386df' },
  { x: 9.595082283, y: 4.177108765, label: 6, color: '#4386df' },
  { x: -12.72361374, y: -0.803601265, label: 5, color: '#b5787f' },
  { x: 14.3335743, y: -0.182452202, label: 6, color: '#4386df' },
  { x: 9.070306778, y: 5.550672531, label: 6, color: '#4386df' },
  { x: 9.514863968, y: 4.457343102, label: 6, color: '#4386df' },
  { x: -11.08736801, y: -1.773014069, label: 5, color: '#b5787f' },
  { x: 9.46423912, y: 4.794232368, label: 6, color: '#4386df' },
  { x: 10.45295715, y: 2.830684662, label: 6, color: '#4386df' },
  { x: 12.35633087, y: 1.351653099, label: 6, color: '#4386df' },
  { x: 9.538459778, y: 4.321185112, label: 6, color: '#4386df' },
  { x: 9.575014114, y: 7.291690826, label: 6, color: '#4386df' },
  { x: 15.22191238, y: -3.882866859, label: 6, color: '#4386df' },
  { x: 9.303556442, y: 5.419158936, label: 6, color: '#4386df' },
  { x: -11.91716766, y: -1.229852676, label: 5, color: '#b5787f' },
  { x: 9.542934418, y: 5.081168175, label: 6, color: '#4386df' },
  { x: -11.25191689, y: -1.800707817, label: 5, color: '#b5787f' },
  { x: 9.248670578, y: 5.500589371, label: 6, color: '#4386df' },
  { x: -11.10796165, y: -1.609460831, label: 5, color: '#b5787f' },
  { x: -11.07927895, y: -1.658212662, label: 5, color: '#b5787f' },
  { x: 9.718095779, y: 3.896495819, label: 6, color: '#4386df' },
  { x: 9.534942627, y: 4.145939827, label: 6, color: '#4386df' },
  { x: 13.35225868, y: 0.952512741, label: 6, color: '#4386df' },
  { x: 14.26580429, y: -0.165468216, label: 6, color: '#4386df' },
  { x: -11.08214188, y: -1.73151207, label: 5, color: '#b5787f' },
  { x: -5.531193733, y: 0.887466431, label: 3, color: '#35f870' },
  { x: 9.510811806, y: 6.141694069, label: 6, color: '#4386df' },
  { x: 13.31175613, y: 1.088600636, label: 6, color: '#4386df' },
  { x: 9.459812164, y: 6.300113678, label: 6, color: '#4386df' },
  { x: 12.92589664, y: 1.188123226, label: 6, color: '#4386df' },
  { x: 9.579406738, y: 6.843974113, label: 6, color: '#4386df' },
  { x: -11.12314987, y: -1.902667999, label: 5, color: '#b5787f' },
  { x: 9.400021553, y: 4.85107708, label: 6, color: '#4386df' },
  { x: 9.468133926, y: 6.19929409, label: 6, color: '#4386df' },
  { x: -11.66348267, y: -1.76244545, label: 5, color: '#b5787f' },
  { x: -11.52401257, y: -2.327692032, label: 5, color: '#b5787f' },
  { x: 13.78511429, y: 0.539827347, label: 6, color: '#4386df' },
  { x: 13.48351288, y: 0.560133934, label: 6, color: '#4386df' },
  { x: 9.771291733, y: 3.850522995, label: 6, color: '#4386df' },
]
