// Classes used by Leaflet to position controls
import { PositionsKeys } from '../types/MapElementTypes'
import { colorObjType } from '../components/mapElements/ColorPickerLayer'
import tinycolor from 'tinycolor2'

export const POSITION_CLASSES: PositionsKeys = {
  bottomleft: 'leaflet-bottom leaflet-left',
  bottomright: 'leaflet-bottom leaflet-right',
  topleft: 'leaflet-top leaflet-left',
  topright: 'leaflet-top leaflet-right',
}

export const getScaleMap = (map: L.Map): number => {
  if (!map) {
    throw new Error('map is not defined')
  }
  const maxNativeZoom = map.getMaxZoom()
  const currentZoom = map.getZoom()
  return Math.pow(2, maxNativeZoom - currentZoom)
}

export const appColorCodes = {
  blackSecondary: '#333333',
}

export function colorToRgba(color: string): colorObjType {
  // Default color is white
  let hex = 'ffffff'
  if (color.startsWith('#')) {
    hex = color.slice(1)
  } else {
    hex = Number(color).toString(16)
  }
  hex = hex.padStart(6, '0')
  const r = parseInt(hex.slice(0, 2), 16)
  const g = parseInt(hex.slice(2, 4), 16)
  const b = parseInt(hex.slice(4, 6), 16)
  const a = 1 // Currently, API does not support Alpha channel
  return { r, g, b, a }
}

export function rgbaToHex(r: number, g: number, b: number, a = 1) {
  const rgbaColor = tinycolor({ r, g, b, a }).toRgbString()
  return tinycolor(rgbaColor).toHexString()
}

export const layersZIndexMap = new Map<string, number>() // add name and z-index in order to display layers
layersZIndexMap.set('base', 1)
layersZIndexMap.set('Tissue', 2)
layersZIndexMap.set('Cell', 3)
