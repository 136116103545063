import { Box, SxProps, Theme, Typography } from '@mui/material'
import TextFields from '@mui/material/TextField'

import theme from '../../theme'

type Props = {
  value: string
  title: string
  name: string
  id: string
}

export default function TextField({
  value,
  title,
  name,
  id,
}: Props): JSX.Element {
  return (
    <Box sx={textFieldBoxStyle}>
      <Typography sx={titleLabelStyle}>{title}</Typography>
      <TextFields
        sx={underCirculeStyle}
        fullWidth
        name={name}
        id={id}
        defaultValue={value}
      />
    </Box>
  )
}

const textFieldBoxStyle: SxProps<Theme> = {
  marginBottom: '14px',
}

const titleLabelStyle: SxProps<Theme> = {
  fontSize: '13px',
  marginBottom: '8px',
}

const underCirculeStyle: SxProps<Theme> = {
  width: '260px',
  height: '36px',
  input: {
    padding: '10px 6px',
    height: '16px',
    backgroundColor: theme.palette.bgSecondary.main,
  },
}
