import { TextField, IconButton } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import theme from '../../theme'
import { useContext } from 'react'
import CommonValue from '../../contexts/CommonValue'
import useTranslation from '../../hooks/useTranslation'

type SearchBoxProps = {
  value: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  onSearch?: () => void
}

export default function SearchTextInput({
  value,
  onChange,
  onSearch,
}: SearchBoxProps): JSX.Element {
  const { globalLanguage } = useContext(CommonValue)

  const t = useTranslation(globalLanguage)

  function handleKeyPress(event: React.KeyboardEvent<HTMLInputElement>): void {
    if (event.key === 'Enter') {
      onSearch && onSearch()
    }
  }
  return (
    <TextField
      id='outlined-basic'
      variant='outlined'
      value={value}
      onChange={onChange}
      placeholder={t.search}
      InputProps={{
        sx: {
          width: '260px',
          height: '36px',
          backgroundColor: theme.palette.bgSecondary.main,
        },
        endAdornment: (
          <IconButton aria-label='search' onClick={onSearch} size='small'>
            <SearchIcon />
          </IconButton>
        ),
        onKeyPress: handleKeyPress,
      }}
    />
  )
}
