import { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import CommonValue from '../contexts/CommonValue'
import useTranslation from './useTranslation'
import { ApiClient } from '../lib/ApiClient'
import { AxiosProgressEvent } from 'axios'
import { Point } from 'leaflet'
import {
  createRequestHeaders,
  getPercentCompleted,
  handleRequestError,
} from './utils/ComonUtility'
import { ROIData } from '../components/tools/selectionTool/types/roiTypes'
import { constants } from '../components/tools/selectionTool/roiConstants'

export const usePathologicalImageRegion = () => {
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [isLoading, setIsLoading] = useState(false)
  const { globalLanguage } = useContext(CommonValue)

  const tn = useTranslation(globalLanguage)
  const navigate = useNavigate()

  const regionPathoLogicalImage = async (
    id: number,
    polygon: Point[][],
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setUploadingPercent: (percent: number) => void = () => {},
    abortController?: AbortController,
  ) => {
    setIsLoading(true)
    setErrorMessage('')

    // //NOTE: 確認用 現在約4倍の領域で扱う必要があるため、その補正処理。API正式対応時には削除します。
    //adjustInputBounds();

    const url = '/pathological_images/region'

    const listPolygon = polygon.map((poly) => {
      return `[${poly.map((p) => `[${p.x},${p.y}]`).join(',')}]`
    })

    const response = await ApiClient.post(
      url,
      {
        id: id,
        polygon: listPolygon,
      },
      {
        headers: createRequestHeaders(),
        onUploadProgress: onUloadProgress,
        signal: abortController?.signal,
      },
    ).catch((error) => {
      if (!handleRequestError(error, navigate)) {
        setErrorMessage(tn.message.UPLOAD_IMAGE_ERROR)
      }
    })
    // //NOTE: 確認用 現在約4倍の領域で扱う必要があるため、その補正処理。API正式対応時には削除します。
    adjustOutput(response?.data.results)

    setIsLoading(false)
    return response?.data.results as ROIData[]

    function onUloadProgress(progressEvent: AxiosProgressEvent) {
      const percentCompleted = getPercentCompleted(progressEvent)
      setUploadingPercent(percentCompleted)
    }

    // //NOTE: 確認用 現在約4倍の領域で扱う必要があるため、その補正処理。API正式対応時には削除します。
    // function adjustInputBounds() {
    //   if (!constants.debug?.adjustScale) return;
    //   min.x *= constants.debug.adjustScale;
    //   min.y *= constants.debug.adjustScale;
    //   max.x *= constants.debug.adjustScale;
    //   max.y *= constants.debug.adjustScale;
    // }

    //NOTE: 確認用 現在約4倍の領域で扱う必要があるため、その補正処理。API正式対応時には削除します。
    function adjustOutput(roi: ROIData[] | undefined) {
      if (!constants.debug?.adjustScale || !roi) return

      roi.forEach((r) => {
        Object.keys(r).forEach((key) => {
          r[key].centroids = r[key].centroids.map((data) => [
            data[0] / constants.debug.adjustScale,
            data[1] / constants.debug.adjustScale,
          ])
        })
      })
    }
  }

  return {
    errorMessage,
    isLoading,
    regionPathoLogicalImage,
  }
}
