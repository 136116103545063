import { useEffect, useRef, useState } from 'react'
import { SelectionToolType } from './selectionToolType'
import { registerRectangularSelector } from './selectors/registerRectangularSelector'
import { useMapEvents } from 'react-leaflet'
import { Bounds, LatLng, LeafletEvent, Point } from 'leaflet'
import { Ratio } from '../../../types/Ratio'
import { usePathologicalImageRegion } from '../../../hooks/usePathoLogicalImageRegion'
import { renderROI } from './roi'
import { registerFreehandSelector } from './selectors/registerFreehandSelector'
import { useRoiInteracts } from '../../../contexts/RoiInteracts'

export const useSelectionTool = (
  id: number,
  scaleRatios: Ratio[],
  handleSetModal: (value: boolean) => void,
) => {
  const [tool, setTool] = useState(SelectionToolType.Cursor)
  const map = useMapEvents({})
  const disposer = useRef(() => {})
  const adornerDisposer = useRef<(() => void) | null>(null)
  const { regionPathoLogicalImage } = usePathologicalImageRegion()
  const abortControllerRef = useRef(new AbortController())
  const {
    handleSetRoiData,
    rois,
    resetRois,
    handleSetRoiLayer,
    handleSetRoiAnalyzed,
    handleSetExclusionZones,
    handleSetExclusionZonesAnalyzed,
    exclusionZones,
  } = useRoiInteracts()

  useEffect(() => {
    map.addEventListener('selectionstart', onSelectionStart)
    map.addEventListener('selectionend', onSelectionEnd)
    map.addEventListener('excludeselectionstart', onSelectionStart)
    map.addEventListener('excludeselectionend', onExcludeSelectionEnd)

    return () => {
      disposer.current()
      disposeAdorner()

      map.removeEventListener('selectionstart', onSelectionStart)
      map.removeEventListener('selectionend', onSelectionEnd)
      map.removeEventListener('excludeselectionstart', onSelectionStart)
      map.removeEventListener('excludeselectionend', onExcludeSelectionEnd)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, scaleRatios])

  function selectTool(value: SelectionToolType, isExlude = false): void {
    disposer.current()
    disposer.current = switchTool(value)

    setTool(value)
    return

    function switchTool(value: SelectionToolType) {
      switch (value) {
        case SelectionToolType.Rectanguler:
          return registerRectangularSelector(map, scaleRatios, isExlude)
        case SelectionToolType.Lasso:
          return registerFreehandSelector(map, scaleRatios)
        case SelectionToolType.Cursor:
        default:
          return () => null
      }
    }
  }

  function disposeAdorner() {
    adornerDisposer.current && adornerDisposer.current()
    adornerDisposer.current = null

    abortControllerRef.current?.abort()
    abortControllerRef.current = new AbortController()
  }

  function onSelectionStart() {
    disposeAdorner()
  }

  const prepareRoiData = (evt: SelectionEndEvent) => {
    const selectedArea = evt.squareLocation // fix when is not square selection
    let square = [] as number[][]
    let cords = [] as LatLng[]
    // @ts-ignore
    if (selectedArea.end.length === 0 && Array.isArray(selectedArea.start)) {
      cords = selectedArea.start as LatLng[]
    } else {
      square = [
        [selectedArea.start.lat, selectedArea.start.lng],
        [selectedArea.end.lat, selectedArea.end.lng],
      ]
    }

    return {
      points: evt.points,
      bounds: evt.bounds,
      squareCords: square,
      freeHandCords: cords,
    }
  }

  async function onSelectionEnd(event: LeafletEvent) {
    const evt = event as SelectionEndEvent
    selectTool(SelectionToolType.Cursor)
    const data = prepareRoiData(evt)
    handleSetModal(true)
    handleSetRoiData(data)
  }

  async function onExcludeSelectionEnd(event: LeafletEvent) {
    const evt = event as SelectionEndEvent
    selectTool(SelectionToolType.Cursor)
    const data = prepareRoiData(evt)
    handleSetExclusionZones(data)
  }

  const handleRegionImage = async (isExcludeRegion = false) => {
    let points = null
    const refControl = abortControllerRef.current
    if (isExcludeRegion) {
      if (exclusionZones.length <= 0) return
      points = exclusionZones.map((e) => e.points)
    } else {
      points = rois.map((e) => e.points)
    }

    const roisResponse = await regionPathoLogicalImage(
      id,
      points,
      () => null,
      refControl,
    )

    if (!roisResponse) {
      resetRois()
      return
    }

    const roisArr = roisResponse.map((roi, index) => {
      if (isExcludeRegion) {
        return { bounds: exclusionZones[index].bounds, data: roi }
      } else {
        const { layer } = renderROI(map, roi, scaleRatios[map.getZoom()])
        handleSetRoiLayer(layer, index)
        return { bounds: rois[index].bounds, data: roi }
      }
    })

    if (isExcludeRegion) {
      handleSetExclusionZonesAnalyzed(roisArr)
    } else {
      handleSetRoiAnalyzed(roisArr)
    }
  }

  const resetRoisOnMap = () => {
    disposeAdorner()
  }

  return {
    tool,
    selectTool,
    cleanup: resetRoisOnMap,
    handleRegionImage,
  }
}

type SelectionEndEvent = LeafletEvent & {
  bounds: Bounds
  points: Point[]
  squareLocation: {
    start: LatLng
    end: LatLng
  }
}
