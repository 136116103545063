import { Button, SxProps, Theme } from '@mui/material'

import theme from '../../theme'

type Props = {
  onClick: () => void
  text: string
}

export default function MiddleOutlineButton({
  onClick,
  text,
}: Props): JSX.Element {
  return (
    <Button variant='outlined' sx={buttonStyle} onClick={onClick}>
      {text}
    </Button>
  )
}

const buttonStyle: SxProps<Theme> = {
  textTransform: 'none',
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  width: '110px',
  height: '45px',
  borderColor: theme.palette.primary.main,
}
