import React, { createContext, ReactElement, useEffect, useState } from 'react'
import { colorObjType } from '../components/mapElements/ColorPickerLayer'
import { colorToRgba } from '../constants/utils'
import { rgbaToHex } from '../constants/utils'
import useLayerSaveState from '../hooks/useLayerSaveState'

type AddLayerItem = {
  layerType: string
  layerId: number
  layerName: string
  color: string
}

type LayersColorsContextType = {
  layersColors: layer[]
  setLayersColors: (layerName: string, color: colorObjType) => void
  handleLoadLayers: (layerList: AddLayerItem[]) => void
  handleSaveLayerColor: (layerName: string, color: colorObjType) => void
  handleSetSegmentationOpacity: (segmentation: string, opacity: number) => void
}

type layer = {
  id: string
  layerName: string
  color: colorObjType
  layerId: number
  layerType: string
}

type segmentationOpacityType = {
  [key: string]: number
}

type Props = {
  children: ReactElement
}

const LayersColorsContext = createContext<LayersColorsContextType | undefined>(
  undefined,
)

export const LayersColors = ({ children }: Props) => {
  const [layersColors, setLayersColors] = useState<
    LayersColorsContextType['layersColors']
  >([])
  const [svgList, setSvgList] = useState<ReactElement[] | []>([])
  const { postLayerColorByLayerId } = useLayerSaveState()
  const [segmentationOpacity, setSegmentationOpacity] =
    useState<segmentationOpacityType>({})

  const onPostLayerColorSuccess = (layerName: string, color: colorObjType) => {
    const newLayersColors = layersColors.map((layerColor) => {
      if (layerColor.layerName === layerName) {
        return {
          ...layerColor,
          color,
        }
      } else {
        return layerColor
      }
    })
    setLayersColors(newLayersColors)
  }

  const postLayerColors = async (layerName: string, color: colorObjType) => {
    const { r, g, b, a } = color
    const hexColor = rgbaToHex(r, g, b, a)
    const layerColor = layersColors.find(
      (layer) => layer.layerName === layerName,
    )
    if (layerColor) {
      await postLayerColorByLayerId(
        layerColor.layerType,
        hexColor,
        layerColor.layerId,
        () => onPostLayerColorSuccess(layerName, color),
      )
    }
  }

  const handleLoadLayers = (layerList: AddLayerItem[]) => {
    const newLayersColors = layerList.map((layerItem) => {
      const colorFromServer = colorToRgba(layerItem.color)
      return {
        id: layerItem.layerName.split(' ')[0],
        layerName: layerItem.layerName,
        color: colorFromServer,
        layerId: layerItem.layerId,
        layerType: layerItem.layerType,
      }
    })
    setLayersColors(newLayersColors)
  }

  const handleSetLayerColor = (layerName: string, color: colorObjType) => {
    const newLayersColors = layersColors.map((layerColor) => {
      if (layerColor.layerName === layerName) {
        return {
          ...layerColor,
          color,
        }
      } else {
        return layerColor
      }
    })
    setLayersColors(newLayersColors)
  }

  const handleSetSegmentationOpacity = (
    segmentation: string,
    opacity: number,
  ) => {
    setSegmentationOpacity((prev) => {
      return {
        ...prev,
        [segmentation]: opacity / 100,
      }
    })
  }

  useEffect(() => {
    const svgList = layersColors.map((layer) => {
      const segmentOpacity = segmentationOpacity[layer.layerType]
      const { color, id } = layer
      const red = color.r
      const green = color.g
      const blue = color.b
      const colorMatrix = `${(red / 255).toFixed(2)} 0 0 0 0
                                         ${(green / 255).toFixed(2)} 0 0 0 0
                                         ${(blue / 255).toFixed(2)} 0 0 0 0
                                         0 0 0 ${segmentOpacity} 0`

      const colorClass = document.createElement('style')
      colorClass.type = 'text/css'
      colorClass.innerHTML = `.${id} { filter: url(#${id}); }`
      document.getElementsByTagName('head')[0].appendChild(colorClass)

      return (
        <svg key={id}>
          <defs>
            <filter id={id} x='0' y='0'>
              <feColorMatrix type='matrix' values={colorMatrix} />
            </filter>
          </defs>
        </svg>
      )
    })
    setSvgList(svgList)
  }, [layersColors, segmentationOpacity])

  useEffect(() => {
    if (layersColors.length === 0) return
    const segmentations = [] as string[]
    for (const layer of layersColors) {
      const { layerType } = layer
      if (segmentations.includes(layerType)) continue
      segmentations.push(layerType)
    }
    segmentations.forEach((segmentation) => {
      if (Object.keys(segmentationOpacity).includes(segmentation)) return
      setSegmentationOpacity((prev) => {
        return {
          ...prev,
          [segmentation]: 1,
        }
      })
    })
  }, [layersColors])

  return (
    <LayersColorsContext.Provider
      value={{
        layersColors,
        setLayersColors: handleSetLayerColor,
        handleLoadLayers,
        handleSaveLayerColor: postLayerColors,
        handleSetSegmentationOpacity,
      }}
    >
      {children}
      {svgList}
    </LayersColorsContext.Provider>
  )
}

export const useLayersColors = () => {
  const context = React.useContext(LayersColorsContext)
  if (context === undefined) {
    throw new Error(
      'useLayersColors must be used within a LayersColorsProvider',
    )
  }
  return context
}
